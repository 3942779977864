import React, { useState } from 'react';
import { Tabs, Tooltip, Typography } from 'antd';
import { connect } from 'react-redux';
import { CaseService } from '../../../../../hooks/case/cases';
import { CaseStatus } from '../../../../../enums/case';

import { isRoleEnough } from '../../../../../utils/auth';
import TabContent from './TabContent';
import { moduleName as authModuleName, User } from '../../../../../store/ducks/auth';
import { RootState } from '../../../../../store/reducers';

interface INotificationsReceivers {
  caseServices: CaseService[];
  caseStatus?: CaseStatus;
  view: 'doctor' | 'labStaff';
  user: User | null;
}

const getTooltipTitle = (caseService: CaseService, user: User | null): string | null => {
  if ((user?.role === 'lab manager' || user?.role === 'lab staff')
    && caseService.labService?.lab.id !== user?.labStaff?.lab?.id) {
    return 'You have no access to edit this service!';
  }
  if (!caseService.labStaff) return 'Service should have assigned staff!';

  return null;
};

const LabNotificationsReceivers: React.FC<INotificationsReceivers> = ({
  caseServices, caseStatus, view, user,
}) => {
  const [activeKey, setActiveKey] = useState<string | undefined>('1');

  const handleTabsChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <div style={{ padding: '0 16px', position: 'relative' }}>

      <Tabs
        key="labServicesTabs"
        activeKey={activeKey}
        onChange={handleTabsChange}
        items={caseServices.map((caseService) => ({
          label: (
            <Tooltip title={getTooltipTitle(caseService, user)}>
              {caseService.labService?.service.name || 'Service'}
            </Tooltip>
          ),
          key: caseService.id,
          children: (
            <TabContent
              caseServiceId={caseService.id}
              view={view}
              caseStatus={caseStatus}
              // Disable form and buttons in tab content
              disabled={isRoleEnough('lab manager')
                ? false : (caseService.labStaff?.id !== user?.labStaff?.id && user?.role === 'lab staff')
                || caseService?.labService?.lab?.id !== user?.labStaff?.lab?.id}
            />
          ),
          disabled: !caseService.labStaff
          || isRoleEnough('admin')
            ? !caseService.labStaff : user?.labStaff?.lab.id !== caseService.labService?.lab.id,
        }))}
      />
      {activeKey === '1' && (
        <Typography.Title level={5}>
          Start by selecting tab with service
        </Typography.Title>
      )}
    </div>
  );
};

LabNotificationsReceivers.defaultProps = {
  caseStatus: undefined,
};

const mapStateToProps = (state: RootState) => ({
  user: state[authModuleName].user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LabNotificationsReceivers);
