import dayjs from 'dayjs';
import { User } from './users';
import { JsonResult, PagingDataResponse } from '../types';
import { DefaultFetchError, FetchGet, FetchGetId, useFetchGet, useFetchGetId } from './fetch';

export interface Log {
  id: number;
  user: User;
  event: string;
  action: string;
  entity: string;
  createdAt: string;
  updatedAt: string;
  entityId?: string | number;
  data?: JsonResult;
  description?: string;
}

export type EntityType = 'auth' | 'users' | 'loggers' | 'practices' | 'doctors' | 'labs' | 'lab-staff' | 'cases';

export interface LoggersGetParams {
  page?: number;
  user?: string;
  take?: number;
  event?: number;
  entity: EntityType;
  orderBy?: 'ASC' | 'DESC';
  entityId?: string;
  isDoctor?: boolean;
  orderByColumn?: 'id' | 'event' | 'entity' | 'created' | 'updated';
}

export interface TableLogRow extends Log {
  key: string;
}

interface LogsTableData {
  logs: TableLogRow[];
  total: number;
}

// eslint-disable-next-line max-len
export const useTableLogRow = (): FetchGet<PagingDataResponse<Log>, LoggersGetParams, DefaultFetchError, LogsTableData> => (
  useLoggersGet((data: PagingDataResponse<Log>): LogsTableData => ({
    logs: data.data.map((log: Log): TableLogRow => ({
      key: `${log.id}`,
      ...log,
    })),
    total: data.meta.itemCount,
  }))
);

export function useLoggersGet<D = PagingDataResponse<Log>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, LoggersGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LoggersGetParams, DD>(
    'loggers',
    { decorateData, autoStart: false },
  );
}

export interface LogId<D> extends Log {
  after?: D;
  before?: D;
}

export const useLogId = <D = JsonResult>(): FetchGetId<LogId<D>> => (
  useFetchGetId('loggers', '', { autoStart: false, startStateLoading: false }));

export interface LogItem {
  date?: Date;
  pathname: string;
  action: string;
  element: string;
}

export const logUserActivity = (action: LogItem) => {
  const date = dayjs();
  const logsList: LogItem[] = localStorage.getItem('logs') ? JSON.parse(localStorage.getItem('logs') || '') || [] : [];

  localStorage.setItem('logs', JSON.stringify([...logsList, { date, ...action }]));
};

export const sendUserActivityLog = () => {
  // eslint-disable-next-line
  console.log('send');

  localStorage.setItem('logs', '');
};
