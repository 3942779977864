import React from 'react';
import Layout from '../../components/Layout/Auth';
import Content from '../../components/Pages/NewPassword';
import { displayName } from '../../config';

const NewPassword = (): React.JSX.Element => {
  document.title = `${displayName}: New password`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default NewPassword;
