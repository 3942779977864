import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { App } from 'antd';
import { Action } from '../../../../store';
import { moduleName as authModuleName, User } from '../../../../store/ducks/auth';
import { Error } from '../../../../store/ducks/common';
import { moduleName as labsModuleName, updateLab as actionLabUpdate, UpdateLab } from '../../../../store/ducks/lab';
import { RootState } from '../../../../store/reducers';
import Services from '../../Labs/Services';

interface LabServices {
  loading: boolean;
  loadingRefresh: boolean;
  error: Error | null;
  labUpdate: (payload: UpdateLab) => Action;
  user: User | null;
}

const LabServices = (props: LabServices) => {
  const { message } = App.useApp();
  const { loading, loadingRefresh, error, labUpdate, user } = props;
  const { id: paramsLabId = '' } = useParams<{ id: string; }>();
  const navigate = useNavigate();
  const requestLabId = paramsLabId || user?.labStaff?.lab?.id;

  useEffect(() => {
    if (error?.message) {
      message.error(error.message, 5);
    }
  }, [error]);

  const handleClose = () => {
    if (paramsLabId) {
      navigate(`/labs/${paramsLabId}`);
    } else {
      navigate('/staff');
    }
  };

  const handleSubmit = (body: UpdateLab) => {
    labUpdate({ ...body, id: requestLabId });
  };

  return (
    <div>
      <Services
        isOpen
        close={handleClose}
        handleSubmit={handleSubmit}
        labId={requestLabId}
        loading={loading || loadingRefresh}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[labsModuleName].loading,
  error: state[labsModuleName].error,
  user: state[authModuleName].user,
  loadingRefresh: state[authModuleName].loadingRefresh,
});

const mapDispatchToProps = {
  labUpdate: actionLabUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabServices);
