import { App, Form, Tabs, Typography } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FieldData } from 'rc-field-form/lib/interface';
import { LabService } from '../../../../../hooks/services';
import { JsonResult } from '../../../../../types';
import ContentCard from '../../../../Common/ContentCard';
import LabServiceView from '../LabServiceView';
import { IFormChangedProps } from '../../../../Common/DrawerModal';

interface LabServicesForm {
  servicesState: [LabService[] | undefined, Dispatch<SetStateAction<LabService[] | undefined>>];
  editMode?: {
    onSubmit: (values: LabService[]) => void;
    hideControls?: boolean;
  };
  loading?: boolean;
  paddingSize?: 'small' | 'default';
  hideTitle?: boolean;
  setIsObjEqualFormProps?: React.Dispatch<React.SetStateAction<IFormChangedProps | undefined>>;
}

const LabServicesForm: React.FC<LabServicesForm> = (props) => {
  const { servicesState, editMode, paddingSize, loading, hideTitle, setIsObjEqualFormProps } = props;
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [services, setServices] = servicesState;
  const [activeService, setActiveService] = useState<LabService>();
  const [activeKey, setActiveKey] = useState<string>('0');
  const isEditingState = useState<boolean>(false);
  const [isEditing, setIsEditing] = isEditingState;

  useEffect(() => {
    if (!services) return;

    setActiveService(services[+activeKey]);
  }, [services]);

  const updateService = (values: LabService) => {
    if (!services) return;

    const newServices = [...services];

    newServices.splice(+activeKey, 1, values);

    setServices(newServices);
    if (editMode?.onSubmit) {
      editMode.onSubmit(newServices);
      if (setIsObjEqualFormProps) {
        resetFormsTouchedState();
      }
    }
  };

  const handleSubmit = () => {
    form.validateFields()
      .then((values: LabService) => {
        updateService(values);
        setIsEditing(false);
      })
      .catch((error) => {
        if (error.errorFields.some((field: JsonResult) => field.name.includes('minRange')
          || field.name.includes('maxRange'))) {
          message.error('Tooth ranges must not overlap.', 5);
        }
        if (error.errorFields.some((field: JsonResult) => field.name.includes('price'))) {
          message.error('Price is required', 5);
        }
      });
  };

  const handleTabsChange = (key: string) => {
    if (isEditing) return;

    setActiveService(services?.filter((service) => service.service)[+key]);
    setActiveKey(key);
  };
  /** Needed for 'exit confirmation modal' */
  const [fieldsValue, setFieldsValue] = useState();
  const [fieldChangedValue, setFieldChangedValue] = useState<FieldData[]>([]);

  const resetFormsTouchedState = () => {
    setFieldsValue(undefined);
    setFieldChangedValue([]);
  };

  /** When any form field changes setFieldsValue called with a delay to not overload the CPU with the .getFieldsValue */
  useEffect(() => {
    if (fieldChangedValue) {
      const id = setTimeout(() => {
        setFieldsValue(form.getFieldsValue());
      }, 350);

      return () => clearTimeout(id);
    }

    return undefined;
  }, [fieldChangedValue]);

  useEffect(() => {
    if (setIsObjEqualFormProps) {
      setIsObjEqualFormProps({ obj1: activeService, obj2: fieldsValue });
    }
  }, [fieldsValue]);

  return (
    <>
      {!hideTitle && <Typography.Title level={4}>Services</Typography.Title>}
      <ContentCard paddingSize={paddingSize} loading={loading}>
        <Tabs
          key="labServicesTabs"
          activeKey={activeKey}
          onChange={handleTabsChange}
          items={services?.map((service, index) => ({
            label: service?.name,
            key: index.toString(),
            children: (
              <LabServiceView
                form={form}
                serviceName={service.name || ''}
                service={activeService}
                isEditingState={isEditingState}
                editMode={editMode ? {
                  handleSubmit,
                  hideControls: editMode?.hideControls,
                } : undefined}
                materialsRowExtra={!hideTitle && (
                  <Typography.Text style={{
                    display: 'flex',
                    gap: '4px',
                    fontSize: '12px',
                    alignItems: 'center',
                    color: 'var(--color-grey-additional)',
                  }}
                  >
                    <InfoCircleOutlined style={{ fontSize: '14px' }} />
                    Please make sure to create tooth ranges for all teeth and specify corresponding lead times
                  </Typography.Text>
                )}
                withPrices
                onFormFieldsChange={setIsObjEqualFormProps ? (changedFields) => {
                  setFieldChangedValue(changedFields);
                } : undefined}
              />
            ),
          }))}
        />
      </ContentCard>
    </>
  );
};

LabServicesForm.defaultProps = {
  editMode: undefined,
  paddingSize: 'default',
  loading: false,
  hideTitle: false,
  setIsObjEqualFormProps: undefined,
};

export default LabServicesForm;
