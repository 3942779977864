export enum CaseStatuses {
  all = 'All Statuses',
  draft = 'New',
  assigning = 'Assigning',
  'in progress' = 'In Progress',
  printing = 'Printing',
  delivery = 'Delivery',
  completed = 'Completed',
  cancel = 'Canceled',
}

export type CaseStatus = 'draft'
  | 'cancel'
  | 'assigning'
  | 'in progress'
  | 'printing'
  | 'delivery'
  | 'completed';

export const caseStatusArr = ['draft', 'cancel', 'assigning', 'in progress', 'printing', 'delivery', 'completed'];

export type CaseServiceStatus = 'draft' | 'unassigned' | 'declined' | 'request' | 'assigning' |'assigned'
  | 'in progress' | 'ready for approval' | 'pending' | 'approved' | 'rejected' | 'in progress printing' | 'completed';

export const caseServicesStatusArr = ['draft', 'unassigned', 'declined', 'request', 'assigning', 'assigned',
  'in progress', 'ready for approval', 'pending', 'approved', 'rejected', 'in progress printing', 'completed'];
/** Status 'ready for approval' label was changed to 'Preparing files' */

export type ServiceType = 'surgical' | 'restorative' | 'printing';
export type MaterialType = 'implant' | 'crown';
