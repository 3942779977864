import React, { PropsWithChildren } from 'react';
import { Typography, Select, Button } from 'antd';
import type { Dayjs } from 'dayjs';
import { CalendarMode } from 'antd/es/calendar/generateCalendar';
import { FilterOutlined } from '@ant-design/icons';
import { useContextCalendar } from '../Context';

import styles from './index.module.scss';

export interface HeaderPropsExport extends PropsWithChildren {
  value: Dayjs;
  type: CalendarMode;
  onChange: (date: Dayjs) => void;
  onTypeChange: (type: CalendarMode) => void;
  onFiltersClick?: () => void;
}

interface HeaderProps extends HeaderPropsExport {
  name?: string;
  setDate: (date: Dayjs) => void;
}

function Header({
  name, value, onChange, setDate, children, onFiltersClick,
}: HeaderProps): React.JSX.Element {
  const start = 0;
  const end = 12;
  const monthOptions = [];

  let current = value.clone();
  const localeData = value.localeData();
  const months = [];

  for (let i = 0; i < 12; i++) {
    current = current.month(i);
    months.push(localeData.monthsShort(current));
  }

  for (let i = start; i < end; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i} className="month-item">
        {months[i]}
      </Select.Option>,
    );
  }

  const year = value.year();
  const month = value.month();
  const options = [];

  for (let i = year - 10; i < year + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>,
    );
  }

  return (
    <div className={styles.header}>
      <div>
        {typeof name === 'string' ? (
          <Typography.Title level={4}>
            {name}
          </Typography.Title>
        ) : name}
      </div>
      <div className={styles.control}>
        {/* {user?.role === UserRoles.APPLICANT ? null : ( */}
        {/*  <NavLink to="/cases/create"> */}
        {/*    <Button type="primary">Create case</Button> */}
        {/*  </NavLink> */}
        {/* )} */}

        <Select
          popupMatchSelectWidth={false}
          className="my-year-select"
          value={year as any}
          onChange={(newYear) => {
            const now = value.clone().year(newYear);

            onChange(now);
            setDate(now);
          }}
        >
          {options}
        </Select>
        <Select
          popupMatchSelectWidth={false}
          value={month as any}
          onChange={(newMonth) => {
            const now = value.clone().month(newMonth);

            onChange(now);
            setDate(now);
          }}
        >
          {monthOptions}
        </Select>
        {onFiltersClick ? (
          <Button key="filterBtn" id="filterBtn" type="default" onClick={onFiltersClick}>
            <FilterOutlined />
            {' '}
            Filter
          </Button>
        ) : null}

        {children}
      </div>
    </div>
  );
}

Header.defaultProps = {
  name: undefined,
  onFiltersClick: () => undefined,
};

export default Header;
