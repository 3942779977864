import { FormInstance } from 'antd/es/form';
import React, { useState } from 'react';
import { Col, Form, Input, Row, Typography } from 'antd';
import { JsonResult } from '../../../../../types';
import { email, confirmPassword, phoneNumber, required, password } from '../../../../../utils/inputRules';

import ContentCard from '../../../../Common/ContentCard';
import ControlsEditMode from '../../../../Common/ControlsEditMode';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';

interface LabManagerInfo {
  initialData?: JsonResult;
  editMode?: boolean;
  isAdmin?: boolean;
  hasPassword?: boolean;
  disabled?: boolean;
  onSubmit?: () => void;
  handleChange?: (field: JsonResult[]) => void;
  children?: React.ReactNode;
  form: FormInstance;
}

const LabManagerInfo: React.FC<LabManagerInfo> = (props) => {
  const {
    disabled,
    isAdmin,
    initialData,
    editMode,
    hasPassword,
    onSubmit,
    children,
    form,
    handleChange,
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isDisabledField = disabled || !!(editMode && !isEditing);

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (!onSubmit) return;

    onSubmit();
    setIsEditing(false);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  return (
    <div>
      <Typography.Title level={4}>Lab Manager Information</Typography.Title>
      <ContentCard>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialData}
          onFinish={onSubmit && handleSubmit}
          onFieldsChange={handleChange}
        >
          <Form.Item name="userId" className="hidden-input">
            <Input type="hidden" disabled />
          </Form.Item>
          <Form.Item name="staffId" className="hidden-input">
            <Input type="hidden" disabled />
          </Form.Item>
          <Row gutter={15}>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={!isAdmin ? required : undefined}
              >
                <Input
                  placeholder="Type here"
                  disabled={isDisabledField}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={!isAdmin ? required : undefined}
              >
                <Input
                  placeholder="Type here"
                  disabled={isDisabledField}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[...email, ...required]}
              >
                <Input
                  placeholder="Type here"
                  disabled={isDisabledField}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Contact Phone"
                name="phone"
                rules={!isAdmin ? [...required, ...phoneNumber] : [...phoneNumber]}
              >
                <PhoneNumberInput disabled={isDisabledField} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Position"
                name="position"
              >
                <Input
                  placeholder="Type here"
                  disabled={isDisabledField}
                />
              </Form.Item>
            </Col>
          </Row>
          {hasPassword && (
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[...password]}
                  hasFeedback
                >
                  <Input.Password
                    placeholder="Type here"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  rules={[...required, ...confirmPassword]}
                  dependencies={['password']}
                  hasFeedback
                >
                  <Input.Password
                    placeholder="Type here"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
        {editMode && (
          <ControlsEditMode
            isEditing={isEditing}
            onCancel={handleCancel}
            onChangeEditing={setIsEditing}
            onSubmit={handleFormSubmit}
            isFormValid
          />
        )}
        {children}
      </ContentCard>
    </div>
  );
};

LabManagerInfo.defaultProps = {
  initialData: {},
  editMode: false,
  isAdmin: false,
  disabled: false,
  hasPassword: false,
  onSubmit: () => undefined,
  handleChange: () => undefined,
  children: null,
};

export default LabManagerInfo;
