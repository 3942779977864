import { SortOrder } from 'antd/es/table/interface';

import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { isEqual } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { ModalStaticFunctions } from 'antd/es/modal/confirm';
import { JsonResult } from '../types';
import { Address } from '../hooks/addresses';
import confirm from '../components/Common/ModalConfirm';
import { Action } from '../store';
import { caseServicesStatusArr, CaseServiceStatus, CaseStatus, caseStatusArr } from '../enums/case';
import { User } from '../hooks/users';

export function capitalizeFirstLetter(str: string): string {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export function capitalize(str?: string): string {
  if (!str) return '';

  const words = str.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }

  return words.join(' ');
}

export interface ValidSearchParams {
  [key: string]: string | string[];
}

export function getValidSearchParams(
  listValidParams: string[] | '*',
  searchParams: URLSearchParams,
  defaultParams?: { [key: string]: number | string | string[]; },
): ValidSearchParams {
  const props = {};
  const fn = (key: string) => {
    const value = searchParams.getAll(key);

    if (value.length || !(value.length === 1 && value[0] === '')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props[key] = value.length === 1 ? value[0] : value;
    } else if (defaultParams) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      props[key] = typeof defaultParams[key] === 'number' ? defaultParams[key].toString() : defaultParams[key];
    }
  };

  if (listValidParams === '*') {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of searchParams.keys()) {
      fn(key);
    }
  } else {
    listValidParams.forEach(fn);
  }

  return props;
}

interface Date {
  $y: number;
  $M: number;
  $D: number;
  $H?: number;
  $m?: number;
}
interface Time {
  $H: number;
  $m: number;
}

export const getDateTimeISO = (date: Date, time: Time) => {
  if (!date || !time) {
    return null;
  }
  const formattedDate = new Date(Date.UTC(date.$y, date.$M, date.$D, time.$H, time.$m));

  return formattedDate.toISOString();
};

export const getDateISO = (date: Date) => {
  if (!date) {
    return null;
  }
  const formattedDate = new Date(Date.UTC(date.$y, date.$M, date.$D, date.$H, date.$m));

  return formattedDate.toISOString();
};

export const getDaysBetween = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return '';
  }

  return `${dayjs(startDate)?.utc()?.format('ddd, MMM D')} - ${dayjs(endDate)?.utc()?.format('ddd, MMM D')}`;
};

export const getHoursBetween = (startDate: string, endDate: string) => {
  if (!startDate || !endDate) {
    return '';
  }

  return `${dayjs(startDate).utc()?.format('HH:mm')} - ${dayjs(endDate).utc()?.format('HH:mm')}`;
};

export const getDayJsTimeBetween = (startDate: string, endDate: string) => (
  [dayjs(startDate, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'), dayjs(endDate, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]')]
);

export function getValidSearchParamsWithout(
  excludeList: string[],
  searchParams: URLSearchParams,
  listValidParams: string[] | '*' = '*',
): ValidSearchParams {
  const list = getValidSearchParams(listValidParams, searchParams);

  excludeList.forEach((key) => {
    if (typeof list[key] !== 'undefined') {
      delete list[key];
    }
  });

  return list;
}

/**
 * Returns a JS object representation of a Javascript Web Token from its common encoded
 * string form.
 *
 * @template T the expected shape of the parsed token
 * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
 * @returns {(T | undefined)} an object-representation of the token
 * or undefined if parsing failed
 */
export function getParsedJwt<T = JsonResult>(
  token?: string,
): T | undefined {
  try {
    return token ? JSON.parse(atob(token.split('.')[1])) : undefined;
  } catch {
    return undefined;
  }
}

export const getSorterParams = (sorter?: Record<string, SortOrder>): { [key: string]: string; } => {
  const sorterEntries = Object.entries(sorter || {});
  const newParams: { [key: string]: string; } = {};

  if (sorterEntries.length) {
    newParams.orderByColumn = sorterEntries[0][0] || '';
    newParams.orderBy = sorterEntries[0][1] === 'ascend' ? 'ASC' : 'DESC';
  } else {
    newParams.orderByColumn = '';
    newParams.orderBy = '';
  }

  return queryFilterParams(newParams);
};

export const queryFilterParams = (queryParams: Record<string, string>): Record<string, string> => {
  const params = Object.entries(queryParams);
  const newParams: { [key: string]: string; } = {};

  params.forEach(([key, value]) => {
    if (value) {
      newParams[key] = value.toString();
    }
  });

  return newParams;
};

export const getBase64 = (file: Blob): Promise<unknown> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const downloadFromAnchor = (blobPart: ArrayBuffer, name?: string, type?: string) => {
  const file = new Blob([blobPart], { type });
  const fileURL = window.URL.createObjectURL(file);
  const link = document.createElement('a');

  link.href = fileURL;
  link.setAttribute('download', name || 'filename');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getListLocationParams = ({ search }: { search: string; }): Record<string, any[]> => {
  if (!search) {
    return {};
  }

  const decodeSearch = decodeURI(search);

  if (decodeSearch && !decodeSearch.indexOf('?')) {
    return decodeSearch
      .slice(1)
      .split('&')
      .map((value) => value.split('='))
      .reduce(
        (accumulator, [key, value]) => {
          const str = decodeURIComponent(value);
          const list = str.split(',');

          return Object.assign(accumulator, {
            [key]: list.filter((v: string) => v !== '').length !== list.length ? [str] : list,
          });
        },
        {},
      );
  }

  return {};
};

interface LocationSearchOptions {
  exclude?: Record<string, JsonResult | string | number | boolean | null>;
  only?: (string | number | boolean | null)[];
}

export const createLocationSearch = (
  params: Record<string, JsonResult | string | number | boolean | null>,
  options?: LocationSearchOptions,
): string => {
  const { exclude, only } = options || {};

  // eslint-disable-next-line
  function getValue(param: any) {
    if (Array.isArray(param)) {
      const data = param[0];

      if (typeof data === 'object' && data.value) {
        return param.map(({ value }) => value).join(',');
      }

      return param.join(',');
    }

    if (typeof param === 'string' || typeof param === 'number') {
      return param.toString();
    }

    return JSON.stringify(param);
  }

  let list = Object.keys(params);

  if (only && only.length) {
    list = list.filter((name) => only.indexOf(name) !== -1);
  }

  let newPath = '?';

  list.forEach((key: string) => {
    const param = params[key];
    const result = getValue(param);

    if (result && result !== '[]' && result !== '{}' && result !== 'null'
      && (!exclude || (result !== '0,0' && getValue(exclude[key]) !== result))
    ) {
      newPath += `${key}=${encodeURIComponent(getValue(param).replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'))}&`;
    }
  });
  newPath = newPath.slice(0, newPath.length - 1);

  return newPath;
};

export const generateRandomString = (): string => Math.random().toString(36).substring(2, 8);

export const getTimeZoneName = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const arrayEquals = (a: unknown[], b: unknown[]): boolean => {
  if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
    const sortedA = a.sort((prev, next) => prev < next ? 1 : -1);
    const sortedB = b.sort((prev, next) => prev < next ? 1 : -1);

    return sortedA.every((val, index) => val === sortedB[index]);
  }

  return false;
};

export const formatPhoneNumber = (phone: string | null, format: 'national' | 'international' = 'international') => {
  if (!phone) return ' - ';

  if (isValidPhoneNumber(`${phone}`)) {
    const phoneNumber = parsePhoneNumber(`${phone}`);

    if (format === 'national') return phoneNumber.formatNational();

    return phoneNumber.formatInternational();
  }

  return phone;
};

/** removeEmptyValues function recursively iterates over the object and performs the following steps:
 1. Create an empty filteredObject to store the filtered values.
 2. Iterate over each key-value pair in the object using Object.entries.
 3. If the value is an object (isObject helper function), recursively call removeEmptyValues on the
 nested object and check if the resulting nested object is empty or not.
 -If the nested object is not empty, add it to the filteredObject.
 4. If the value is not an object and is not an empty value (isEmptyValue helper function),
 add it to the filteredObject.
 5. Return the filteredObject. */
function removeEmptyValues<T extends object>(object: T): T {
  const filteredObject = {} as T;

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(object)) {
    if (isObject(value)) {
      const nestedObject = removeEmptyValues(value);

      if (!isEmptyObject(nestedObject)) {
        // @ts-ignore compare compare
        filteredObject[key] = nestedObject;
      }
    } else if (!isEmptyValue(value)) {
      // @ts-ignore compare compare
      filteredObject[key] = value;
    }
  }

  return filteredObject;
}

function isObject(value: unknown): value is object { return typeof value === 'object' && value !== null; }
function isEmptyObject(obj: object): boolean { return Object.keys(obj).length === 0; }
function isEmptyValue(value: unknown): boolean { return value === '' || value === null || value === undefined; }

export function isObjEqualLodash<T>(object1: T, object2: T): boolean {
  if (!object1 || !object2) return false;

  return isEqual(removeEmptyValues(object1), removeEmptyValues(object2));
}

export function isObjEqual<T>(object1: T, object2: T): boolean {
  if (!object1 || !object2) return false;

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  // cannot simply compare key-array lengths as lengths could be same while the keys themselves differ
  // cannot skip this check either and just check the values of all keys concatenated
  // because { "key": undefined }["key"] and {}["key"] would equal incorrectly
  // eslint-disable-next-line no-restricted-syntax
  for (const k of keys1) if (!keys2.includes(k)) return false;
  // eslint-disable-next-line no-restricted-syntax
  for (const k of keys2) if (!keys1.includes(k)) return false;

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) { // @ts-ignore compare compare
    if (object1[key] !== object2[key]) return false;
  }

  return true;
}

export function filterObjectProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Response = Record<string, any>>(obj: Record<string, any>, keysToDelete: string[]): Response {
  const filteredEntries = Object.entries(obj).filter(([key]) => !keysToDelete.includes(key));

  return Object.fromEntries(filteredEntries) as Response;
}

export const formatAddressToString = (address: Address | undefined): string | null => {
  if (!address) return '-';

  const street = address.street ? address.street : '-';
  const apartment = address.apartment ? address.apartment : '';
  // const city = address.city ? address.city.name : '-';
  const city = address.cityString || '-';
  const state = address.state ? address.state.name : '-';
  const country = address.state ? address.country.name : '-';
  const code = address.code ? address.code : '-';

  return `${street}, ${apartment ? `${apartment},` : ''} ${city}, ${state}, ${country} ${code}`;
};

export interface ActionConfirm<DataType> {
  modal: Omit<ModalStaticFunctions, 'warn'>;
  title: string;
  actionName: string;
  hook?: () => Promise<DataType | null>;
  thenFunc?: () => void;
  content?: string;
  reduxAction?: () => Action;
  centered?: boolean;
}

export const onActionClick = <DataType>({
  modal, title, actionName, hook, thenFunc, content, reduxAction, centered,
}: ActionConfirm<DataType>) => {
  confirm({
    modal,
    title,
    centered,
    content: content || `Are you sure you want to ${actionName.toLowerCase()} this account?`,
    onOk: () => {
      hook?.().then(() => {
        if (thenFunc) {
          thenFunc();
        }
      });
      reduxAction?.();
    },
  });
};

export const isCaseStatusEnough = (
  currentStatus: CaseStatus | undefined,
  requiredStatus: CaseStatus | undefined,
): boolean => (
  caseStatusArr.indexOf(currentStatus || 'draft') >= caseStatusArr.indexOf(requiredStatus || 'draft')
);

export const isCaseServiceStatusEnough = (
  currentStatus: CaseServiceStatus | undefined,
  requiredStatus: CaseServiceStatus | undefined,
): boolean => (
  caseServicesStatusArr.indexOf(currentStatus || 'draft') >= caseServicesStatusArr.indexOf(requiredStatus || 'draft')
);

export const isDateInFuture = (date: string | undefined): boolean => (date ? dayjs(dayjs())?.isBefore(dayjs(date))
  : true);

/** If difference between 'date' and 'today' < than 0, then due date in past */
export const isDateInPast = (date: string | undefined): boolean => (
  date ? dayjs(date).diff(dayjs(), 'days') < 0 : true
);

export const getInitials = (firstName: string | undefined, lastName: string | undefined): string => (
  !firstName && !lastName ? '-' : `${firstName?.substring(0, 1) || '-'}${lastName?.substring(0, 1)}`.toUpperCase()
);

export const getUserFullName = (user: Partial<User> | undefined) => (
  user?.firstName || user?.lastName ? `${user?.firstName || '-'} ${user.lastName || '-'}` : '-'
);

export const getDaysDiffFromToday = (date: Dayjs): number => (
  date.diff(dayjs(), 'days')
);

export const getFileExtension = (name: string) => {
  const splitFileName = name.split('.');
  const fileExtension = splitFileName[splitFileName.length - 1];

  return fileExtension || '';
};

export const removeDuplicatesFromObjectArr = <T extends {[key: string]: any; }>(arr: T[], compareKey: string) => {
  if (arr.length) {
    const uniqueIds: string[] = [];

    const unique = arr.filter((item) => {
      const isDuplicate = uniqueIds.includes(item?.[compareKey]);

      if (!isDuplicate) {
        uniqueIds.push(item?.[compareKey]);

        return true;
      }

      return false;
    });

    return unique;
  }

  return arr;
};

/** Color can be in hex or css-variable. Transparency percent should be 0-100. */
export const getColorMixTransparentProp = (fromColor: string, transparencyPerc: number) => (
  `color-mix(in srgb, ${fromColor} ${transparencyPerc}%, transparent)`
);

/** Use to convert firebase (or other) object which comes with 'Record<<string, T>>' type instead of array */
export function convertObjectRecordToArr<T>(data: Record<string, T>): Array<{ id: string; } & T> {
  return Object.keys(data).map((key) => ({ id: key, ...data[key] }));
}
