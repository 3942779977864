import { SagaIterator } from 'redux-saga';
import { spawn } from 'redux-saga/effects';
import { saga as local } from './ducks/local';
import { saga as auth } from './ducks/auth';
import { saga as user } from './ducks/user';
import { saga as practice } from './ducks/practice';
import { saga as doctor } from './ducks/doctor';
import { saga as lab } from './ducks/lab';
import { saga as labStaff } from './ducks/labStaff';
import { saga as service } from './ducks/service';
import { saga as patient } from './ducks/patient';
import { saga as priceLevel } from './ducks/priceLevel';
import { saga as cases } from './ducks/cases';
import { saga as events } from './ducks/events';

export default function* Saga(): SagaIterator {
  yield spawn(local);
  yield spawn(auth);
  yield spawn(user);
  yield spawn(practice);
  yield spawn(doctor);
  yield spawn(lab);
  yield spawn(labStaff);
  yield spawn(service);
  yield spawn(patient);
  yield spawn(priceLevel);
  yield spawn(cases);
  yield spawn(events);
}
