import React from 'react';

import { Layout } from 'antd';
import Content from '../../components/Pages/Chat';
import { displayName } from '../../config';
import ChatsProvider from '../../context/chats';

const PracticeRequest: React.FC = (): React.JSX.Element => {
  document.title = `${displayName}: Chat`;

  return (
    <Layout>
      <ChatsProvider>
        <Content />
      </ChatsProvider>
    </Layout>
  );
};

export default PracticeRequest;
