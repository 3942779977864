import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { alertDelaySuccess, name as appName } from '../../config';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse, Error } from './common';
import { Action } from '../index';
import { fetchSaga } from './auth';
import { Doctor } from '../../hooks/doctors';
import { createLocationSearch } from '../../utils';

/**
 * Constants
 * */
export const moduleName = 'doctor';
const prefix = `${appName}/${moduleName}`;

export const CREATE_DOCTOR = `${prefix}/CREATE_DOCTOR`;
export const CREATE_DOCTOR_START = `${prefix}/CREATE_DOCTOR_START`;
export const CREATE_DOCTOR_SUCCESS = `${prefix}/CREATE_DOCTOR_SUCCESS`;
export const CREATE_DOCTOR_ERROR = `${prefix}/CREATE_DOCTOR_ERROR`;
export const CREATE_DOCTOR_ERROR_RESET = `${prefix}/CREATE_DOCTOR_ERROR_RESET`;

export const UPDATE_DOCTOR = `${prefix}/UPDATE_DOCTOR`;
export const UPDATE_DOCTOR_START = `${prefix}/UPDATE_DOCTOR_START`;
export const UPDATE_DOCTOR_SUCCESS = `${prefix}/UPDATE_DOCTOR_SUCCESS`;
export const UPDATE_DOCTOR_ERROR = `${prefix}/UPDATE_DOCTOR_ERROR`;
export const UPDATE_DOCTOR_ERROR_RESET = `${prefix}/UPDATE_DOCTOR_ERROR_RESET`;

export const ASSIGN_DOCTOR = `${prefix}/ASSIGN_DOCTOR`;
export const ASSIGN_DOCTOR_START = `${prefix}/ASSIGN_DOCTOR_START`;
export const ASSIGN_DOCTOR_SUCCESS = `${prefix}/ASSIGN_DOCTOR_SUCCESS`;
export const ASSIGN_DOCTOR_ERROR = `${prefix}/ASSIGN_DOCTOR_ERROR`;
export const ASSIGN_DOCTOR_ERROR_RESET = `${prefix}/ASSIGN_DOCTOR_ERROR_RESET`;

export const DELETE_DOCTOR = `${prefix}/DELETE_DOCTOR`;
export const DELETE_DOCTOR_START = `${prefix}/DELETE_DOCTOR_START`;
export const DELETE_DOCTOR_SUCCESS = `${prefix}/DELETE_DOCTOR_SUCCESS`;
export const DELETE_DOCTOR_ERROR = `${prefix}/DELETE_DOCTOR_ERROR`;
export const DELETE_DOCTOR_ERROR_RESET = `${prefix}/DELETE_DOCTOR_ERROR_RESET`;

export const CLEAR_DOCTOR_DATA = `${prefix}/CLEAR_DOCTOR_DATA`;

/**
 * Reducer
 * */
export interface State {
  doctor: Doctor | null;
  doctorLoading: boolean;

  doctorError: Error | null;

  doctorCreateError: Error | null;
  doctorUpdateError: Error | null;
}

const defaultState: State = {
  doctor: null,
  doctorLoading: false,

  doctorError: null,

  doctorCreateError: null,
  doctorUpdateError: null,
};

export interface CreateDoctor {
  note?: string;
  nickname?: string;
  specialty?: string;
  position?: string;
  cases?: number[];
  practice?: string;
  user: {
    email: string;
    password?: string;
    firstName: string;
    lastName: string;
    phone: string;
    role?: string;
    settings?: {
      isEmailVerified: boolean;
      isPhoneVerified: boolean;
    };
    id?: string; // uuid
  };
}

export interface AssignDoctor {
  practiceId: string;
  doctorId: string;
}

export interface UpdateDoctor {
  id?: string;
  secretKey?: string;
  note?: string;
  nickname?: string;
  specialty?: string;
  position?: string;
  cases?: number[];
  practice?: string;
  user: {
    email: string;
    password?: string;
    firstName: string;
    lastName: string;
    phone: string;
    role?: string;
    settings?: {
      isEmailVerified: boolean;
      isPhoneVerified: boolean;
    };
    id?: string; // uuid <- required on update
  };
}

export default function reducer(
  state = defaultState,
  action: Action = { type: 'undefined' },
): State {
  const { type, payload } = action;

  switch (type) {
    case CREATE_DOCTOR_START:
      return { ...state, doctorLoading: true };
    case CREATE_DOCTOR_SUCCESS:
      return { ...state, doctorLoading: false };
    case CREATE_DOCTOR_ERROR:
      return { ...state, doctorLoading: false, doctorError: payload, doctorCreateError: payload };
    case CREATE_DOCTOR_ERROR_RESET:
      return { ...state, doctorError: null, doctorCreateError: null };

    case UPDATE_DOCTOR_START:
      return { ...state, doctorLoading: true };
    case UPDATE_DOCTOR_SUCCESS:
      return { ...state, doctorLoading: false, doctor: payload };
    case UPDATE_DOCTOR_ERROR:
      return { ...state, doctorLoading: false, doctorError: payload, doctorUpdateError: payload };
    case UPDATE_DOCTOR_ERROR_RESET:
      return { ...state, doctorError: null, doctorUpdateError: payload };

    case ASSIGN_DOCTOR_START:
      return { ...state, doctorLoading: true };
    case ASSIGN_DOCTOR_SUCCESS:
      return { ...state, doctorLoading: false };
    case ASSIGN_DOCTOR_ERROR:
      return { ...state, doctorLoading: false, doctorError: payload };
    case ASSIGN_DOCTOR_ERROR_RESET:
      return { ...state, doctorError: null };

    case CLEAR_DOCTOR_DATA:
      return { ...state, doctor: null };

    default:
      return { ...state };
  }
}

/**
 * Action Creators
 * */

export const createDoctor = (payload: CreateDoctor): Action => ({
  type: CREATE_DOCTOR,
  payload,
});

export const assignDoctor = (payload: AssignDoctor): Action => ({
  type: ASSIGN_DOCTOR,
  payload,
});

export const updateDoctor = (payload: UpdateDoctor): Action => ({
  type: UPDATE_DOCTOR,
  payload,
});

export const clearDoctorData = (): Action => ({
  type: CLEAR_DOCTOR_DATA,
});

/**
 * Helper functions
 */

/**
 * Sagas
 */
function* createDoctorSaga({ payload }: { payload: CreateDoctor; }): Generator {
  yield put({
    type: CREATE_DOCTOR_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/doctors`,
    {
      method: 'POST',
      body: { ...payload },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    CREATE_DOCTOR_SUCCESS,
    CREATE_DOCTOR_ERROR,
    false,
  );
}

function* assignDoctorSaga({ payload }: { payload: AssignDoctor; }): Generator {
  yield put({
    type: ASSIGN_DOCTOR_START,
  });

  const { practiceId, ...body } = payload;

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/practices/add-doctor/${practiceId}`,
    {
      method: 'POST',
      body,
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    ASSIGN_DOCTOR_SUCCESS,
    ASSIGN_DOCTOR_ERROR,
    false,
  );
}

function* updateDoctorSaga({ payload: { id, secretKey, ...data } }: { payload: UpdateDoctor; }): Generator {
  yield put({
    type: UPDATE_DOCTOR_START,
  });

  const response = secretKey
    ? (yield call(
      fetchSaga,
      `${process.env.REACT_APP_API_URL}/doctors/update-secret/${id}${createLocationSearch({ secretKey })}`,
      {
        method: 'PATCH',
        body: { ...data },
      },
    )) as FetchResponse
    : (yield call(
      fetchSaga,
      `${process.env.REACT_APP_API_URL}/doctors/${id}`,
      {
        method: 'PATCH',
        body: { ...data },
      },
    )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    UPDATE_DOCTOR_SUCCESS,
    UPDATE_DOCTOR_ERROR,
    false,
    () => data,
  );
}

export function* saga(): Generator {
  yield takeLatest(
    CREATE_DOCTOR,
    cancelableLocationSaga.bind(
      null,
      createDoctorSaga,
      CREATE_DOCTOR_ERROR,
      false,
    ),
  );
  yield takeLatest(CREATE_DOCTOR_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: CREATE_DOCTOR_ERROR_RESET,
    });
  });

  yield takeLatest(
    UPDATE_DOCTOR,
    cancelableLocationSaga.bind(
      null,
      updateDoctorSaga,
      UPDATE_DOCTOR_ERROR,
      false,
    ),
  );
  yield takeLatest(UPDATE_DOCTOR_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: UPDATE_DOCTOR_ERROR_RESET,
    });
  });

  yield takeLatest(
    ASSIGN_DOCTOR,
    cancelableLocationSaga.bind(
      null,
      assignDoctorSaga,
      ASSIGN_DOCTOR_ERROR,
      false,
    ),
  );
  yield takeLatest(ASSIGN_DOCTOR_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: ASSIGN_DOCTOR_ERROR_RESET,
    });
  });
}
