import React, { useEffect, useRef, useState } from 'react';
import { App, Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import clsx from 'clsx';

import confirm from '../ModalConfirm';
import ViewBar from '../ViewBar';
import { JsonResult } from '../../../types';
import { isObjEqualLodash } from '../../../utils';

export interface IFormChangedProps {
  obj1: JsonResult | undefined;
  obj2: JsonResult | undefined;
}

interface DrawerModal extends DrawerProps {
  title: string;
  open: boolean;
  onClose: () => void;
  className?: string;
  status?: string;
  viewbarContent?: React.ReactNode;
  withSteps?: boolean;
  currentStep?: number;
  totalSteps?: number;
  closeIcon?: React.ReactNode;
  withoutConfirmation?: boolean;
  isFormChanged?: boolean;
  checkIsObjEqual?: boolean;
  isObjEqualFormProps?: IFormChangedProps;
}

const DrawerModal: React.FC<DrawerModal> = ({
  title,
  status,
  children,
  open,
  onClose,
  viewbarContent,
  extra,
  className,
  withSteps,
  currentStep,
  totalSteps,
  closeIcon,
  withoutConfirmation,
  isFormChanged,
  checkIsObjEqual,
  isObjEqualFormProps,
  ...props
}) => {
  const { modal } = App.useApp();
  const handleOk = () => {
    onClose();
  };

  const handleClose = () => {
    const isObjNotEq = isObjEqualFormProps?.obj1 && isObjEqualFormProps?.obj2 && checkIsObjEqual
      ? !isObjEqualLodash(isObjEqualFormProps?.obj1, isObjEqualFormProps?.obj2) : false;

    if ((isFormChanged || isObjNotEq) && !withoutConfirmation) {
      confirm({
        modal,
        onOk: () => handleOk(),
        title: 'Confirmation',
        content: 'You have unsaved data. Do you want leave this page?',
        okText: 'Yes',
        cancelText: 'No',
      });
    } else {
      handleOk();
    }
  };

  const viewbarRef = useRef<HTMLDivElement>(null);
  const [viewbarHeight, setViewbarHeight] = useState<number>(74); // 136

  useEffect(() => {
    if (viewbarRef?.current?.clientHeight) {
      setViewbarHeight(viewbarRef.current.clientHeight || 74);
    }
  }, [viewbarRef?.current?.clientHeight]);

  return (
    (
      <Drawer
        open={open}
        placement="top"
        height="100%"
        rootClassName={clsx('common-drawer-component', className)}
        headerStyle={{ display: 'none' }}
        {...props}
      >
        <div style={{ overflow: 'hidden', maxHeight: 'calc(100vh - 24px - 24px)' }}>
          <ViewBar
            closeIcon={closeIcon}
            onClose={handleClose}
            title={title}
            status={status}
            controls={extra}
            withSteps={withSteps}
            currentStep={currentStep}
            totalSteps={totalSteps}
            forwardedRef={viewbarRef}
            style={{ zIndex: 2, position: 'sticky', marginBottom: 0 }}
          >
            {viewbarContent}
          </ViewBar>
          <div
            style={{
              overflow: 'auto', maxHeight: `calc(100vh - 48px - ${viewbarHeight}px)`, paddingTop: '24px', zIndex: 1,
            }}
          >
            {children}
          </div>
        </div>
      </Drawer>
    )
  );
};

DrawerModal.defaultProps = {
  status: '',
  className: '',
  viewbarContent: undefined,
  withSteps: false,
  currentStep: 1,
  totalSteps: 1,
  closeIcon: null,
  withoutConfirmation: false,
  isFormChanged: false,
  checkIsObjEqual: false,
  isObjEqualFormProps: undefined,
};

export default DrawerModal;
