import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Card, Space } from 'antd';
import { MessageOutlined, WechatOutlined } from '@ant-design/icons';
import ChatForm from './Form';
import AddNewChat from './AddNewChat';
import { isRoleEnough } from '../../../../utils/auth';

import originStyles from '../index.module.scss';

const actionBtnStyles: React.CSSProperties = { height: '24px', width: '24px', padding: 0 };

interface IMainContent {
  isChatsListOpen: boolean;
  toggleChatsListOpen: () => void;
}

const MainContent: React.FC<IMainContent> = ({ isChatsListOpen, toggleChatsListOpen }) => (
  <Card bodyStyle={{ padding: '16px 24px' }} className={originStyles.formCard}>
    <PageHeader
      style={{ padding: 0 }}
      title={(
        <Space size={10}>
          <WechatOutlined />
          Chats
        </Space>
      )}
      extra={(
        <>
          <Button
            size="middle"
            style={actionBtnStyles}
            type="text"
            icon={<MessageOutlined style={{ fontSize: 20 }} />}
            onClick={toggleChatsListOpen}
            title={`${isChatsListOpen ? 'Close' : 'Open'} chats list`}
          />
          {isRoleEnough('doctor') && (
            <AddNewChat />
          )}
        </>
      )}
    />

    <ChatForm />
  </Card>
);

export default MainContent;
