import { PlusOutlined } from '@ant-design/icons';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { SelectProps } from 'antd/lib/select';
import { BaseSelectRef } from 'rc-select';
import { App, Button, Select } from 'antd';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { PriceLevelsGetParams, usePriceLevelId, usePriceLevelsGet } from '../../../../hooks/priceLevels';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import PriceLevelDrawer from '../../../Pages/PriceLevels/Drawer';
import NotFoundContent from '../../NotFoundContent';

const SelectPriceLevel = ({
  value: selectValue, onClear, ...props
}: SelectProps<string, Option>): React.JSX.Element => {
  const { message } = App.useApp();
  const priceLevelsGet = usePriceLevelsGet();
  const priceLevelById = usePriceLevelId();
  const selectRef = createRef<BaseSelectRef>();
  const form = useFormInstance();

  const [options, setOptions] = useState<Option[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const fetch = () => {
    const params: PriceLevelsGetParams = { page: 1, take: 100, isActive: true };

    priceLevelsGet.fetch(params);
  };

  useEffect(() => {
    if (priceLevelsGet.data?.data.length) {
      setOptions(priceLevelsGet.data.data.map((item): Option => ({ label: item.name, value: item.id })));
    }
  }, [priceLevelsGet.data]);

  useEffect(() => {
    if (options.length && selectValue) {
      const current = options.find(({ value }) => value === selectValue);

      if (!current && selectValue) {
        priceLevelById.fetch(undefined, `${selectValue}`)
          .then((res) => setOptions([
            ...options,
            { label: res?.name || '', value: res?.id || '' },
          ]));
      }
    }
  }, [options, selectValue]);

  useEffect(() => {
    if (priceLevelsGet.error) {
      message.error(getMessageInError(priceLevelsGet.error));
      priceLevelsGet.clearError();
    }
  }, [priceLevelsGet.error]);

  useEffect(() => {
    fetch();
  }, []);

  const onReload = (id?: string) => {
    if (id) {
      form.setFieldValue('priceLevel', id);
    }
    fetch();
  };

  const handleDrawerState = useCallback((isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  }, [isDrawerOpen, setIsDrawerOpen]);

  return (
    <>
      <PriceLevelDrawer
        drawerOpen={isDrawerOpen}
        setDrawerOpen={handleDrawerState}
        onReload={onReload}
      />
      <Select
        ref={selectRef}
        loading={priceLevelsGet.loading || priceLevelById.loading}
        options={options}
        value={priceLevelsGet.loading || priceLevelById?.loading ? null : selectValue}
        placeholder="Please select"
        notFoundContent={<NotFoundContent message="Price level not found." />}
        // eslint-disable-next-line
        dropdownRender={(menu) => (
          <>
            <Button
              id="addNewPriceLevel"
              type="text"
              onClick={() => {
                selectRef.current?.blur();
                handleDrawerState(true);
              }}
            >
              <PlusOutlined />
              Add New Price Level
            </Button>
            {menu}
          </>
        )}
        {...props}
      />
    </>
  );
};

export default SelectPriceLevel;
