import React, { useEffect, useMemo, useRef } from 'react';
import { Button, Divider, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import useCurrentLocation from '../../../../../hooks/router';
import { queryFilterParams } from '../../../../../utils';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import SelectPractice from '../../../../Common/Selects/SelectPractice';
import SelectDoctor from '../../../../Common/Selects/SelectDoctor';
import SelectStatus from '../../../../Common/Selects/SelectStatus';
import { CaseStatuses } from '../../../../../enums/case';
import { isRoleEnough } from '../../../../../utils/auth';
import { useContextCasesCalendar } from '../../../../../context/caseCalendar';

import styles from './index.module.scss';

const Filters = () => {
  const { toggleFiltersOpen } = useContextCasesCalendar();
  const key = useRef<number>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const currentLocation = useCurrentLocation();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  const statusOptions = useMemo(() => (
    Object.entries(CaseStatuses)
      .filter(([key]) => (
        key !== 'printing' && key !== 'delivery' && key !== 'all'
        /** Hide filter with status 'New' from 'lab staff' and 'lab manager' */
        && (isRoleEnough(['lab staff', 'lab manager']) ? key !== 'draft' : true)
      ))
      .map(([key, value]) => ({
        label: value,
        value: key,
      }))
  ), []);

  const practice = Form.useWatch('practice', form);
  const isAdmin = isRoleEnough('admin');

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      /** if no values in form on submit then clear query params */
      if (Object.values(values).filter((val) => !!val)?.length) {
        setSearchParams(queryFilterParams({
          ...values,
          status: values?.status === 'all' ? undefined : values.status,
        }));
      } else { clearQueryParams(); }
    });
  };

  const clearQueryParams = () => {
    navigate(currentLocation, { replace: true });
    form.resetFields();
  };

  useEffect(() => {
    const query = qs.parse(location.search);

    form.setFieldsValue(query);
  }, [location.search]);

  return (
    <div className={styles.filtersWrapper}>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        className={styles.form}
        initialValues={{ }}
      >
        <div className={styles.header}>
          <span className={styles.title}>Filter</span>
          <Button type="text" onClick={toggleFiltersOpen} disabled={isAdmin && !qs.parse(location.search)?.practice}>
            <CloseOutlined style={{ fontSize: '16px' }} />
          </Button>
        </div>
        {isAdmin ? (
          <Form.Item label="Practice" name="practice">
            <SelectPractice onlyStatus="active" onChange={() => form.setFieldValue('doctor', undefined)} />
          </Form.Item>
        ) : null}
        {isRoleEnough('practice manager') ? (
          <Form.Item label="Doctor" name="doctor">
            <SelectDoctor
              onlyStatus="active"
              practice={practice}
              requestOnlyWithPractice={isAdmin}
              disabled={!practice && isAdmin}
            />
          </Form.Item>
        ) : null}
        <Form.Item label="Status" name="status">
          <SelectStatus options={statusOptions} />
        </Form.Item>
      </Form>

      <div key="controls" className={styles.controls}>
        <Divider />
        <div className={clsx('table-form-controls', styles.buttons)}>
          <Button
            key="Clear"
            onClick={(e) => {
              e.preventDefault();
              setTimeout(() => {
                key.current = Date.now();
                clearQueryParams();
              }, 0);
            }}
          >
            Clear All
          </Button>
          <Button
            key="Query"
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              form?.submit();
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Filters;
