import { LeftOutlined, RightOutlined } from '@ant-design/icons/lib';
import { App, Button, Col, Descriptions, Form, Row, Space } from 'antd';
import Icon from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Action } from '../../../../store';
import {
  updateCase as actionUpdateCase,
  UpdateCase,
  moduleName as casesModule,
} from '../../../../store/ducks/cases';
import { RootState } from '../../../../store/reducers';
import { DefaultModalProps, JsonResult } from '../../../../types';
import { prepareCaseBody, prepareCaseCalcPriceBody, prepareCaseInitialData } from '../../../../utils/prepareData';
import DrawerModal from '../../../Common/DrawerModal';
import { Send } from '../../../Common/Icon';
import { Error as ErrorCommon } from '../../../../store/ducks/common';
import { useContextCaseFlow } from '../../../../context/caseFlow';
import { useCaseCalcPrice, useDeleteCaseFile, useUploadCaseFile } from '../../../../hooks/case/cases';
import SummaryInfoForm from '../Forms/SummaryInfoForm';
import SelectTreatmentPlanForm from '../Forms/SelectTreatmentPlanForm';
import TeethTreatmentPlanForm from '../Forms/TeethTreatmentPlanForm';
import ImplantInfoForm from '../Forms/ImplantInfoForm';
import PrintingForm from '../Forms/PrintingForm';
import Loading from '../../../Common/Loading';
import PatientSelectForm from '../Forms/PatientSelectForm';
import { getArrayWithBridgesArrays } from '../../../../utils/teeth';
import { isRoleEnough } from '../../../../utils/auth';
import { getMessageInError } from '../../../../hooks/fetch';
import { DescriptionItem, stylesClipDesc } from '../Info';
import { getCaseStatusLabel } from '../Table';
import DateColorStatus from '../../../Common/DateColorStatus';
import { getDaysDiffFromToday } from '../../../../utils';
import { UploadingFile, IPercentCompleted } from '../../../Common/AttachmentLoader';

interface CaseUpdate extends DefaultModalProps {
  id: string | number;
  title: string;
  close: () => void;
  isAdmin?: boolean;
  disabled?: {
    [key: string]: boolean;
  };
  handleSave?: (body: UpdateCase) => void;
  loading: boolean;
  error: ErrorCommon | null;
  updateCase: (payload: UpdateCase) => Action;
}

const CaseUpdate = (props: CaseUpdate): JSX.Element => {
  const { message } = App.useApp();
  const {
    id,
    handleSave,
    title,
    isOpen,
    close,
    isAdmin,
    disabled,
    loading,
    error,
    updateCase,
  } = props;

  const {
    foundSurgicalService,
    foundRestorativeService,
    foundPrintingService,
    materialImplantId,
    materialCrownId,
    // Form values
    patientInfo,
    selectTreatmentPlan,
    teethValues,
    implantInfo,
    printingInfo,
    // Handle Form values
    setPatientInfo,
    setSelectTreatmentPlan,
    setTeethValues,
    setImplantInfo,
    setPrintingInfo,
    // Requests byId
    caseByIdInContext,
    patientByIdInContext,
  } = useContextCaseFlow();
  const caseCalcPrice = useCaseCalcPrice();
  const uploadCaseFile = useUploadCaseFile((progressEvent) => {
    setPercentCompleted((prevState) => {
      const prevStateCurrentFile = prevState.find((item) => (
        item.fileSizeBytes === progressEvent.total
      ));
      const newPercentValue = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      return (
        [
          ...prevState.filter((item) => item.fileSizeBytes !== progressEvent.total),
          {
            fileSizeBytes: progressEvent.total,
            percent: prevStateCurrentFile && (newPercentValue < prevStateCurrentFile.percent)
              ? prevStateCurrentFile?.percent : newPercentValue,
          },
        ]
      );
    });
  });
  const deleteFile = useDeleteCaseFile();

  const [patientSelectForm] = Form.useForm();
  const [selectTreatmentPlanForm] = Form.useForm();
  const [teethTreatmentPlanForm] = Form.useForm();
  const [implantInfoForm] = Form.useForm();
  const [printingInfoForm] = Form.useForm();
  const [summaryInfoForm] = Form.useForm();

  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([]);
  const [filesLoading, setFilesLoading] = useState<boolean>(false);

  const [printingIsSelectedId, setPrintingIsSelectedId] = useState<string>('');
  const isPrintingSelectedId = isFormTouched ? printingIsSelectedId : caseByIdInContext?.data?.teeth?.services
    .find((item) => item.id === foundPrintingService?.id)?.id;
  const [dateSizeCount, setDateSizeCount] = useState<number>(
    getDaysDiffFromToday(dayjs(caseByIdInContext?.data?.dueDate)) + 1, // Remove +1?
  );

  const totalSteps = isPrintingSelectedId ? 4 : 3;
  const [step, setStep] = useState<number>(2);

  /** Get case info by id */
  const fetchCaseById = () => {
    caseByIdInContext?.fetch(undefined, id);
  };

  useEffect(() => {
    if (id && isOpen) {
      fetchCaseById();
    }
  }, [id]);

  const initialValues = {
    ...prepareCaseInitialData(caseByIdInContext?.data),
  };

  useEffect(() => {
    if (caseByIdInContext?.data && !caseByIdInContext.error && isOpen) {
      setPatientInfo(initialValues.patientInfo || {});
      setSelectTreatmentPlan(initialValues.selectTreatmentPlan || {});
      setTeethValues(initialValues.teethValues || []);
      setImplantInfo(initialValues.implantInfo || {});
      setPrintingInfo(initialValues.printingInfo || {});
      summaryInfoForm.setFieldsValue(initialValues.summaryInfo);
    }
  }, [caseByIdInContext?.data]);

  /** Request to calculate total price per case  */
  const [caseTotalPrice, setCaseTotalPrice] = useState<number>(0);
  const [leadTimePrice, setLeadTimePrice] = useState<number>(0);

  const servicesArrValues: string[] = Form.useWatch('services', selectTreatmentPlanForm);

  useEffect(() => {
    /** Clean printing data if printing service was unselected before calculating */
    if (!printingIsSelectedId || printingIsSelectedId === '') {
      setPrintingInfo({});
      printingInfoForm.setFieldsValue({ material: undefined });
    }
  }, [printingIsSelectedId]);

  const calcTotalCasePrice = () => {
    if (teethValues.filter((item) => item.materials?.length).length && step >= 2) {
      caseCalcPrice.fetch(
        prepareCaseCalcPriceBody(
          patientInfo,
          step === 2 ? selectTreatmentPlanForm.getFieldsValue() : selectTreatmentPlan,
          teethValues,
          printingInfoForm.getFieldsValue()?.material ? printingInfoForm.getFieldsValue() : printingInfo,
          dateSizeCount,
        ),
      ).then((res) => {
        setCaseTotalPrice(res?.totalPrice || 0);
        setLeadTimePrice(res?.leadTimePrice || 0);
      });
    }
  };

  const [triggerCalcPrice, setTriggerCalcPrice] = useState<JsonResult[]>();

  useEffect(() => {
    if (isOpen && teethValues.filter((item) => item.materials?.length).length && step >= 2 && (
      servicesArrValues?.length || selectTreatmentPlan?.services?.length)
    ) {
      const timeoutId = setTimeout(calcTotalCasePrice, 1500);

      return () => clearTimeout(timeoutId);
    }

    if (!teethValues.filter((item) => item.materials?.length).length) {
      setCaseTotalPrice(0);
      setLeadTimePrice(0);
    }

    return undefined;
  }, [teethValues, selectTreatmentPlan, printingInfo, triggerCalcPrice, step, dateSizeCount]);

  /** Handling close/next/back/submit buttons  */
  const handleClose = () => {
    close();
    patientSelectForm.resetFields();
    selectTreatmentPlanForm.resetFields();
    teethTreatmentPlanForm.resetFields();
    implantInfoForm.resetFields();
    printingInfoForm.resetFields();
    summaryInfoForm.resetFields();
    caseCalcPrice.clearResponse();
  };

  useEffect(() => {
    if (!loading && !error) {
      /** close modal, when request finish without errors */
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    if (!isOpen) return;
    setStep(2);
    setUploadedFiles([]);
    // Calc price when we open update modal
    calcTotalCasePrice();
  }, [isOpen]);

  useEffect(() => {
    if (error?.message && isOpen) {
      message.error(error.message, 5);
    }
  }, [error]);

  useEffect(() => {
    if (deleteFile?.error && isOpen) {
      message.error(deleteFile?.error?.message, 5);
    }
  }, [deleteFile?.error]);

  const isServiceSelected = (serviceId: string | undefined) => !!servicesArrValues?.includes(serviceId || '');

  const [teethErrors, setTeethErrors] = useState<string[]>([]);

  const handleNext = () => {
    if (step === 1) {
      patientSelectForm.validateFields().then((values: JsonResult) => {
        setPatientInfo(values);
        setStep((prev) => prev + 1);
      });
    }
    if (step === 2) {
      const errors: string[] = [];

      Promise.all([
        selectTreatmentPlanForm.validateFields().then((values: JsonResult) => {
          setSelectTreatmentPlan(values);
        }),
        teethTreatmentPlanForm.validateFields(),
        implantInfoForm.validateFields().then((values) => {
          setImplantInfo(values);
        }),
        /** Validating  */
        new Promise((resolve, reject) => {
          if (teethValues.length >= 1 && teethValues.some((tooth) => tooth.materials?.length)) {
            resolve('Success');
          } else {
            const messageErr = 'You have to select at least one tooth on the Treatment plan form.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          }
        }),
        new Promise((resolve, reject) => {
          /** if surgical selected, then at least one implant should be chosen. */
          if (!isServiceSelected(foundSurgicalService?.id)) {
            resolve('Success');
          } else if (teethValues.length >= 1
            && teethValues.some((tooth) => tooth.materials?.includes(materialImplantId))) {
            resolve('Success');
          } else {
            const messageErr = 'You have to set at least one implant if you selected Surgical service.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          }
        }),
        new Promise((resolve, reject) => {
          /** if restorative selected, then at least one crown should be chosen.  */
          if (!isServiceSelected(foundRestorativeService?.id)) {
            resolve('Success');
          } else if (teethValues.length >= 1
            && teethValues.some((tooth) => tooth.materials?.includes(materialCrownId))) {
            resolve('Success');
          } else {
            const messageErr = 'You have to set at least one crown if you selected Restorative service.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          }
        }),
        new Promise((resolve, reject) => {
          /** the bridge with missing crowns in the middle.  */
          if (!teethValues.some((tooth) => tooth.isBridge && !tooth.materials?.length)) {
            resolve('Success');
          } else {
            const messageErr = 'A bridge can\'t have missing crowns in the middle.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          }
        }),
        new Promise((resolve, reject) => {
          /** Single crown without an implant that is not inside the bridge (if restorative or both are selected)  */
          if ((isServiceSelected(foundRestorativeService?.id) && isServiceSelected(foundSurgicalService?.id))
            && teethValues.some((tooth) => tooth.materials?.includes(materialCrownId)
              && (!tooth.isBridge && !tooth.materials?.includes(materialImplantId)))) {
            const messageErr = 'A crown must have an \'implant\' or be a part of the bridge if both surgical and '
              + 'restorative plan is selected.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          } else {
            resolve('Success');
          }
        }),
        new Promise((resolve, reject) => {
          /** the bridge without at least one implant (if both surgical and restorative services are selected).  */
          if ((isServiceSelected(foundRestorativeService?.id) && isServiceSelected(foundSurgicalService?.id))
            && getArrayWithBridgesArrays(teethValues)?.some((teethArr) => !teethArr?.some((tooth) => (
              tooth.materials?.includes(materialImplantId || '')
            )))) {
            const messageErr = 'A bridge must have at least one implant if both surgical and restorative plan '
              + 'is selected.';

            reject(new Error(messageErr));
            errors.push(messageErr);
          } else {
            resolve('Success');
          }
        }),
      ]).then(() => {
        setStep((prev) => prev + 1);
        setTeethErrors([]);
      }).catch(() => {
        setTeethErrors(errors);
      });
    }
    if (step === 3 && !!printingIsSelectedId) {
      printingInfoForm.validateFields().then((values: JsonResult) => {
        setPrintingInfo(values);
        setStep((prev) => prev + 1);
      });
    }
  };

  const handleFilesUpdate = (files: UploadFile[]) => {
    setUploadedFiles(files);
  };

  const handleFileDelete = async (fileId: string) => {
    await deleteFile.fetch(`${id}/file/${fileId}`);

    if (!deleteFile?.error) {
      caseByIdInContext?.fetch(undefined, id);
    }
  };

  const [wLoadingUploadingFiles, setWLoadingUploadingFiles] = useState<UploadingFile[]>([]);
  const [percentCompleted, setPercentCompleted] = useState<IPercentCompleted[]>([]);

  const handleSubmit = () => {
    summaryInfoForm.validateFields().then((values) => {
      if (!patientByIdInContext?.data?.doctor?.id) {
        message.error('The patient must have a doctor, but the one you chose doesn\'t.');
      }

      const body = prepareCaseBody(
        {
          ...patientInfo,
          practice: patientByIdInContext?.data?.doctor?.practice?.id || '',
          doctor: patientByIdInContext?.data?.doctor?.id || '',
        }, // Step 1 - Patient info + doctor & practice.
        selectTreatmentPlan, // Step 2
        teethValues, // Step 2
        implantInfo,
        printingIsSelectedId ? printingInfo : {}, // Step 3
        values, // Summary Info { note: string }
        dateSizeCount,
      );

      const uploadingFiles = uploadedFiles.map((file) => ({ ...file, loading: true }));

      setWLoadingUploadingFiles(uploadingFiles);

      setFilesLoading(true);
      Promise.all([
        ...uploadedFiles.map((file) => {
          const formData = new FormData();

          if (file.originFileObj) {
            formData.append('file', file.originFileObj);
          }

          return uploadCaseFile.fetch(formData, `${id}/file`).then(() => {
            const currentFile = uploadingFiles.find((uploadingFile) => uploadingFile.uid === file.uid);
            const currentFileIndex = currentFile ? uploadingFiles.indexOf(currentFile) : -1;

            if (currentFile && currentFileIndex >= 0) {
              uploadingFiles.splice(currentFileIndex, 1, { ...currentFile, loading: false });
            }

            setWLoadingUploadingFiles(uploadingFiles);
          });
        }),
      ])?.then(() => {
        if (handleSave) {
          handleSave(body);
        } else {
          updateCase({ ...body, id: `${id}` });
        }
        setUploadedFiles([]);
      }).finally(() => {
        setFilesLoading(false);
        setPercentCompleted([]);
        setWLoadingUploadingFiles([]);
      });
    });
  };

  useEffect(() => {
    if (uploadCaseFile.error) {
      message.error(getMessageInError(uploadCaseFile.error));
      uploadCaseFile.clearError();
    }
  }, [uploadCaseFile.error]);

  const descriptionItems: DescriptionItem[] = [
    {
      label: 'Patient',
      content: `${patientByIdInContext?.data?.firstName || ''} ${patientByIdInContext?.data?.lastName || ''}`,
    },
    {
      label: 'Practice',
      content: patientByIdInContext?.data?.doctor?.practice?.name || caseByIdInContext?.data?.practice?.name || '',
      roleNeeded: 'doctor',
    },
    {
      label: 'Due Date',
      content: <DateColorStatus
        date={selectTreatmentPlan?.dueDate || caseByIdInContext?.data?.dueDate}
        format="MMM DD, YYYY"
      />,
      title: dayjs(selectTreatmentPlan?.dueDate)?.format('MMM DD, YYYY'),
    },
    {
      label: 'Doctor',
      content: `${patientByIdInContext?.data?.doctor?.user?.firstName || ''} ${
        patientByIdInContext?.data?.doctor?.user?.lastName || ''}`,
      roleNeeded: 'doctor',
    },
  ];

  return (
    <div>
      <DrawerModal
        title={title}
        status={caseByIdInContext?.data?.status
          && getCaseStatusLabel(caseByIdInContext.data.status, caseByIdInContext.data?.caseServices)}
        open={isOpen}
        zIndex={1001}
        onClose={handleClose}
        destroyOnClose
        withSteps
        currentStep={step}
        totalSteps={totalSteps}
        isFormChanged={isFormTouched}
        viewbarContent={caseByIdInContext?.data ? (
          <Row justify="center" style={{ maxWidth: '520px' }}>
            <Col span={24}>
              <Descriptions
                size="small"
                column={2}
                labelStyle={{ color: 'var(--color-main-dark-gray)', opacity: '0.7', fontSize: '14px' }}
                contentStyle={{ color: 'var(--color-main-dark-gray)', fontWeight: '500', fontSize: '15px' }}
              >
                {descriptionItems
                  .filter(({ roleNeeded }) => isRoleEnough(roleNeeded || 'lab staff'))
                  .map(({ label, content, title }) => (
                    <Descriptions.Item key={label} label={label} contentStyle={stylesClipDesc}>
                      <span title={!title && typeof content === 'string' ? content : title}>{content}</span>
                    </Descriptions.Item>
                  ))}
              </Descriptions>
            </Col>
          </Row>
        ) : undefined}
        extra={(
          <Space>
            {step > 1 && (
              <Button
                ghost
                onClick={() => {
                  if (step === 2) {
                    implantInfoForm.validateFields().then((values) => {
                      setImplantInfo(values);
                    });
                    selectTreatmentPlanForm.validateFields().then((values: JsonResult) => {
                      setSelectTreatmentPlan(values);
                    });
                  }
                  setStep((prev) => prev - 1);
                }}
              >
                <LeftOutlined style={{ fontSize: 12 }} />
                Back
              </Button>
            )}
            {step < totalSteps && (
              <Button
                /** For case creation Next button have type 'primary', when in other places it have 'default' type */
                type="primary"
                onClick={handleNext}
                id="nextBtn"
              >
                Next
                <RightOutlined style={{ fontSize: 12 }} />
              </Button>
            )}
            {step === totalSteps && (
              <Button
                type="primary"
                icon={<Icon component={Send} />}
                loading={loading || filesLoading}
                onClick={handleSubmit}
                disabled={false}
                id="submitBtn"
              >
                Update Case
              </Button>
            )}
          </Space>
        )}
      >
        <Row justify="center">
          <Loading visible={!!caseByIdInContext?.loading} absolute />
          <Col
            span={24}
            md={22}
            lg={20}
            xxl={16}
          >
            <div>
              {step === 1 && (
                <PatientSelectForm
                  isManager={isRoleEnough(['practice manager'])}
                  form={patientSelectForm}
                  disabled={disabled}
                  initialData={initialValues?.patientInfo}
                  onFieldsChange={() => setIsFormTouched(true)}
                />
              )}
              {step === 2 && (
                <>
                  <SelectTreatmentPlanForm
                    form={selectTreatmentPlanForm}
                    isAdmin={isAdmin}
                    disabled={disabled?.selectTreatmentPlan}
                    initialData={initialValues?.selectTreatmentPlan}
                    caseTotalPrice={{
                      price: caseTotalPrice,
                      leadTimePrice,
                      loading: caseCalcPrice.loading,
                    }}
                    setPrintingId={setPrintingIsSelectedId}
                    setDateSizeCount={setDateSizeCount}
                    handleChange={(e) => {
                      setTriggerCalcPrice(e);
                      setIsFormTouched(true);
                      /** Update context form values on each form values change */
                      // setSelectTreatmentPlan(selectTreatmentPlanForm?.getFieldsValue());
                    }}
                  />
                  <TeethTreatmentPlanForm
                    form={teethTreatmentPlanForm}
                    isAdmin={isAdmin}
                    disabled={disabled?.teethValues}
                    selectTreatmentPlanForm={selectTreatmentPlanForm}
                    teethErrors={teethErrors}
                    setTeethErrors={setTeethErrors}
                  />
                  <ImplantInfoForm
                    form={implantInfoForm}
                    initialData={initialValues?.implantInfo}
                    selectTreatmentPlanForm={selectTreatmentPlanForm}
                  />
                </>
              )}
              {step === 3 && isPrintingSelectedId && (
                <PrintingForm
                  form={printingInfoForm}
                  isAdmin={isAdmin}
                  disabled={disabled?.printingInfo}
                  initialData={initialValues?.printingInfo}
                  caseTotalPrice={{
                    price: caseTotalPrice,
                    leadTimePrice,
                    loading: caseCalcPrice.loading,
                  }}
                  practiceId={patientByIdInContext?.data?.doctor?.practice?.id}
                  handleChange={(e) => {
                    setTriggerCalcPrice(e);
                    /** Update context form values on each form values change */
                    setPrintingInfo(printingInfoForm?.getFieldsValue());
                  }}
                  isUpdate
                />
              )}
              {(step === 4 || (step === 3 && !isPrintingSelectedId)) && (
                <SummaryInfoForm
                  type="update"
                  form={summaryInfoForm}
                  isAdmin={isAdmin}
                  disabled={disabled?.summaryInfo}
                  initialData={initialValues?.summaryInfo}
                  caseTotalPrice={{
                    price: caseTotalPrice,
                    leadTimePrice,
                    loading: caseCalcPrice.loading,
                  }}
                  handleFilesUpdate={handleFilesUpdate}
                  handleFileDelete={handleFileDelete}
                  uploadingFiles={wLoadingUploadingFiles.length ? wLoadingUploadingFiles : undefined}
                  percentCompleted={percentCompleted}
                />
              )}
            </div>
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[casesModule].caseLoading,
  error: state[casesModule].caseError,
});

const mapDispatchToProps = {
  updateCase: actionUpdateCase,
};

CaseUpdate.defaultProps = {
  onReload: undefined,
  isAdmin: false,
  disabled: undefined,
  initialData: undefined,
  handleSave: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseUpdate);
