import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useEffect, useState } from 'react';

import { PageHeader } from '@ant-design/pro-layout';

import { App, Button, Collapse, Descriptions, Divider, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ModalState, ModalTypes } from '../../../../types';
import { EyeClose, EyeOpen, Sliders } from '../../../Common/Icon';
import Status from '../../../Common/Status';
import { useLabId } from '../../../../hooks/labs';
import { getMessageInError } from '../../../../hooks/fetch';
import { formatAddressToString, formatPhoneNumber } from '../../../../utils';
import LabInfo from './LabInfo';
import TableLabStaff from './Staff/Table';
import LabStaffCreateDrawer from './Staff/Create';
import { UserRoles } from '../../../../enums/user';
import { moduleName as labsModuleName, UpdateLab, updateLab as actionLabUpdate } from '../../../../store/ducks/lab';
import { RootState } from '../../../../store/reducers';
import { Error } from '../../../../store/ducks/common';
import { Action } from '../../../../store';
import { prepareLabInitialData } from '../../../../utils/prepareData';
import LabStaffInfoDrawer from './Staff/StaffInfo';
import LabServices from '../Services';
import { User, moduleName as authModuleName } from '../../../../store/ducks/auth';
import { isRoleEnough } from '../../../../utils/auth';

const buttonStyles: React.CSSProperties = {
  minWidth: '120px',
  display: 'flex',
  alignItems: 'center',
};

interface LabProfile {
  loading: boolean;
  error: Error | null;
  labUpdate: (payload: UpdateLab) => Action;
  user: User | null;
}

const Labs: React.FC<LabProfile> = (props) => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { id: paramsLabId = '' } = useParams<{ id: string; }>();
  const { loading, error, labUpdate, user } = props;

  const requestLabId = paramsLabId || user?.labStaff?.lab?.id;

  const [modal, setModal] = useState<ModalState | null>(null);

  const [collapseState, setCollapseState] = useState<boolean>(false);
  const labById = useLabId();

  useEffect(() => {
    if (error?.message) {
      message.error(error.message, 5);
    }
  }, [error]);

  const initialData = prepareLabInitialData(labById.data);

  const fetchLabId = () => {
    labById.fetch(undefined, requestLabId);
  };

  useEffect(() => {
    if (requestLabId && !loading && !error) {
      /** fetch lab by id when page loads, and then every time when update request loading finish without errors */
      fetchLabId();
    }
  }, [requestLabId, loading]);

  useEffect(() => {
    if (labById.error) {
      message.error(getMessageInError(labById.error), 5);
      labById.clearError();
    }
  }, [labById.error]);

  const handleSubmit = (body: UpdateLab) => {
    labUpdate({ ...body, id: requestLabId });
  };

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <>
      <LabStaffCreateDrawer
        isOpen={(modal?.type === ModalTypes.create && modal.role === UserRoles.labStaff)
        || (modal?.type === ModalTypes.create && modal.role === UserRoles.labManager)}
        close={closeModal}
        isManager={modal?.role === UserRoles.labManager}
        onReload={modal?.onReload}
        labId={requestLabId}
      />
      <LabStaffInfoDrawer
        isOpen={(modal?.type === ModalTypes.info && modal.role === UserRoles.labStaff)
        || (modal?.type === ModalTypes.info && modal.role === UserRoles.labManager)}
        close={closeModal}
        id={modal?.id || ''}
        onReload={modal?.onReload}
      />
      <LabServices
        labId={requestLabId}
        loading={loading}
        error={error}
        isOpen={(modal?.type === ModalTypes.update && modal.key === 'service')}
        close={closeModal}
        handleSubmit={handleSubmit}
      />
      <Content>
        <PageHeader
          className="profile-header-content"
          title={labById.data?.name || 'Lab Name'}
          extra={(
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              {isRoleEnough('admin') && (
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Typography.Text type="secondary">
                    ID:
                  </Typography.Text>
                  <Typography.Text>
                    {labById.data?.accountNumber || '-'}
                  </Typography.Text>
                </div>
              )}
              <Status status={labById.data?.status || '-'} />
            </div>
          )}
          onBack={() => navigate('/labs', { replace: true })}
          footer={(
            <Collapse
              ghost
              bordered={false}
              accordion
              activeKey={collapseState ? 'showHideInfo' : 'table'}
            >
              <Collapse.Panel
                key="showHideInfo"
                showArrow={false}
                extra={(
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                      ghost
                      icon={collapseState ? <EyeClose /> : <EyeOpen />}
                      onClick={() => setCollapseState(!collapseState)}
                      style={buttonStyles}
                      loading={labById.loading}
                      id="toggleShowHideBtn"
                    >
                      <span style={{ width: '70px' }}>{collapseState ? 'Hide Info' : 'Show Info'}</span>
                    </Button>
                    <Button
                      type="default"
                      icon={<Sliders />}
                      style={buttonStyles}
                      onClick={() => openModal({ type: ModalTypes.update, key: 'service' })}
                      id="servicesBtn"
                    >
                      <span style={{ width: '70px' }}>Services</span>
                    </Button>
                  </div>
                )}
                header={(
                  <Descriptions
                    size="small"
                    column={2}
                    labelStyle={{ color: 'var(--color-main-dark-gray)', opacity: '0.7', fontSize: '14px' }}
                    contentStyle={{ color: 'var(--color-main-dark-gray)', fontWeight: '500', fontSize: '15px' }}
                  >
                    <Descriptions.Item label="Email">
                      {labById.data?.email || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Billing Address">
                      {formatAddressToString(labById.data?.addresses?.[0]) || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone">
                      {labById.data?.phone ? formatPhoneNumber(labById.data?.phone) : '-'}
                    </Descriptions.Item>
                  </Descriptions>
                )}
              >
                <Divider />
                <LabInfo
                  loading={loading}
                  initialData={initialData}
                  handleSubmit={handleSubmit}
                  reloadData={fetchLabId}
                />
              </Collapse.Panel>
              <Collapse.Panel
                key="table"
                header=""
                showArrow={false}
              >
                <TableLabStaff openModal={openModal} labId={requestLabId} />
              </Collapse.Panel>
            </Collapse>
          )}
        />
      </Content>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[labsModuleName].loading,
  error: state[labsModuleName].error,
  user: state[authModuleName].user,
});

const mapDispatchToProps = {
  labUpdate: actionLabUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Labs);
