import { PagingDataResponse } from '../types';
import { AdditionalOption } from './additionalOptions';
import { DefaultFetchError, FetchGet, FetchGetId, useFetchGet, useFetchGetId } from './fetch';
import { Material } from './materials';
import { Practice } from './practices';
import { Service } from './services';
import { ServiceStatus } from '../enums/services';

export interface PriceLevel {
  id: string;
  name: string;
  isActive: boolean;
  priceLevelServices?: PriceLevelService[];
  practices?: string[] | Practice[];
}

export interface PriceLevelService {
  id?: string;
  name?: string;
  status?: ServiceStatus;
  priceLevelAdditionalOptions?: AdditionalOption[];
  priceLevelMaterials?: Material[];
  // eslint-disable-next-line
  service?: Service;
  selected?: boolean;
}

export interface CreatePriceLevelService {
  id?: string;
  name?: string;
  status?: ServiceStatus;
  priceLevelAdditionalOptions?: AdditionalOption[];
  priceLevelMaterials?: Material[];
  service?: string;
}

export interface PriceLevelsTableRow {
  key: string;
  id: string;
  name: string;
  isActive: boolean;
}

export interface PriceLevelsTableData {
  priceLevels: PriceLevelsTableRow[];
  total: number;
}

export interface PriceLevelsGetParams {
  page?: number;
  take?: number;
  isActive?: boolean;
  name?: string;
  orderBy?: 'ASC';
  orderByColumn?: 'name' | 'createdAt'; // | 'created' | 'updated'
}

export function usePriceLevelsGet<D = PagingDataResponse<PriceLevel>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, PriceLevelsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, PriceLevelsGetParams, DD>(
    'price-levels',
    { decorateData, autoStart: false },
  );
}

// eslint-disable-next-line max-len
export const useTablePriceLevelsRow = (): FetchGet<PagingDataResponse<PriceLevel>, PriceLevelsGetParams, DefaultFetchError, PriceLevelsTableData> => (
  usePriceLevelsGet((data: PagingDataResponse<PriceLevel>): PriceLevelsTableData => ({
    priceLevels: data.data.map((priceLevel: PriceLevel): PriceLevelsTableRow => ({
      id: priceLevel.id,
      key: priceLevel.id,
      name: priceLevel.name,
      isActive: priceLevel.isActive,
    })),
    total: data.meta.itemCount,
  }))
);

export const usePriceLevelId = (): FetchGetId<PriceLevel> => useFetchGetId(
  'price-levels',
  '',
  { autoStart: false, startStateLoading: false },
);
