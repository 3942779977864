import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useState } from 'react';
import { ModalState, ModalTypes } from '../../../types';
import ServiceEdit from './Edit';
import ServicesTable from './Table';

const Practices: React.FC = () => {
  const [modal, setModal] = useState<ModalState | null>(null);

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <Content>
      <ServicesTable openModal={openModal} />
      <ServiceEdit
        serviceId={modal?.id}
        drawerOpen={modal?.type === ModalTypes.update && modal.key === 'service' && !!modal?.id}
        setDrawerOpen={closeModal}
      />
    </Content>
  );
};

export default Practices;
