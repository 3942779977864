import { SelectProps } from 'antd/lib/select';
import { App, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import NotFoundContent from '../../NotFoundContent';
import { LabStaffGetParams, useLabStaffGet, useLabStaffId } from '../../../../hooks/labStaff';
import { UserStatus } from '../../../../enums/user';

interface SelectLabStaff extends SelectProps<string | string[], Option> {
  lab?: string;
  requestOnlyWithLab?: boolean;
  onlyStatus?: UserStatus;
  allowAll?: boolean;
  getValueFromUser?: boolean;
  onlyLabManagers?: boolean;
}

const SelectLabStaff: React.FC<SelectLabStaff> = ({
  lab,
  requestOnlyWithLab,
  onlyStatus,
  allowAll,
  onClear,
  getValueFromUser,
  onlyLabManagers,
  ...props
}): React.JSX.Element => {
  const { message } = App.useApp();
  const labStaffGet = useLabStaffGet();
  const labStaffById = useLabStaffId();

  const [options, setOptions] = useState<Option[]>([]);
  const [labsName, setLabsName] = useState<string>('');

  const fetch = () => {
    const params: LabStaffGetParams = { page: 1, take: 100, orderBy: 'ASC', orderByColumn: 'name' };

    if (onlyStatus) {
      params.userStatus = onlyStatus;
    }

    if (lab) { params.labs = [lab]; }

    if (labsName) { params.name = labsName; }

    if (onlyLabManagers) { params.role = 'lab manager'; }

    labStaffGet.fetch(params);
  };

  useEffect(() => {
    if (requestOnlyWithLab && !lab) return undefined;

    if (labsName && labsName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }
    if (labsName === '') {
      fetch();
    }

    return undefined;
  }, [labsName, lab]);

  useEffect(() => {
    if (labStaffGet.data?.data.length) {
      setOptions(labStaffGet.data.data.map(({ user, id }): Option => (
        { label: `${user?.firstName} ${user?.lastName}`, value: getValueFromUser ? user?.id || '' : id }
      )));
    }
  }, [labStaffGet.data]);

  useEffect(() => {
    if (options.length && props.value) {
      const current = options.find(({ value }) => value === props.value);

      if (!current) {
        labStaffById.fetch(undefined, `${props.value}`)
          .then((res) => setOptions([
            ...options,
            { label: `${res?.user?.firstName} ${res?.user?.lastName}` || '',
              value: getValueFromUser ? res?.user?.id || '' : props.value as string },
          ]));
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (labStaffGet.error) {
      message.error(getMessageInError(labStaffGet.error));
      labStaffGet.clearError();
    }
  }, [labStaffGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  const handleClear = () => {
    setLabsName('');

    if (!onClear) return;

    onClear();
  };

  const optionsList = allowAll
    ? [{ label: 'All', value: '' }, ...options]
    : options;

  return (
    <Select
      loading={labStaffGet.loading || labStaffById.loading}
      options={optionsList}
      onSearch={(name) => setLabsName(name)}
      showSearch
      allowClear
      onClear={handleClear}
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Lab staff not found." />}
      {...props}
      value={labStaffGet.loading || labStaffById.loading ? 'Loading...' : props.value}

    />
  );
};

SelectLabStaff.defaultProps = {
  lab: undefined,
  onlyStatus: undefined,
  allowAll: false,
  requestOnlyWithLab: false,
  getValueFromUser: false,
  onlyLabManagers: false,
};

export default SelectLabStaff;
