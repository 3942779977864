import Icon, { DownOutlined, LockFilled, SettingFilled } from '@ant-design/icons';
import { App, Button, Col, Dropdown, Form, Row, Space } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { MenuItemGroupType, SubMenuType } from 'antd/lib/menu/hooks/useItems';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { UserRoles } from '../../../../../../enums/user';
import { Doctor, useDoctorId, useDoctorResendLink } from '../../../../../../hooks/doctors';
import { getMessageInError } from '../../../../../../hooks/fetch';
import { usePasswordForgot } from '../../../../../../hooks/password';
import { User as UserHooks, useUpdateUserStatus } from '../../../../../../hooks/users';
import { Action } from '../../../../../../store';
import { moduleName as authModuleName, User } from '../../../../../../store/ducks/auth';
import { Error } from '../../../../../../store/ducks/common';
import {
  AssignDoctor,
  assignDoctor as actionAssignDoctor,
  clearDoctorData as actionClearDoctorData,
  moduleName as doctorModuleName,
  updateDoctor as actionUpdateDoctor,
  UpdateDoctor,
} from '../../../../../../store/ducks/doctor';
import { RootState } from '../../../../../../store/reducers';
import { DefaultModalProps } from '../../../../../../types';
import { capitalizeFirstLetter, isObjEqualLodash, onActionClick } from '../../../../../../utils';
import { prepareDoctorBody, prepareDoctorInitialData } from '../../../../../../utils/prepareData';
import DrawerModal from '../../../../../Common/DrawerModal';
import { ArchiveFilled, Send } from '../../../../../Common/Icon';
import TablePatients from '../../../../Patients/Table';
import DoctorInfoForm from '../Forms/DoctorInfoForm';
import { isRoleEnough } from '../../../../../../utils/auth';

const getDoctorDisabledFields = (doctorData?: Doctor): { [key: string]: boolean; } | undefined => {
  if (!doctorData) return undefined;

  if (doctorData.user?.role === UserRoles.doctor && !doctorData?.practice) {
    return {
      position: true,
      firstName: true,
      lastName: true,
      nickname: true,
      specialty: true,
      email: true,
      phone: true,
      role: true,
    };
  }

  if (doctorData.user?.role === UserRoles.practiceManager && !isRoleEnough('admin')) {
    return {
      email: true,
    };
  }

  return undefined;
};

interface DoctorInfoDrawer extends DefaultModalProps {
  id: number | string;
  // redux props \/
  assignDoctor: (payload: AssignDoctor) => Action;
  updateDoctor: (payload: UpdateDoctor) => Action;
  clearDoctorData: () => Action;
  doctorLoading: boolean;
  doctor: Doctor | null;
  doctorError: Error | null;
  user: User | null;
  disableGetPracticeFromUrl?: boolean;
}

const DoctorInfoDrawer: React.FC<DoctorInfoDrawer> = React.memo(({
  id,
  isOpen,
  close,
  assignDoctor,
  updateDoctor,
  doctorLoading,
  doctor,
  doctorError,
  clearDoctorData,
  user,
  onReload,
  disableGetPracticeFromUrl,
}) => {
  const { message, modal } = App.useApp();
  const [form] = Form.useForm();
  const { id: paramsPracticeId = '' } = useParams<{ id: string; }>();
  const doctorById = useDoctorId();
  const doctorResendLink = useDoctorResendLink();
  const updateUserStatus = useUpdateUserStatus();
  const resetPassword = usePasswordForgot();

  const getRequestPracticeId = () => {
    if (disableGetPracticeFromUrl) {
      return (user?.role === 'practice manager' ? user?.doctor?.practice?.id : '');
    }

    return paramsPracticeId || (user?.role === 'practice manager' ? user?.doctor?.practice?.id : '');
  };

  const requestPracticeId = getRequestPracticeId();

  const [smileUser] = useState<UserHooks>();

  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);

  useEffect(() => {
    setIsFormChanged(!isObjEqualLodash(initialValues, form.getFieldsValue()));
  }, [doctor]);

  const fetchDoctorId = () => {
    doctorById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id && isOpen) {
      fetchDoctorId();
    }
  }, [id]);

  const doctorData = doctor || doctorById.data;
  const initialValues = {
    ...prepareDoctorInitialData(doctorData),
    practice: requestPracticeId || doctorData?.practice?.id,
  };

  useEffect(() => {
    if (form && doctorById.data && !doctorById.error && form) {
      form.setFieldsValue(initialValues);
    }
  }, [form, doctorById.data]);

  useEffect(() => {
    if (doctorById.error && isOpen) {
      message.error(getMessageInError(doctorById.error), 5);
      doctorById.clearError();
    }
  }, [doctorById.error]);

  useEffect(() => {
    if (doctorError?.message && isOpen) {
      message.error(doctorError.message, 5);
    }
  }, [doctorError]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const body = prepareDoctorBody(values, values.role, doctorById.data?.user?.id || undefined);

      if (smileUser && smileUser.doctor?.id) {
        assignDoctor({ practiceId: body.practice, doctorId: smileUser.doctor.id });

        return;
      }

      updateDoctor({ ...body, id: `${id}` });
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setIsFormChanged(false);
    setIsFormValid(true);
    setIsFormTouched(false);
  };

  const handleClose = () => {
    close();
    setIsFormTouched(false);
    clearDoctorData();
  };

  useEffect(() => {
    if (!doctorLoading && !doctorError && isOpen && isFormTouched) {
      handleClose();
      onReload?.();
      message.success('Doctor has been updated!');
    }
  }, [doctorLoading]);

  useEffect(() => {
    if (updateUserStatus.data && !updateUserStatus.error && id && isOpen) {
      fetchDoctorId();
      onReload?.();
    }
  }, [updateUserStatus.data]);

  const [lastActionName, setLastActionName] = useState<string>('');

  const items: (MenuItemType | SubMenuType | MenuItemGroupType)[] = [
    {
      label: 'Resend Link',
      key: '1',
      icon: <Icon component={Send} />,
      onClick: () => {
        onActionClick({
          modal,
          title: 'Resend Link',
          content: 'Are you sure you want to resend link?',
          actionName: 'resend link',
          hook: () => doctorResendLink.fetch(undefined, `${id}/resend-link`),
        });
        setLastActionName('link was send');
      },
      className: 'resendLinkBtn',
    },
    {
      label: 'Reset Password',
      key: '2',
      icon: <LockFilled />,
      onClick: () => {
        onActionClick({
          modal,
          title: 'Reset Password',
          content: 'Are you sure you want to reset password for this user?',
          actionName: 'reset password',
          hook: () => resetPassword.fetch({ email: doctorById.data?.user?.email || '' }),
        });
        setLastActionName('Password reset link was send');
      },
      className: 'resetPasswordBtn',
    },
    doctorById.data?.user?.status === 'deactivated' ? {
      label: 'Activate',
      key: '5',
      icon: <SettingFilled />,
      onClick: () => {
        onActionClick({
          modal,
          title: 'Activate',
          actionName: 'activate',
          hook: () => updateUserStatus.fetch({ status: 'active' }, doctorById.data?.user?.id),
        });
        setLastActionName('activated');
      },
      className: 'resendLinkBtn',
    } : {
      label: 'Deactivate',
      key: '3',
      icon: <SettingFilled />,
      onClick: () => {
        onActionClick({
          modal,
          title: 'Deactivate',
          actionName: 'deactivate',
          hook: () => updateUserStatus.fetch({ status: 'deactivated' }, doctorById.data?.user?.id),
        });
        setLastActionName('deactivated');
      },
      className: 'deactivateBtn',
    },
    doctorById.data?.user?.status === 'archived' ? {
      label: 'Unarchive',
      key: '6',
      icon: <ArchiveFilled size={12} />,
      onClick: () => {
        onActionClick({
          modal,
          title: 'Activate',
          actionName: 'activate',
          hook: () => updateUserStatus.fetch({ status: 'active' }, doctorById.data?.user?.id),
        });
        setLastActionName('activated');
      },
      className: 'unarchiveBtn',
    } : {
      label: 'Archive',
      key: '4',
      icon: <ArchiveFilled size={12} />,
      onClick: () => {
        onActionClick({
          modal,
          title: 'Archive',
          actionName: 'archive',
          hook: () => updateUserStatus.fetch({ status: 'archived' }, doctorById.data?.user?.id),
        });
        setLastActionName('archived');
      },
      className: 'archiveBtn',
    },
  ];

  const menuItems = items
    .filter((item) => doctorById.data?.user?.status === 'pending'
      ? item.label === 'Resend Link' : item.label !== 'Resend Link')
    .filter((item) => (doctorById.data?.user?.status === 'deactivated'
      || doctorById.data?.user?.status === 'archived')
      ? item : (item.label !== 'Archive' && item.label !== 'Unarchive'));

  useEffect(() => {
    if ((updateUserStatus.data && !updateUserStatus.error) || (doctorResendLink.data && !doctorResendLink.error)
    || (resetPassword.data && !resetPassword.error)) {
      message.success(`${capitalizeFirstLetter(lastActionName)}!`);
    }
  }, [updateUserStatus.data, doctorResendLink.data, resetPassword.data]);

  useEffect(() => {
    if (updateUserStatus.error) {
      message.error(getMessageInError(updateUserStatus.error));
      updateUserStatus.clearError();
    }
  }, [updateUserStatus.error]);

  useEffect(() => {
    if (doctorResendLink.error) {
      message.error(getMessageInError(doctorResendLink.error));
      doctorResendLink.clearError();
    }
  }, [doctorResendLink.error]);

  useEffect(() => {
    if (resetPassword.error) {
      message.error(getMessageInError(resetPassword.error));
      resetPassword.clearError();
    }
  }, [resetPassword.error]);

  return (
    <DrawerModal
      title={initialValues.role === 'practice manager' ? 'Practice Manager Profile' : 'Doctor Profile'}
      status={doctorById.data?.user?.status || ''}
      open={isOpen}
      onClose={handleClose}
      extra={(
        <Space>
          <Dropdown
            trigger={['click']}
            key="button"
            menu={{ items: menuItems }}
          >
            <Button ghost onClick={(e) => e.preventDefault()} id="actionsDropdownButton">
              <Space>
                <SettingFilled />
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Space>
      )}
      destroyOnClose
      afterOpenChange={() => {
        form.resetFields();
        setIsFormChanged(false);
        setIsFormTouched(false);
      }}
      isFormChanged={isFormChanged}
    >
      <Row justify="center">
        <Col span={24} md={20} lg={16} xxl={12}>
          <div>
            <Form
              form={form}
              name="doctor_info"
              layout="vertical"
              autoComplete="off"
              initialValues={initialValues}
              onFieldsChange={() => {
                setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length));
                setIsFormChanged(!isObjEqualLodash(initialValues, form.getFieldsValue()));
                if (!isFormTouched) { setIsFormTouched(true); }
              }}
            >
              <DoctorInfoForm
                cardLoading={doctorById.loading}
                editMode={{
                  onSubmit: handleSubmit,
                  isFormValid,
                  onCancel: handleCancel,
                  loading: doctorLoading,
                }}
                disabled={getDoctorDisabledFields(doctorById?.data)}
                modalPracticeId={requestPracticeId}
              />
            </Form>
            <div style={{ marginBottom: '30px' }}>
              <TablePatients params={{ doctor: `${id}` }} isDoctorInfoPage />
            </div>
          </div>
        </Col>
      </Row>
    </DrawerModal>
  );
});

DoctorInfoDrawer.displayName = 'DoctorInfoDrawer';

DoctorInfoDrawer.defaultProps = {
  onReload: undefined,
  disableGetPracticeFromUrl: false,
};

export default connect((state: RootState) => ({
  doctorLoading: state[doctorModuleName].doctorLoading,
  doctor: state[doctorModuleName].doctor,
  doctorError: state[doctorModuleName].doctorError,
  user: state[authModuleName].user,
}), {
  updateDoctor: actionUpdateDoctor,
  assignDoctor: actionAssignDoctor,
  clearDoctorData: actionClearDoctorData,
})(DoctorInfoDrawer);
