export enum LabStatuses {
  active = 'active',
  pending = 'pending',
  archived = 'archived',
  deactivated = 'deactivated',
}

export type LabStatus = 'active' | 'pending' | 'archived' | 'deactivated';

export enum LabStatusesOptions {
  active = 'Active',
  pending = 'Pending',
  archived = 'Archived',
  deactivated = 'Deactivated',
}

export enum LabRolesOptions {
  'lab manager' = 'Lab Manager',
  'lab staff' = 'Lab Staff',
}
