import React, { useEffect, useState } from 'react';
import { App, Col, Form, Row } from 'antd';
import { connect } from 'react-redux';
import { useUpdateCase } from '../../../../hooks/case/cases';
import { TableLabStaffRow } from '../../../../hooks/labStaff';

import { User, moduleName as authModuleName } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { DefaultModalProps } from '../../../../types';
import {
  prepareCaseAssignStaffBody,
  prepareCaseAssignStaffInitialData,
  prepareCaseUnassignStaffBody, prepareCaseUnassignStaffFromServiceBody, PreparedCaseAssignLabInitialData,
} from '../../../../utils/prepareData';
import DrawerModal from '../../../Common/DrawerModal';
import { useContextCaseFlow } from '../../../../context/caseFlow';
import Loading from '../../../Common/Loading';
import AssignStaffTable from '../AssignStaffTable';
import AssignServiceForm from '../Forms/AssignServiceForm';

interface AssignStaff extends DefaultModalProps {
  id: string | number;
  title: string;
  user: User | null;
}

const AssignStaff = (props: AssignStaff): React.JSX.Element => {
  const { message } = App.useApp();
  const {
    isOpen,
    close,
    id,
    title,
    user,
  } = props;

  const {
    caseByIdInContext,
    practicePriceLevelServiceInContext,
    getCaseServiceByPriceLevelServiceId,
  } = useContextCaseFlow();

  const [assignServiceForm] = Form.useForm();
  // const updateCaseService = useUpdateCaseService();
  const updateCase = useUpdateCase();
  const [caseServicesLabs, setCaseServicesLabs] = useState<string[] | null | undefined>(null);
  const [selectedLabs, setSelectedLabs] = useState<string[] | null | undefined>(null);
  const [selectedLabServices, setSelectedLabServices] = useState<string[]>([]);
  const [selectedAdditionalOptions, setSelectedAdditionalOptions] = useState<string[]>([]);

  useEffect(() => {
    if (user?.labStaff?.lab?.id) {
      setSelectedLabs([user.labStaff.lab.id]);
    }
  }, [user]);

  /** Get case info by id */
  const fetchCaseById = () => {
    caseByIdInContext?.fetch(undefined, id);
  };

  useEffect(() => {
    if (id && isOpen) {
      fetchCaseById();
    }
  }, [id]);

  /** Handling close/next/back/submit buttons  */
  const handleClose = () => {
    close();
    assignServiceForm.resetFields();
  };

  const handleSubmit = (staff: TableLabStaffRow, type?: 'assign' | 'unassign' | 'unassignById', itemId?: string) => {
    if (type === 'unassignById') {
      const body = prepareCaseUnassignStaffFromServiceBody(
        caseByIdInContext?.data?.caseServices,
        staff,
        itemId,
      );

      updateCase.fetch(
        { caseServices: body },
        id,
      ).then(() => {
        fetchCaseById();
      }).catch((error) => {
        message.error(error, 5);
      });

      return;
    }

    if (type === 'unassign') {
      const body = prepareCaseUnassignStaffBody(
        caseByIdInContext?.data?.caseServices,
        staff,
      );

      updateCase.fetch(
        { caseServices: body },
        id,
      ).then(() => {
        fetchCaseById();
      }).catch((error) => {
        message.error(error, 5);
      });

      // Promise.all(body.map((caseService) => updateCaseService.fetch(
      //   { labStaff: caseService.labStaff },
      //   caseService.id,
      // ))).then(() => {
      //   fetchCaseById();
      // }).catch((error) => {
      //   message.error(error, 5);
      // });
    } else {
      assignServiceForm.validateFields().then((values) => {
        const selectedServices = (caseByIdInContext?.data?.practice?.priceLevelServices
          || practicePriceLevelServiceInContext?.data?.priceLevel.priceLevelServices)
          ?.filter((service) => (values?.services?.includes(service?.id || '')))
          ?.map((service) => ({
            ...service,
            priceLevelAdditionalOptions: service.priceLevelAdditionalOptions?.filter((priceAdditionalOption) => (
              caseByIdInContext?.data?.teeth?.services?.find((serv) => serv.id === service.id)?.additionalOptions
                ?.some((additionalOption) => additionalOption?.id === priceAdditionalOption?.id || '')
            )),
          }
          )) || [];

        const body = prepareCaseAssignStaffBody(
          selectedServices,
          staff,
          caseByIdInContext?.data?.caseServices,
        );

        updateCase.fetch(
          { caseServices: body },
          id,
        ).then(() => {
          fetchCaseById();
        }).catch((error) => {
          message.error(error, 5);
        });

        // Promise.all(body.map((caseService) => updateCaseService.fetch(
        //   { labStaff: caseService.labStaff },
        //   caseService.id,
        // ))).then(() => {
        //   fetchCaseById();
        // }).catch((error) => {
        //   message.error(error, 5);
        // });
      });
    }
  };

  const [initialValues, setInitialValues] = useState<PreparedCaseAssignLabInitialData>({});

  useEffect(() => {
    if (caseByIdInContext?.data && (caseByIdInContext?.data?.practice || practicePriceLevelServiceInContext?.data)) {
      setInitialValues(prepareCaseAssignStaffInitialData(
        caseByIdInContext?.data,
        (caseByIdInContext?.data?.practice?.priceLevelServices
          || practicePriceLevelServiceInContext?.data?.priceLevel?.priceLevelServices),
      ));
    }
  }, [caseByIdInContext?.data, practicePriceLevelServiceInContext?.data]);

  useEffect(() => {
    if (assignServiceForm && caseByIdInContext?.data && !caseByIdInContext.error) {
      assignServiceForm.resetFields();
      assignServiceForm.setFieldsValue(initialValues);
    }
  }, [assignServiceForm, caseByIdInContext?.data, practicePriceLevelServiceInContext?.data, initialValues]);

  const servicesArrValues: string[] = Form.useWatch('services', assignServiceForm);

  useEffect(() => {
    if (!isOpen) return;

    if (servicesArrValues?.length) {
      if (!user?.labStaff?.lab?.id) {
        const caseService = getCaseServiceByPriceLevelServiceId(servicesArrValues[0]);

        if (caseService?.labService?.lab?.id) {
          setSelectedLabs([caseService.labService.lab.id]);
        }
      }

      const newSelectedLabServices: string[] = [];
      const newSelectedAdditionalOptions: string[] = [];

      servicesArrValues.forEach((priceLevelService) => {
        const service = getCaseServiceByPriceLevelServiceId(priceLevelService);

        newSelectedLabServices.push(service?.labService?.service?.id as string);
        newSelectedAdditionalOptions.push(...(service?.caseAdditionalOptions
          ?.map((option) => option?.labAdditionalOption?.id as string) || []));
      });

      setSelectedLabServices(newSelectedLabServices);
      setSelectedAdditionalOptions(newSelectedAdditionalOptions);
    } else {
      if (!user?.labStaff?.lab?.id) {
        setSelectedLabs(null);
      }
      setSelectedLabServices([]);
      setSelectedAdditionalOptions([]);
    }
  }, [servicesArrValues, isOpen]);

  useEffect(() => {
    if (caseByIdInContext?.data?.caseServices?.length) {
      const labsIds = caseByIdInContext?.data?.caseServices
        ?.filter((caseService) => caseService?.labService?.lab?.id)
        ?.map((caseService) => caseService?.labService?.lab?.id ? caseService?.labService?.lab?.id : '');

      setCaseServicesLabs(labsIds);
    }
  }, [caseByIdInContext?.data]);

  const [tableParams, setTableParams] = useState({
    services: '',
    serviceAdditionalOptions: '',
    userStatus: 'active',
  });

  useEffect(() => {
    setTableParams({
      services: selectedLabServices?.join(',') || '',
      serviceAdditionalOptions: selectedAdditionalOptions?.join(',') || '',
      userStatus: 'active',
    });
  }, [selectedLabServices, selectedAdditionalOptions]);

  return (
    <div>
      <DrawerModal
        title={title}
        open={isOpen}
        zIndex={1001}
        onClose={handleClose}
        destroyOnClose
      >
        <Row justify="center">
          <Loading visible={!!caseByIdInContext?.loading} absolute />
          <Col
            span={24}
            md={23}
            lg={22}
          >
            <div>
              <AssignServiceForm
                form={assignServiceForm}
                initialData={initialValues}
                formType="staff"
                isDatePickerDisabled
                selectedLab={selectedLabs?.[0]}
              />
              <AssignStaffTable
                isActionDisabled={!servicesArrValues?.length}
                onRowAction={handleSubmit}
                labIds={selectedLabs || caseServicesLabs}
                params={tableParams}
              />
            </div>
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state[authModuleName].user,
});

AssignStaff.defaultProps = {
  onReload: undefined,
  isAdmin: true,
  disabled: undefined,
  initialData: undefined,
  handleSave: undefined,
};

export default connect(mapStateToProps)(AssignStaff);
