import { createContext, useContext } from 'react';

interface TableContext {
  onFilterClose?: () => void;
}

const defaultValue = {
  onFilterClose: undefined,
};

export const TableContext = createContext<TableContext>(defaultValue);

export const useContextTable = (): TableContext => useContext(TableContext);
