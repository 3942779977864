import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

interface FileDownloadContext {
  isAllDownloaded: boolean;
  setIsAllDownloaded: (state: boolean) => void;
}

export const defaultValue = {
  isAllDownloaded: false,
  setIsAllDownloaded: (): undefined => undefined,
};

export const FileDownloadContext = createContext<FileDownloadContext>(defaultValue);

const FileDownloadProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isAllDownloaded, setIsAllDownloaded] = useState(true);

  return (
    <FileDownloadContext.Provider value={{ isAllDownloaded, setIsAllDownloaded }}>
      {children}
    </FileDownloadContext.Provider>
  );
};

export default FileDownloadProvider;

export const useContextFileDownload = (): FileDownloadContext => useContext(FileDownloadContext);
