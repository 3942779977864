import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { App, FormInstance } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { LabStatus } from '../../../../enums/lab';
import { Report, useTableLabInvoiceReport } from '../../../../hooks/reports';
import { JsonResult } from '../../../../types';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import ContentCard from '../../../Common/ContentCard';
import Status from '../../../Common/Status';
import Table from '../../../Common/Table';
import { getMessageInError } from '../../../../hooks/fetch';
import PriceBlock from '../../../Common/PriceBlock';

interface LabInvoice {
  params: JsonResult | null;
}

const serviceLineHeight = 25;

const LabInvoice: React.FC<LabInvoice> = (props) => {
  const { message } = App.useApp();
  const { params } = props;
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const generateLabInvoiceReport = useTableLabInvoiceReport();

  useEffect(() => {
    if (!params) return;

    actionRef.current?.reload();
  }, [params]);

  const getServiceOptionsCount = (caseService: JsonResult) => (
    ((caseService?.caseMaterials?.length || 0) + (caseService?.caseAdditionalOptions?.length || 0)) || 1);

  const getPriceByService = (caseService: JsonResult): { servicePrice: number; leadTimePrice: number; } => {
    let servicePrice = 0;

    caseService?.caseMaterials?.forEach((caseMaterial: JsonResult) => {
      servicePrice += (caseMaterial?.price || 0);
    });

    caseService?.caseAdditionalOptions?.forEach((caseAdditionalOption: JsonResult) => {
      servicePrice += (caseAdditionalOption?.price || 0);
    });

    return { servicePrice, leadTimePrice: caseService?.leadTimePrice || 0 };
  };

  const getPriceByCase = (caseServices: JsonResult[]) => {
    let casePrice = 0;

    caseServices?.forEach((caseService) => {
      const priceByService = getPriceByService(caseService);

      casePrice += (priceByService.servicePrice + priceByService.leadTimePrice);
    });

    return casePrice;
  };

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<Report>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    return generateLabInvoiceReport.fetch({
      ...newParams,
      ...params,
      practice: undefined,
      doctor: undefined,
    }).then((data) => {
      if (data) {
        const { reports, total } = data;

        return ({ data: reports || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  useEffect(() => {
    if (generateLabInvoiceReport.error) {
      message.error(getMessageInError(generateLabInvoiceReport.error));
      generateLabInvoiceReport.clearError();
    }
  }, [generateLabInvoiceReport.error]);

  const columns: ProColumns<Report>[] = [
    {
      title: 'Case',
      sorter: false,
      dataIndex: 'id',
      width: 74,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: false,
      renderText: (status: LabStatus) => (
        <Status status={status} />
      ),
    },
    {
      title: 'Services',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.caseServices?.length
        ? row?.caseServices?.map((caseService: JsonResult) => (
          <div
            key={caseService.id}
            style={{ height: serviceLineHeight * getServiceOptionsCount(caseService), marginBottom: 10 }}
          >
            {caseService?.labService?.service?.name?.split(' ')?.[0]}
          </div>
        ))
        : '-',
    },
    {
      title: 'Options',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.caseServices?.length
        ? row?.caseServices?.map((caseService: JsonResult) => (
          <div key={`${caseService?.id}-options`} style={{ marginBottom: 10 }}>
            {caseService?.caseMaterials?.map((caseMaterial: JsonResult) => (
              <div key={`${caseMaterial?.id}-material`} style={{ height: serviceLineHeight }}>
                {caseMaterial?.labMaterial?.material?.name}
              </div>
            ))}
            {caseService?.caseAdditionalOptions?.map((caseAdditionalOption: JsonResult) => (
              <div key={`${caseAdditionalOption?.id}-additionalOption`} style={{ height: serviceLineHeight }}>
                {caseAdditionalOption?.labAdditionalOption?.additionalOption?.name}
              </div>
            ))}
          </div>
        ))
        : '-',
    },
    {
      title: 'Lab total price',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.caseServices?.length
        ? row?.caseServices?.map((caseService: JsonResult) => (
          <div key={`${caseService?.id}-options-price`} style={{ marginBottom: 10 }}>
            {caseService?.caseMaterials?.map((caseMaterial: JsonResult) => (
              <div key={`${caseMaterial?.id}-material-price`} style={{ height: serviceLineHeight }}>
                $
                {caseMaterial?.price}
              </div>
            ))}
            {caseService?.caseAdditionalOptions?.map((caseAdditionalOption: JsonResult) => (
              <div key={`${caseAdditionalOption?.id}-additionalOption-price`} style={{ height: serviceLineHeight }}>
                $
                {caseAdditionalOption?.price}
              </div>
            ))}
          </div>
        ))
        : '-',
    },
    {
      title: 'Total per service',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.caseServices?.length
        ? row?.caseServices?.map((caseService: JsonResult) => {
          const priceByService = getPriceByService(caseService);

          return (
            <div
              key={`${caseService?.id}-price`}
              style={{ height: serviceLineHeight * getServiceOptionsCount(caseService), marginBottom: 10 }}
            >
              <PriceBlock
                price={priceByService.servicePrice}
                leadTimePrice={priceByService.leadTimePrice}
                wrap
                cutIfNeeded
              />
            </div>
          );
        })
        : '-',
    },
    {
      title: 'Total per case',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.caseServices?.length ? `$${getPriceByCase(row.caseServices)}` : '-',
    },
    {
      title: 'Due date',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.dueDate ? dayjs(row.dueDate).format('MMM DD, YYYY') : '-',
    },
  ];

  return (
    <ContentCard paddingSize="small">
      <Table<Report>
        formRef={formRef}
        columns={columns}
        className={clsx('simpleList', 'listWithoutFilters')} // listAlignTop
        columnsState={{ persistenceKey: 'pro-table-lab-report', persistenceType: 'localStorage' }}
        request={params ? tableRequest : undefined}
        actionRef={actionRef}
        form={{ ignoreRules: false }}
        rowClassName="cursor-pointer"
        showSorterTooltip={false}
      />
    </ContentCard>
  );
};

export default LabInvoice;
