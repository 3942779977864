import React from 'react';
import { Layout } from 'antd';
import { displayName } from '../../../config';
import CaseFlowProvider from '../../../context/caseFlow';
import FileDownloadProvider from '../../../context/fileDownload';
import CasesCalendar from '../../../components/Pages/Cases/Calendar';
import CasesCalendarProvider from '../../../context/caseCalendar';

const CasesCalendarPage = (): React.JSX.Element => {
  document.title = `${displayName}: Calendar`;

  return (
    <Layout>
      <CaseFlowProvider>
        <CasesCalendarProvider>
          <FileDownloadProvider>
            <CasesCalendar />
          </FileDownloadProvider>
        </CasesCalendarProvider>
      </CaseFlowProvider>
    </Layout>
  );
};

export default CasesCalendarPage;
