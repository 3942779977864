import React, { useEffect, useState } from 'react';
import { TableLabRow, useLabId } from '../../../../../hooks/labs';
import LabServicesForm from '../../../Labs/Forms/LabServicesForm';
import { LabService } from '../../../../../hooks/services';
import { prepareLabServicesInitialData } from '../../../../../utils/prepareData';
import { useContextCaseFlow } from '../../../../../context/caseFlow';

interface ExpandedRowItem {
  row: TableLabRow | undefined;
}

const ExpandedRowItem: React.FC<ExpandedRowItem> = ({ row }) => {
  const labById = useLabId();
  const servicesState = useState<LabService[] | undefined>();
  const [services, setServices] = servicesState;
  const { servicesGetInContext } = useContextCaseFlow();

  const fetchLabById = (labId: string) => {
    if (!labId) return;
    labById.fetch(undefined, labId);
  };

  useEffect(() => {
    if (row?.id) {
      fetchLabById(row.id);
    }
  }, [row?.id]);

  useEffect(() => {
    if (!servicesGetInContext?.data?.data || !labById.data || !labById.data.labServices) return;

    setServices(prepareLabServicesInitialData(labById.data.labServices));
  }, [labById.data, servicesGetInContext?.data]);

  return (
    <div>
      <LabServicesForm
        servicesState={servicesState}
        editMode={{
          onSubmit: () => undefined,
          hideControls: true,
        }}
        loading={servicesGetInContext?.loading || labById.loading}
        paddingSize="small"
        hideTitle
      />
    </div>
  );
};

export default ExpandedRowItem;
