import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import React from 'react';
import { Callbacks } from 'rc-field-form/lib/interface';
import { connect } from 'react-redux';
import { JsonResult } from '../../../../../types';
import { RootState } from '../../../../../store/reducers';
import { moduleName } from '../../../../../store/ducks/patient';
import AdminCaseFormItems from './FormItems';

interface AdminCaseCreateForm {
  disabled?: {
    [key: string]: boolean;
  };
  onSubmit?: () => void;
  loading?: boolean;
  form: FormInstance;
  onFieldsChange?: Callbacks<JsonResult>['onFieldsChange'];
}

const AdminCaseCreateForm: React.FC<AdminCaseCreateForm> = (props) => {
  const { disabled, onSubmit, form, loading, onFieldsChange } = props;

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit && onSubmit}
        autoComplete="off"
        onFieldsChange={(e, y) => {
          if (onFieldsChange) onFieldsChange(e, y);
        }}
      >
        <AdminCaseFormItems
          loading={loading}
          disabled={disabled?.patient}
        />
      </Form>
    </div>
  );
};

AdminCaseCreateForm.defaultProps = {
  disabled: undefined,
  loading: false,
  onSubmit: () => undefined,
  onFieldsChange: undefined,
};

export default connect((state: RootState) => ({
  patientResponse: state[moduleName].patientResponse,
}))(AdminCaseCreateForm);
