// version: 3.0.0 - fix generic types and add cache

import { useCallback, useEffect, useRef, useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../utils';
import { FetchError, FetchSuccess, RequestResult } from '../types';
import { RootState } from '../store/reducers';
import { moduleName, reLogin as actionReLogin } from '../store/ducks/auth';
import { store } from '../store';
import { SimpleObject } from '../store/ducks/common';

export interface PagingParams extends SimpleObject {
  page?: number;
  pageSize?: number;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: string;
}

export interface FetchProps<Data, Params = undefined, DecorateData = Data> {
  fetchCreator: (token?: string, props?: Params, ...args: unknown[]) => Promise<AxiosResponse<Data>>;
  decorateData?: (data: Data) => DecorateData;
  startStateLoading?: boolean;
  multiple?: string;
  cacheLifetime?: number;
}

export type DefaultFetchError = FetchError;

export interface DefaultFetch<Data, Error = DefaultFetchError, Params = undefined, DecorateData = Data>
  extends RequestResult {
  fetch: (props?: Params) => Promise<DecorateData | null>;
  finish: (data?: DecorateData) => void;
  error: AxiosError<Error> | null;
  response: AxiosResponse<Data> | undefined;
  clearError: () => void;
  clearResponse: () => void;
}

export interface FetchHooks<Data, Error = DefaultFetchError, Params = undefined, DecorateData = Data>
  extends DefaultFetch<Data, Error, Params, DecorateData> {
  data?: DecorateData;
}

// eslint-disable-next-line
const requestQueue: { [key: string]: Promise<any>; } = {};

interface ICache<Data> {
  cacheLifetime: number; // milliseconds
  response: AxiosResponse<Data> | undefined;
}

// eslint-disable-next-line
const cache: { [key: string]: ICache<any>; } = {};

export function useFetch<Data, Error = DefaultFetchError, Params = undefined, DecorateData = Data>({
  fetchCreator,
  decorateData,
  startStateLoading = false,
  multiple, // string key
  cacheLifetime, // milliseconds. required multiple variable
}: FetchProps<Data, Params, DecorateData>): FetchHooks<Data, Error, Params, DecorateData> {
  const live = useRef<boolean>(true);
  const { accessToken } = useSelector((state: RootState) => state[moduleName]);
  // const dispatch = useDispatch();
  // const reLogin = useCallback(() => dispatch(actionReLogin()), [dispatch]);
  const reLogin = (resolve: (value: string) => Promise<void>) => store.dispatch(actionReLogin(resolve));

  const [loading, setLoading] = useState(startStateLoading);
  const [error, setError] = useState<AxiosError<Error> | null>(null);
  const [data, setData] = useState<DecorateData>();
  const [response, setResponse] = useState<AxiosResponse<Data> | undefined>();

  useEffect(() => {
    if (response && cacheLifetime && multiple) {
      cache[multiple] = {
        cacheLifetime: Date.now() + cacheLifetime,
        response,
      };

      setTimeout(() => {
        delete cache[multiple];
      }, cacheLifetime);
    }
  }, [response]);

  const fetch = useCallback(async (params?: Params, ...args: unknown[]): Promise<DecorateData | null> => {
    const response = multiple && cache[multiple] ? cache[multiple].response : undefined;

    setError(null);
    setLoading(true);

    if (process.env.REACT_APP_FETCH_DELAY) {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, parseInt(process.env.REACT_APP_FETCH_DELAY || '0', 10)));
    }

    const checkResponse = async (useReLogin = false, token = accessToken): Promise<DecorateData | null> => {
      let promise = useReLogin && multiple ? requestQueue[multiple] : undefined;

      const prepareData = (response: AxiosResponse<Data>): DecorateData | null => {
        const result = decorateData ? decorateData(response.data) : response.data;

        setData(result as DecorateData);
        setResponse(response);

        return result as DecorateData;
      };

      if (!promise) {
        if (response) {
          return prepareData(response);
        }

        promise = fetchCreator(token || '', params, ...args, response);

        if (multiple) {
          requestQueue[multiple] = promise;
        }
      }

      let resultPromiseAfterReLogin = null;
      const resultPromise = await promise.then((response) => {
        if (!live.current) {
          return null;
        }

        return prepareData(response);
      }).catch(async (e) => {
        if (!live.current) {
          return null;
        }

        // if necessary, change the condition in more detail
        if (useReLogin && e.response?.status === 401) {
          await new Promise((resolve) => {
            reLogin(async (value: string) => {
              resultPromiseAfterReLogin = await checkResponse(false, value);

              resolve(resultPromiseAfterReLogin);
            });
          });

          return null;
        }

        setError(e);

        return e;
      }).finally(() => {
        if (live.current) {
          setLoading(false);
        }

        if (multiple) {
          delete requestQueue[multiple];
        }
      });

      return resultPromiseAfterReLogin || resultPromise;
    };

    return checkResponse(true);
  }, [accessToken]);

  useEffect(() => () => {
    live.current = false;
  }, []);

  return {
    loading,
    error,
    data,
    fetch,
    response,
    finish: (result) => {
      setData(result);
      setLoading(false);
      setError(null);
    },
    clearError: () => setError(null),
    clearResponse: () => setData(undefined),
  };
}

export interface FetchGet<Data, Params = undefined, Error = DefaultFetchError, DecorateData = Data>
  extends DefaultFetch<Data, Error, Params, DecorateData> {
  data?: DecorateData;
}

export interface FetchOptions<Data, Params, DecorateData = Data> {
  url?: string;
  authorization?: boolean;
  decorateData?: (data: Data) => DecorateData;
  config?: AxiosRequestConfig;
  params?: Params;
  autoStart?: boolean;
  multiple?: string;
  cacheLifetime?: number;
  startStateLoading?: boolean;
}

export type FetchGetOptions<Data, Params, DecorateData = Data> = FetchOptions<Data, Params, DecorateData>;

export function useFetchGet<Data, Error = DefaultFetchError, Params = undefined, DecorateData = Data>(
  path: string,
  options?: FetchGetOptions<Data, Params, DecorateData>,
): FetchGet<Data, Params, Error, DecorateData> {
  const {
    url,
    decorateData,
    config = {},
    params = {},
    autoStart = true,
    authorization = true,
    startStateLoading = true,
    multiple,
    cacheLifetime,
  } = options || {};

  const { fetch, ...args } = useFetch<Data, Error, Params, DecorateData>({
    fetchCreator: (token, paramsCreator?: Params) => {
      const headers = {
        ...config?.headers,
      };

      if (authorization) {
        headers.Authorization = `Bearer ${token}`;
      }

      return axios.get<Data>(
        url || `${process.env.REACT_APP_API_URL}/${path}`,
        {
          ...config,
          headers,
          params: {
            ...config?.params,
            ...params,
            ...paramsCreator,
          },
        },
      );
    },
    decorateData,
    startStateLoading,
    multiple,
    cacheLifetime,
  });

  useEffect(() => {
    if (autoStart) {
      fetch();
    }
  }, []);

  return {
    ...args,
    fetch,
  };
}

export interface FetchGetId<Data, Error = DefaultFetchError, Params = undefined, DecorateData = Data>
  extends DefaultFetch<Data, Error, Params, DecorateData> {
  data?: DecorateData;
  fetch: (params?: Params, id?: string | number) => Promise<DecorateData | null>;
}

export type FetchGetIdOptions<Data, Params, DecorateData = Data> = FetchOptions<Data, Params, DecorateData>;

export function useFetchGetId<Data, Error = DefaultFetchError, Params = undefined, DecorateData = Data>(
  path: string,
  // eslint-disable-next-line default-param-last
  initialId = '',
  // eslint-disable-next-line default-param-last
  options: FetchGetIdOptions<Data, Params, DecorateData> = {},
  responseType: 'arraybuffer' | 'json' | 'blob' | 'text' | 'stream' | 'document' = 'json',
  axiosOnDownloadProgress: (progressEvent: ProgressEvent) => void = () => undefined,
): FetchGetId<Data, Error, Params, DecorateData> {
  const {
    url,
    decorateData,
    config = {},
    params = {},
    autoStart = true,
    authorization = true,
    startStateLoading = false,
    multiple,
  } = options || {};

  const { fetch, ...args } = useFetch<Data, Error, Params, DecorateData>({
    fetchCreator: (token, paramsCreator?: Params, id = initialId) => {
      const headers = {
        ...config?.headers,
      };

      if (authorization) {
        headers.Authorization = `Bearer ${token}`;
      }

      return axios.get<Data>(
        url || `${process.env.REACT_APP_API_URL}/${path}${id ? `/${id}` : ''}`,
        {
          ...config,
          headers,
          params: {
            ...config?.params,
            ...params,
            ...paramsCreator,
          },
          responseType,
          onDownloadProgress: axiosOnDownloadProgress
            ? (progressEvent) => axiosOnDownloadProgress(progressEvent) : undefined,
        },
      );
    },
    decorateData,
    startStateLoading,
    multiple,
  });

  useEffect(() => {
    if (autoStart) {
      fetch();
    }
  }, []);

  return {
    ...args,
    fetch,
  };
}

export interface FetchCreate<Data = FetchSuccess, Error = DefaultFetchError, Params = undefined>
  extends DefaultFetch<Data, Error, Params> {
  data?: Data;
  fetch: (formData?: Params, id?: string) => Promise<Data | null>;
}

export type FetchCreateOptions<Data, Params> = FetchOptions<Data, Params>;

export function useFetchCreate<Data, Error, Params>(
  path: string,
  options?: FetchCreateOptions<Data, Params>,
  axiosOnUploadProgress: (progressEvent: ProgressEvent) => void = () => undefined,
): FetchCreate<Data, Error, Params> {
  const {
    url,
    decorateData,
    config = {},
    params = {},
    authorization = true,
    startStateLoading = false,
  } = options || {};

  return useFetch<Data, Error, Params>({
    fetchCreator: (token, formData?: Params, partUrl = '') => {
      const headers = {
        ...config?.headers,
      };

      if (authorization) {
        headers.Authorization = `Bearer ${token}`;
      }

      return axios.post<Data>(
        url || `${process.env.REACT_APP_API_URL}/${path}${partUrl ? `/${partUrl}` : ''}`,
        formData,
        {
          ...config,
          headers,
          params: {
            ...config?.params,
            ...params,
          },
          onUploadProgress: axiosOnUploadProgress
            ? (progressEvent) => axiosOnUploadProgress(progressEvent) : undefined,
        },
      );
    },
    decorateData,
    startStateLoading,
  });
}

export interface FetchUpdate<Data, Error = DefaultFetchError, Params = undefined>
  extends DefaultFetch<Data, Error, Params> {
  data?: Data;
  fetch: (params?: Params, id?: string | number) => Promise<Data | null>;
}

export function useFetchUpdate<Data, Error, Params>(
  path: string,
  initialId = '',
): FetchUpdate<Data, Error, Params> {
  return useFetch<Data, Error, Params>({
    fetchCreator: (token, params?: Params, id = initialId) => axios.patch<Data>(
      `${process.env.REACT_APP_API_URL}/${path}${id ? `/${id}` : ''}`,
      params,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  });
}

export interface FetchDelete<Data, Error = DefaultFetchError, Params = string>
  extends DefaultFetch<Data, Error, Params> {
  data?: Data;
  fetch: (id?: Params) => Promise<Data | null>;
}

export function useFetchDelete<Data, Error, Params = string>(
  path: string,
  initialId = '',
): FetchDelete<Data, Error, Params> {
  return useFetch<Data, Error, Params>({
    fetchCreator: (token, id) => axios.delete<Data>(
      `${process.env.REACT_APP_API_URL}/${path}${id || initialId ? `/${id || initialId}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ),
  });
}

export function getMessageInError(err: AxiosError | null): string {
  if (!err) {
    return 'Unknown error';
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const message = err.response?.data?.detail || err.response?.data?.message || err?.message;

  if (message) {
    return capitalizeFirstLetter(Array.isArray(message) ? message[0] : message);
  }

  return 'Something went wrong!';
}
