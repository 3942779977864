import { FormInstance } from 'antd/es/form';
import React, { FunctionComponent, useState } from 'react';
import { Col, Divider, Form, Row } from 'antd';
import clsx from 'clsx';
import { teethIconsRow1, teethIconsRow2 } from '../../../../../Common/Icon/Teeth';
import { MissingToothIcon, ToBeExtractedIcon } from '../../../../../Common/Icon';
import { HeadingContentRow } from '../index';
import ToothColItem, { ButtonOptionTooltip } from '../ToothColItem';
import {
  findToothByKey, isToothBridge, isToothMissing,
  isToothSelected,
  isToothToBeExtracted,
  numberFromToothIconKey,
} from '../../../../../../utils/teeth';
import { Tooth } from '../../../../../../hooks/case/cases';

import styles from '../index.module.scss';

export const formStylesSummaryView: React.CSSProperties = {
  pointerEvents: 'none',
  maxHeight: '160px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // maxWidth: '400px',
  backgroundColor: 'var(--color-teeth-map-background)', // var(--color-page-background)
  borderRadius: '4px',
};

/** Teeth Treatment Plan Form Component */

interface DentateStatusStepForm {
  form: FormInstance;
  servicesArrValues?: string[];
  disabled?: boolean;
  onSubmit?: () => void;
  teethValues: Tooth[];
  setTeethTreatmentPlan?: React.Dispatch<React.SetStateAction<Tooth[]>>;
  // Add functions:
  uncheckTooth?: (key: string) => void;
  addToBeExtracted?: (key: string) => void;
  addMissingTooth?: (key: string) => void;
  summaryView?: boolean;
}

const DentateStatusStepForm: React.FC<DentateStatusStepForm> = (props) => {
  const {
    form,
    onSubmit,
    teethValues,
    // Add functions:
    uncheckTooth,
    addToBeExtracted,
    addMissingTooth,
    summaryView,
  } = props;
  const teethIconsArrayRow1 = Object.entries(teethIconsRow1);
  const teethIconsArrayRow2 = Object.entries(teethIconsRow2);

  const [tooltipOpenKey, setTooltipOpenKey] = useState<string>('');

  const [isBulkActive, setIsBulkActive] = useState<'missing' | 'toBeExtracted' | ''>('');
  const handleIsBulkActive = (legendKey: 'missing' | 'toBeExtracted') => (
    legendKey === isBulkActive ? setIsBulkActive('') : setIsBulkActive(legendKey)
  );

  const handleSubmit = () => {
    if (!onSubmit) return;

    onSubmit();
  };

  const tooltipSelectOptions = (key: string): ButtonOptionTooltip[] => ([
    {
      children: 'Missing tooth',
      icon: <MissingToothIcon style={{ width: 16, height: 16 }} />,
      onClick: () => {
        setTooltipOpenKey('');
        addMissingTooth?.(key);
      },
    },
    {
      children: 'To be extracted',
      icon: <ToBeExtractedIcon style={{ width: 16, height: 16 }} />,
      onClick: () => {
        setTooltipOpenKey('');
        addToBeExtracted?.(key);
      },
    },
  ]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      style={summaryView ? formStylesSummaryView : {}}
    >
      <Row gutter={15} className="content-wrapper-row" style={summaryView ? { transform: 'scale(0.6)' } : {}}>
        <Col span={24}>
          <HeadingContentRow items={[
            { icon: <MissingToothIcon />,
              label: 'Missing tooth',
              onClick: () => handleIsBulkActive('missing'),
              isActive: isBulkActive === 'missing' },
            { icon: <ToBeExtractedIcon />,
              label: 'To be extracted',
              onClick: () => handleIsBulkActive('toBeExtracted'),
              isActive: isBulkActive === 'toBeExtracted' },
          ]}
          />
          <Divider />
          <Row
            gutter={[1, 1]}
            justify="center"
            style={{ alignItems: 'flex-end' }}
            wrap={!summaryView}
          >
            {teethIconsArrayRow1.map(([key, Icon]: [
                  key: string, Icon: FunctionComponent<{ style?: React.CSSProperties; className?: string; }>]) => (
                    <ToothColItem
                      key={key}
                      itemKey={key}
                      tooltipOpenKey={tooltipOpenKey}
                      setTooltipOpenKey={setTooltipOpenKey}
                      tooltipSelectOptions={() => tooltipSelectOptions(key)}
                      beforeContent={(
                        <div className={styles.numberLabelWrapper}>
                          <span
                            className={clsx(
                              styles.numberLabel,
                              { [styles.selectedLabel]: isToothSelected(key, teethValues) },
                            )}
                          >
                            {numberFromToothIconKey(key)}
                          </span>
                        </div>
                      )}
                      onClick={() => {
                        if (isBulkActive) {
                          if (isBulkActive === 'missing') addMissingTooth?.(key);
                          if (isBulkActive === 'toBeExtracted') addToBeExtracted?.(key);
                        }
                        const tooth = findToothByKey(key, teethValues);

                        if (tooth) {
                          if (tooth.toBeExtracted || tooth.isMissing) {
                            uncheckTooth?.(key);
                          }
                          setTooltipOpenKey('');
                        } else if (!isBulkActive) {
                          setTooltipOpenKey(tooltipOpenKey === key ? '' : key);
                        }
                      }}
                    >
                      <Icon
                        className={clsx(styles.tooth, {
                          [styles.activeToothOptions]: tooltipOpenKey === key,
                          [styles.toBeExtracted]: isToothToBeExtracted(key, teethValues),
                          [styles.isMissing]: isToothMissing(key, teethValues),
                          [styles.isBridge]: isToothBridge(key, teethValues),
                        })}
                      />
                    </ToothColItem>
            ))}
          </Row>
          <Row
            gutter={[1, 1]}
            justify="center"
            style={{ alignItems: 'flex-start' }}
            wrap={!summaryView}
          >
            {teethIconsArrayRow2.map(([key, Icon]: [
                  key: string, Icon: FunctionComponent<{ style?: React.CSSProperties; className?: string; }>]) => (
                    <ToothColItem
                      key={key}
                      itemKey={key}
                      tooltipOpenKey={tooltipOpenKey}
                      setTooltipOpenKey={setTooltipOpenKey}
                      iconBtnAlignItems="flex-start"
                      tooltipSelectOptions={() => tooltipSelectOptions(key)}
                      afterContent={(
                        <div className={styles.numberLabelWrapper}>
                          <span
                            className={clsx(
                              styles.numberLabel,
                              { [styles.selectedLabel]: isToothSelected(key, teethValues) },
                            )}
                          >
                            {numberFromToothIconKey(key)}
                          </span>
                        </div>
                      )}
                      onClick={() => {
                        if (isBulkActive) {
                          if (isBulkActive === 'missing') addMissingTooth?.(key);
                          if (isBulkActive === 'toBeExtracted') addToBeExtracted?.(key);
                        }
                        const tooth = findToothByKey(key, teethValues);

                        if (tooth) {
                          if (tooth.toBeExtracted || tooth.isMissing) {
                            uncheckTooth?.(key);
                          }
                          setTooltipOpenKey('');
                        } else if (!isBulkActive) {
                          setTooltipOpenKey(tooltipOpenKey === key ? '' : key);
                        }
                      }}
                    >
                      <Icon
                        className={clsx(styles.tooth, {
                          [styles.activeToothOptions]: tooltipOpenKey === key,
                          [styles.shadowKey24n23]: tooltipOpenKey === key
                          && (numberFromToothIconKey(key) === 24 || numberFromToothIconKey(key) === 23),
                          [styles.shadowKey26n25]: tooltipOpenKey === key
                          && (numberFromToothIconKey(key) === 25 || numberFromToothIconKey(key) === 26),
                          [styles.toBeExtracted]: isToothToBeExtracted(key, teethValues),
                          [styles.isMissing]: isToothMissing(key, teethValues),
                          [styles.isBridge]: isToothBridge(key, teethValues),
                        })}
                      />
                    </ToothColItem>
            ))}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

DentateStatusStepForm.defaultProps = {
  servicesArrValues: [],
  disabled: false,
  onSubmit: () => undefined,
  // Add functions:
  setTeethTreatmentPlan: () => undefined,
  uncheckTooth: () => undefined,
  addToBeExtracted: () => undefined,
  addMissingTooth: () => undefined,
  summaryView: false,
};

export default DentateStatusStepForm;
