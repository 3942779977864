import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styles from './index.module.scss';

interface PhoneNumberInput {
  value?: string;
  onChange?: (phone: string) => void;
  disabled?: boolean;
}

const PhoneNumberInput: React.FC<PhoneNumberInput> = ({ value, onChange, disabled, ...rest }) => (
  <PhoneInput
    className={styles.inputBlock}
    disabled={disabled}
    defaultCountry="US"
    name="phone"
    placeholder="Enter phone number"
    value={value || ''}
    onChange={(phone) => onChange?.(phone || '')}
    limitMaxLength
  />
);

PhoneNumberInput.defaultProps = {
  value: '',
  onChange: () => undefined,
  disabled: false,
};

export default PhoneNumberInput;
