import React from 'react';

import { Layout } from 'antd';
import Content from '../../components/Pages/Notifications';
import { displayName } from '../../config';

const PracticeRequest: React.FC = (): React.JSX.Element => {
  document.title = `${displayName}: Notifications`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default PracticeRequest;
