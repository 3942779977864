import {
  DefaultFetchError,
  FetchCreate,
  FetchDelete,
  useFetchCreate,
  useFetchDelete,
} from './fetch';
import { FetchSuccess, StoredFile } from '../types';
import { User } from './users';

export interface IChatFile {
  fileId: string;
  originalName: string;
  mimeType: string;
}

interface ITimestamp {
  nanoseconds: number;
  seconds: number;
}

export interface IMessage {
  files?: IChatFile[];
  message: string;
  speakerId: string;
  timestamp: ITimestamp;
}

interface IParticipant {
  speakerId: string;
  timestamp: ITimestamp;
}

export interface IChatRoom {
  archive: boolean;
  initiatorId: string;
  messages?: Record<string, IMessage>;
  participants?: Record<string, IParticipant>;
  speakerId: string;
  timestamp: ITimestamp;
}

export interface IRoomsData {
  [key: string]: IChatRoom;
}

/** Interfaces for converted response */
export interface IMessageWithId extends IMessage {
  id: string;
}

export interface IParticipantWithId extends IParticipant {
  id: string;
}

export interface IChatRoomWithId {
  id: string;
  messages: IMessageWithId[]; // { id: string; } & IMessage;
  participants: IParticipantWithId[]; // { id: string; } & IParticipant;
  archive: boolean;
  initiatorId: string;
  speakerId: string; // This speaker ID (which on top level) is id of Lab manager with whom chat was started
  timestamp: ITimestamp;
}

/** Requests */
export interface IGetRoomUsersParams {
  users: string[];
}

export interface IPostNewRoom {
  speakerId: string;
}

export interface IPostMessage {
  message: string;
  files?: IChatFile[];
}

export interface IPostChatParticipants {
  participants: { speakerId: string; }[];
}

export const useGetRoomUsers = (): FetchCreate<User[], DefaultFetchError, IGetRoomUsersParams> => (
  useFetchCreate('users/room-users', { autoStart: false }));

export const usePostNewRoom = (): FetchCreate<{ key: string; }, DefaultFetchError, IPostNewRoom> => (
  useFetchCreate('firebase-chat/rooms', { autoStart: false }));

export const usePostChatMessage = (): FetchCreate<FetchSuccess, DefaultFetchError, IPostMessage> => (
  useFetchCreate('firebase-chat/rooms', { autoStart: false }));

export const usePostChatParticipants = (): FetchCreate<FetchSuccess, DefaultFetchError, IPostChatParticipants> => (
  useFetchCreate('firebase-chat/rooms', { autoStart: false }));

export const useDeleteRoom = (): FetchDelete<FetchSuccess, DefaultFetchError, string> => (
  useFetchDelete('firebase-chat/rooms'));

export const useDeleteMessage = (): FetchDelete<FetchSuccess, DefaultFetchError, string> => (
  useFetchDelete('firebase-chat/rooms')); // {roomId}/messages/{messageId}

export const useUploadMessageFile = (
  onUploadProgress?: (progressEvent: ProgressEvent) => void,
  abortController?: AbortController,
): FetchCreate<
  StoredFile,
  DefaultFetchError,
  FormData
> => useFetchCreate(
  'firebase-chat/file',
  {
    config: {
      headers: {
        'content-type': 'multipart/form-data',
      },
      signal: abortController?.signal,
    },
  },
  onUploadProgress,
);
