import { Empty, Pagination, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import {
  NotificationsGetParams,
  useNotificationsGet,
  useUpdateNotificationStatus,
} from '../../../../hooks/notifications';
import Loading from '../../../Common/Loading';
import NotificationRow from './NotificationRow';
import styles from '../index.module.scss';

interface NotificationsList {
  entity?: string;
}

const NotificationsList: React.FC<NotificationsList> = ({ entity }) => {
  const [params, setParams] = useState<NotificationsGetParams | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const notificationsGet = useNotificationsGet();
  const updateNotificationSawStatus = useUpdateNotificationStatus();
  const updateNotificationRemovedStatus = useUpdateNotificationStatus();

  const loadNotifications = () => {
    notificationsGet.fetch({ orderBy: 'DESC', removed: false, take: 10, ...params });
  };

  useEffect(() => {
    if (!params) return;

    loadNotifications();
  }, [params]);

  useEffect(() => {
    const query = qs.parse(location.search);

    const { current, entity: queryEntity, ...queryParams } = query;

    setParams({
      ...queryParams,
      page: parseInt(current as string, 10) || 1,
      entity: (queryEntity as string)?.split(','),
    });
  }, [location]);

  const markAsRead = (id: string) => (
    updateNotificationSawStatus.fetch({ saw: true }, id)
  );

  const removeNotification = (id: string) => (
    updateNotificationRemovedStatus.fetch({ removed: true }, id).then(() => {
      loadNotifications();
    })
  );

  const onPageChange = (current: number) => {
    const query = qs.parse(location.search);
    const params = {
      ...query,
      current,
    };

    navigate({ pathname: location.pathname, search: qs.stringify(params) }, { replace: true });
  };

  const markPageAsRead = () => {
    const unreadNotifications = notificationsGet?.data?.data?.filter((notification) => !notification.status.saw);

    if (!unreadNotifications) return;

    Promise.all(
      unreadNotifications.map((notification) => markAsRead(notification.id)),
    );
  };

  useEffect(() => {
    const queryParams = {
      current: 1,
      entity,
    };

    navigate({ pathname: location.pathname, search: qs.stringify(queryParams) }, { replace: true });
  }, [entity]);

  useEffect(() => {
    markPageAsRead();
  }, [notificationsGet?.data]);

  return (
    <Space direction="vertical" className={styles.fullWidth}>
      {notificationsGet?.data && notificationsGet?.data?.data && notificationsGet?.data?.data?.length
        ? (
          <>
            {notificationsGet.data.data.map((item) => (
              <Space direction="vertical" key={item.id} className={styles.fullWidth}>
                <NotificationRow item={item} markAsRead={markAsRead} remove={removeNotification} />
              </Space>
            ))}
            <div className={styles.paginationRow}>
              <Pagination
                onChange={onPageChange}
                defaultCurrent={notificationsGet.data.meta.page}
                pageSize={notificationsGet.data.meta.take}
                total={notificationsGet.data.meta.itemCount}
                showSizeChanger={false}
              />
            </div>
          </>
        )
        : (
          <div className={styles.placeholderWrapper}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      <Loading absolute delay={200} visible={notificationsGet.loading || updateNotificationRemovedStatus.loading} />
    </Space>
  );
};

NotificationsList.defaultProps = {
  entity: undefined,
};

export default NotificationsList;
