import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { App, Button, Form, Input, Progress } from 'antd';
import { DeleteOutlined, PaperClipOutlined, SendOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd/es/grid';
import { required } from '../../../../../utils/inputRules';
import { getMessageInError } from '../../../../../hooks/fetch';
import Messages from './Messages';
import { IPostMessage, usePostChatMessage, useUploadMessageFile } from '../../../../../hooks/chats';
import { StoredFile } from '../../../../../types';

import styles from './index.module.scss';

const ChatForm: React.FC = () => {
  const { message } = App.useApp();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const messagesListRef = useRef<HTMLDivElement | null>(null);
  const roomId = searchParams.get('id') || '';

  const postChatMessage = usePostChatMessage();

  const [percentCompleted, setPercentCompleted] = useState<number | null>(null);
  const controller = new AbortController();
  // eslint-disable-next-line max-len
  const uploadMessageFile = useUploadMessageFile((progressEvent) => { setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total)); }, controller);

  const [file, setFile] = useState<File | null>(null);
  const uploadRef = useRef<HTMLInputElement>(null);

  const handleFileUploadClick = () => uploadRef.current?.click();

  const handleFileUploadReset = () => {
    setFile(null);
    setPercentCompleted(null);

    if (uploadRef.current) {
      uploadRef.current.value = '';
    }
  };

  /** Handle Form */
  const handleResetForm = () => {
    form.resetFields();
    handleFileUploadReset();
  };

  const postMessageFetch = (message: string, file?: StoredFile | null) => {
    const formData: IPostMessage = { message };

    if (file) { formData.files = [{ fileId: file.id, originalName: file.originalName, mimeType: file.mimeType }]; }

    return (

      postChatMessage.fetch(formData, `${roomId}/messages`)
        .then(() => { handleResetForm(); })
    );
  };

  const handleSubmit = ({ message }: { message: string; }) => {
    if (message) {
      if (file) {
        const formData = new FormData();

        formData.append('file', file);
        uploadMessageFile.fetch(formData)
          .then((res) => {
            postMessageFetch(message, res);
          })
          .finally(() => setPercentCompleted(null));

        return;
      }

      postMessageFetch(message);
    }
  };

  useEffect(() => {
    if (postChatMessage.error) {
      message.error(getMessageInError(postChatMessage.error));
      postChatMessage.clearError();
    }
  }, [postChatMessage.error]);

  useEffect(() => {
    if (uploadMessageFile.error) {
      message.error(getMessageInError(uploadMessageFile.error));
      uploadMessageFile.clearError();
    }
  }, [uploadMessageFile.error]);

  return (
    <div className={styles.wrapper}>
      <Messages messagesListRef={messagesListRef} />

      <Form
        form={form}
        name="chat_form"
        layout="vertical"
        initialValues={{ message: '' }}
        className={styles.form}
        disabled={postChatMessage.loading || uploadMessageFile.loading || !roomId}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label=""
              name="message"
              rules={required}
              style={{ marginBottom: '12px' }}
              extra={file && (
              <div>
                {percentCompleted && (
                <Progress percent={percentCompleted} strokeColor="var(--color-main-orange)" />
                )}
                {file.name}
                <Button
                  type="link"
                  className={styles.delete}
                  onClick={handleFileUploadReset}
                  icon={<DeleteOutlined className={styles.icon} />}
                />
              </div>
              )}
            >
              <Input.TextArea
                rows={3}
                maxLength={2000}
                placeholder="Text your message"
              />
            </Form.Item>

            <input
              ref={uploadRef}
              name="file"
              type="file"
              className={styles.inputFile}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFile(e.target.files?.[0] || null)}
            />
          </Col>

          <Col span={24} className={styles.actions}>
            <Button
              size="middle"
              style={{ height: '28px', width: '28px' }}
              type="link"
              icon={<PaperClipOutlined style={{ fontSize: '18px' }} />}
              onClick={handleFileUploadClick}
              disabled={uploadMessageFile.loading}
            />
            <Button
              size="middle"
              type="primary"
              icon={<SendOutlined />}
              onClick={() => form.validateFields().then((values) => handleSubmit(values))}
              loading={postChatMessage.loading || uploadMessageFile.loading}
            >
              Send
            </Button>
          </Col>
        </Row>

      </Form>
    </div>
  );
};

export default ChatForm;
