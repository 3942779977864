/* eslint-disable max-len */
import { Rule } from 'antd/es/form';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const email: Rule[] = [{ type: 'email', message: 'The Email is invalid' }];
export const number: Rule[] = [{ pattern: /^[0-9]+$/, message: 'The field should contain only numbers' }];
export const required: Rule[] = [{ required: true, message: 'The field is required', whitespace: true }];
export const requiredDatepicker: Rule[] = [{ required: true, message: 'The field is required' }];
export const greaterFive: Rule[] = [{ pattern: /([5-9]|\d{2,})/, message: 'Time must be greater than 5' }];
export const minThreeSymbols: Rule[] = [{ min: 3, message: 'The field must be at least 3 characters' }];
export const minSixSymbols: Rule[] = [{ min: 6, message: 'The field must be at least 6 characters' }];
export const minEightSymbols: Rule[] = [{ min: 8, message: 'The field must be at least 8 characters' }];
export const noWhiteSpacesNSpecChars: Rule[] = [{
  pattern: /^[A-Za-z0-9._@+-]+$/,
  message: 'The field cannot contain white spaces or special characters',
}];
export const noWhiteSpaces: Rule[] = [{ pattern: /^\S+$/g, message: 'This field cannot contain white spaces' }];

export const upperLetter: Rule[] = [{ pattern: /(?=.*[A-Z])/, message: 'Need at least 1 upper case letter' }];
export const lowerLetter: Rule[] = [{ pattern: /(?=.*[a-z])/, message: 'Need at least 1 lower case letter' }];
export const numberOrSpecialCharacter: Rule[] = [{ pattern: /(?=.*[!@#$%^&*_0-9])/, message: 'Need at least 1 number or special character' }];

export const passwordFromBackend: Rule[] = [{ pattern: /(?:(?=.*\d)|(?=.*\W+))(?![\n.])(?=.*[A-Z])(?=.*[a-z]).*$/,
  message: 'Passwords have to contain at least 1 upper case letter, 1 lower case letter, and 1 number or special character' }];

export const phoneNumber: Rule[] = [{
  message: 'The phone number is invalid',
  validator: (_, value) => {
    if (value === undefined || value === '' || value === null) return Promise.resolve();
    if (isValidPhoneNumber(`${value}`)) return Promise.resolve();

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Phone invalid');
  },
},
...noWhiteSpaces];
export const login: Rule[] = [...minThreeSymbols, ...required, ...noWhiteSpacesNSpecChars];
export const emailSignIn: Rule[] = [...required, ...noWhiteSpacesNSpecChars, ...email];
export const password: Rule[] = [...required, ...minEightSymbols, ...noWhiteSpaces,
  ...upperLetter, ...lowerLetter, ...numberOrSpecialCharacter];
export const confirmPassword: Rule[] = [({ getFieldValue }) => ({
  validator(_, value) {
    if (!value || getFieldValue('password') === value) return Promise.resolve();

    return Promise.reject(new Error('The two passwords that you entered do not match!'));
  },
})];
