import { FetchSuccess } from '../types';
import { DefaultFetchError, FetchCreate, useFetchCreate } from './fetch';

export interface PasswordCreateParams {
  email: string;
}

export const usePasswordForgot = (): FetchCreate<FetchSuccess, DefaultFetchError, PasswordCreateParams> => (
  useFetchCreate('users/forgot-password')
);
