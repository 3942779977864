import { SelectProps } from 'antd/lib/select';
import { App, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { LabStatus } from '../../../../enums/lab';
import { LabsGetParams, useLabId, useLabsGet } from '../../../../hooks/labs';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import NotFoundContent from '../../NotFoundContent';

interface SelectLab extends SelectProps<string | string[], Option> {
  onlyStatus?: LabStatus;
  allowAll?: boolean;
}

const SelectLab: React.FC<SelectLab> = ({ onlyStatus, allowAll, onClear, ...props }): JSX.Element => {
  const { message } = App.useApp();
  const labsGet = useLabsGet();
  const labById = useLabId();

  const [options, setOptions] = useState<Option[]>([]);
  const [labsName, setLabsName] = useState<string>('');

  const fetch = () => {
    const params: LabsGetParams = { page: 1, take: 100, orderBy: 'ASC', orderByColumn: 'name' };

    if (onlyStatus) {
      params.status = onlyStatus;
    }

    if (labsName) {
      params.name = labsName;
    }

    labsGet.fetch(params);
  };

  useEffect(() => {
    if (labsName && labsName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }
    if (labsName === '') {
      fetch();
    }

    return undefined;
  }, [labsName]);

  useEffect(() => {
    if (labsGet.data?.data.length) {
      setOptions(labsGet.data.data.map(({ name, id }): Option => ({ label: name, value: id })));
    }
  }, [labsGet.data]);

  useEffect(() => {
    if (options.length && props.value) {
      const current = options.find(({ value }) => value === props.value);

      if (!current) {
        labById.fetch(undefined, `${props.value}`)
          .then((res) => setOptions([
            ...options,
            { label: res?.name || '', value: props.value as string },
          ]));
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (labsGet.error) {
      message.error(getMessageInError(labsGet.error));
      labsGet.clearError();
    }
  }, [labsGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  const handleClear = () => {
    setLabsName('');

    if (!onClear) return;

    onClear();
  };

  const optionsList = allowAll
    ? [{ label: 'All', value: '' }, ...options]
    : options;

  return (
    <Select
      loading={labsGet.loading || labById.loading}
      options={optionsList}
      onSearch={(name) => setLabsName(name)}
      showSearch
      allowClear
      onClear={handleClear}
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Lab not found." />}
      value={labsGet.loading || labsGet.loading ? 'Loading...' : props.value}
      {...props}
    />
  );
};

SelectLab.defaultProps = {
  onlyStatus: undefined,
  allowAll: false,
};

export default SelectLab;
