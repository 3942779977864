import Button, { ButtonProps } from 'antd/es/button';
import React, { useState } from 'react';
import { JsonResult } from '../../../../types';
import LabCreate from '../Create';
import { initialCountryData } from '../../../../utils/prepareData';

interface LabCreateButton {
  title: string;
  id: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  type: ButtonProps['type'];
  style?: JsonResult;
  isAdmin?: boolean;
}

const LabCreateButton = (props: LabCreateButton) => {
  const { title, id, type, children, icon, disabled, style, isAdmin } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <Button
        id={id}
        type={type}
        icon={icon}
        disabled={disabled}
        onClick={() => setIsDrawerOpen(true)}
        style={style}
      >
        {children}
      </Button>
      <LabCreate
        title={title}
        drawerOpen={isDrawerOpen}
        setDrawerOpen={setIsDrawerOpen}
        initialData={{
          lab: {
            country: initialCountryData,
          },
        }}
        isAdmin={isAdmin}
      />
    </>
  );
};

LabCreateButton.defaultProps = {
  icon: null,
  disabled: false,
  style: {},
  isAdmin: false,
};

export default LabCreateButton;
