import { Provider } from 'react-redux';
import React from 'react';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import { store } from './store';
import AntdConfigProvider from './AntdConfigProvider';
import Routes from './routes';

import './App.scss';

dayjs.extend(localeData);

function App(): React.JSX.Element {
  return (
    <Provider store={store}>
      <AntdConfigProvider>
        <Routes />
      </AntdConfigProvider>
    </Provider>
  );
}

export default App;
