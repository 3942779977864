import { LeftOutlined, RightOutlined } from '@ant-design/icons/lib';
import { App, Button, Col, Form, Row, Space, Typography } from 'antd';
import Icon from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { UserRoles } from '../../../../enums/user';
import { CreateAddress } from '../../../../hooks/addresses';
import { getMessageInError } from '../../../../hooks/fetch';
import { User as UserHooks, useUsersGet } from '../../../../hooks/users';
import { Action } from '../../../../store';
import {
  practiceCreate as actionPracticeCreate,
  moduleName,
  PracticeCreatePayload,
} from '../../../../store/ducks/practice';
import { RootState } from '../../../../store/reducers';
import { JsonResult } from '../../../../types';
import { isObjEqualLodash } from '../../../../utils';
import {
  PreparedPracticeInitialData,
  preparePracticeBody,
  prepareSmileUserInitialData,
} from '../../../../utils/prepareData';
import DrawerModal from '../../../Common/DrawerModal';
import { Send } from '../../../Common/Icon';
import EmailForm from '../Forms/EmailForm';
import PracticeForm from '../Forms/PracticeForm';
import PracticeManagerInfo from '../Forms/PracticeManagerInfo';
import { Error } from '../../../../store/ducks/common';

interface PracticeCreate {
  loading?: boolean;
  hasPassword?: boolean;
  title: string;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  isAdmin?: boolean;
  initialData?: PreparedPracticeInitialData;
  disabled?: {
    [key: string]: boolean;
  };
  handleSave?: (body: PracticeCreatePayload) => void;
  practiceLoading: boolean;
  withEmailForm?: boolean;
  error: Error | null;
  practiceCreate: (payload: PracticeCreatePayload) => Action;
  requiredFieldsType?: 'create' | 'completeRegistration';
}

const foundSmileUserMessage = (
  <div>
    <Typography>We found an existing account associated with this email.</Typography>
    <Typography>Please, review Practice Manager Info and verify by clicking "Send Registration Link"</Typography>
  </div>
);

const PracticeCreate: React.FC<PracticeCreate> = (props) => {
  const { message } = App.useApp();
  const {
    loading,
    initialData,
    handleSave,
    title,
    drawerOpen,
    setDrawerOpen,
    isAdmin,
    disabled,
    practiceLoading,
    error,
    practiceCreate,
    hasPassword,
    requiredFieldsType,
    withEmailForm,
  } = props;

  const [practiceForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [managerForm] = Form.useForm();
  const totalSteps = withEmailForm ? 3 : 2;
  const [step, setStep] = useState<number>(1);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  // TODO getUsers.loading returns true in all cases, need to review
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [smileUser, setSmileUser] = useState<UserHooks | null>();
  const [practiceInfo, setPracticeInfo] = useState<JsonResult>({});
  const addressesState = useState<CreateAddress[]>(initialData?.addresses || []);
  const sameAsBillingState = useState<boolean | undefined>(initialData?.sameAsBilling || false);
  const getUsers = useUsersGet();

  const [addresses] = addressesState;
  const [sameAsBilling] = sameAsBillingState;

  const emailForm1Value = Form.useWatch('email', emailForm);

  const handleClose = () => {
    practiceForm.resetFields();
    emailForm.resetFields();
    managerForm.resetFields();
    setEmail(undefined);
    setSmileUser(null);
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (!practiceLoading && !error && drawerOpen && isFormTouched) {
      handleClose();
    }
  }, [practiceLoading]);

  const handleManagerFormChange = (field: JsonResult[]) => {
    handleFormChange();

    if (field[0].name[0] === 'email') {
      setEmail(field[0].value);
    }
  };

  useEffect(() => {
    if (!drawerOpen) return;

    setStep(1);
    setIsFormTouched(false);
  }, [drawerOpen]);

  useEffect(() => {
    setEmail(managerForm.getFieldValue('email'));
  }, [managerForm?.getFieldsValue()]);

  useEffect(() => {
    if (error?.message) {
      message.error(error.message, 5);
    }
  }, [error]);

  const findSmileUser = (email: string) => {
    setUsersLoading(true);
    getUsers.fetch({ email })
      .then((response) => {
        const foundSmileUser = response?.data.find((user) => user.role === UserRoles.doctor && !user.doctor?.practice);

        if (response?.data?.length && !foundSmileUser) {
          emailForm.setFields([
            {
              name: 'email',
              errors: ['Email already exists'],
            },
          ]);
        }

        if (foundSmileUser) {
          setSmileUser(foundSmileUser);
          managerForm?.setFieldsValue({ ...foundSmileUser });
        } else {
          setSmileUser(null);
          managerForm?.setFieldsValue({
            email: emailForm1Value,
            firstName: undefined,
            lastName: undefined,
            nickname: undefined,
            phone: undefined,
            position: undefined,
            specialty: undefined,
          });
        }

        if (!response?.data.length || foundSmileUser) {
          setStep((prev) => prev + 1);
        }
      })
      .catch((error) => {
        message.error(getMessageInError(error));
        getUsers.clearError();
      })
      .finally(() => {
        setUsersLoading(false);
      });
  };

  const handleNext = () => {
    if (step === 1) {
      practiceForm.validateFields().then((values: JsonResult) => {
        setPracticeInfo(values);
        setStep((prev) => prev + 1);
      });
    }
    if (withEmailForm && step === 2) {
      emailForm.validateFields().then(({ email }) => {
        findSmileUser(email);
      });
    }
  };

  const handleFormChange = () => {
    const isPracticeChanged = !isObjEqualLodash(initialData?.practice, practiceForm?.getFieldsValue());
    const isManagerChanged = !isObjEqualLodash(initialData?.manager, managerForm?.getFieldsValue());

    setIsFormTouched(isPracticeChanged || isManagerChanged);
  };

  const handleSubmit = () => {
    managerForm.validateFields().then((values: JsonResult) => {
      if (smileUser || initialData?.smileUser) {
        const body = preparePracticeBody(practiceInfo, addresses, sameAsBilling);

        practiceCreate({ ...body, authorization: true, url: '/practices/doctor' });

        return;
      }
      const body = preparePracticeBody(practiceInfo, addresses, sameAsBilling, values);

      if (handleSave) {
        handleSave(body);
      } else {
        practiceCreate({ isAdmin, authorization: isAdmin, ...body });
      }
    });
  };

  const emailManagerValue = Form.useWatch('email', managerForm);
  const firstNameManagerValue = Form.useWatch('firstName', managerForm);
  const lastNameManagerValue = Form.useWatch('lastName', managerForm);
  const phoneManagerValue = Form.useWatch('phone', managerForm);
  const passwordManagerValue = Form.useWatch('password', managerForm);
  const confirmPasswordManagerValue = Form.useWatch('confirmPassword', managerForm);

  const smileUserInitialData = smileUser ? prepareSmileUserInitialData(smileUser) : null;

  return (
    <div>
      <DrawerModal
        title={title}
        open={drawerOpen}
        onClose={handleClose}
        withSteps
        currentStep={step}
        totalSteps={totalSteps}
        isFormChanged={isFormTouched}
        extra={(
          <Space>
            {step > 1 && (
              <Button
                ghost
                onClick={() => setStep((prev) => prev - 1)}
              >
                <LeftOutlined style={{ fontSize: 12 }} />
                Back
              </Button>
            )}
            {step < totalSteps && (
              <Button
                type="default"
                onClick={handleNext}
                id="nextBtn"
              >
                Next
                <RightOutlined style={{ fontSize: 12 }} />
              </Button>
            )}
            {step === totalSteps && (
              <Button
                type="primary"
                icon={<Icon component={Send} />}
                loading={practiceLoading}
                onClick={handleSubmit}
                disabled={requiredFieldsType === 'create'
                  ? (!email)
                  : (!emailManagerValue || !firstNameManagerValue || !lastNameManagerValue || !phoneManagerValue
                    || (hasPassword && (!passwordManagerValue || !confirmPasswordManagerValue)))}
                id="submitBtn"
              >
                {isAdmin ? 'Send Registration Link' : 'Submit'}
              </Button>
            )}
          </Space>
        )}
      >
        <Row justify="center">
          <Col span={24} md={20} lg={16} xxl={12}>
            <div>
              {step === 1 && (
                <PracticeForm
                  isAdmin={isAdmin}
                  requiredFieldsType={requiredFieldsType}
                  form={practiceForm}
                  disabled={disabled}
                  initialData={initialData?.practice}
                  addressesState={addressesState}
                  sameAsBillingState={sameAsBillingState}
                  handleChange={handleFormChange}
                  loading={loading || practiceLoading}
                />
              )}
              {withEmailForm && step === 2 && (
                <EmailForm
                  title="Practice Manager Email"
                  form={emailForm}
                  loading={usersLoading}
                  initialData={{
                    email: smileUser?.email
                    || initialData?.smileUser?.email
                    || initialData?.manager?.email,
                  }}
                />
              )}
              {(withEmailForm ? step === 3 : step === 2) && (
                <PracticeManagerInfo
                  isAdmin={isAdmin}
                  hasPassword={hasPassword}
                  requiredFieldsType={requiredFieldsType}
                  disabled={!!smileUser || !!initialData?.smileUser || disabled?.manager}
                  message={smileUser ? foundSmileUserMessage : undefined}
                  initialData={smileUserInitialData || initialData?.smileUser || initialData?.manager}
                  form={managerForm}
                  handleChange={handleManagerFormChange}
                  loading={loading || practiceLoading}
                />
              )}
            </div>
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  practiceLoading: state[moduleName].loading,
  error: state[moduleName].error,
});

const mapDispatchToProps = {
  practiceCreate: actionPracticeCreate,
};

PracticeCreate.defaultProps = {
  loading: false,
  isAdmin: false,
  hasPassword: false,
  withEmailForm: false,
  disabled: undefined,
  initialData: undefined,
  handleSave: undefined,
  requiredFieldsType: 'create',
};

export default connect(mapStateToProps, mapDispatchToProps)(PracticeCreate);
