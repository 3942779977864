import { Divider, Form, Input } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Service } from '../../../../hooks/services';
import { Action } from '../../../../store';
import {
  serviceUpdate as actionServiceUpdate,
  ServiceUpdatePayload,
} from '../../../../store/ducks/service';
import { JsonResult } from '../../../../types';
import { isObjEqualLodash } from '../../../../utils';
import { prepareServiceBody, prepareServiceInitialData } from '../../../../utils/prepareData';
import ControlsEditMode from '../../../Common/ControlsEditMode';
import AdditionalOptions from '../Forms/AdditionalOptions';
import Materials from '../Forms/Materials';

interface ServiceView {
  service: Service;
  materialsName?: string;
  additionalOptionsName?: string;
  hidePrice?: boolean;
  setIsFormTouched: Dispatch<SetStateAction<boolean>>;
  serviceUpdate: (payload: ServiceUpdatePayload) => Action;
}

const ServiceView = (props: ServiceView) => {
  const { materialsName, additionalOptionsName, service, setIsFormTouched, serviceUpdate, hidePrice } = props;
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [initialValues, setInitialValues] = useState<JsonResult>({});

  useEffect(() => {
    if (!service) return;

    setInitialValues(prepareServiceInitialData(service, !isEditing));
    setIsFormTouched(false);
    setIsEditing(false);
  }, [service]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (!service) return;

    setInitialValues(prepareServiceInitialData(service, !isEditing));
    setIsFormTouched(false);
  }, [isEditing]);

  const handleSubmit = () => {
    form.validateFields()
      .then((values) => {
        serviceUpdate(prepareServiceBody(values));
      });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsFormTouched(false);
  };

  const handleFieldsChange = () => {
    setIsFormTouched(!isObjEqualLodash(initialValues, form.getFieldsValue()));
    setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length));
  };

  return (
    <>
      <Form
        form={form}
        layout="inline"
        initialValues={initialValues}
        onFieldsChange={handleFieldsChange}
      >
        <Form.Item name="id" className="hidden-input">
          <Input type="hidden" />
        </Form.Item>
        <Materials
          isEditing={isEditing}
          serviceName={service.name}
          name={materialsName || 'materials'}
          nameDisabled={service.name !== 'Printing'}
          hidePrice={hidePrice}
        />
        <Divider />
        <AdditionalOptions
          isEditing={isEditing}
          name={additionalOptionsName || 'additionalOptions'}
          hidePrice={hidePrice}
        />
      </Form>
      <ControlsEditMode
        isEditing={isEditing}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        onChangeEditing={setIsEditing}
        isFormValid={isFormValid}
      />
    </>
  );
};

ServiceView.defaultProps = {
  materialsName: undefined,
  additionalOptionsName: undefined,
  hidePrice: false,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = {
  serviceUpdate: actionServiceUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceView);
