import { DownOutlined, LockFilled, SettingFilled } from '@ant-design/icons/lib';
import { App, Button, Col, Dropdown, Row, Space, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Content } from 'antd/lib/layout/layout';
import { useUpdateUserSendSms, useUpdateUserStatus, useUserId } from '../../../hooks/users';
import { moduleName as authModuleName, signOut as actionSignOut } from '../../../store/ducks/auth';
import { moduleName as userModuleName } from '../../../store/ducks/user';
import { moduleName as doctorModuleName } from '../../../store/ducks/doctor';
import { moduleName as labStaffModuleName } from '../../../store/ducks/labStaff';
import { RootState } from '../../../store/reducers';
import ContentCard from '../../Common/ContentCard';
import { Profile as ProfileIcon } from '../../Common/Icon';
import styles from './index.module.scss';
import ProfileForm from './ProfileForm';
import { Action } from '../../../store';
import { getMessageInError } from '../../../hooks/fetch';
import { capitalizeFirstLetter, onActionClick } from '../../../utils';
import { Error } from '../../../store/ducks/common';
import { usePasswordForgot } from '../../../hooks/password';

interface Profile {
  loadingGetUser: boolean;
  currentUserId?: string;
  signOut: () => Action;
  userUpdateLoading: boolean;
  userUpdateError: Error | null;
  doctorUpdateLoading: boolean;
  doctorUpdateError: Error | null;
  labStaffUpdateLoading: boolean;
  labStaffUpdateError: Error | null;
}

const Profile: React.FC<Profile> = (props) => {
  const { message, modal } = App.useApp();
  const {
    currentUserId, loadingGetUser, signOut,
    userUpdateLoading, userUpdateError,
    doctorUpdateLoading, doctorUpdateError,
    labStaffUpdateLoading, labStaffUpdateError,
  } = props;
  const userById = useUserId();
  const updateUserStatus = useUpdateUserStatus();
  const resetPassword = usePasswordForgot();
  const updateUserSendSms = useUpdateUserSendSms();

  const [lastActionName, setLastActionName] = useState<string>('');
  const [isSendSms, setIsSendSms] = useState<boolean>(!!userById?.data?.isSendSms);

  const fetchUserId = () => {
    userById.fetch(undefined, currentUserId).then((res) => setIsSendSms(!!res?.isSendSms));
  };

  const handleChangeReceiveSms = (receiveSms: boolean) => {
    setIsSendSms(receiveSms);

    updateUserSendSms.fetch({ isSendSms: receiveSms }, currentUserId || '')
      .then(() => message.success(`SMS notifications were successfully turned ${receiveSms ? 'on' : 'off'}!`));
  };

  useEffect(() => {
    if (currentUserId && !userUpdateLoading && !userUpdateError && !doctorUpdateLoading && !doctorUpdateError
      && !labStaffUpdateLoading && !labStaffUpdateError) {
      fetchUserId();
      // message.success('Profile has been updated!');
    }
  }, [currentUserId, userUpdateLoading, doctorUpdateLoading, labStaffUpdateLoading]);

  const menuItems = [
    {
      label: 'Reset Password',
      key: '1',
      icon: <LockFilled />,
      onClick: () => {
        onActionClick({
          modal,
          title: 'Reset Password',
          content: 'Are you sure you want to reset your password?',
          actionName: 'reset password',
          hook: () => resetPassword.fetch({ email: userById.data?.email || '' }),
          thenFunc: () => setTimeout(signOut, 2000),
        });
        setLastActionName('Password reset link was send to your email');
      },
    },
  ];

  useEffect(() => {
    if ((updateUserStatus.data && !updateUserStatus.error) || (resetPassword.data && !resetPassword.error)) {
      message.success(`${capitalizeFirstLetter(lastActionName)}!`);
    }
  }, [updateUserStatus.data, resetPassword.data]);

  useEffect(() => {
    if (updateUserStatus.error) {
      message.error(getMessageInError(updateUserStatus.error));
      updateUserStatus.clearError();
    }
  }, [updateUserStatus.error]);

  useEffect(() => {
    if (resetPassword.error) {
      message.error(getMessageInError(resetPassword.error));
      resetPassword.clearError();
    }
  }, [resetPassword.error]);

  useEffect(() => {
    if (updateUserSendSms.error) {
      message.error(getMessageInError(updateUserSendSms.error));
      updateUserSendSms.clearError();
    }
  }, [updateUserSendSms.error]);

  return (
    <Content>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <ProfileIcon />
          <Typography.Title level={4} className={styles.title}>
            Profile
          </Typography.Title>
        </div>

        <div className={styles.actions}>
          <div className={styles.receiveSms}>
            <span>Receive SMS: </span>
            <Switch
              loading={updateUserSendSms.loading || userById.loading}
              onChange={handleChangeReceiveSms}
              checked={isSendSms}
            />
          </div>
          <Dropdown
            trigger={['click']}
            key="button"
            menu={{ items: menuItems }}
          >
            <Button ghost onClick={(e) => e.preventDefault()} id="actionsDropdownButton">
              <Space>
                <SettingFilled />
                Actions
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
      </div>
      <Row justify="center">
        <Col span={24} md={20} lg={16} xxl={12}>
          {userById?.data ? (
            <ProfileForm
              user={userById?.data}
              loading={userById.loading}
              cardLoading={!userById?.data || loadingGetUser}
            />
          ) : (
            <ContentCard loading={loadingGetUser} cardLoading={!userById?.data || loadingGetUser}>
              Loading...
            </ContentCard>
          )}
        </Col>
      </Row>
    </Content>
  );
};

Profile.defaultProps = {
  currentUserId: undefined,
};

const mapStateToProps = (state: RootState) => ({
  currentUserId: state[authModuleName].user?.id,
  loadingGetUser: state[authModuleName].loadingGetUser,
  userUpdateLoading: state[userModuleName].userLoading,
  userUpdateError: state[userModuleName].userError,
  doctorUpdateLoading: state[doctorModuleName].doctorLoading,
  doctorUpdateError: state[doctorModuleName].doctorError,
  labStaffUpdateLoading: state[labStaffModuleName].labStaffLoading,
  labStaffUpdateError: state[labStaffModuleName].labStaffError,
});

const mapDispatchToProps = {
  signOut: actionSignOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
