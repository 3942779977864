import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useState } from 'react';
import TablePatients from './Table';
import { ModalState, ModalTypes } from '../../../types';
import PatientCreateDrawer from './Create';
import PatientInfoDrawer from './Info';

const Patients: React.FC = () => {
  const [modal, setModal] = useState<ModalState | null>(null);

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <Content>
      <PatientCreateDrawer
        isOpen={modal?.type === ModalTypes.create}
        close={closeModal}
        onReload={modal?.onReload}
      />
      <PatientInfoDrawer
        isOpen={modal?.type === ModalTypes.info}
        close={closeModal}
        id={modal?.id || ''}
        onReload={modal?.onReload}
      />
      <TablePatients openModal={openModal} />
    </Content>
  );
};

export default Patients;
