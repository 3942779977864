import { RcFile } from 'antd/es/upload';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { alertDelaySuccess, name as appName } from '../../config';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse, Error } from './common';
import { Action } from '../index';
import { fetchSaga } from './auth';
import { Case, Tooth } from '../../hooks/case/cases';

/**
 * Constants
 * */
export const moduleName = 'cases';
const prefix = `${appName}/${moduleName}`;

export const CREATE_CASE = `${prefix}/CREATE_CASE`;
export const CREATE_CASE_START = `${prefix}/CREATE_CASE_START`;
export const CREATE_CASE_SUCCESS = `${prefix}/CREATE_CASE_SUCCESS`;
export const CREATE_CASE_ERROR = `${prefix}/CREATE_CASE_ERROR`;
export const CREATE_CASE_ERROR_RESET = `${prefix}/CREATE_CASE_ERROR_RESET`;

export const UPDATE_CASE = `${prefix}/UPDATE_CASE`;
export const UPDATE_CASE_START = `${prefix}/UPDATE_CASE_START`;
export const UPDATE_CASE_SUCCESS = `${prefix}/UPDATE_CASE_SUCCESS`;
export const UPDATE_CASE_ERROR = `${prefix}/UPDATE_CASE_ERROR`;
export const UPDATE_CASE_ERROR_RESET = `${prefix}/UPDATE_CASE_ERROR_RESET`;

export const CANCEL_CASE = `${prefix}/CANCEL_CASE`;
export const CANCEL_CASE_START = `${prefix}/CANCEL_CASE_START`;
export const CANCEL_CASE_SUCCESS = `${prefix}/CANCEL_CASE_SUCCESS`;
export const CANCEL_CASE_ERROR = `${prefix}/CANCEL_CASE_ERROR`;
export const CANCEL_CASE_ERROR_RESET = `${prefix}/CANCEL_CASE_ERROR_RESET`;

export const UPDATE_CASE_SERVICE = `${prefix}/UPDATE_CASE_SERVICE`;
export const UPDATE_CASE_SERVICE_START = `${prefix}/UPDATE_CASE_SERVICE_START`;
export const UPDATE_CASE_SERVICE_SUCCESS = `${prefix}/UPDATE_CASE_SERVICE_SUCCESS`;
export const UPDATE_CASE_SERVICE_ERROR = `${prefix}/UPDATE_CASE_SERVICE_ERROR`;
export const UPDATE_CASE_SERVICE_ERROR_RESET = `${prefix}/UPDATE_CASE_SERVICE_ERROR_RESET`;

export const CLEAR_CASE_DATA = `${prefix}/CLEAR_CASE_DATA`;

/**
 * Reducer
 * */
export interface State {
  case: Case | null;
  caseLoading: boolean;
  caseUpdateLoading: boolean;
  caseCancelLoading: boolean;
  caseServiceLoading: boolean;

  caseError: Error | null;

  caseCreateError: Error | null;
  caseUpdateError: Error | null;
  caseCancelError: Error | null;
  caseServiceError: Error | null;
}

const defaultState: State = {
  case: null,
  caseLoading: false,
  caseUpdateLoading: false,
  caseCancelLoading: false,
  caseServiceLoading: false,

  caseError: null,

  caseCreateError: null,
  caseUpdateError: null,
  caseCancelError: null,
  caseServiceError: null,
};

export interface TeethWithServices {
  services: {
    id: string;
    additionalOptions: {
      id: string;
    }[];
  };
  teeth?: Tooth[];
}

export interface CreateCase {
  id?: string;
  dueDate?: string;
  dateSize?: number;
  practice?: string;
  patient?: string;
  doctor?: string;
  address?: string;
  note?: string;
  previewStatus?: string;
  resultStatus?: string;
  rejectionReason?: string;
  manufacturer?: string;
  family?: string;
  shade?: string;
  printingMaterialId?: string;
  teeth?: TeethWithServices;
  caseServices?: CreateCaseService[];
  status?: string;
}

export interface CreateCaseService {
  id?: string;
  dueDate?: string;
  status?: string;
  note?: string;
  previewStatus?: string;
  resultStatus?: string;
  rejectionReason?: string;
  labService?: string;
  caseMaterials?: {
    labMaterial: string;
  }[];
  caseAdditionalOptions?: {
    labAdditionalOption: string;
  }[];
  labStaff?: string | null;
}

export interface UpdateCase extends CreateCase {
  id?: string;
}

export interface UpdateCaseService {
  id: string;
  labStaff: string | null;
}

export interface UploadCaseServiceFile {
  id: string;
  type: string;
  file: RcFile | undefined;
}

export interface DeleteFile {
  id: string;
  type: 'cases' | 'case-services';
  fileId: string;
}

export interface UploadCaseFile {
  id: string;
  file: RcFile | undefined;
}

export interface CancelCase {
  id: string;
  cancellationReason: string;
}

export default function reducer(
  state = defaultState,
  action: Action = { type: 'undefined' },
): State {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CASE_START:
      return { ...state, caseLoading: true };
    case CREATE_CASE_SUCCESS:
      return { ...state, caseLoading: false, case: payload };
    case CREATE_CASE_ERROR:
      return { ...state, caseLoading: false, caseError: payload, caseCreateError: payload };
    case CREATE_CASE_ERROR_RESET:
      return { ...state, caseError: null, caseCreateError: null };

    case UPDATE_CASE_START:
      return { ...state, caseLoading: true, caseUpdateLoading: true };
    case UPDATE_CASE_SUCCESS:
      return { ...state, caseLoading: false, caseUpdateLoading: false, case: payload };
    case UPDATE_CASE_ERROR:
      return { ...state, caseLoading: false, caseUpdateLoading: false, caseError: payload, caseUpdateError: payload };
    case UPDATE_CASE_ERROR_RESET:
      return { ...state, caseError: null, caseUpdateError: null };

    case CANCEL_CASE_START:
      return { ...state, caseCancelLoading: true };
    case CANCEL_CASE_SUCCESS:
      return { ...state, caseCancelLoading: false, case: payload };
    case CANCEL_CASE_ERROR:
      return { ...state, caseCancelLoading: false, caseCancelError: payload };
    case CANCEL_CASE_ERROR_RESET:
      return { ...state, caseCancelError: null };

    case UPDATE_CASE_SERVICE_START:
      return { ...state, caseServiceLoading: true };
    case UPDATE_CASE_SERVICE_SUCCESS:
      return { ...state, caseServiceLoading: false };
    case UPDATE_CASE_SERVICE_ERROR:
      return { ...state, caseServiceLoading: false, caseServiceError: payload };
    case UPDATE_CASE_SERVICE_ERROR_RESET:
      return { ...state, caseServiceError: null };

    case CLEAR_CASE_DATA:
      return { ...state, case: null };

    default:
      return { ...state };
  }
}

/**
 * Action Creators
 * */

export const createCase = (payload: CreateCase): Action => ({
  type: CREATE_CASE,
  payload,
});

export const updateCase = (payload: UpdateCase): Action => ({
  type: UPDATE_CASE,
  payload,
});

export const cancelCase = (payload: CancelCase): Action => ({
  type: CANCEL_CASE,
  payload,
});

export const updateCaseService = (payload: UpdateCaseService): Action => ({
  type: UPDATE_CASE_SERVICE,
  payload,
});

export const clearCaseData = (): Action => ({
  type: CLEAR_CASE_DATA,
});

/**
 * Helper functions
 */

/**
 * Sagas
 */
function* createCaseSaga({ payload }: { payload: CreateCase; }): Generator {
  yield put({
    type: CREATE_CASE_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/cases`,
    {
      method: 'POST',
      body: { ...payload },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    CREATE_CASE_SUCCESS,
    CREATE_CASE_ERROR,
    false,
  );
}

function* updateCaseSaga({ payload: { id, ...data } }: { payload: UpdateCase; }): Generator {
  yield put({
    type: UPDATE_CASE_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/cases/${id}`,
    {
      method: 'PATCH',
      body: { ...data },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    UPDATE_CASE_SUCCESS,
    UPDATE_CASE_ERROR,
    false,
    () => data,
  );
}

function* updateCaseServiceSaga({ payload: { id, ...data } }: { payload: UpdateCaseService; }): Generator {
  yield put({
    type: UPDATE_CASE_SERVICE_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/case-services/${id}`,
    {
      method: 'PATCH',
      body: { ...data },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    UPDATE_CASE_SERVICE_SUCCESS,
    UPDATE_CASE_SERVICE_ERROR,
    false,
    () => data,
  );
}

function* cancelCaseSaga({ payload: { id, ...data } }: { payload: CancelCase; }): Generator {
  yield put({
    type: CANCEL_CASE_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/cases/${id}/cancel`,
    {
      method: 'POST',
      body: { ...data },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    CANCEL_CASE_SUCCESS,
    CANCEL_CASE_ERROR,
    false,
  );
}

export function* saga(): Generator {
  yield takeLatest(
    CREATE_CASE,
    cancelableLocationSaga.bind(
      null,
      createCaseSaga,
      CREATE_CASE_ERROR,
      false,
    ),
  );
  yield takeLatest(CREATE_CASE_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: CREATE_CASE_ERROR_RESET,
    });
  });

  yield takeLatest(
    UPDATE_CASE,
    cancelableLocationSaga.bind(
      null,
      updateCaseSaga,
      UPDATE_CASE_ERROR,
      false,
    ),
  );
  yield takeLatest(UPDATE_CASE_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: UPDATE_CASE_ERROR_RESET,
    });
  });

  yield takeLatest(
    CANCEL_CASE,
    cancelableLocationSaga.bind(
      null,
      cancelCaseSaga,
      CANCEL_CASE_ERROR,
      false,
    ),
  );
  yield takeLatest(CANCEL_CASE_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: CANCEL_CASE_ERROR_RESET,
    });
  });
  yield takeLatest(
    UPDATE_CASE_SERVICE,
    cancelableLocationSaga.bind(
      null,
      updateCaseServiceSaga,
      UPDATE_CASE_SERVICE_ERROR,
      false,
    ),
  );
  yield takeLatest(UPDATE_CASE_SERVICE_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: UPDATE_CASE_SERVICE_ERROR_RESET,
    });
  });
}
