import { App } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { UserRoles } from '../../../../enums/user';
import {
  moduleName,
  User,
} from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { prepareSmileUserInitialData } from '../../../../utils/prepareData';
import PracticeCreate from '../../Practices/Create';
import confirm from '../../../Common/ModalConfirm';

interface SmileUserLogin {
  authorized: boolean;
  user: User | null;
}
const SmileUserLogin: React.FC<SmileUserLogin> = ({ authorized, user }) => {
  const { modal } = App.useApp();
  const [userRole, setUserRole] = useState<UserRoles.practiceManager | UserRoles.doctor | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  useEffect(() => {
    const isSmileUser = user?.role === UserRoles.doctor && !user?.doctor?.practice;

    if (!userRole && isSmileUser) {
      confirm({
        modal,
        title: 'Are you a practice manager?',
        onOk: () => {
          setUserRole(UserRoles.practiceManager);
          setIsDrawerOpen(true);
        },
        onCancel: () => setUserRole(UserRoles.doctor),
        okText: 'Yes',
        cancelText: 'No',
        closable: false,
      });
    }
  }, [authorized]);

  useEffect(() => {
    if (userRole === UserRoles.doctor) {
      confirm({
        modal,
        title: 'Please, contact your Practice Manager in order to get access to the platform',
        okText: 'Ok',
        onOk: () => undefined,
        cancelButtonProps: { style: { display: 'none' } },
        closable: false,
      });
    }
  }, [userRole]);

  const smileUserInitialData = prepareSmileUserInitialData(user);

  return (
    <div>
      {user && (
        <PracticeCreate
          title="Add New Practice"
          drawerOpen={isDrawerOpen}
          setDrawerOpen={setIsDrawerOpen}
          initialData={{
            smileUser: smileUserInitialData,
          }}
        />
      )}
    </div>
  );
};

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  user: state[moduleName].user,
}))(SmileUserLogin);
