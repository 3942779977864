import { FormInstance } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { Checkbox, Col, DatePicker, Form, Row, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { JsonResult } from '../../../../../types';

import ContentCard from '../../../../Common/ContentCard';
import ControlsEditMode from '../../../../Common/ControlsEditMode';
import Loading from '../../../../Common/Loading';
import { useContextCaseFlow } from '../../../../../context/caseFlow';
import { requiredDatepicker } from '../../../../../utils/inputRules';
import { IPrice, Material } from '../../../../../hooks/materials';
import { getDaysDiffFromToday } from '../../../../../utils';
import { countDaysOff, getDisabledDaysCount, isThisDayEvent } from '../../../../../utils/datePickerHelpers';
import PriceBlock from '../../../../Common/PriceBlock';

export interface CaseTotalPriceProp {
  price: number;
  leadTimePrice: number;
  loading: boolean;
}

interface SelectTreatmentPlanForm {
  initialData?: JsonResult;
  editMode?: boolean;
  isAdmin?: boolean;
  disabled?: boolean;
  onSubmit?: () => void;
  handleChange?: (field: JsonResult[]) => void;
  children?: React.ReactNode;
  form: FormInstance;
  caseTotalPrice: CaseTotalPriceProp;
  setPrintingId: React.Dispatch<React.SetStateAction<string>>;
  setDateSizeCount?: React.Dispatch<React.SetStateAction<number>>;
}

const SelectTreatmentPlanForm: React.FC<SelectTreatmentPlanForm> = (props) => {
  const {
    initialData,
    editMode,
    onSubmit,
    handleChange,
    children,
    form,
    caseTotalPrice,
    setPrintingId,
    setDateSizeCount,
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [maxExpeditedDaysState, setMaxExpeditedDaysState] = useState<number>(0);
  const [maxRegularDaysState, setMaxRegularDaysState] = useState<number>(0);

  const {
    caseByIdInContext, selectTreatmentPlan, foundSurgicalService, foundRestorativeService, foundPrintingService,
    practicePriceLevelServiceInContext, patientByIdInContext, teethValues, materialImplantId, materialCrownId,
    eventsListGetInContext,
  } = useContextCaseFlow();

  useEffect(() => {
    /** Used on case update. When we set data in context for this field, we updates form values */
    form.setFieldsValue(selectTreatmentPlan);
  }, [selectTreatmentPlan]);

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (!onSubmit) return;

    onSubmit();
    setIsEditing(false);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const servicesArrValues: string[] = Form.useWatch('services', form);

  const isServiceSelected = (serviceId: string) => !!servicesArrValues?.includes(serviceId);
  const isRestorativeSelected = foundRestorativeService ? servicesArrValues?.includes(foundRestorativeService.id || '')
    : false;
  const isDisabledCheckbox = (serviceId: string | undefined): boolean => {
    if (!serviceId) return false;

    if (serviceId === foundSurgicalService?.id) {
      return !!teethValues.filter((tooth) => tooth.materials
        ?.includes(materialImplantId || ''))?.length;
    }
    if (serviceId === foundRestorativeService?.id) {
      return !!teethValues.filter((tooth) => tooth.materials
        ?.includes(materialCrownId || ''))?.length
        || !!teethValues?.some((tooth) => tooth.isBridge);
    }

    return false;
  };
  const getTooltipTitleByService = (serviceId: string | undefined): string => {
    if (serviceId === foundSurgicalService?.id) {
      return 'Please deselect the implant before unchecking the service';
    }
    if (serviceId === foundRestorativeService?.id) {
      return 'Please deselect the crown and bridge before unchecking the service';
    }

    return '';
  };

  useEffect(() => {
    if (foundPrintingService && servicesArrValues?.find((item) => item === foundPrintingService.id)) {
      setPrintingId(foundPrintingService?.id || '');
    }
    if (!form.getFieldsValue()?.services?.find((item: string) => item === foundPrintingService?.id)) {
      setPrintingId('');
    }
  }, [foundPrintingService, servicesArrValues]);

  /** Disabling dates in DatePicker functionality \/ */
  const [implantTeethLength, setImplantTeethLength] = useState(0);
  const [crownTeethLength, setCrownTeethLength] = useState(0);

  useEffect(() => {
    setImplantTeethLength(teethValues?.filter((tooth) => tooth.materials?.includes(materialImplantId))?.length);
    setCrownTeethLength(teethValues?.filter((tooth) => tooth.materials?.includes(materialCrownId))?.length);
  }, [teethValues]);

  const [matchingMaterialImplant, setMatchingMaterialImplant] = useState<Material | undefined>(undefined);
  const [matchingMaterialCrown, setMatchingMaterialCrown] = useState<Material | undefined>(undefined);

  useEffect(() => {
    if (foundSurgicalService) {
      setMatchingMaterialImplant(foundSurgicalService?.priceLevelMaterials?.find((priceMaterial) => (
        priceMaterial?.id === materialImplantId
      )));
    }
    if (foundRestorativeService) {
      setMatchingMaterialCrown(foundRestorativeService?.priceLevelMaterials?.find((priceMaterial) => (
        priceMaterial?.id === materialCrownId
      )));
    }
  }, [foundSurgicalService, foundRestorativeService, teethValues, implantTeethLength, crownTeethLength]);

  const [matchingPriceSurgical, setMatchingPriceSurgical] = useState<IPrice | undefined>(undefined);
  const [matchingPriceRestorative, setMatchingPriceRestorative] = useState<IPrice | undefined>(undefined);

  useEffect(() => {
    if (matchingMaterialImplant) {
      setMatchingPriceSurgical(matchingMaterialImplant?.prices?.find((price) => (
        price.minRange <= implantTeethLength && price.maxRange >= implantTeethLength
      )));
    }
    if (matchingMaterialCrown) {
      setMatchingPriceRestorative(matchingMaterialCrown?.prices?.find((price) => (
        price.minRange <= crownTeethLength && price.maxRange >= crownTeethLength
      )));
    }
  }, [matchingMaterialImplant, matchingMaterialCrown, teethValues, crownTeethLength, implantTeethLength]);

  useEffect(() => {
    if (matchingPriceSurgical || matchingPriceRestorative) {
      setMaxExpeditedDaysState(Math.max(
        matchingPriceSurgical?.expeditedDate || 0,
        matchingPriceRestorative?.expeditedDate || 0,
      ));
    } else if (!matchingPriceSurgical && !matchingPriceRestorative) {
      setMaxExpeditedDaysState(0);
    }

    if (matchingPriceSurgical || matchingPriceRestorative) {
      setMaxRegularDaysState(Math.max(
        matchingPriceSurgical?.dateSize || 0,
        matchingPriceRestorative?.dateSize || 0,
      ));
    } else if (!matchingPriceSurgical && !matchingPriceRestorative) {
      setMaxRegularDaysState(0);
    }
  }, [matchingPriceSurgical, matchingPriceRestorative, teethValues]);

  const dueDateValue = Form.useWatch('dueDate', form);

  /** Count how many daysOff before selected in datepicker date */
  const daysOffTotalCount = countDaysOff(dayjs(dueDateValue), eventsListGetInContext?.data?.data || []);

  const [arrayOfExpeditedDays, setArrayOfExpeditedDays] = useState<string[]>([]);

  useEffect(() => {
    if (eventsListGetInContext?.data) {
      let count = maxExpeditedDaysState;
      let index = maxExpeditedDaysState;

      const tempArr: string[] = [];

      while (count < maxRegularDaysState) {
        if (!isThisDayEvent(dayjs().add(index, 'days'), eventsListGetInContext.data.data)) {
          tempArr.push(dayjs().add(index, 'days').format('YYYY-MM-DD'));
          count++;
        }
        index++;
      }
      setArrayOfExpeditedDays(tempArr);
    }
  }, [maxRegularDaysState, maxExpeditedDaysState, eventsListGetInContext?.data]);

  useEffect(() => {
    if (dueDateValue) {
      setDateSizeCount?.(getDaysDiffFromToday(dayjs(dueDateValue)) - daysOffTotalCount + 1);
    } else setDateSizeCount?.(0);
  }, [dueDateValue, daysOffTotalCount]);

  /** Defines how many disabled days are in this case. */
  const disabledDaysCount = getDisabledDaysCount({
    surgicalDateSize: matchingPriceSurgical?.dateSize,
    surgicalExpeditedSize: matchingPriceSurgical?.expeditedDate,
    restorativeDateSize: matchingPriceRestorative?.dateSize,
    restorativeExpeditedSize: matchingPriceRestorative?.expeditedDate,
  }) - 1;
  /** DO -1 to let chose minimal disabled days count.
   * For example if dateSize is 5 - disabledDays count will return 5 but we need to have ability to select it. */

  const isDatepickerDateDisabled = (current: Dayjs, checkIsEvent = false): boolean => {
    if (checkIsEvent) {
      if (isThisDayEvent(current, eventsListGetInContext?.data?.data || [])) {
        return true;
      }
    }

    if (disabledDaysCount) {
      return dayjs().add(disabledDaysCount, 'days') >= current;
    }

    return false;
  };

  /** While due date range of available dates changes trigger validate fields. */
  // useEffect(() => {
  //   form.validateFields();
  // }, [matchingPriceSurgical, matchingPriceRestorative]);

  const isDateExpedited = (date: Dayjs): boolean => (
    !!arrayOfExpeditedDays.find((expeditedDay) => dayjs(expeditedDay).isSame(date, 'day')));

  const dateRender = (current: Dayjs) => {
    const style: React.CSSProperties = {};

    /** Render date with pink background if date between exp and reg days and not disabled */
    if (isDateExpedited(dayjs(current))) {
      style.backgroundColor = '#ffcccc';
    }

    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  };

  return (
    <div>
      <Row justify="space-between" className="case-card-heading">
        <div className="typography-wrapper">
          <Typography.Title level={4} style={{ marginBottom: 0 }}>Select Treatment Plan</Typography.Title>
        </div>
        <div className="case-total-price">
          <Typography.Text>Total price per Case</Typography.Text>
          <div className="price">
            {/* CHECK when use this re-rendering component + google page translator, page crashes
              * https://github.com/facebook/react/issues/11538 */}
            <PriceBlock
              price={caseTotalPrice.price || 0}
              leadTimePrice={caseTotalPrice?.leadTimePrice || 0}
              loading={caseTotalPrice.loading}
            />
          </div>
        </div>
      </Row>
      <ContentCard paddingSize="small">
        <Form
          form={form}
          layout="vertical"
          initialValues={initialData}
          onFinish={onSubmit && handleSubmit}
          onFieldsChange={handleChange}
        >
          <Row gutter={15}>
            <Col span={24}>
              <Loading
                absolute
                delay={200}
                visible={!!patientByIdInContext?.loading || !!practicePriceLevelServiceInContext?.loading}
              />
              <Form.Item
                name="services"
                rules={[{ required: true, message: 'You have to select at least one service!' }]}
              >
                <Checkbox.Group
                  style={{ width: '100%' }}
                  onChange={(checkboxValues) => !checkboxValues.includes(foundRestorativeService?.id || '')
                      && checkboxValues.includes(foundPrintingService?.id || '')
                      && form.setFieldValue(
                        'services',
                        checkboxValues.filter((item) => item !== foundPrintingService?.id),
                      )}
                >
                  <Row gutter={40}>
                    {(caseByIdInContext?.data?.practice?.priceLevelServices
                      || practicePriceLevelServiceInContext?.data?.priceLevel?.priceLevelServices)
                      ?.sort((a, b) => (a?.name || '') < (b?.name || '') ? 1 : -1)
                      ?.filter((service) => service?.service?.status === 'active')
                      ?.filter((service) => !!service?.priceLevelMaterials
                        ?.some((material) => material?.material?.isActive))
                      ?.filter((service) => !!service?.priceLevelMaterials?.some((material) => material?.isActive))
                      ?.map((service) => (
                        <Col key={service.id} span={8}>
                          <Tooltip
                            placement="topLeft"
                            title={isDisabledCheckbox(service.id) ? getTooltipTitleByService(service.id) : undefined}
                          >
                            <Checkbox
                              value={service.id}
                              // disabled={(service.name?.toLowerCase()?.includes('printing') && !isRestorativeSelected)
                              // || (isDisabledCheckbox(service.id))}
                              disabled={(service?.service?.serviceType === 'printing' && !isRestorativeSelected)
                              || (isDisabledCheckbox(service.id))}
                            >
                              {service.name}
                            </Checkbox>
                          </Tooltip>
                          <Col span={24}>
                            <Form.Item name={service.id}>
                              <Checkbox.Group style={{ width: '100%' }}>
                                {service.priceLevelAdditionalOptions
                                  ?.filter((priceOption) => priceOption?.isActive)
                                  ?.filter((priceOption) => priceOption?.additionalOption?.isActive)
                                  ?.map((option) => (
                                    <Col key={option.id} span={24}>
                                      <Checkbox
                                        value={option.id}
                                        disabled={!isServiceSelected(service?.id || '')}
                                      >
                                        {option?.additionalOption?.name || '-'}
                                      </Checkbox>
                                    </Col>
                                  ))}
                              </Checkbox.Group>
                            </Form.Item>
                          </Col>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>

            <Col span={9}>
              <>
                <Form.Item
                  label="Due Date"
                  name="dueDate"
                  rules={[...requiredDatepicker, {
                    message: 'This date is not available for selection',
                    validator: (_, value) => {
                      /** Uncomment if need to have ability to update something in case without updating due date */
                      // With this code need to disable sending of dateSize on update call.
                      // if (dayjs(caseByIdInContext?.data?.dueDate).isSame(dayjs(value), 'day')) {
                      //   return Promise.resolve();
                      // }

                      if (dayjs().add(0, 'days') >= dayjs(value)) {
                        return Promise.reject(new Error('Due date invalid'));
                      }

                      if (!isDatepickerDateDisabled(dayjs(value), true)) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error('Due date invalid'));
                    },
                  }]}
                >
                  <DatePicker
                    format="MMM DD, YYYY"
                    style={{ width: '100%' }}
                    disabled={!teethValues.length}
                    disabledDate={(current) => (
                      dayjs().add(0, 'days') >= current
                      || isDatepickerDateDisabled(current, true)
                    )}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    cellRender={dateRender}
                  />
                </Form.Item>
                {isDateExpedited(dayjs(dueDateValue))
                  && (
                    <p style={{ color: 'var(--color-date-expedited-price)' }}>
                      Expedited pricing has been applied. Please select a later date to return to the regular pricing
                      (dates with expedited pricing marked in pink)
                    </p>
                  )}
              </>
            </Col>
            <Col span={2}>
              <Form.Item label=" ">
                <Tooltip
                  placement="bottom"
                  title={(
                    <>
                      <p>The deadline by which the lab has to complete all requested services</p>
                      <p>Dates marked as grey - unavailable for selection</p>
                      <span>Dates marked as pink - dates to which will be applied expedited pricing</span>
                    </>
                )}
                >
                  <InfoCircleOutlined style={{ fontSize: '16px' }} />
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {editMode && (
          <ControlsEditMode
            isEditing={isEditing}
            onCancel={handleCancel}
            onChangeEditing={setIsEditing}
            onSubmit={handleFormSubmit}
            isFormValid
          />
        )}
        {children}
      </ContentCard>
    </div>
  );
};

SelectTreatmentPlanForm.defaultProps = {
  initialData: {},
  editMode: false,
  isAdmin: false,
  disabled: false,
  onSubmit: () => undefined,
  handleChange: () => undefined,
  children: null,
  setDateSizeCount: () => undefined,
};

export default SelectTreatmentPlanForm;
