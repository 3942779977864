import { Form, Input, InputNumber, Select, Space, Switch, Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { AdditionalOptionsTypes } from '../../../../../enums/services';
import styles from '../../Edit/index.module.scss';

interface OptionRow {
  name: number;
  restField: {
    fieldKey?: number;
  };
  isEditing?: boolean;
  type?: string;
  singleType?: boolean;
  nameDisabled?: boolean;
  hidePrice?: boolean;
  hideToggle?: boolean;
  disableSwitch?: boolean;
}

const OptionRow = (props: OptionRow) => {
  const { name, restField, isEditing, singleType, type, nameDisabled, hidePrice, hideToggle, disableSwitch } = props;

  return (
    <>
      <Form.Item name={[name, 'id']} className="hidden-input">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={[name, 'material']} className="hidden-input">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={[name, 'additionalOption']} className="hidden-input">
        <Input type="hidden" />
      </Form.Item>
      <Space className={clsx(styles.row, 'service-row')} align="center">
        <Form.Item
          className="no-space-form-item"
          name={[name, 'name']}
          {...restField}
        >
          <Input placeholder="Name" disabled={nameDisabled || !isEditing} />
        </Form.Item>
        {!hidePrice && (
          <>
            <Typography>Base price:</Typography>
            <Form.Item
              className={clsx(styles.price, 'no-space-form-item')}
              name={[name, 'price']}
              rules={[{ validator: (_, value) => {
                if (Number(value) >= 0) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Price invalid'));
              } }]}
              {...restField}
            >
              <InputNumber prefix="$" min={0} disabled={!isEditing} placeholder="Price" controls={false} />
            </Form.Item>
            {!singleType ? (
              <Form.Item
                className={clsx(styles.type, 'no-space-form-item')}
                name={[name, 'type']}
                {...restField}
              >
                <Select
                  options={Object.values(AdditionalOptionsTypes).map((value) => ({ label: value, value }))}
                  disabled={!isEditing}
                />
              </Form.Item>
            ) : (
              <Typography>{type}</Typography>
            )}
          </>
        )}
        {isEditing && !hideToggle && (
          <Form.Item
            className="no-space-form-item"
            name={[name, 'isActive']}
            valuePropName="checked"
            {...restField}
          >
            <Switch disabled={disableSwitch} />
          </Form.Item>
        )}
      </Space>
    </>
  );
};

OptionRow.defaultProps = {
  hideToggle: false,
  singleType: false,
  isEditing: false,
  nameDisabled: false,
  hidePrice: false,
  type: '',
  disableSwitch: false,
};

export default OptionRow;
