import { Content } from 'antd/es/layout/layout';
import React from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import AdminInfoDrawer from '../AdminInfo';
import { UserRoles } from '../../../../enums/user';
import DoctorInfoDrawer from '../../Practices/Profile/Doctor/DoctorInfo';
import LabStaffInfoDrawer from '../../Labs/Profile/Staff/StaffInfo';

function PageCaseId(): JSX.Element {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { id = '' } = useParams<{ id: string; }>();
  const closeModal = () => navigate({ pathname: searchParams.get('fromPage') && searchParams.get('fromPage') !== '/'
    ? `/${searchParams.get('fromPage')}` : '/users' });

  const role = searchParams.get('role') || '';

  return (
    <Content>
      {!role && (
        <div>
          Wrong page params. Get back and try again!
        </div>
      )}
      <AdminInfoDrawer
        isOpen={(role === UserRoles.admin) || (role === UserRoles.root)}
        close={closeModal}
        id={id}
      />
      <DoctorInfoDrawer
        isOpen={((role === UserRoles.doctor) && !!id) || ((role === UserRoles.practiceManager) && !!id)}
        close={closeModal}
        id={id}
        disableGetPracticeFromUrl
      />
      <LabStaffInfoDrawer
        isOpen={((role === UserRoles.labStaff) && !!id) || ((role === UserRoles.labManager) && !!id)}
        close={closeModal}
        id={id}
      />
    </Content>
  );
}

export default PageCaseId;
