import { Button, Empty, Space, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Notification } from '../../../../hooks/notifications';
import { groupArrayBy } from '../../../../utils/prepareData';
import styles from '../index.module.scss';
import NotificationRow from './NotificationRow';

interface NotificationsContent {
  notifications?: Notification[];
}

const NotificationsContent: React.FC<NotificationsContent> = ({ notifications }) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const prepareDateField = (data: any) => dayjs(data) === dayjs() ? 'Today' : dayjs(data).format('dddd, MMMM DD');

  const groupedNotifications = groupArrayBy(notifications || [], 'createdAt', prepareDateField);

  return (
    <div className={styles.notificationsWrapper}>
      <Typography.Title level={3} className={styles.notificationsGroupTitle}>Recent notifications</Typography.Title>
      <Space direction="vertical" className={styles.notificationsContent}>
        {notifications && notifications.length
          ? Object.entries(groupedNotifications).map(([date, items]) => (
            <Space direction="vertical" key={date} style={{ width: '100%' }}>
              <Typography className={styles.notificationsGroupTitle}>{date}</Typography>
              {items.map((item: Notification) => <NotificationRow key={item.id} item={item} />)}
            </Space>
          ))
          : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
      </Space>
      <Button
        type="default"
        className={styles.viewAllButton}
        onClick={() => navigate('/notifications')}
      >
        View All
      </Button>
    </div>
  );
};

NotificationsContent.defaultProps = {
  notifications: undefined,
};

export default NotificationsContent;
