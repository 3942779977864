import React from 'react';
import { Card, Divider } from 'antd';
import { CardProps } from 'antd/lib/card/Card';
import clsx from 'clsx';
import Status from '../Status';
import { capitalizeFirstLetter } from '../../../utils';

import styles from './index.module.scss';

const cardHeadStyles: React.CSSProperties = {
  backgroundColor: 'var(--color-main-orange)',
  color: 'white',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '24px',
};

const cardHeadSecondaryStyles: React.CSSProperties = {
  backgroundColor: 'var(--color-button-third-border)',
  color: 'var(--color-main-dark-gray)',
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '28px',
};

export interface DescriptionItem {
  label: string;
  content: string;
  style?: React.CSSProperties;
}

interface ServiceCard extends CardProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  descriptions?: {
    items: DescriptionItem[];
    // columns: number,
  };
  additionalOptions?: { content: string; }[];
  secondaryColorScheme?: boolean;
  filesContent?: React.ReactNode;
  status?: string;
  dueDateExtra?: React.ReactNode;
  hideDueDateDefaultLabel?: boolean;
}

const ServiceCard: React.FC<ServiceCard> = (props) => {
  const {
    title, children, descriptions, additionalOptions, secondaryColorScheme, status, dueDateExtra, extra, filesContent,
    hideDueDateDefaultLabel,
    ...rest
  } = props;

  return (
    <Card
      size="small"
      title={status ? (
        <div style={{ display: 'flex', gap: '20px' }}>
          {title}
          <Status status={capitalizeFirstLetter(status)} />
        </div>
      ) : title}
      bordered={false}
      className={clsx(styles.card, { [styles.secondaryBg]: secondaryColorScheme })}
      headStyle={secondaryColorScheme ? cardHeadSecondaryStyles : cardHeadStyles}
      extra={dueDateExtra ? (
        <div className={styles.extraWrapper}>
          <div className={styles.dueDateExtra} style={hideDueDateDefaultLabel ? { padding: 0 } : undefined}>
            {!hideDueDateDefaultLabel && 'Due Date'}
            {' '}
            <span>{dueDateExtra}</span>
          </div>
          {extra}
        </div>
      ) : extra}
      {...rest}
    >
      {!!descriptions?.items.length && (
        <div className={styles.descriptionsWrapper}>
          {descriptions?.items.map(({ content, label, style }) => (
            <div key={label || Math.random()} className={styles.descriptionItem} style={style}>
              <span className={styles.label}>{label}</span>
              <span>{content}</span>
            </div>
          ))}
        </div>
      )}
      {!!additionalOptions?.length && (
        <>
          <Divider style={{ margin: '20px 0', borderColor: 'var(--color-main-orange-50)' }} />
          <div className={styles.label}>Additional option:</div>
          {additionalOptions.map(({ content }) => (
            <div key={content || Math.random()}>{content}</div>
          ))}
        </>
      )}
      {!!filesContent && (
        <div className={styles.filesContent}>
          {filesContent}
        </div>
      )}
      {children}
    </Card>
  );
};

ServiceCard.defaultProps = {
  children: undefined,
  descriptions: { items: [] },
  additionalOptions: [],
  secondaryColorScheme: false,
  filesContent: undefined,
  status: undefined,
  dueDateExtra: undefined,
  hideDueDateDefaultLabel: false,
};

export default ServiceCard;
