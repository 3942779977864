import React, { PropsWithChildren, useEffect } from 'react';
import { Layout } from 'antd';

import { connect } from 'react-redux';
import SiderCommon from './Sider';
import HeaderCommon from './Header';
import styles from './index.module.scss';
import { sendUserActivityLog } from '../../../hooks/loggers';
import { isRoleEnough } from '../../../utils/auth';
import { moduleName, User } from '../../../store/ducks/auth';
import { RootState } from '../../../store/reducers';

interface MainMenu extends PropsWithChildren {
  user: User | null;
}

const Main: React.FC<MainMenu> = ({ children, user }): JSX.Element => {
  useEffect(() => {
    setInterval(() => {
      sendUserActivityLog();
    }, 300000);
  }, []);

  useEffect(() => sendUserActivityLog, []);

  return (
    <Layout>
      <HeaderCommon />
      <Layout className={styles.layout} id="layout-content">
        {!isRoleEnough(['doctor', 'lab staff'], true, user?.role) && (
          <SiderCommon />
        )}
        {children}
      </Layout>
    </Layout>
  );
};

export default connect((state: RootState) => ({
  user: state[moduleName].user,
}))(Main);
