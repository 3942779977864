import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { alertDelaySuccess, name as appName } from '../../config';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse, Error } from './common';
import { Action } from '../index';
import { fetchSaga, User } from './auth';
import { CreateAddress } from '../../hooks/addresses';
import { Patient } from '../../hooks/patients';

/**
 * Constants
 * */
export const moduleName = 'patient';
const prefix = `${appName}/${moduleName}`;

export const CREATE_PATIENT = `${prefix}/CREATE_PATIENT`;
export const CREATE_PATIENT_START = `${prefix}/CREATE_PATIENT_START`;
export const CREATE_PATIENT_SUCCESS = `${prefix}/CREATE_PATIENT_SUCCESS`;
export const CREATE_PATIENT_ERROR = `${prefix}/CREATE_PATIENT_ERROR`;
export const CREATE_PATIENT_ERROR_RESET = `${prefix}/CREATE_PATIENT_ERROR_RESET`;

export const UPDATE_PATIENT = `${prefix}/UPDATE_PATIENT`;
export const UPDATE_PATIENT_START = `${prefix}/UPDATE_PATIENT_START`;
export const UPDATE_PATIENT_SUCCESS = `${prefix}/UPDATE_PATIENT_SUCCESS`;
export const UPDATE_PATIENT_ERROR = `${prefix}/UPDATE_PATIENT_ERROR`;
export const UPDATE_PATIENT_ERROR_RESET = `${prefix}/UPDATE_PATIENT_ERROR_RESET`;

export const DELETE_PATIENT = `${prefix}/DELETE_PATIENT`;
export const DELETE_PATIENT_START = `${prefix}/DELETE_PATIENT_START`;
export const DELETE_PATIENT_SUCCESS = `${prefix}/DELETE_PATIENT_SUCCESS`;
export const DELETE_PATIENT_ERROR = `${prefix}/DELETE_PATIENT_ERROR`;
export const DELETE_PATIENT_ERROR_RESET = `${prefix}/DELETE_PATIENT_ERROR_RESET`;

export const CLEAR_PATIENT_DATA = `${prefix}/CLEAR_PATIENT_DATA`;

/**
 * Reducer
 * */
export interface State {
  patient: Patient | null;
  patientLoading: boolean;
  patientResponse: CreatePatientResponse | null;

  patientError: Error | null;
}

const defaultState: State = {
  patient: null,
  patientLoading: false,
  patientResponse: null,

  patientError: null,
};

export interface CreatePatient {
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  addresses?: CreateAddress[];
  doctor: string;
  cases?: [];
  note?: string;
}

export interface UpdatePatient {
  id: string;
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  addresses?: CreateAddress[];
  doctor: string;
  cases?: [];
  note?: string;
}

export interface CreatePatientResponse extends Patient {
  user: User;
}

export default function reducer(
  state = defaultState,
  action: Action = { type: 'undefined' },
): State {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PATIENT_START:
      return { ...state, patientLoading: true };
    case CREATE_PATIENT_SUCCESS:
      return { ...state, patientLoading: false, patientResponse: payload };
    case CREATE_PATIENT_ERROR:
      return { ...state, patientLoading: false, patientError: payload };
    case CREATE_PATIENT_ERROR_RESET:
      return { ...state, patientError: null };

    case UPDATE_PATIENT_START:
      return { ...state, patientLoading: true };
    case UPDATE_PATIENT_SUCCESS:
      return { ...state, patientLoading: false, patient: payload, patientResponse: payload };
    case UPDATE_PATIENT_ERROR:
      return { ...state, patientLoading: false, patientError: payload };
    case UPDATE_PATIENT_ERROR_RESET:
      return { ...state, patientError: null };

    case CLEAR_PATIENT_DATA:
      return { ...state, patient: null };

    default:
      return { ...state };
  }
}

/**
 * Action Creators
 * */

export const createPatient = (payload: CreatePatient): Action => ({
  type: CREATE_PATIENT,
  payload,
});

export const updatePatient = (payload: UpdatePatient): Action => ({
  type: UPDATE_PATIENT,
  payload,
});

export const clearPatientData = (): Action => ({
  type: CLEAR_PATIENT_DATA,
});

/**
 * Helper functions
 */

/**
 * Sagas
 */
function* createPatientSaga({ payload }: { payload: CreatePatient; }): Generator {
  yield put({
    type: CREATE_PATIENT_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/patients`,
    {
      method: 'POST',
      body: { ...payload },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    CREATE_PATIENT_SUCCESS,
    CREATE_PATIENT_ERROR,
    false,
  );
}

function* updatePatientSaga({ payload: { id, ...data } }: { payload: UpdatePatient; }): Generator {
  yield put({
    type: UPDATE_PATIENT_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/patients/${id}`,
    {
      method: 'PATCH',
      body: { ...data },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    UPDATE_PATIENT_SUCCESS,
    UPDATE_PATIENT_ERROR,
    false,
    () => data,
  );
}

export function* saga(): Generator {
  yield takeLatest(
    CREATE_PATIENT,
    cancelableLocationSaga.bind(
      null,
      createPatientSaga,
      CREATE_PATIENT_ERROR,
      false,
    ),
  );
  yield takeLatest(CREATE_PATIENT_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: CREATE_PATIENT_ERROR_RESET,
    });
  });

  yield takeLatest(
    UPDATE_PATIENT,
    cancelableLocationSaga.bind(
      null,
      updatePatientSaga,
      UPDATE_PATIENT_ERROR,
      false,
    ),
  );
  yield takeLatest(UPDATE_PATIENT_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: UPDATE_PATIENT_ERROR_RESET,
    });
  });
}
