import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LockFilled, MailFilled } from '@ant-design/icons';
import { Alert, Button, Checkbox, Form as AntdForm, Input } from 'antd';

import { connect } from 'react-redux';
import { emailSignIn, number as numberRule, password } from '../../../../utils/inputRules';
import { RootState } from '../../../../store/reducers';
import {
  errorSignInReset as actionErrorSignInReset,
  SignIn,
  signIn as actionSignIn,
  User,
  moduleName,
} from '../../../../store/ducks/auth';
import { JsonResult, Error } from '../../../../store/ducks/common';
import { Action } from '../../../../store';
import SmileUserLogin from '../SmileUserLogin';
import { getRedirectRoutePath, isLoginPermitted } from '../../../../utils/auth';

import styles from './index.module.scss';

interface Form {
  authorized: boolean;
  response: JsonResult | null;
  user: User | null;
  error: Error | null;
  signIn: (payload: SignIn) => Action;
  loading: boolean;
  errorSignInReset: () => void;
}

const Form: React.FC<Form> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  authorized,
  signIn,
  loading,
  error,
  user,
  errorSignInReset,
}) => {
  const navigate = useNavigate();
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isValidCred, setIsValidCred] = useState<boolean>(false);

  useEffect(() => {
    if (user && isLoginPermitted(user)) {
      navigate(getRedirectRoutePath(user), { replace: true });
    }
  }, [user]);

  const handleSubmit = (values: SignIn) => {
    signIn(values);
    // code for 2FA. Add state in store, if SignInStatusStep = 2 then setIsValidCred(true)...
    // if (res.status) setIsValidCred(true);
  };

  useEffect(() => {
    errorSignInReset();
  }, []);

  // CODE form
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCodeSubmit = (values: { code: string; }) => {
    navigate('/users', { replace: true });
  };

  /** Form for 2FA code */
  if (isValidCred) {
    return (
      <AntdForm
        layout="vertical"
        name="code_sign_in"
        onFinish={handleCodeSubmit}
        className={styles.form}
        autoComplete="off"
      >
        {error ? (
          <AntdForm.Item>
            <Alert
              onClose={errorSignInReset}
              message={error.message}
              type="error"
              closable
              showIcon
            />
          </AntdForm.Item>
        ) : null}
        <AntdForm.Item label="Code" name="code" rules={numberRule}>
          <Input size="large" placeholder="Enter code" maxLength={6} />
        </AntdForm.Item>
        <AntdForm.Item shouldUpdate>
          {({ getFieldsValue }) => {
            const { code: codeValue } = getFieldsValue();
            const formIsComplete = !!codeValue && codeValue.length === 6;

            return (
              <Button
                size="large"
                type="default"
                htmlType="submit"
                block
                loading={loading}
                disabled={!formIsComplete}
              >
                Submit
              </Button>
            );
          }}
        </AntdForm.Item>
        <AntdForm.Item className={styles.linkField}>
          <Link to="/sign-in" style={{ color: 'var(--color-main-orange)' }}>
            Resend code
          </Link>
        </AntdForm.Item>
      </AntdForm>
    );
  }

  return (
    <AntdForm
      layout="vertical"
      name="sign_in"
      onFinish={handleSubmit}
      className={styles.form}
      autoComplete="off"
      initialValues={{ remember: true }}
      validateTrigger={isSubmitClicked ? undefined : ['onSubmit']}
    >
      {error ? (
        <AntdForm.Item>
          <Alert
            onClose={errorSignInReset}
            message={error.message}
            type="error"
            closable
            showIcon
          />
        </AntdForm.Item>
      ) : null}
      <AntdForm.Item label="Email" name="email" rules={emailSignIn}>
        <Input size="large" prefix={<MailFilled />} placeholder="Enter email" />
      </AntdForm.Item>
      <AntdForm.Item label="Password" name="password" rules={password}>
        <Input.Password size="large" prefix={<LockFilled />} placeholder="Enter password" />
      </AntdForm.Item>
      <AntdForm.Item className={styles.checkbox}>
        <AntdForm.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </AntdForm.Item>
        <Link to="/forgot-password" style={{ color: 'var(--color-main-orange)' }}>
          Forgot your password?
        </Link>
      </AntdForm.Item>
      <AntdForm.Item shouldUpdate>
        {({ getFieldsValue }) => {
          const { email: emailValue, password: passwordValue } = getFieldsValue();
          const formIsComplete = !!emailValue && emailValue.length >= 3 && !!passwordValue;

          return (
            <Button
              size="large"
              type="default"
              htmlType="submit"
              block
              loading={loading}
              disabled={!formIsComplete}
              id="loginBtn"
              onClick={() => setIsSubmitClicked(true)}
            >
              Log In
            </Button>
          );
        }}
      </AntdForm.Item>
      <SmileUserLogin />
    </AntdForm>
  );
};

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  response: state[moduleName].responseSignIn,
  user: state[moduleName].user,
  error: state[moduleName].errorSignIn,
  loading: state[moduleName].loadingSignIn,
}), {
  signIn: actionSignIn,
  errorSignInReset: actionErrorSignInReset,
})(Form);
