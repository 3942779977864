import { FormInstance } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { Col, Form, Radio, Row, Select, Typography } from 'antd';
import { JsonResult, Option } from '../../../../../types';

import ContentCard from '../../../../Common/ContentCard';
import ControlsEditMode from '../../../../Common/ControlsEditMode';
import Loading from '../../../../Common/Loading';
import { useContextCaseFlow } from '../../../../../context/caseFlow';
import NotFoundContent from '../../../../Common/NotFoundContent';
import { formatAddressToString } from '../../../../../utils';
import { required } from '../../../../../utils/inputRules';
import { CaseTotalPriceProp } from '../SelectTreatmentPlanForm';
import PriceBlock from '../../../../Common/PriceBlock';

interface PrintingForm {
  initialData?: JsonResult;
  editMode?: boolean;
  isAdmin?: boolean;
  disabled?: boolean;
  onSubmit?: () => void;
  handleChange?: (field: JsonResult[]) => void;
  children?: React.ReactNode;
  form: FormInstance;
  caseTotalPrice: CaseTotalPriceProp;
  practiceId?: string;
  isUpdate?: boolean;
}

const PrintingForm: React.FC<PrintingForm> = (props) => {
  const {
    initialData,
    editMode,
    onSubmit,
    children,
    form,
    handleChange,
    caseTotalPrice,
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { practicePriceLevelServiceInContext, printingInfo, foundPrintingService } = useContextCaseFlow();

  useEffect(() => {
    /** Used on case update. When we set data in context for this field, we updates form values */
    form.setFieldsValue(printingInfo);
  }, [printingInfo]);

  const [addressesOptions, setAddressesOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (practicePriceLevelServiceInContext?.data) {
      setAddressesOptions(
        practicePriceLevelServiceInContext?.data.addresses.map((address) => ({
          value: address.id || '',
          label: formatAddressToString(address) || '-',
        })),
      );
    }
  }, [practicePriceLevelServiceInContext?.data]);

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (!onSubmit) return;

    onSubmit();
    setIsEditing(false);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  return (
    <div>
      <Row justify="space-between" className="case-card-heading">
        <div className="typography-wrapper">
          <Typography.Title level={4} style={{ marginBottom: 0 }}>Printing</Typography.Title>
        </div>
        <div className="case-total-price">
          <Typography.Text>Total price per Case</Typography.Text>
          <div className="price">
            <Loading visible={caseTotalPrice.loading} size="small" />
            <PriceBlock
              price={caseTotalPrice?.price || 0}
              leadTimePrice={caseTotalPrice?.leadTimePrice || 0}
              loading={caseTotalPrice?.loading}
            />
          </div>
        </div>
      </Row>
      <ContentCard paddingSize="default">
        <Form
          form={form}
          layout="vertical"
          initialValues={initialData}
          onFinish={onSubmit && handleSubmit}
          onFieldsChange={handleChange}
        >
          <Row gutter={15}>

            <Col span={24}>
              <Form.Item name="address" label="Shipping Address" rules={required}>
                <Select
                  loading={practicePriceLevelServiceInContext?.loading}
                  options={addressesOptions}
                  placeholder="Please select"
                  notFoundContent={<NotFoundContent message="Addresses not found." />}
                />
              </Form.Item>
            </Col>

            {!!foundPrintingService?.priceLevelMaterials?.filter((material) => material?.isActive)?.length && (
              <Col span={12}>
                <Form.Item name="material" label="Material">
                  <Radio.Group style={{ width: '100%' }}>
                    {foundPrintingService?.priceLevelMaterials?.filter((material) => material?.isActive)
                      ?.map((option) => (
                        <Col key={option.id} span={24}>
                          <Radio value={option.id}>
                            {option?.material?.name || '-'}
                          </Radio>
                        </Col>
                      ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
        {editMode && (
          <ControlsEditMode
            isEditing={isEditing}
            onCancel={handleCancel}
            onChangeEditing={setIsEditing}
            onSubmit={handleFormSubmit}
            isFormValid
          />
        )}
        {children}
      </ContentCard>
    </div>
  );
};

PrintingForm.defaultProps = {
  initialData: {},
  editMode: false,
  isAdmin: false,
  disabled: false,
  onSubmit: () => undefined,
  handleChange: () => undefined,
  children: null,
  practiceId: undefined,
  isUpdate: false,
};

export default PrintingForm;
