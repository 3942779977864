import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, Progress, Space, Typography, Divider, App } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import { ItemRender } from 'antd/es/upload/interface';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, { ReactNode, useEffect, useState } from 'react';
import { ServiceFile, useDownloadAllFiles } from '../../../hooks/case/cases';
import { downloadFromAnchor } from '../../../utils';
import FileRow from './FileRow';

import styles from '../ServiceFilesContent/index.module.scss';

export interface UploadingFile extends UploadFile {
  loading: boolean;
}

const mb = 1048576;

export interface IPercentCompleted {
  fileSizeBytes: number;
  percent: number;
}

interface AttachmentLoader {
  accept?: string;
  defaultFiles?: ServiceFile[];
  disabled?: boolean;
  isExtraShown?: (files: UploadFile[]) => boolean;
  extra?: ReactNode;
  addFileBtnLabel?: string;
  hideControls?: boolean;
  onUpdate?: (files: UploadFile[]) => void;
  handleFileDelete?: (fileId: string) => void;
  uploadingFiles?: UploadingFile[];
  tempFiles?: UploadFile[];
  uploadHintText?: React.ReactNode;
  onFilesLoad?: () => void;
  isFilesLoaded?: boolean;
  caseId?: string;
  caseServiceId?: string;
  commentView?: boolean;
  clearFilesStateTrigger?: boolean;
  resetFilesTriggerState?: () => void;
  percentCompleted?: IPercentCompleted[];
  showDownloadAll?: boolean;
}

const AttachmentLoader: React.FC<AttachmentLoader> = (props) => {
  const { message } = App.useApp();
  const {
    accept,
    defaultFiles,
    extra,
    disabled,
    isExtraShown,
    addFileBtnLabel,
    hideControls,
    onUpdate,
    handleFileDelete,
    uploadingFiles,
    tempFiles,
    uploadHintText,
    onFilesLoad,
    isFilesLoaded,
    caseId,
    caseServiceId,
    commentView,
    clearFilesStateTrigger,
    resetFilesTriggerState,
    percentCompleted,
    showDownloadAll,
  } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval>>();

  const [parsedAcceptExtensions, setParsedAcceptExtensions] = useState<string[]>([]);

  useEffect(() => {
    if (accept) {
      setParsedAcceptExtensions(accept?.split(',') || []);
    }
  }, [accept]);

  /** Download all files */
  const [downloadAllPercent, setDownloadAllPercent] = useState<number | null>(null);

  const controller = new AbortController();

  // eslint-disable-next-line max-len
  const downloadAllFiles = useDownloadAllFiles((progressEvent) => { setDownloadAllPercent(Math.round((progressEvent.loaded * 100) / progressEvent.total)); }, controller);

  /** When component unmounts - abort file download request */
  useEffect(() => () => controller.abort(), []);

  const handleDownloadAllFiles = () => {
    if (downloadAllFiles.loading) return;

    downloadAllFiles.fetch(
      undefined,
      `${caseId}/all-files`,
    ).then((response) => {
      if (!response) return;

      downloadFromAnchor(response, `case-${caseId}-all-files`, 'application/zip');
    }).finally(() => {
      setDownloadAllPercent(null);
    });
  };

  useEffect(() => {
    if (!!uploadingFiles?.length && !intervalId) {
      setUploadPercent(0);
      let uploadedSize = 0;

      // eslint-disable-next-line max-len
      const filesSize = uploadingFiles.reduce((accumulator, currentValue) => accumulator + (currentValue?.size || 0), 0);

      const interval = setInterval(() => {
        uploadedSize += mb;
        setUploadPercent((uploadedSize * 100) / filesSize);
      }, 100);

      setIntervalId(interval);
    }

    if (!uploadingFiles?.length && intervalId) {
      clearInterval(intervalId);
      setIntervalId(undefined);
    }
  }, [uploadingFiles]);

  useEffect(() => {
    if (uploadPercent >= 85 && intervalId) {
      setIntervalId(undefined);
      clearInterval(intervalId);
    }
  }, [uploadPercent]);

  useEffect(() => {
    if (!isFilesLoaded) return;

    clearInterval(intervalId);
    setIntervalId(undefined);
    setUploadPercent(100);

    setTimeout(() => {
      onFilesLoad?.();
    }, 1000);
  }, [isFilesLoaded]);

  useEffect(() => {
    if (!onUpdate || !uploadedFiles?.length) return;

    onUpdate(uploadedFiles);
  }, [uploadedFiles]);

  useEffect(() => {
    if (!tempFiles) return;
    setUploadedFiles(tempFiles);
  }, [tempFiles]);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setUploadedFiles((prev) => [...prev, ...fileList]);
    setFileList([]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileList([]);
  };

  const handleRemove = (index: number) => {
    const newFiles = [...uploadedFiles];

    newFiles.splice(index, 1);

    setUploadedFiles(newFiles);
  };

  const handleChange = (info: UploadChangeParam) => {
    const { status } = info.file;

    /** Validate files and show message with error if wrong type. */
    let newFileList: UploadFile[] = [];

    if (accept) {
      const splitFileName = info.file?.name?.split('.');
      const fileExtension = splitFileName[splitFileName.length - 1];

      if (!parsedAcceptExtensions.includes(`.${fileExtension}`)) {
        message.error(`Only zip/stl/dicom/dcm/neaf files are allowed! Unsupported file type .${fileExtension}`);

        return;
      }

      newFileList = info.fileList?.filter((uploadFile) => {
        const splitUploadFileName = uploadFile?.name?.split('.');
        const fileExt = splitUploadFileName[splitUploadFileName.length - 1];

        return parsedAcceptExtensions.includes(`.${fileExt}`);
      });
    }

    if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);

      return;
    }

    if (info.file?.size && info.file.size > 4000000000) {
      message.error('File is too large!');

      return;
    }

    setFileList(accept ? newFileList : info.fileList);
  };

  useEffect(() => {
    if (clearFilesStateTrigger) {
      setUploadedFiles([]);
      setFileList([]);
      resetFilesTriggerState?.();
    }
  }, [clearFilesStateTrigger]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customRequest = ({ onSuccess }: any): void => {
    setTimeout(() => {
      if (onSuccess) {
        onSuccess('ok');
      }
    }, 0);
  };

  const renderFileRow: ItemRender = (node, file, fileList, actions) => <FileRow name={file?.name} actions={actions} />;

  return (
    <>
      <Modal
        title="Add Attachment"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        centered
        zIndex={1002}
      >
        <Dragger
          multiple
          onChange={handleChange}
          customRequest={customRequest}
          itemRender={renderFileRow}
          fileList={fileList}
          accept={accept}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Drag & Drop Files or Select File(s)</p>
          <p className="ant-upload-hint">
            {!uploadHintText && uploadHintText !== '' ? (
              <>
                <strong>.stl</strong>
                {', '}
                <strong>.dicom</strong>
                {' '}
                or
                {' '}
                <strong>.neaf</strong>
                {' '}
                files are required, max 4Gb.
                <br />
                You can additionally upload other filetypes as well.
              </>
            ) : uploadHintText}
          </p>
        </Dragger>
      </Modal>

      {(!uploadedFiles.length && !defaultFiles?.length) && (
        <Typography style={{ color: 'var(--color-text-disabled)' }}>No files uploaded!</Typography>
      )}

      <Space direction="vertical" style={{ width: '100%' }} size={20}>
        {/** If Staff upload single Dicom file show warning Alert */}
        {defaultFiles?.length === 1 && defaultFiles?.[0]?.file?.mimeType === 'application/dicom'
        && isExtraShown?.(uploadedFiles) && !hideControls && (
          <Alert
            type="warning"
            message="You have uploaded a single DICOM file, please make sure you would like to proceed"
            showIcon
          />
        )}
        {(!!defaultFiles?.length || !!uploadedFiles?.length || !!uploadingFiles?.length) && (
          <div>
            {defaultFiles && (
              <div>
                {defaultFiles.map((file, index) => (
                  <FileRow
                    key={file.id}
                    fileId={file.file.id}
                    name={file.file.originalName}
                    url={file?.url}
                    type={file?.file?.mimeType}
                    actions={{ remove: () => handleFileDelete ? handleFileDelete(file.id) : handleRemove(index) }}
                    hideControls={hideControls}
                    caseId={caseId}
                    caseServiceId={caseServiceId}
                    commentView={commentView}
                  />
                ))}
              </div>
            )}
            {uploadingFiles && (
              <div>
                {uploadingFiles.map((file, index) => {
                  const getPercentCompletedProp = (percentCompletedP: IPercentCompleted[]) => {
                    const filesWithSizeDiff = percentCompletedP?.map((item) => (
                      {
                        ...item,
                        fileSizeBytes: file.size ? item.fileSizeBytes - file.size : 0,
                      }
                    )) || [];

                    const smallestFile = filesWithSizeDiff.filter((item) => item.fileSizeBytes > 0)
                      .sort((a, b) => (
                        a.fileSizeBytes > b.fileSizeBytes ? 1 : -1))[0];

                    return smallestFile;
                  };

                  return (
                    <FileRow
                      key={file.uid}
                      name={file.name}
                      loading={file.loading}
                      actions={{ remove: () => handleRemove(index) }}
                      hideControls={hideControls}
                      percentCompletedProp={getPercentCompletedProp(percentCompleted || [])}
                    />
                  );
                })}
              </div>
            )}
            {uploadedFiles && !uploadingFiles && (
              <div>
                {uploadedFiles.map((file, index) => (
                  <FileRow
                    key={file.uid}
                    name={file.name}
                    actions={{ remove: () => handleRemove(index) }}
                    hideControls={hideControls}
                  />
                ))}
              </div>
            )}
          </div>
        )}

        {(uploadingFiles?.length || 0) > 1 && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Divider style={{ margin: '4px 0', fontSize: '14px' }}>Total progress:</Divider>
            <Progress percent={Math.round(uploadPercent)} showInfo={false} strokeColor="var(--color-main-orange)" />
          </div>
        )}

        {!hideControls && (
          <>
            <Space className={styles.row}>
              <Button ghost onClick={handleOpen} icon={<PlusOutlined />} disabled={disabled}>
                {addFileBtnLabel || 'Add File'}
              </Button>

              {extra && (isExtraShown
                ? isExtraShown(uploadedFiles) && extra
                : extra)}
            </Space>
            {showDownloadAll ? (
              <div>
                {!downloadAllPercent && downloadAllFiles.loading && (
                  <p>Preparing archive... It can take a few minutes</p>
                )}
                {downloadAllPercent && (
                  <Progress percent={downloadAllPercent} strokeColor="var(--color-main-orange)" />
                )}
                <Button
                  loading={downloadAllFiles.loading}
                  onClick={handleDownloadAllFiles}
                >
                  Download all files
                </Button>
              </div>
            ) : null}
          </>
        )}
      </Space>
    </>
  );
};

AttachmentLoader.defaultProps = {
  accept: undefined,
  defaultFiles: undefined,
  disabled: false,
  extra: undefined,
  isExtraShown: undefined,
  onUpdate: undefined,
  addFileBtnLabel: undefined,
  hideControls: false,
  handleFileDelete: undefined,
  uploadingFiles: undefined,
  tempFiles: undefined,
  uploadHintText: undefined,
  onFilesLoad: undefined,
  isFilesLoaded: false,
  caseId: undefined,
  caseServiceId: undefined,
  commentView: false,
  clearFilesStateTrigger: false,
  resetFilesTriggerState: () => undefined,
  percentCompleted: [],
  showDownloadAll: false,
};

export default AttachmentLoader;
