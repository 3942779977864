import { Form, Typography } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { useState } from 'react';
import { Callbacks } from 'rc-field-form/lib/interface';
import { JsonResult } from '../../../../../types';

import Address from '../../../../Common/Address';
import ContentCard from '../../../../Common/ContentCard';
import LabInfoForm from '../LabInfoForm';

interface LabForm {
  disabled?: {
    [key: string]: boolean;
  };
  initialData?: JsonResult;
  editMode?: boolean;
  onSubmit?: () => void;
  isAdmin?: boolean;
  loading?: boolean;
  form: FormInstance;
  onFieldsChange?: Callbacks<JsonResult>['onFieldsChange'];
  requiredFieldsType?: 'create' | 'update';
}

const LabForm: React.FC<LabForm> = (props) => {
  const {
    disabled, isAdmin, initialData, editMode, onSubmit, form, loading, onFieldsChange, requiredFieldsType,
  } = props;
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const handleFormSubmit = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialData}
        onFinish={onSubmit && onSubmit}
        autoComplete="off"
        onFieldsChange={(e, y) => {
          if (onFieldsChange) onFieldsChange(e, y);
          setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length));
        }}
      >
        <LabInfoForm
          loading={loading}
          isAdmin={isAdmin}
          editMode={editMode ? {
            onSubmit: handleFormSubmit,
            onCancel: handleCancel,
            isFormValid,
          } : undefined}
          disabled={disabled?.lab}
          requiredFieldsType={requiredFieldsType}
        />

        <div>
          <Typography.Title level={4}>Billing Address</Typography.Title>
          <ContentCard loading={loading}>
            <Address
              form={form}
              editMode={editMode ? {
                onSubmit: handleFormSubmit,
                onCancel: handleCancel,
                isFormValid,
              } : undefined}
              disabled={disabled?.billingAddress}
              requiredFieldsType={requiredFieldsType === 'update' ? 'completeRegistration' : 'create'}
            />
          </ContentCard>
        </div>
      </Form>
    </div>
  );
};

LabForm.defaultProps = {
  initialData: {},
  disabled: {
    practice: false,
    billingAddress: false,
  },
  loading: false,
  editMode: false,
  onSubmit: () => undefined,
  isAdmin: false,
  onFieldsChange: undefined,
  requiredFieldsType: 'create',
};

export default LabForm;
