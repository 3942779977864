import React, { useEffect, useState } from 'react';
import { App, Button, Col, Form, Row, Space } from 'antd';
import Icon from '@ant-design/icons';
import { connect } from 'react-redux';
import { UserRoles } from '../../../../enums/user';
import DrawerModal from '../../../Common/DrawerModal';
import { Send } from '../../../Common/Icon';
import { RootState } from '../../../../store/reducers';
import {
  moduleName as userModuleName,
  CreateUser,
  createUser as actionCreateUser,
} from '../../../../store/ducks/user';
import { Action } from '../../../../store';
import { Error } from '../../../../store/ducks/common';
import { DefaultModalProps, JsonResult } from '../../../../types';
import { prepareUserAdminBody } from '../../../../utils/prepareData';
import AdminInfoForm from '../Forms/AdminInfoForm';

interface UserCompleteRegisterProps {
  loading?: boolean;
  hasPassword?: boolean;
  initialData?: JsonResult;
  disabled?: {
    [key: string]: boolean;
  };
  handleSave?: (body: CreateUser) => void;
  title?: string;
  submitBtnText?: string;
  submitBtnIcon?: React.ReactNode;
}

interface AdminCreateDrawer extends UserCompleteRegisterProps, DefaultModalProps {
  // redux props \/
  createUser: (payload: CreateUser) => Action;
  userLoading: boolean;
  userError: Error | null;
}

const AdminCreateDrawer: React.FC<AdminCreateDrawer> = React.memo((props) => {
  const { message } = App.useApp();
  const {
    isOpen,
    close,
    onReload,
    createUser,
    userLoading,
    userError,
    initialData,
    handleSave,
    title,
    loading,
    hasPassword,
    submitBtnText,
    submitBtnIcon,
  } = props;
  const [form] = Form.useForm();

  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isFormTouched, setIsFormTouched] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const body = prepareUserAdminBody(values);

      if (handleSave) {
        handleSave(body);
      } else {
        createUser(body);
      }
    });
  };

  useEffect(() => {
    if (userError?.message) {
      message.error(userError.message, 5);
    }
  }, [userError]);

  useEffect(() => {
    if (!userLoading && !userError && isOpen && isFormTouched) {
      close();
      setIsFormTouched(false);
      onReload?.();
    }
  }, [userLoading]);

  useEffect(() => {
    if (!initialData) return;

    form?.setFieldsValue(initialData);
  }, [initialData]);

  return (
    <DrawerModal
      title={title || 'Create a new Admin'}
      open={isOpen}
      onClose={close}
      extra={(
        <Space>
          <Button
            id="sendRegistrationLink"
            type="primary"
            icon={submitBtnIcon || <Icon component={Send} />}
            disabled={!isFormValid}
            onClick={handleSubmit}
            loading={userLoading}
          >
            {submitBtnText || 'Send Registration Link'}
          </Button>
        </Space>
      )}
      destroyOnClose
      afterOpenChange={() => form.resetFields()}
    >
      <Row justify="center">
        <Col span={24} md={20} lg={16} xxl={12}>
          <div>
            <Form
              form={form}
              name="user_create"
              layout="vertical"
              autoComplete="off"
              initialValues={initialData ? { ...initialData, role: UserRoles.admin }
                : undefined}
              onFieldsChange={() => {
                setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length));
                setIsFormTouched(true);
              }}
            >
              <AdminInfoForm
                formType="create"
                cardLoading={loading}
                hasPassword={hasPassword}
              />
            </Form>
          </div>
        </Col>
      </Row>
    </DrawerModal>
  );
});

AdminCreateDrawer.displayName = 'AdminCreateDrawer';

AdminCreateDrawer.defaultProps = {
  title: undefined,
  loading: false,
  hasPassword: false,
  initialData: undefined,
  disabled: undefined,
  handleSave: undefined,
  submitBtnText: undefined,
  submitBtnIcon: undefined,
  onReload: undefined,
};

export default connect((state: RootState) => ({
  userLoading: state[userModuleName].userLoading,
  userError: state[userModuleName].userError,
}), {
  createUser: actionCreateUser,
})(AdminCreateDrawer);
