import React from 'react';
import { FormInstance } from 'antd';

import PatientInfoForm from '../PatientInfoForm';

interface PatientFormContent {
  form: FormInstance;
  cardLoading?: boolean;
  formType?: 'create' | 'update';
  editMode?: {
    onSubmit: () => void;
    onCancel: () => void;
    isFormValid?: boolean;
    loading?: boolean;
  };
  modalDoctorId?: string | number;
  disabledEdit?: boolean;
}

const PatientFormContent: React.FC<PatientFormContent> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { modalDoctorId } = props;

  return (
    <>
      <PatientInfoForm
        {...props}
      />

      {/** Uncomment if patient form should have Address fields
      <div>
        <Typography.Title level={4}>Billing Address</Typography.Title>
        <ContentCard loading={cardLoading}>
          <Address
            disabled={disabledEdit}
            form={form}
            requiredFieldsType={formType === 'create' ? 'create' : 'completeRegistration'}
            editMode={editMode}
          />
        </ContentCard>
      </div> */}
    </>
  );
};

PatientFormContent.defaultProps = {
  formType: 'create',
  cardLoading: false,
  editMode: undefined,
  modalDoctorId: undefined,
  disabledEdit: false,
};

export default PatientFormContent;
