import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckOutlined } from '@ant-design/icons';
import { App } from 'antd';
import { useConfirmAdmin } from '../../../../hooks/users';
import { Action } from '../../../../store';
import { Error } from '../../../../store/ducks/common';
import { JsonResult } from '../../../../types';
import {
  moduleName,
  CreateUser,
  updateUser as actionUpdateUser,
  UpdateUser,
} from '../../../../store/ducks/user';
import { RootState } from '../../../../store/reducers';
import { prepareUserInitialData } from '../../../../utils/prepareData';
import AdminCreateDrawer from '../Create';
import { getMessageInError } from '../../../../hooks/fetch';

interface CompleteAdminRegistration {
  userLoading: boolean;
  error: Error | null;
  updateUser: (payload: UpdateUser) => Action;
}

const CompleteAdminRegistration: React.FC<CompleteAdminRegistration> = (props) => {
  const { message } = App.useApp();
  const { userLoading, error, updateUser } = props;
  const navigate = useNavigate();

  const { token = '' } = useParams<{ token: string; }>();

  const confirmAdmin = useConfirmAdmin();

  const handleDrawerClose = () => {
    if (error) return;

    navigate({ pathname: '/sign-in' }, { replace: true });
  };

  const fetchConfirmAdmin = () => {
    confirmAdmin.fetch({ secretKey: token });
  };

  useEffect(() => {
    if (token) {
      fetchConfirmAdmin();
    }
  }, [token]);

  useEffect(() => {
    if (error?.message) {
      message.error(error.message, 5);
    }
  }, [error]);

  const handleSubmit = async (body: CreateUser) => {
    if (!initialData?.id || !token) {
      message.error('Something went wrong!');

      return;
    }

    await updateUser({ secretKey: token, id: initialData.id, ...body });
  };

  const [initialData, setInitialData] = useState<JsonResult | undefined>();

  useEffect(() => {
    if (confirmAdmin.data) {
      setInitialData(prepareUserInitialData(confirmAdmin.data));
    }
  }, [confirmAdmin.data]);

  useEffect(() => {
    if (confirmAdmin.error) {
      message.error(getMessageInError(confirmAdmin.error));
      confirmAdmin.clearError();
    }
  }, [confirmAdmin.error]);

  return (
    <AdminCreateDrawer
      initialData={initialData}
      handleSave={handleSubmit}
      title="Complete Registration Form"
      submitBtnText="Complete Registration"
      submitBtnIcon={<CheckOutlined />}
      isOpen
      close={() => handleDrawerClose()}
      loading={confirmAdmin?.loading || userLoading}
      hasPassword
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  userLoading: state[moduleName].userLoading,
  error: state[moduleName].userError,
});

const mapDispatchToProps = {
  updateUser: actionUpdateUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteAdminRegistration);
