import React, { useEffect, useState } from 'react';
import { App, Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { onValue, ref } from 'firebase/database';
import Message from './Message';
import { RootState } from '../../../../../../store/reducers';
import { moduleName, User } from '../../../../../../store/ducks/auth';
import NotFoundContent from '../../../../../Common/NotFoundContent';
import { db } from '../../../../../../firebase-config';
import { IChatRoom, IChatRoomWithId, useDeleteRoom } from '../../../../../../hooks/chats';
import { convertObjectRecordToArr, getUserFullName, queryFilterParams } from '../../../../../../utils';
import Loading from '../../../../../Common/Loading';
import { useContextChats } from '../../../../../../context/chats';
import Participants from './Participants';
import confirm from '../../../../../Common/ModalConfirm';
import { isRoleEnough } from '../../../../../../utils/auth';
import { getChatItemFromStorage, setChatItemInStorage } from '../../../storage-utils';

import styles from './index.module.scss';

interface IChatMessages {
  currentUser: User | null;
  messagesListRef: React.RefObject<HTMLDivElement>;
  isRememberMe: boolean;
}

const Messages: React.FC<IChatMessages> = ({ currentUser, messagesListRef, isRememberMe }) => {
  const { modal, message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const deleteRoom = useDeleteRoom();
  const { getRoomUsersInContext, roomUsers, handleRoomUsers } = useContextChats();
  const [roomData, setRoomData] = useState<IChatRoomWithId | null>(null);

  const [loading, setLoading] = useState(false);
  const [participantsModalOpen, setParticipantsModalOpen] = useState<boolean>(false);
  const closeParticipantsModal = () => { setParticipantsModalOpen(false); };

  const roomId = searchParams.get('id') || '';

  const [prevLastMssgId, setPrevLastMssgId] = useState('');

  useEffect(() => {
    /** when user change room save last read message from storage to show on this item mark "New Messages" */
    setPrevLastMssgId(getChatItemFromStorage(roomId, isRememberMe)?.lastReadMessageId || '');
  }, [roomId]);

  const handleDeleteRoom = () => {
    confirm({
      modal,
      title: 'Close conversation',
      content: 'Are you sure you want to close conversation and delete room?',
      onOk: () => deleteRoom.fetch(roomId),
    });
  };

  useEffect(() => {
    if (!roomId) {
      /** If !roomId then clear roomData and inform user that chat was closed */
      if (roomData) {
        message.info('Conversation was closed!', 5);
        setRoomData(null);
      }
      setLoading(false);

      return undefined;
    }

    const query = ref(db, `rooms/${roomId}`);

    setLoading(true);

    return onValue(query, (snapshot) => {
      const data: IChatRoom = snapshot.val();

      /** If data exist convert it from Record<roomId(string), object{}> to array */
      if (snapshot.exists()) {
        const messagesArray = convertObjectRecordToArr(data.messages || {});

        const roomData: IChatRoomWithId = {
          ...data,
          id: roomId,
          messages: messagesArray,
          participants: convertObjectRecordToArr(data.participants || {}),
        };

        setChatItemInStorage({ lastReadMessageId: messagesArray[messagesArray.length - 1]?.id }, roomId, isRememberMe);

        setRoomData(roomData);
        setLoading(false);
      } else {
        /** If snapshot not exist then clear chatId. Then will be triggered clear room-data function & inform message */
        setSearchParams(queryFilterParams({ id: '' }));
      }
    });
  }, [roomId]);

  useEffect(() => {
    const container = messagesListRef.current;

    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth',
      });

      /* if (firstRender && roomData?.messages?.length) {
        setFirstRender(false);

        container.scrollTop = container.scrollHeight;
      } else {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth',
        });
      } */
    }
  }, [roomData?.messages.length]);

  /** Get room users data */
  const [userIdsToFetch, setUserIdsToFetch] = useState<string[]>([]);

  useEffect(() => {
    const allUserIds: string[] = [];

    if (roomData?.initiatorId) allUserIds.push(roomData.initiatorId);
    if (roomData?.speakerId) allUserIds.push(roomData.speakerId);
    roomData?.messages?.forEach((message) => {
      if (message.speakerId && !allUserIds.includes(message.speakerId)) {
        allUserIds.push(message.speakerId);
      }
    });
    roomData?.participants?.forEach((participant) => {
      if (participant.speakerId && !allUserIds.includes(participant.speakerId)) {
        allUserIds.push(participant.speakerId);
      }
    });

    const uniqueUserIds: string[] = Array.from(new Set(allUserIds));

    setUserIdsToFetch(uniqueUserIds);
  }, [roomData]);

  useEffect(() => {
    if (roomData) {
      const participantsIds = roomData?.participants?.map((user) => user.speakerId);

      /** If user not admin and not in participants - redirect */
      if (![roomData?.initiatorId, roomData?.speakerId, ...participantsIds].includes(currentUser?.id || '')
      && !isRoleEnough('admin')) {
        setSearchParams(queryFilterParams({ id: '' }));
      }
    }
  }, [roomData]);

  useEffect(() => {
    if (getRoomUsersInContext && userIdsToFetch.length && roomData) {
      /** If room already loaded data for users and length of users[] which need to fetch not changed, then return */
      if (roomUsers?.[roomId]?.length && (roomUsers?.[roomId]?.length === userIdsToFetch.length)) { return; }

      getRoomUsersInContext.fetch({ users: userIdsToFetch }).then((res) => {
        if (res) {
          handleRoomUsers({
            [roomId]: res,
          });
        }
      });
    }
  }, [userIdsToFetch]);

  /** Get room users data */
  const initiatorUserObj = roomUsers?.[roomId]?.find((user) => user.id === roomData?.initiatorId);
  const speakerUserObj = roomUsers?.[roomId]?.find((user) => user.id === roomData?.speakerId);
  const displayChatName = currentUser?.role?.includes('lab') ? `${getUserFullName(initiatorUserObj)}`
    : `${getUserFullName(speakerUserObj)}, ${speakerUserObj?.labStaff?.lab?.name || '-'}`;

  return (
    <div className={styles.chat}>
      <div className={styles.head}>
        <h2>{displayChatName === '-, -' ? '' : displayChatName}</h2>
        <div className={styles.chatActions}>
          {currentUser?.id === roomData?.speakerId && (
            <Button
              size="middle"
              style={{ height: '28px', width: '28px' }}
              type="link"
              icon={<UserAddOutlined style={{ fontSize: '18px' }} />}
              onClick={() => setParticipantsModalOpen(true)}
              title="Add user to conversation"
              disabled={!roomId}
            />
          )}
          {(currentUser?.id === roomData?.initiatorId || currentUser?.id === roomData?.speakerId) && (
            <Button
              type="link"
              onClick={handleDeleteRoom}
              danger
              disabled={!roomId}
            >
              Close conversation
            </Button>
          )}
        </div>
      </div>

      <Participants
        roomId={roomId}
        roomData={roomData}
        isOpen={participantsModalOpen}
        close={closeParticipantsModal}
      />

      <div className={styles.messages} ref={messagesListRef}>
        <Loading visible={loading || !!getRoomUsersInContext?.loading} absolute delay={100} />
        {roomData?.messages?.length ? roomData?.messages?.map((message) => (
          <Message
            key={message.id}
            {...message}
            isMessageOwner={currentUser?.id === message.speakerId}
            // If prevLastMessage is current message, and at the moment it's not last:
            isLastRead={prevLastMssgId === message.id && prevLastMssgId
              !== roomData.messages[(roomData.messages || []).length - 1]?.id}
          />
        )) : (
          <NotFoundContent
            message={roomId ? "There's no messages yet!" : 'Select chat!'}
            style={{ minHeight: '110px' }}
          />
        )}
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  currentUser: state[moduleName].user,
  isRememberMe: state[moduleName].remember,
}))(Messages);
