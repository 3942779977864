import { SelectProps } from 'antd/lib/select';
import { App, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { PatientsGetParams, usePatientId, usePatientsGet } from '../../../../hooks/patients';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import NotFoundContent from '../../NotFoundContent';

interface SelectPatient extends SelectProps<string | string[], Option> {
  onlyRemoved?: boolean;
  onlyActive?: boolean;
  triggerFetchDependency?: string;
  doctor?: string;
  requestOnlyWithDoctor?: boolean;
}

const SelectPatient: React.FC<SelectPatient> = ({
  onlyRemoved, onlyActive, triggerFetchDependency, doctor,
  requestOnlyWithDoctor, onClear, ...props }): JSX.Element => {
  const { message } = App.useApp();
  const patientsGet = usePatientsGet();
  const patientById = usePatientId();

  const [options, setOptions] = useState<Option[]>([]);
  const [patientName, setPatientName] = useState<string>('');

  const fetch = () => {
    const params: PatientsGetParams = { page: 1, take: 100, orderBy: 'ASC', orderByColumn: 'fullName' };

    if (onlyActive) {
      params.removed = false;
    }
    if (onlyRemoved) {
      params.removed = true;
    }

    if (patientName) {
      params.fullName = patientName;
    }

    if (doctor) {
      params.doctor = doctor;
    }

    patientsGet.fetch(params);
  };

  useEffect(() => {
    if (requestOnlyWithDoctor && !doctor) return undefined;

    if (patientName && patientName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }
    if (patientName === '') {
      fetch();
    }

    return undefined;
  }, [patientName, doctor]);

  useEffect(() => {
    if (patientsGet.data?.data.length) {
      setOptions(patientsGet.data.data.map(({ firstName, lastName, id }): Option => (
        { label: `${firstName} ${lastName}`, value: id }
      )));
    }
  }, [patientsGet.data]);

  useEffect(() => {
    if (options.length && props.value) {
      const current = options.find(({ value }) => value === props.value);

      if (!current) {
        patientById.fetch(undefined, `${props.value}`)
          .then((res) => setOptions([
            ...options,
            { label: `${res?.firstName ? `${res?.firstName} ` : ''}${res?.lastName || ''}` || '',
              value: props.value as string },
          ]));
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (triggerFetchDependency) {
      fetch();
    }
  }, [triggerFetchDependency]);

  useEffect(() => {
    if (patientsGet.error) {
      message.error(getMessageInError(patientsGet.error));
      patientsGet.clearError();
    }
  }, [patientsGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  const handleClear = () => {
    setPatientName('');

    if (!onClear) return;

    onClear();
  };

  return (
    <Select
      loading={patientsGet.loading || patientById.loading}
      options={options}
      onSearch={(name) => setPatientName(name)}
      showSearch
      allowClear
      onClear={handleClear}
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Patient not found." />}
      {...props}
      value={patientsGet.loading || patientById.loading ? 'Loading...' : props.value}
    />
  );
};

SelectPatient.defaultProps = {
  onlyRemoved: undefined,
  onlyActive: undefined,
  triggerFetchDependency: undefined,
  doctor: undefined,
  requestOnlyWithDoctor: false,
};

export default SelectPatient;
