export interface IRoomsDataStorage {
  [key: string]: ISetStorageData;
}

export interface ISetStorageData {
  lastReadMessageId: string;
}

export const setChatItemInStorage = (data: ISetStorageData, roomId: string, userRememberMe?: boolean) => {
  const storage = userRememberMe ? localStorage : sessionStorage;

  const prevData: IRoomsDataStorage = JSON.parse(storage.getItem('roomsData') || '{}');

  storage.setItem('roomsData', JSON.stringify({
    ...prevData,
    [roomId]: data,
  }));
};

export const getChatItemFromStorage = (roomId: string, userRememberMe?: boolean) => {
  const storage = userRememberMe ? localStorage : sessionStorage;

  const item = storage.getItem('roomsData');

  const roomData: ISetStorageData | undefined = JSON.parse(item || '{}')?.[roomId];

  return roomData;
};

export const getAllChatFromStorage = (userRememberMe?: boolean): IRoomsDataStorage => {
  const storage = userRememberMe ? localStorage : sessionStorage;

  return JSON.parse(storage.getItem('roomsData') || '{}');
};
