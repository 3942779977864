import React, { useEffect, useState } from 'react';
import { Button, Form, App } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd/es/grid';
import { useForm } from 'antd/es/form/Form';
import {
  useLabNotificationsReceiversGet,
  useLabReceiverCreate, useLabReceiverDelete,
  LabNotificationsReceiver,
} from '../../../../../../hooks/case/cases';
import { getMessageInError } from '../../../../../../hooks/fetch';
import { required } from '../../../../../../utils/inputRules';
import { CaseStatus } from '../../../../../../enums/case';
import SelectLabRecipient from '../../../../../Common/Selects/SelectLabRecipient';
import InfiniteScrollList from '../../InfiniteScrollList';

import styles from '../../index.module.scss';

interface ITabContent {
  caseServiceId: string | number;
  caseStatus?: CaseStatus;
  view: 'doctor' | 'labStaff';
  disabled?: boolean;
}

const TabContent: React.FC<ITabContent> = ({
  caseServiceId, caseStatus, view, disabled,
}) => {
  const [form] = useForm();
  const { message } = App.useApp();
  const notificationsReceiversGet = useLabNotificationsReceiversGet();

  const receiverCreate = useLabReceiverCreate(caseServiceId || '');
  const receiverDelete = useLabReceiverDelete();

  const [dataState, setDataState] = useState<LabNotificationsReceiver[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = useState<number>(1);

  const loadMoreData = (resetPage?: number) => {
    if (notificationsReceiversGet.loading) return;

    notificationsReceiversGet
      /** fetch params with pagination: { page: resetPage || currentPage, take: 20 } */
      .fetch({ page: currentPage || 1 }, `${caseServiceId}/recipients`).then((response) => {
        if (response) {
        /** If we reset page, then we replace whole data with data from current request */
          if (resetPage) {
            setDataState(response);
          } else {
            setDataState((prevState) => [...prevState, ...response]);
          }

          setCurrentPage((prevPage) => (prevPage + 1));
        }
      });
  };

  useEffect(() => {
    if (caseServiceId && !notificationsReceiversGet.loading && !notificationsReceiversGet.data) {
      loadMoreData();
    }
  }, [caseServiceId]);

  const handleResetForm = (clearFormField = true) => {
    if (clearFormField) {
      form.resetFields();
    }
    setCurrentPage(1);
    loadMoreData(1);
  };

  const handleSubmit = ({ labStaff }: { labStaff: string; }) => {
    if (labStaff) {
      receiverCreate.fetch({ labStaff })?.then(() => handleResetForm());
    }
  };

  useEffect(() => {
    if (notificationsReceiversGet.error) {
      message.error(getMessageInError(notificationsReceiversGet.error));
      notificationsReceiversGet.clearError();
    }
  }, [notificationsReceiversGet.error]);

  useEffect(() => {
    if (receiverCreate.error) {
      message.error(getMessageInError(receiverCreate.error));
      receiverCreate.clearError();
    }
  }, [receiverCreate.error]);

  useEffect(() => {
    if (receiverDelete.error) {
      message.error(getMessageInError(receiverDelete.error));
      receiverDelete.clearError();
    }
  }, [receiverDelete.error]);

  return (
    <>
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ labStaff: null }}
          autoComplete="off"
          disabled={disabled || receiverCreate.loading || notificationsReceiversGet.loading
            || caseStatus === 'cancel' || caseStatus === 'completed'}
        >
          <Row>
            <Col span={24}>
              <div className={styles.flexRow} style={{ gap: '8px' }}>
                <Form.Item
                  label="Lab staff"
                  name="labStaff"
                  rules={required}
                  style={{ width: '100%' }}
                >
                  <SelectLabRecipient caseServiceId={caseServiceId} triggerReload={receiverDelete.data} />
                </Form.Item>
                <Form.Item label=" ">
                  <Button
                    size="middle"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => form.validateFields().then((values) => handleSubmit(values))}
                    loading={receiverCreate.loading || notificationsReceiversGet.loading}
                    disabled={disabled}
                  >
                    Add
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>

      <InfiniteScrollList<LabNotificationsReceiver>
        view={view}
        dataState={dataState}
        loadMoreData={loadMoreData}
        loading={notificationsReceiversGet.loading}
        receiverDeleteHook={receiverDelete}
        handleResetForm={handleResetForm}
        disabled={disabled}
      />
    </>
  );
};

TabContent.defaultProps = {
  caseStatus: undefined,
  disabled: false,
};

export default TabContent;
