import React, { useEffect, useState } from 'react';
import message from 'antd/lib/message';
import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd/es/grid';
import { useForm } from 'antd/es/form/Form';
import {
  PracticeNotificationsReceiver,
  useNotificationsReceiversGet,
  useReceiverCreate, useReceiverDelete,
} from '../../../../../hooks/case/cases';
import { getMessageInError } from '../../../../../hooks/fetch';
import { required } from '../../../../../utils/inputRules';
import { CaseStatus } from '../../../../../enums/case';
import SelectPracticeRecipient from '../../../../Common/Selects/SelectPracticeRecipient';
import InfiniteScrollList from '../InfiniteScrollList';

import styles from '../index.module.scss';

interface INotificationsReceivers {
  caseId: string | number;
  caseStatus?: CaseStatus;
  view: 'doctor' | 'labStaff';
}

const PracticeNotificationsReceivers: React.FC<INotificationsReceivers> = ({ caseId, caseStatus, view }) => {
  const [form] = useForm();
  const notificationsReceiversGet = useNotificationsReceiversGet();

  const receiverCreate = useReceiverCreate(caseId || '');
  const receiverDelete = useReceiverDelete();

  const [dataState, setDataState] = useState<PracticeNotificationsReceiver[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = useState<number>(1);

  const loadMoreData = (resetPage?: number) => {
    if (notificationsReceiversGet.loading) return;

    notificationsReceiversGet
      /** fetch params with pagination: { page: resetPage || currentPage, take: 20 } */
      .fetch(undefined, `${caseId}/recipients`).then((response) => {
        if (response) {
          /** If we reset page, then we replace whole data with data from current request */
          if (resetPage) {
            setDataState(response);
          } else {
            setDataState((prevState) => [...prevState, ...response]);
          }

          setCurrentPage((prevPage) => (prevPage + 1));
        }
      });
  };

  useEffect(() => {
    if (caseId && !notificationsReceiversGet.loading && !notificationsReceiversGet.data) {
      loadMoreData();
    }
  }, [caseId]);

  const handleResetForm = (clearDoctorField = true) => {
    if (clearDoctorField) {
      form.resetFields();
    }
    setCurrentPage(1);
    loadMoreData(1);
  };

  const handleSubmit = ({ doctor }: { doctor: string; }) => {
    if (doctor) {
      receiverCreate.fetch({ doctor })?.then(() => handleResetForm());
    }
  };

  useEffect(() => {
    if (notificationsReceiversGet.error) {
      message.error(getMessageInError(notificationsReceiversGet.error));
      notificationsReceiversGet.clearError();
    }
  }, [notificationsReceiversGet.error]);

  useEffect(() => {
    if (receiverCreate.error) {
      message.error(getMessageInError(receiverCreate.error));
      receiverCreate.clearError();
    }
  }, [receiverCreate.error]);

  useEffect(() => {
    if (receiverDelete.error) {
      message.error(getMessageInError(receiverDelete.error));
      receiverDelete.clearError();
    }
  }, [receiverDelete.error]);

  return (
    <div style={{ padding: '0 16px', position: 'relative' }}>

      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ doctor: null }}
          autoComplete="off"
          disabled={receiverCreate.loading || notificationsReceiversGet.loading
            || caseStatus === 'cancel' || caseStatus === 'completed'}
        >
          <Row>
            <Col span={24}>
              <div className={styles.flexRow} style={{ gap: '8px' }}>
                <Form.Item
                  label="Doctor"
                  name="doctor"
                  rules={required}
                  style={{ width: '100%' }}
                >
                  <SelectPracticeRecipient caseId={caseId} triggerReload={receiverDelete.data} />
                </Form.Item>
                <Form.Item label=" ">
                  <Button
                    size="middle"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => form.validateFields().then((values) => handleSubmit(values))}
                    loading={receiverCreate.loading || notificationsReceiversGet.loading}
                  >
                    Add
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>

      <InfiniteScrollList<PracticeNotificationsReceiver>
        view={view}
        dataState={dataState}
        loadMoreData={loadMoreData}
        loading={notificationsReceiversGet.loading}
        receiverDeleteHook={receiverDelete}
        handleResetForm={handleResetForm}
      />
    </div>
  );
};

PracticeNotificationsReceivers.defaultProps = {
  caseStatus: undefined,
};

export default PracticeNotificationsReceivers;
