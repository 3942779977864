import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { CalendarOutlined, UnorderedListOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface RadioButtonProps {
  active: string;
}

function RadioButton({
  active,
}: RadioButtonProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <div className={styles.radio}>
      <Button
        icon={<UnorderedListOutlined />}
        className={active === 'cases' ? styles.active : ''}
        type="text"
        onClick={() => navigate('/cases')}
      >
        List view
      </Button>

      <Button
        icon={<CalendarOutlined />}
        className={active === 'calendar' ? styles.active : ''}
        type="text"
        onClick={() => navigate('/cases/calendar')}
      >
        Calendar view
      </Button>
    </div>
  );
}

export default RadioButton;
