import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { Callbacks } from 'rc-field-form/lib/interface';
import { connect } from 'react-redux';
import { JsonResult } from '../../../../../types';
import { RootState } from '../../../../../store/reducers';
import { CreatePatientResponse, moduleName } from '../../../../../store/ducks/patient';
import PatientFormItems from './FormItems';
import { useContextCaseFlow } from '../../../../../context/caseFlow';

interface PatientSelectForm {
  disabled?: {
    [key: string]: boolean;
  };
  initialData?: JsonResult;
  editMode?: boolean;
  onSubmit?: () => void;
  isManager?: boolean;
  loading?: boolean;
  form: FormInstance;
  onFieldsChange?: Callbacks<JsonResult>['onFieldsChange'];
  requiredFieldsType?: 'create' | 'update';
  patientResponse: CreatePatientResponse | null;
}

const PatientSelectForm: React.FC<PatientSelectForm> = (props) => {
  const {
    disabled, isManager, initialData, editMode, onSubmit, form, loading, onFieldsChange, requiredFieldsType,
    patientResponse,
  } = props;
  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const { patientInfo } = useContextCaseFlow();

  useEffect(() => {
    /** Used on case update. When we set data in context for this field, we updates form values */
    form.setFieldsValue(patientInfo);
  }, [patientInfo]);

  const handleFormSubmit = () => {
    form.submit();
  };

  const handleCancel = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (patientResponse?.id) {
      /** when patient is created set him in form automatically */
      form.setFieldValue('patient', patientResponse?.id);
    }
  }, [patientResponse]);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialData}
        onFinish={onSubmit && onSubmit}
        autoComplete="off"
        onFieldsChange={(e, y) => {
          if (onFieldsChange) onFieldsChange(e, y);
          setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length));
        }}
      >
        <PatientFormItems
          loading={loading}
          isManager={isManager}
          editMode={editMode ? {
            onSubmit: handleFormSubmit,
            onCancel: handleCancel,
            isFormValid,
          } : undefined}
          disabled={disabled?.patient}
          requiredFieldsType={requiredFieldsType}
          patientCreateResponseId={patientResponse?.id}
        />
      </Form>
    </div>
  );
};

PatientSelectForm.defaultProps = {
  initialData: {},
  disabled: undefined,
  loading: false,
  editMode: false,
  onSubmit: () => undefined,
  isManager: false,
  onFieldsChange: undefined,
  requiredFieldsType: 'create',
};

export default connect((state: RootState) => ({
  patientResponse: state[moduleName].patientResponse,
}))(PatientSelectForm);
