import React, { Key, useCallback, useEffect, useRef, useState } from 'react';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { FilterOutlined, PlusOutlined, RobotFilled } from '@ant-design/icons/lib';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { App, Button, FormInstance } from 'antd';
import { connect } from 'react-redux';
import { LabStatus, LabStatuses, LabStatusesOptions } from '../../../../enums/lab';
import { TableLabRow, useTableLabRow } from '../../../../hooks/labs';
import Status from '../../../Common/Status';
import { LabServiceTable, useServicesGet } from '../../../../hooks/services';

import SelectServiceMulti from '../../../Common/Selects/SelectServiceMulti';
import Table from '../../../Common/Table';
import { getMessageInError } from '../../../../hooks/fetch';
import { ModalState, Option } from '../../../../types';
import { formatAddressToString, formatPhoneNumber, getSorterParams, queryFilterParams } from '../../../../utils';
import LabCreateButton from '../CreateButton';
import { Error } from '../../../../store/ducks/common';
import { RootState } from '../../../../store/reducers';
import { moduleName } from '../../../../store/ducks/lab';

interface LabsTable {
  params?: Record<string, string>;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  // redux props \/
  loading: boolean;
  error: Error | null;
}

function LabsTable({ params, selectedRows, onRowSelection, loading, error }: LabsTable): React.JSX.Element {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const formRef = useRef<FormInstance>();
  const labsGet = useTableLabRow();
  const servicesGet = useServicesGet();
  const actionRef = useRef<ActionType>();
  const [, setSearchParams] = useSearchParams();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    if (labsGet.error) {
      message.error(getMessageInError(labsGet.error));
      labsGet.clearError();
    }
  }, [labsGet.error]);

  useEffect(() => {
    /** reload table data, after create/update request finished without errors */
    if (!loading && !error) {
      actionRef.current?.reload();
    }
  }, [loading]);

  // GET options for multiselect
  const [servicesFilterOptions, setServicesFilterOptions] = useState<Option[]>([]);

  useEffect(() => {
    servicesGet.fetch({ page: 1, take: 100 }).then((data) => {
      if (data) {
        setServicesFilterOptions(data.data?.map(({ name, id }): Option => (
          { label: name, value: id }
        )));
      }
    });
  }, []);

  const toolBarRender = () => [
    <Button key="filterBtn" id="filterBtn" type="default" onClick={() => setIsFilterOpen(!isFilterOpen)}>
      <FilterOutlined />
      {' '}
      Filter
    </Button>,
    <LabCreateButton
      key="addButton"
      id="addButton"
      title="Add New Lab"
      icon={<PlusOutlined />}
      type="primary"
      isAdmin
    >
      Add New Lab
    </LabCreateButton>,
  ];

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableLabRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return labsGet.fetch({
      ...newParams,
      ...params,
      services: newParams.services?.split(','),
    }).then((data) => {
      if (data) {
        const { labs, total } = data;

        return ({ data: labs || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  };

  const columns: ProColumns<TableLabRow>[] = [
    {
      title: 'Lab Name',
      dataIndex: 'name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Account #',
      dataIndex: 'accountNumber',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      renderText: (status: LabStatus) => (
        <Status status={LabStatuses[status]} />
      ),
      valueEnum: LabStatusesOptions,
    },
    {
      title: 'Services',
      dataIndex: 'services',
      sorter: false,
      ellipsis: true,
      renderText: (_, row) => (row.labServices as LabServiceTable[])
        ?.filter((item) => item?.status === 'active')
        ?.filter((item) => item?.service?.status === 'active')
        ?.map((item: LabServiceTable) => item?.service?.name)
        .join(', '),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderFormItem: (_, { defaultRender, ...config }) => (
        <SelectServiceMulti
          {...config}
          selectOptions={servicesFilterOptions}
          placeholder="Please select"
          value={Array.isArray(config.value) ? config.value : config?.value?.split(',')}
          mode="multiple"
        />
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      ellipsis: true,
      formItemProps: {
        rules: [{ min: 2 }],
      },
    },
    {
      title: 'Contact Phone',
      dataIndex: 'phone',
      sorter: true,
      ellipsis: true,
      renderText: (phone) => formatPhoneNumber(phone, 'international'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      sorter: false,
      ellipsis: true,
      renderText: (_, row) => (row?.addresses?.map((address) => formatAddressToString(address))
        .join(';')),
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <Table<TableLabRow>
      formRef={formRef}
      className={isFilterOpen ? 'activeFilter' : ''}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      form={{ ignoreRules: false }}
      headerTitle={(
        <>
          <RobotFilled />
          Labs list
        </>
      )}
      rowSelection={!!onRowSelection && rowSelection}
      rowClassName="cursor-pointer"
      columnsState={{ persistenceKey: 'pro-table-labs', persistenceType: 'localStorage' }}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
      onFilterClose={() => setIsFilterOpen(false)}
      onRow={(record) => ({
        onClick: () => {
          navigate({ pathname: `/labs/${record.id}` }, { replace: true });
        },
      })}
    />
  );
}

LabsTable.defaultProps = {
  params: {},
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
};

export default connect((state: RootState) => ({
  loading: state[moduleName].loading,
  error: state[moduleName].error,
}))(LabsTable);
