import { FetchSuccess, PagingDataResponse, StoredFile } from '../types';
import { Address } from './addresses';
import { Doctor } from './doctors';
import {
  DefaultFetchError, FetchCreate,
  FetchGet,
  FetchGetId, FetchUpdate, useFetchCreate,
  useFetchGet,
  useFetchGetId, useFetchUpdate,
} from './fetch';
import { PracticeStatus } from '../enums/practice';
import { PriceLevel, PriceLevelService } from './priceLevels';

export interface Practice {
  id: string;
  isPasswordSet?: boolean;
  accountNumber: string;
  name: string;
  status: PracticeStatus;
  email: string;
  phone: string;
  note?: string;
  addresses: Address[];
  doctors: Doctor[];
  priceLevel: PriceLevel;
  priceLevelServices?: PriceLevelService[];

  cssColor?: string | null;
  file?: StoredFile;
}

export interface PracticeConfirm {
  secretKey: string;
}

export interface PracticesGetParams {
  page?: number;
  take?: number;
  accountNumber?: string;
  name?: string;
  status?: string;
  email?: string;
  phone?: string;
  priceLevel?: string | number;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'accountNumber' | 'name' | 'status' | 'email' | 'phone' | 'address'; // | 'created' | 'updated'
}

export interface TablePracticeRow extends Practice {
  key: string;
}

interface PracticesTableData {
  practices: TablePracticeRow[];
  total: number;
}

// eslint-disable-next-line max-len
export const useTablePracticeRow = (): FetchGet<PagingDataResponse<Practice>, PracticesGetParams, DefaultFetchError, PracticesTableData> => (
  usePracticesGet((data: PagingDataResponse<Practice>): PracticesTableData => ({
    practices: data.data.map((practice: Practice): TablePracticeRow => ({
      key: practice.id,
      id: practice.id,
      accountNumber: practice.accountNumber,
      name: practice.name,
      status: practice.status,
      email: practice.email,
      phone: practice.phone,
      note: practice.note,
      priceLevel: practice.priceLevel,
      addresses: practice.addresses || [],
      doctors: practice.doctors || [],
    })),
    total: data.meta.itemCount,
  }))
);

export function usePracticesGet<D = PagingDataResponse<Practice>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, PracticesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, PracticesGetParams, DD>(
    'practices',
    { decorateData, autoStart: false },
  );
}

export const useConfirmPractice = (): FetchCreate<Practice, DefaultFetchError, PracticeConfirm> => useFetchCreate(
  'practices/confirm-practice',
  {
    authorization: false,
  },
);

export const usePracticeId = (): FetchGetId<Practice> => useFetchGetId('practices', '', { autoStart: false });

export const usePracticePriceLevelServiceId = (): FetchGetId<Practice> => (
  useFetchGetId('practices', '', { autoStart: false }));
// /practices/{id}/price-level-service

export const useUpdatePracticeStatus = (): FetchUpdate<
  FetchSuccess, DefaultFetchError, { status: PracticeStatus; }> => useFetchUpdate('practices');

export const usePracticeResendLink = (): FetchGetId<Practice> => useFetchGetId('practices', '', {
  autoStart: false, startStateLoading: false });
