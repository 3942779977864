import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { DownOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { useParams, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { App, Button, Dropdown, FormInstance, Space } from 'antd';
import React, { Key, useCallback, useEffect, useRef, useState } from 'react';

import Status from '../../../../../Common/Status';
import Table from '../../../../../Common/Table';
import { useAuth } from '../../../../../../context/auth';
import { getMessageInError } from '../../../../../../hooks/fetch';
import { ModalState, ModalTypes } from '../../../../../../types';
import { UserRoles, UserStatuses } from '../../../../../../enums/user';
import { TableDoctorRow, useTableDoctorRow } from '../../../../../../hooks/doctors';
import { capitalize, formatPhoneNumber, getSorterParams, queryFilterParams } from '../../../../../../utils';
import { PracticeRolesOptions } from '../../../../../../enums/practice';

interface TableDoctors {
  params?: Record<string, string>;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  practiceId?: string;
}

function TableDoctors(
  { params, openModal, selectedRows, onRowSelection, practiceId }: TableDoctors,
): React.JSX.Element {
  const { isRoleEnough } = useAuth();
  const { message } = App.useApp();
  const formRef = useRef<FormInstance>();
  const doctorsGet = useTableDoctorRow();
  const actionRef = useRef<ActionType>();
  const [, setSearchParams] = useSearchParams();
  const { id: paramsPracticeId = '' } = useParams<{ id: string; }>();

  const requestPracticeId = paramsPracticeId || practiceId;

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    if (doctorsGet.error) {
      message.error(getMessageInError(doctorsGet.error), 5);
      doctorsGet.clearError();
    }
  }, [doctorsGet.error]);

  const onReload = () => actionRef.current?.reload();

  const menuItems = [
    {
      label: 'Practice Manager',
      key: '1',
      onClick: () => openModal?.({ type: ModalTypes.create, role: UserRoles.practiceManager, onReload }),
      className: 'createPracticeManagerBtn',
    },
    {
      label: 'Doctor',
      key: '2',
      onClick: () => openModal?.({ type: ModalTypes.create, role: UserRoles.doctor, onReload }),
      className: 'createDoctorBtn',
    },
  ];

  const toolBarRender = () => [
    <Button key="filterBtn" id="filterBtn" type="default" onClick={() => setIsFilterOpen(!isFilterOpen)}>
      <FilterOutlined />
      {' '}
      Filter
    </Button>,
    openModal ? (
      <Dropdown
        trigger={['click']}
        key="button"
        menu={{ items: menuItems }}
      >
        <Button type="primary" onClick={(e) => e.preventDefault()} id="addDropdownButton">
          <Space>
            <PlusOutlined />
            Add New
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    ) : null,
  ];

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableDoctorRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return doctorsGet.fetch({
      ...newParams,
      ...params,
      practice: requestPracticeId,
    }).then((data) => {
      if (data) {
        const { doctors, total } = data;

        return ({ data: doctors || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  };

  const columns: ProColumns<TableDoctorRow>[] = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => (row.user ? (`${row.user.firstName || ''} ${row.user?.lastName || ''}`) : ''),
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname', // nickname
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      renderText: (_, row) => (
        <Status status={row.user?.status} />
      ),
      valueEnum: UserStatuses,
      ellipsis: false,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => row.user?.email,
      formItemProps: {
        rules: [{ min: 2 }],
      },
    },
    {
      title: 'Contact Phone',
      dataIndex: 'phone',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => (formatPhoneNumber(row.user?.phone || '')),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: true,
      valueEnum: PracticeRolesOptions,
      width: 105,
      ellipsis: true,
      renderText: (_, row) => capitalize(row.user?.role),
    },
    {
      title: 'Speciality',
      dataIndex: 'specialty',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: true,
      ellipsis: true,
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <Table<TableDoctorRow>
      formRef={formRef}
      className={isFilterOpen ? 'activeFilter' : ''}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      form={{ ignoreRules: false }}
      headerTitle="Doctors list"
      rowSelection={!!onRowSelection && !!isRoleEnough('admin') && rowSelection}
      rowClassName="cursor-pointer"
      columnsState={{ persistenceKey: 'pro-table-doctors', persistenceType: 'localStorage' }}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
      onFilterClose={() => setIsFilterOpen(false)}
      onRow={(record) => ({
        onClick: () => {
          openModal?.({ type: ModalTypes.info,
            id: record.id,
            role: UserRoles.doctor,
            onReload,
          });
        },
      })}
    />
  );
}

TableDoctors.defaultProps = {
  params: {},
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
  practiceId: undefined,
};

export default TableDoctors;
