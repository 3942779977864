import { SelectProps } from 'antd/lib/select';
import { Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../../types';
import NotFoundContent from '../../NotFoundContent';

type SelectImplantManufacturer = SelectProps<string | string[], Option>

const SelectImplantManufacturer: React.FC<SelectImplantManufacturer> = ({ ...props }): JSX.Element => {
  const [options, setOptions] = useState<Option[]>([]);
  const [implantManufacturerName, setImplantManufacturerName] = useState<string>('');

  const arrOptions = ['AoN Implants', 'Argon K3Pro', 'Bicon', 'BioHorizons', 'Biomet', 'CeraRoot', 'Cortex',
    'Dentsply Sirona', 'IBS', 'JDentalCare', 'Keystone', 'Megagen', 'MIS', 'Neodent', 'Neoss', 'Nobel Biocare',
    'Paltop', 'Ritter Implants', 'Southern', 'Straumann', 'Swiss Dental Solutions', 'Zeramex', 'Zimmer'];

  useEffect(() => {
    const newOptions = arrOptions.map((item) => ({
      label: item, value: item, id: item,
    }));

    setOptions(newOptions);
  }, []);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      options={implantManufacturerName && !options.find((item) => item.value === implantManufacturerName)
        ? [{ label: implantManufacturerName, value: implantManufacturerName }, ...options]
        : options}
      onSearch={(name) => {
        setImplantManufacturerName(name);
      }}
      onClear={() => setImplantManufacturerName('')}
      // onSelect={() => setImplantManufacturerName('')}
      showSearch
      allowClear
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Lab not found." />}
      {...props}
    />
  );
};

export default SelectImplantManufacturer;
