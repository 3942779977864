import { Address } from './addresses';
import { Doctor } from './doctors';
import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';
import { FetchSuccess, PagingDataResponse } from '../types';
import { Case } from './case/cases';

export interface Patient {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  note: string;
  doctor: Doctor;
  removed: boolean;
  addresses: Address[];
  cases?: Case[];
}

export interface PatientsGetParams {
  page?: number;
  take?: number;
  fullName?: string;
  doctorName?: string;
  doctor?: string;
  email?: string;
  phone?: string;
  address?: string;
  note?: string;
  removed?: boolean;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'fullName' | 'doctorFullName' | 'email' | 'phone' | 'address' | 'note';
}

export interface TablePatientRow extends Patient {
  key: string;
}

interface PatientsTableData {
  patients: TablePatientRow[];
  total: number;
}

// eslint-disable-next-line max-len
export const useTablePatientRow = (): FetchGet<PagingDataResponse<Patient>, PatientsGetParams, DefaultFetchError, PatientsTableData> => (
  usePatientsGet((data: PagingDataResponse<Patient>): PatientsTableData => ({
    patients: data.data.map((patient: Patient): TablePatientRow => ({
      key: patient.id,
      ...patient,
    })),
    total: data.meta.itemCount,
  }))
);

export function usePatientsGet<D = PagingDataResponse<Patient>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, PatientsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, PatientsGetParams, DD>(
    'patients',
    { decorateData, autoStart: false, startStateLoading: false },
  );
}

export const usePatientId = (): FetchGetId<Patient> => useFetchGetId('patients', '', { autoStart: false });

export const useUpdatePatientStatus = (): FetchUpdate<FetchSuccess, DefaultFetchError, { removed: boolean; }> => (
  useFetchUpdate('patients')
);
