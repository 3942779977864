import { FormInstance } from 'antd/es/form';
import React from 'react';
import { Col, Form, Input, Row, Typography } from 'antd';
import { JsonResult } from '../../../../../types';

import ContentCard from '../../../../Common/ContentCard';

interface PracticeManagerInfo {
  onSubmit?: (values: JsonResult) => void;
  form: FormInstance;
}

const PracticeReject: React.FC<PracticeManagerInfo> = (props) => {
  const { onSubmit, form } = props;

  const handleSubmit = (values: JsonResult) => {
    if (!onSubmit) return;

    onSubmit(values);
  };

  return (
    <div>
      <Typography.Title level={4}>Reject reason</Typography.Title>
      <ContentCard>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={15}>
            <Col span={24}>
              <Form.Item
                label="Specify rejection reason"
                name="reason"
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ContentCard>
    </div>
  );
};

PracticeReject.defaultProps = {
  onSubmit: () => undefined,
};

export default PracticeReject;
