import React from 'react';
import { Layout } from 'antd';

import Content from '../../components/Pages/Practices';
import { displayName } from '../../config';

function Practices(): React.JSX.Element {
  document.title = `${displayName}: Practices`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}

export default Practices;
