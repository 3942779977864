import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { ContactsFilled, PlusOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import Calendar from '../../../Common/Calendar';
import { isRoleEnough } from '../../../../utils/auth';
import CaseCreateButton from '../CreateButton';
import RadioButton from '../../../Common/Calendar/Header/RadioButton';
import { useContextCasesCalendar } from '../../../../context/caseCalendar';
import Filters from './Filters';

import styles from './index.module.scss';

function CasesCalendar(): React.JSX.Element {
  const { filtersOpen, toggleFiltersOpen } = useContextCasesCalendar();

  return (
    <Content>
      <div className={clsx(styles.wrapper, {
        [styles.activeFilter]: filtersOpen,
      })}
      >
        <div className={clsx(styles.item, styles.calendar)}>
          <Calendar
            name={(
              <div className={styles.header}>
                <ContactsFilled className={styles.icon} />
                <span>Cases</span>

                <RadioButton active="calendar" />
              </div>
              )}
            headerChildren={(
                isRoleEnough(['root', 'admin', 'practice manager', 'doctor']) && (
                <CaseCreateButton
                  key="addButton"
                  id="addButton"
                  title="Case Creation"
                  icon={<PlusOutlined />}
                  type="primary"
                  isAdmin
                >
                  Add New Case
                </CaseCreateButton>
                )
              )}
            onFiltersClick={toggleFiltersOpen}
          />

        </div>
        <div className={clsx(styles.item, styles.filtersForm)}>
          <Filters />
        </div>
      </div>
    </Content>
  );
}

export default CasesCalendar;
