import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { App, FormInstance } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import React, { useCallback, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import Table from '../Table';
import { getMessageInError } from '../../../hooks/fetch';
import { EntityType, TableLogRow, useTableLogRow } from '../../../hooks/loggers';
import { capitalizeFirstLetter, getSorterParams, queryFilterParams } from '../../../utils';
import ExpandedRowItem from './ExpandedRowItem';
import { PriceLevelService } from '../../../hooks/priceLevels';
import { isRoleEnough } from '../../../utils/auth';

export interface LogCaseAdditionalData {
  materialImplantId: string;
  materialCrownId: string;
  servicesData?: PriceLevelService[];
}

interface TableLoggers {
  entity: EntityType;
  entityId?: number | string;
  isExpandable?: boolean;
  hideTitle?: boolean;
  triggerReload?: number;
  caseAdditionalData?: LogCaseAdditionalData;
}

const TableLoggers: React.FC<TableLoggers> = ({
  entity, entityId, isExpandable, hideTitle, triggerReload, caseAdditionalData,
}): React.JSX.Element => {
  const { message } = App.useApp();
  const formRef = useRef<FormInstance>();
  const logsGet = useTableLogRow();
  const actionRef = useRef<ActionType>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { id = '' } = useParams<{ id: string; }>();

  useEffect(() => {
    if (logsGet.error) {
      message.error(getMessageInError(logsGet.error));
      logsGet.clearError();
    }
  }, [logsGet.error]);

  useEffect(() => {
    if (triggerReload) {
      actionRef.current?.reload();
    }
  }, [triggerReload]);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableLogRow>>> => logsGet.fetch({
    page: current || 1,
    take: pageSize || 10,
    entity,
    entityId: `${entityId}` || id,
    isDoctor: isRoleEnough(['doctor', 'practice manager']) ? true : undefined,
    ...args,
    ...getSorterParams(sorter),
  }).then((data) => {
    if (data) {
      const { logs, total } = data;

      return ({ data: logs || [], success: true, total });
    }

    return ({ data: [], success: false, total: 0 });
  });

  const beforeSearchSubmit = useCallback((beforeSubmitParams: Partial<ParamsType>) => {
    const params = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams(params);

    return params;
  }, [searchParams.get('search')]);

  const columns: ProColumns<TableLogRow>[] = [
    {
      title: 'Modifier',
      dataIndex: 'user',
      sorter: false,
      renderText: (username, { user }) => user?.email,
    },
    {
      title: 'Action',
      dataIndex: 'event',
      sorter: true,
      renderText: (eventName, row) => (row.action ? capitalizeFirstLetter(row.action)
        : capitalizeFirstLetter(row.event)),
    },
    {
      title: 'Timestamp',
      dataIndex: 'createdAt',
      sorter: true,
      hideInSearch: true,
      renderText: (name, { createdAt }) => (
        <>
          {dayjs(createdAt).format('MMM DD, YYYY hh:mm A')}
        </>
      ),
    },
  ];

  const expandedRowRender = isExpandable ? (row: TableLogRow | undefined) => (
    <ExpandedRowItem row={row} caseAdditionalData={caseAdditionalData} />
  ) : undefined;

  return (
    <Table<TableLogRow>
      formRef={formRef}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      headerTitle={!hideTitle && 'Logs'}
      columnsState={{ persistenceKey: 'pro-table-loggers', persistenceType: 'localStorage' }}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
      onFilterClose={() => undefined}
      expandable={isExpandable ? { expandedRowRender } : undefined}
      search={false}
    />
  );
};

TableLoggers.defaultProps = {
  entityId: '',
  isExpandable: false,
  hideTitle: false,
  triggerReload: undefined,
  caseAdditionalData: undefined,
};

export default TableLoggers;
