import React from 'react';
import { Layout } from 'antd';

import Content from '../../components/Pages/Personalization';
import { displayName } from '../../config';

function Personalization(): React.JSX.Element {
  document.title = `${displayName}: Personalization`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}

export default Personalization;
