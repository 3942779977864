import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';

import styles from './index.module.scss';

interface Loading {
  size?: SpinSize;
  absolute?: boolean;
  visible: boolean;
  /** Time in milliseconds */
  delay?: number;
  style?: React.CSSProperties;
}

const Loading = ({ size, absolute, visible, delay, style }: Loading): JSX.Element | null => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (delay) {
      if (visible) {
        setLoading(true);
      } else {
        const timeoutId = setTimeout(() => setLoading(false), delay);

        return () => clearTimeout(timeoutId);
      }
    }

    return undefined;
  }, [visible]);

  if (delay) {
    return loading
      ? (
        <div className={clsx(styles.loading, absolute ? styles.absolute : '')} style={style}>
          <Spin size={size} />
        </div>
      )
      : null;
  }

  return visible
    ? (
      <div className={clsx(styles.loading, absolute ? styles.absolute : '')} style={style}>
        <Spin size={size} />
      </div>
    )
    : null;
};

Loading.defaultProps = {
  size: 'large',
  absolute: false,
  style: undefined,
  delay: undefined,
};

export default Loading;
