import React from 'react';
import { Typography } from 'antd';
import Icon from '@ant-design/icons';
import clsx from 'clsx';
import Status from '../Status';

import styles from './index.module.scss';
import { Close } from '../Icon';

/** Additional component to work with viewbar content */
interface ViewBarContentItem {
  label: string;
  children: React.ReactNode;
}

interface ViewBarDescriptionContent {
  items: ViewBarContentItem[];
}

export const ViewBarDescriptionContent = (props: ViewBarDescriptionContent): JSX.Element => {
  const { items } = props;

  return (
    <div className={styles.gridWrapper}>
      {items.map((item) => (
        <div key={item.label} className={styles.gridItem}>
          <span className={clsx(styles.label)} title={item.label}>
            {item.label}
          </span>
          <span className={clsx(styles.content, 'clip')} title={typeof item.children === 'string' ? item.children : ''}>
            {item.children}
          </span>
        </div>
      ))}
    </div>
  );
};

/** ViewBar */
interface ViewBar {
  title: string;
  status?: string;
  onClose: () => void;
  children?: React.ReactNode;
  controls?: React.ReactNode;
  withSteps?: boolean;
  currentStep?: number;
  totalSteps?: number;
  closeIcon?: React.ReactNode;
  forwardedRef?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
}

const ViewBar = (props: ViewBar): JSX.Element => {
  const {
    title,
    status,
    onClose,
    children,
    controls,
    withSteps,
    currentStep,
    totalSteps,
    closeIcon,
    forwardedRef,
    style,
  } = props;

  return (
    <div className={styles['view-bar']} ref={forwardedRef} style={style}>
      <div className={styles['title-wrapper']}>
        {closeIcon ? (
          <div onClick={onClose} className={styles.icon}>
            {closeIcon}
          </div>
        ) : (
          <Close className={styles.icon} onClick={onClose} />
        )}

        <Typography.Title className={styles.title} level={3}>{title}</Typography.Title>
        {status && (
          <div className={styles.status}>
            <Status status={status} />
          </div>
        )}
      </div>
      <div>
        {children}
      </div>
      <div className={styles.controls}>
        {withSteps && (
          // eslint-disable-next-line
          <Typography.Text className={styles.steps}>Step {currentStep} of {totalSteps}</Typography.Text>
        )}
        {controls}
      </div>
    </div>
  );
};

ViewBar.defaultProps = {
  status: null,
  children: null,
  controls: null,
  withSteps: false,
  currentStep: 1,
  totalSteps: 1,
  closeIcon: <Icon component={Close} />,
  forwardedRef: undefined,
  style: undefined,
};

export default ViewBar;
