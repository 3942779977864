import React, { useState } from 'react';
import { Col, Form, Input, Row, Typography } from 'antd';

import { Rule } from 'antd/es/form';
import ContentCard from '../../../../Common/ContentCard';
import ControlsEditMode from '../../../../Common/ControlsEditMode';
import { email, phoneNumber, required } from '../../../../../utils/inputRules';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import SelectPriceLevel from '../../../../Common/Selects/SelectPriceLevel';

interface PracticeInfoForm {
  isAdmin?: boolean;
  loading?: boolean;
  disabled?: boolean;
  editMode?: {
    onSubmit: () => void;
    onCancel: () => void;
    isFormValid?: boolean;
  };
  requiredFieldsType?: 'create' | 'completeRegistration';
}

const PracticeInfoForm: React.FC<PracticeInfoForm> = (props): JSX.Element => {
  const { isAdmin, editMode, loading, disabled, requiredFieldsType } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isDisabledField = disabled || !!(editMode && !isEditing);

  const isRequiredCompleteRegister: Rule[] = requiredFieldsType === 'completeRegistration' ? required : [];

  const handleCancel = () => {
    if (!editMode) return;
    editMode.onCancel();

    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (!editMode) return;

    editMode.onSubmit();
    setIsEditing(false);
  };

  return (
    <div>
      <Form.Item name="id" className="hidden-input">
        <Input type="hidden" />
      </Form.Item>
      <Typography.Title level={4}>Practice Information</Typography.Title>
      <ContentCard loading={loading}>
        <Row gutter={15}>
          <Col span={isAdmin ? 12 : 24}>
            <Form.Item
              label="Practice Name"
              name="name"
              rules={required}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
              />
            </Form.Item>
          </Col>
          {isAdmin && (
            <Col span={12}>
              <Form.Item
                label="Account Number"
                name="accountNumber"
                rules={required}
              >
                <Input
                  placeholder="Type here"
                  disabled={isDisabledField}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[...email, ...required]}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Contact Phone"
              name="phone"
              rules={[...phoneNumber, ...isRequiredCompleteRegister]}
            >
              <PhoneNumberInput disabled={isDisabledField} />
            </Form.Item>
          </Col>
        </Row>
        {isAdmin && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="Price Level"
                name="priceLevel"
                rules={required}
              >
                <SelectPriceLevel disabled={isDisabledField} />
              </Form.Item>
              <Form.Item
                label="Notes"
                name="note"
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Type here"
                  disabled={isDisabledField}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {editMode && (
          <ControlsEditMode
            isEditing={isEditing}
            onCancel={handleCancel}
            onChangeEditing={setIsEditing}
            onSubmit={handleSubmit}
            isFormValid={typeof editMode.isFormValid !== 'undefined' ? editMode.isFormValid : true}
          />
        )}
      </ContentCard>
    </div>
  );
};

PracticeInfoForm.defaultProps = {
  isAdmin: false,
  loading: false,
  disabled: false,
  editMode: undefined,
  requiredFieldsType: 'create',
};

export default PracticeInfoForm;
