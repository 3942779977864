import React, { useEffect, useState } from 'react';
import { Button, Card } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { onValue, ref } from 'firebase/database';
import { connect } from 'react-redux';
import ChatListItem from './Item';
import { IChatRoomWithId, IRoomsData } from '../../../../hooks/chats';
import { db } from '../../../../firebase-config';
import { convertObjectRecordToArr, getUserFullName } from '../../../../utils';
import Loading from '../../../Common/Loading';
import NotFoundContent from '../../../Common/NotFoundContent';
import { useContextChats } from '../../../../context/chats';
import { RootState } from '../../../../store/reducers';
import { moduleName, User } from '../../../../store/ducks/auth';
import { getChatItemFromStorage } from '../storage-utils';

import originStyles from '../index.module.scss';
import styles from './index.module.scss';

interface IChatsList {
  toggleChatsListOpen: () => void;
  activeId?: string;
  changeActiveChat: (chatId: string) => void;
  currentUser: User | null;
  isRememberMe: boolean;
}

export const filterChatsForUser = (currentUser: User | null, chatsList: IChatRoomWithId[]): IChatRoomWithId[] => {
  if (currentUser?.id) {
    return chatsList.filter((chat) => (
      chat?.initiatorId === currentUser.id || chat?.speakerId === currentUser.id
      || chat.participants?.find((participant) => participant?.speakerId === currentUser.id)
    ));
  }

  return chatsList;
};

const ChatsList: React.FC<IChatsList> = ({
  activeId,
  toggleChatsListOpen,
  changeActiveChat,
  currentUser,
  isRememberMe,
}) => {
  const { getRoomUsersInContext, listUsers, handleListUsers } = useContextChats();
  const [rooms, setRooms] = useState<IChatRoomWithId[]>([]);
  const [filteredRooms, setFilteredRooms] = useState<IChatRoomWithId[]>([]);
  const [userIdsToFetch, setUserIdsToFetch] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFilteredRooms(filterChatsForUser(currentUser, rooms));
  }, [rooms]);

  useEffect(() => {
    setLoading(true);
    const query = ref(db, 'rooms');

    return onValue(query, (snapshot) => {
      const data: IRoomsData = snapshot.val();

      /** If data exist convert it from Record<roomId(string), object{}> to array */
      if (snapshot.exists()) {
        const roomsConverted: IChatRoomWithId[] = Object.keys(data).map((roomId) => {
          const room = data[roomId];

          const { messages, participants, ...rest } = room;

          const messagesArray = convertObjectRecordToArr(messages || {});

          const participantsArray = convertObjectRecordToArr(participants || {});

          return {
            id: roomId,
            messages: messagesArray,
            participants: participantsArray,
            ...rest,
          };
        });

        setRooms(roomsConverted);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    const allUserIds: string[] = [];

    filteredRooms.forEach((room) => {
      if (room.initiatorId) { allUserIds.push(room.initiatorId); }
      if (room.speakerId) { allUserIds.push(room.speakerId); }
    });

    const uniqueUserIds: string[] = Array.from(new Set(allUserIds));

    setUserIdsToFetch(uniqueUserIds);
  }, [filteredRooms]);

  /** When rooms in DB updated search for new user ids.
   * If something changed - set userIdsToFetch and then trigger fetch data about all chat participants.
   * After fetch set all data about users in context state. */
  useEffect(() => {
    if (getRoomUsersInContext && userIdsToFetch.length) {
      getRoomUsersInContext.fetch({ users: userIdsToFetch }).then((res) => {
        if (res) {
          handleListUsers(res);
        }
      });
    }
  }, [userIdsToFetch.length]);

  return (
    <Card
      bodyStyle={{ padding: '16px 24px' }}
      className={originStyles.chatsListCard}
      title="Chats"
      extra={(
        <Button type="text" onClick={toggleChatsListOpen} style={{ padding: '4px' }}>
          <CloseOutlined style={{ fontSize: '16px' }} />
        </Button>
      )}
      headStyle={{ borderBottom: 'none' }}
    >
      <Loading visible={loading || !!getRoomUsersInContext?.loading} absolute delay={100} />
      <div className={styles.list}>
        {filteredRooms.length ? filteredRooms
          .sort((a, b) => {
            const secondsA = a.messages[a.messages.length - 1]?.timestamp?.seconds || a?.timestamp?.seconds || 0;
            const secondsB = b.messages[b.messages.length - 1]?.timestamp?.seconds || b?.timestamp?.seconds || 0;

            return secondsA - secondsB;
          })
          .map((chat) => {
            const latestMessage = chat.messages[chat.messages.length - 1];

            const initiatorUserObj = listUsers.find((user) => user.id === chat.initiatorId);
            const speakerUserObj = listUsers.find((user) => user.id === chat.speakerId);
            const initiatorFullName = getUserFullName(initiatorUserObj);
            const speakerFullName = getUserFullName(speakerUserObj);
            const speakerLabName = speakerUserObj?.labStaff?.lab?.name || '-';

            const displayChatName = currentUser?.role?.includes('lab')
              ? initiatorFullName : `${speakerFullName}, ${speakerLabName}`;

            const lastReadMessageId = getChatItemFromStorage(chat.id, isRememberMe)?.lastReadMessageId;

            return (
              <ChatListItem
                onClick={() => changeActiveChat(chat.id)}
                key={chat.id}
                chatName={displayChatName}
                updatedAtSecond={latestMessage?.timestamp?.seconds || chat.timestamp?.seconds || 0}
                message={chat.messages?.length ? latestMessage.message : 'There\'s no messages yet!'}
                unread={lastReadMessageId ? latestMessage?.id !== lastReadMessageId : false}
                isNew={!lastReadMessageId}
                active={chat.id === activeId}
              />
            );
          }) : <NotFoundContent message="No chats found" />}
      </div>
    </Card>
  );
};

ChatsList.defaultProps = {
  activeId: undefined,
};

export default connect((state: RootState) => ({
  currentUser: state[moduleName].user,
  isRememberMe: state[moduleName].remember,
}))(ChatsList);
