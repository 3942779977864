import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { App, Form, Modal } from 'antd';
import styles from './index.module.scss';
import { getUserFullName } from '../../../../../../../utils';
import { useContextChats } from '../../../../../../../context/chats';
import { IChatRoomWithId, usePostChatParticipants } from '../../../../../../../hooks/chats';
import { DefaultModalProps } from '../../../../../../../types';
import SelectChatParticipantsMulti from '../../../../../../Common/Selects/SelectChatParticipantsMulti';
import { getMessageInError } from '../../../../../../../hooks/fetch';

const getActiveParticipantsIds = (roomData: IChatRoomWithId | null): string[] => {
  const participantsIds = (roomData?.participants || []).map((item) => item.speakerId);

  return [roomData?.speakerId || '', roomData?.initiatorId || '', ...participantsIds];
};

interface IParticipants extends DefaultModalProps {
  roomId: string;
  roomData: IChatRoomWithId | null;
}

/** Component with participants list and modal to control participants */
const Participants: React.FC<IParticipants> = ({ roomId, roomData, isOpen, close }) => {
  const { message } = App.useApp();
  const { roomUsers } = useContextChats();
  const [form] = Form.useForm();
  const postChatParticipants = usePostChatParticipants();

  const labId = roomUsers?.[roomId]?.find((user) => user.id === roomData?.speakerId)
    ?.labStaff?.lab?.id;

  const [initialValues, setInitialValues] = useState<{ participants: string[]; }>({ participants: [] });

  useEffect(() => {
    setInitialValues({ participants: roomData?.participants?.map((user) => user.speakerId) || [] });
  }, [roomData?.participants]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleClose = () => {
    close();
    form.resetFields();
  };

  const handleSubmit = () => {
    form.validateFields().then((values: { participants: string[]; }) => {
      const preparedParticipantsData = values.participants.map((val) => ({ speakerId: val }));

      postChatParticipants.fetch({ participants: preparedParticipantsData }, `${roomId}/participants`)
        .then(() => {
          message.success('Participants successfully updated!');
          handleClose();
        });
    });
  };

  useEffect(() => {
    if (postChatParticipants.error) {
      message.error(getMessageInError(postChatParticipants.error));
      postChatParticipants.clearError();
    }
  }, [postChatParticipants.error]);

  return (
    <>
      <Modal
        title="Add participants"
        open={isOpen}
        onOk={form.submit}
        okText="Save"
        onCancel={handleClose}
        cancelText="Cancel"
        centered
        okButtonProps={{
          loading: postChatParticipants.loading,
        }}
        destroyOnClose
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={handleSubmit}
        >
          <Form.Item name="participants" label="Participants">
            <SelectChatParticipantsMulti
              onlyStatus="active"
              getValueFromUser
              lab={labId}
              hiddenOptionsList={[roomData?.initiatorId || '', roomData?.speakerId || ''].filter((val) => !!val)}
            />
          </Form.Item>
        </Form>
      </Modal>
      <div className={clsx('clip', styles.participantsList)}>
        {roomUsers?.[roomId]
          ?.filter((user) => getActiveParticipantsIds(roomData).includes(user.id))
          ?.map((user, index, arr) => (
            <span
              key={user.id}
              className={clsx(styles.participant)}
            >
              {getUserFullName(user)}
              {index !== (arr.length - 1) && ','}
            </span>

          ))}
      </div>
    </>
  );
};

export default Participants;
