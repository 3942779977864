import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { App, Button, Form, Modal } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { usePostNewRoom } from '../../../../../hooks/chats';
import { getMessageInError } from '../../../../../hooks/fetch';
import { required } from '../../../../../utils/inputRules';
import SelectLab from '../../../../Common/Selects/SelectLab';
import SelectLabStaff from '../../../../Common/Selects/SelectLabStaff';

const AddNewChat = () => {
  const { message } = App.useApp();
  const postNewRoom = usePostNewRoom();
  const [modalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm<{ lab: string; speakerId: string; }>();
  const [_, setSearchParams] = useSearchParams();

  const labValue = Form.useWatch('lab', form);

  const handleSubmit = () => {
    form.validateFields().then(({ speakerId }) => postNewRoom.fetch({ speakerId })).then((res) => {
      if (res) {
        setSearchParams({
          id: res?.key || '',
        });
        closeModal();
      }
    });
  };

  const closeModal = () => {
    setModalOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (postNewRoom.error) {
      message.error(getMessageInError(postNewRoom.error));
      postNewRoom.clearError();
    }
  }, [postNewRoom.error]);

  return (
    <>
      <Modal
        title="Start new chat"
        open={modalOpen}
        onOk={handleSubmit}
        okText="Create"
        onCancel={closeModal}
        cancelText="Cancel"
        centered
        okButtonProps={{
          loading: postNewRoom.loading,
        }}
      >
        <Form
          form={form}
          name="chat-create-form"
          layout="vertical"
          autoComplete="false"
        >

          <Form.Item
            label="Lab"
            name="lab"
            rules={required}
          >
            <SelectLab
              onlyStatus="active"
              onChange={() => {
                form.setFieldValue('speakerId', null);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Lab Manager"
            name="speakerId"
            rules={required}
          >
            <SelectLabStaff
              lab={labValue}
              requestOnlyWithLab
              getValueFromUser
              disabled={!labValue}
              onlyStatus="active"
              onlyLabManagers
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setModalOpen(true)}>
        Start new chat
      </Button>
    </>
  );
};

export default AddNewChat;
