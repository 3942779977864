import { App } from 'antd';
import { useEffect } from 'react';
import { FetchCreate, FetchDelete, FetchGet, FetchUpdate, getMessageInError } from './fetch';

export function useMessageError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listFetch: (FetchCreate | FetchGet<any> | FetchUpdate<any> | FetchDelete<any>)[],
) {
  const { message } = App.useApp();

  useEffect(() => {
    listFetch.forEach((fetch) => {
      if (fetch.error) {
        message.error(getMessageInError(fetch.error));
        fetch.clearError();
      }
    });
  }, [...listFetch.map((fetch) => fetch.error)]);
}
