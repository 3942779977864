import { PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  useFetchGet,
  useFetchGetId,
} from './fetch';

export interface Event {
  id: number;
  name: string;
  eventDate: string;
  repeat: boolean;
}

export interface EventsGetParams {
  id?: string;
  name?: string;
  page?: number;
  take?: number;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'createdAt' | 'updatedAt' | 'eventDate';
}

export const useEventsGet = (): FetchGet<PagingDataResponse<Event>, EventsGetParams, DefaultFetchError> => (
  useFetchGet('events', { autoStart: false, startStateLoading: false })
);

export const useEventId = (): FetchGetId<Event> => useFetchGetId('events', '', { autoStart: false });
