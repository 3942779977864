import { CheckOutlined } from '@ant-design/icons/lib';
import { App, Button, Col, Divider, Form, Row } from 'antd';
import Icon from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { CreateAddress } from '../../../../hooks/addresses';
import { usePracticeId } from '../../../../hooks/practices';
import {
  moduleName,
  practiceAccept,
  practiceReject,
  practiceUpdate,
  PracticeAccept,
  PracticeReject, PracticeUpdatePayload,
} from '../../../../store/ducks/practice';
import { RootState } from '../../../../store/reducers';
import { JsonResult } from '../../../../types';
import { preparePracticeInitialData } from '../../../../utils/prepareData';
import DrawerModal from '../../../Common/DrawerModal';
import { Close, Send, ArrowLeft } from '../../../Common/Icon';
import Accept from '../Forms/Accept';
import PracticeForm from '../Forms/PracticeForm';
import PracticeManagerInfo from '../Forms/PracticeManagerInfo';
import Reject from '../Forms/Reject';
import styles from './index.module.scss';
import { Error } from '../../../../store/ducks/common';

interface PracticeRequest {
  practiceId?: string | number;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  error: Error | null;
  loading: boolean;
  acceptPractice: (payload: PracticeAccept) => Action;
  rejectPractice: (payload: PracticeReject) => Action;
  updatePractice: (payload: PracticeUpdatePayload) => Action;
}

const PracticeRequest: React.FC<PracticeRequest> = (props) => {
  const { message } = App.useApp();
  const {
    practiceId,
    drawerOpen,
    setDrawerOpen,
    acceptPractice,
    rejectPractice,
    updatePractice,
    error,
    loading,
  } = props;
  const [form] = Form.useForm();
  const [acceptForm] = Form.useForm();
  const [rejectForm] = Form.useForm();

  const practiceById = usePracticeId();
  const addressesState = useState<CreateAddress[]>([]);
  const sameAsBillingState = useState<boolean | undefined>(undefined);

  const [addresses, setAddresses] = addressesState;
  const [sameAsBilling, setSameAsBilling] = sameAsBillingState;

  const [isRejectionProceed, setIsRejectionProceed] = useState<boolean>(false);
  const [isAcceptanceProceed, setIsAcceptanceProceed] = useState<boolean>(false);

  const initialData = practiceById.data ? preparePracticeInitialData(practiceById.data) : null;

  const handleClose = () => {
    setDrawerOpen(false);
    form.resetFields();
    acceptForm.resetFields();
    rejectForm.resetFields();
  };

  const handleBack = () => {
    setIsAcceptanceProceed(false);
    setIsRejectionProceed(false);
    acceptForm.resetFields();
    rejectForm.resetFields();
  };

  useEffect(() => {
    if (error) {
      message.error(error.message, 5);
    }
  }, [error]);

  useEffect(() => {
    if (!loading && !error) {
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    if (!initialData) return;

    if (!addresses) setAddresses(initialData?.addresses || []);
    if (sameAsBilling === undefined) setSameAsBilling(initialData.sameAsBilling);
  }, [initialData]);

  const fetchPracticeId = () => {
    practiceById.fetch(undefined, practiceId);
  };

  useEffect(() => {
    if (practiceId && drawerOpen) {
      fetchPracticeId();
    }
  }, [practiceId]);

  const handleAcceptClick = () => {
    setIsAcceptanceProceed(true);
  };

  const handleRejectClick = () => {
    setIsRejectionProceed(true);
  };

  const handleAcceptSubmit = () => {
    acceptForm.submit();
  };

  const handleRejectSubmit = () => {
    rejectForm.submit();
  };

  const handleAccept = () => {
    acceptForm.validateFields().then((values: JsonResult) => {
      const { accountNumber, priceLevel, note } = values;

      acceptPractice({ id: practiceId, accountNumber, note });
      updatePractice({ id: practiceId, priceLevel });
    });
  };

  const handleReject = () => {
    rejectForm.validateFields().then((values: JsonResult) => {
      rejectPractice({ id: practiceId, ...values });
    });
  };

  return (
    <div>
      <DrawerModal
        title="Practice Request"
        open={drawerOpen}
        closeIcon={(isAcceptanceProceed || isRejectionProceed) && <Icon component={ArrowLeft} />}
        withoutConfirmation={isAcceptanceProceed || isRejectionProceed}
        onClose={() => {
          if (isAcceptanceProceed || isRejectionProceed) {
            handleBack();
          } else {
            handleClose();
          }
        }}
        extra={(
          <>
            {isAcceptanceProceed && (
              <Button
                type="primary"
                onClick={handleAcceptSubmit}
                icon={<Icon component={Send} />}
              >
                Send Acception
              </Button>
            )}
            {isRejectionProceed && (
              <Button
                type="primary"
                onClick={handleRejectSubmit}
                icon={<Icon component={Send} />}
              >
                Send Rejection
              </Button>
            )}
          </>
        )}
      >
        <Row justify="center">
          <Col span={24} md={20} lg={16} xxl={12}>
            {!isAcceptanceProceed && !isRejectionProceed && (
              <>
                <PracticeForm
                  form={form}
                  initialData={initialData?.practice}
                  addressesState={addressesState}
                  sameAsBillingState={sameAsBillingState}
                  disabled={{
                    practice: true,
                    billingAddress: true,
                    shippingAddress: true,
                  }}
                />
                <PracticeManagerInfo form={form} initialData={initialData?.manager} disabled>
                  <Divider />
                  <div className={styles.actionsRow}>
                    <Button
                      ghost
                      icon={<Icon component={Close} />}
                      onClick={handleRejectClick}
                    >
                      Reject
                    </Button>
                    <Button
                      type="default"
                      icon={<CheckOutlined />}
                      onClick={handleAcceptClick}
                    >
                      Accept
                    </Button>
                  </div>
                </PracticeManagerInfo>
              </>
            )}
            {isAcceptanceProceed && (
              <Accept form={acceptForm} onSubmit={handleAccept} />
            )}
            {isRejectionProceed && (
              <Reject form={rejectForm} onSubmit={handleReject} />
            )}
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

PracticeRequest.defaultProps = {
  practiceId: undefined,
};

const mapStateToProps = (state: RootState) => ({
  loading: state[moduleName].loading,
  error: state[moduleName].error,
});

const mapDispatchToProps = {
  acceptPractice: practiceAccept,
  rejectPractice: practiceReject,
  updatePractice: practiceUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(PracticeRequest);
