import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { WechatOutlined } from '@ant-design/icons';
import { child, get, ref } from 'firebase/database';
import { connect } from 'react-redux';
import { db } from '../../../../../firebase-config';
import { IChatRoomWithId, IRoomsData } from '../../../../../hooks/chats';
import { convertObjectRecordToArr } from '../../../../../utils';
import { getAllChatFromStorage } from '../../../../Pages/Chat/storage-utils';
import { RootState } from '../../../../../store/reducers';
import { moduleName, User } from '../../../../../store/ducks/auth';
import { filterChatsForUser } from '../../../../Pages/Chat/ChatsList';

import styles from '../index.module.scss';

interface IChatsLink {
  isActive: boolean;
  isRememberMe: boolean;
  currentUser: User | null;
}

const ChatsLink: React.FC<IChatsLink> = ({ isActive, isRememberMe, currentUser }) => {
  const { pathname } = useLocation();
  const [triggerGetData, setTriggerGetData] = useState<number>(Date.now());
  const [roomsData, setRoomsData] = useState<IChatRoomWithId[]>([]);
  const [loading, setLoading] = useState(false);

  const [haveUnreadMessage, setHaveUnreadMessages] = useState(false);
  const [haveNewMessage, setHaveNewMessages] = useState(false);

  useEffect(() => {
    if (loading) return;

    const dbRef = ref(db);

    setLoading(true);
    get(child(dbRef, 'rooms')).then((snapshot) => {
      if (snapshot.exists()) {
        const data: IRoomsData = snapshot.val();

        const roomsConverted: IChatRoomWithId[] = Object.keys(data).map((roomId) => {
          const room = data[roomId];

          const { messages, participants, ...rest } = room;

          const messagesArray = convertObjectRecordToArr(messages || {});

          const participantsArray = convertObjectRecordToArr(participants || {});

          return {
            id: roomId,
            messages: messagesArray,
            participants: participantsArray,
            ...rest,
          };
        });

        const filteredRooms = filterChatsForUser(currentUser, roomsConverted);

        setRoomsData(filteredRooms);
      }
    }).finally(() => setLoading(false));
  }, [triggerGetData]);

  useEffect(() => {
    const intervalId = setInterval(() => setTriggerGetData(Date.now()), 300000);
    /** once in a 5 min trigger GET data from DB */

    return () => clearInterval(intervalId);
  }, []);

  const [prevPathname, setPrevPathname] = useState<string>('');

  useEffect(() => {
    /** When user visit chats page instantly update data */
    if (pathname.includes('chat')) {
      setPrevPathname(pathname);
      setTriggerGetData(Date.now());
    }
    if (prevPathname.includes('chat')) {
      setPrevPathname('');
      setTriggerGetData(Date.now());
    }
  }, [pathname]);

  /** Control badge */
  useEffect(() => {
    const allChatsData = getAllChatFromStorage(isRememberMe);

    if (roomsData.length) {
      const isSomeUnread = roomsData.some((room) => {
        const currentLastReadId = allChatsData?.[room.id]?.lastReadMessageId;

        if (currentLastReadId) {
          return currentLastReadId !== room.messages[(room.messages || []).length - 1]?.id;
        }

        return false;
      });

      const isSomeNew = roomsData.some((room) => {
        const currentLastReadId = allChatsData?.[room.id]?.lastReadMessageId;

        return !currentLastReadId;
      });

      setHaveUnreadMessages(isSomeUnread);
      setHaveNewMessages(isSomeNew);
    } else {
      setHaveUnreadMessages(false);
      setHaveNewMessages(false);
    }
  }, [roomsData]);

  return (
    <div className={clsx(styles.action)}>
      <Link
        to="/chat"
        className={clsx(styles['action-button'], isActive && styles.active)}
        title="Chats"
      >
        <WechatOutlined className={clsx('header-icon-link', styles.chatsLink, {
          [styles.new]: haveNewMessage,
          [styles.unread]: haveUnreadMessage, // Unread should be second to have priority over .new
        })}
        />
      </Link>
    </div>
  );
};

export default connect((state: RootState) => ({
  currentUser: state[moduleName].user,
  isRememberMe: state[moduleName].remember,
}))(ChatsLink);
