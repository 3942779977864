import React from 'react';
import { Layout } from 'antd';

import Content from '../../../../components/Pages/Labs/Profile/Staff/CompleteRegistration';
import { displayName } from '../../../../config';

const CompleteRegistration: React.FC = (): React.JSX.Element => {
  document.title = `${displayName}: Complete Registration`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default CompleteRegistration;
