import React, { useEffect, useState } from 'react';
import { App, Button, Card, Col, ColorPicker, Form, Row, Space, Typography } from 'antd';
import { Color } from 'antd/es/color-picker';
import { connect } from 'react-redux';
import Upload, { UploadProps } from 'antd/es/upload/Upload';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import clsx from 'clsx';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';
import {
  UpdatePersonalization, useDeletePracticeLogo, useUpdatePracticeBranding,
} from '../../../../hooks/personalization';
import { RootState } from '../../../../store/reducers';
import { getUser as getUserAction, moduleName as authModuleName, User } from '../../../../store/ducks/auth';
import { Action } from '../../../../store';
import Loading from '../../../Common/Loading';
import { getMessageInError } from '../../../../hooks/fetch';

import styles from './index.module.scss';

interface IFormValuesPersonalization {
  cssColor?: string;
  file?: Blob | null;
}

interface IUpdatePersonalizationForm {
  user: User | null;
  getUser: () => Action;
}

const UpdateForm: React.FC<IUpdatePersonalizationForm> = ({ user, getUser }) => {
  const { message } = App.useApp();
  const [form] = Form.useForm<UpdatePersonalization>();
  const updatePracticeBranding = useUpdatePracticeBranding();
  const deletePracticeLogo = useDeletePracticeLogo();

  const [fileUrl, setFileUrl] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  /** Initial values */
  const [initialValues, setInitialValues] = useState<IFormValuesPersonalization>({
    cssColor: undefined,
    file: null,
  });

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      cssColor: user?.doctor?.practice?.cssColor || undefined,
    });
  }, [user?.doctor?.practice?.cssColor]);

  useEffect(() => {
    setFileUrl(user?.doctor?.practice?.file?.realPath || '');
  }, [user?.doctor?.practice?.file?.realPath]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  /** Handle values */
  const handleColorChange = ((value: Color, hex: string): void => {
    form.setFieldValue('cssColor', hex);
  });

  const handleSubmit = async (values: UpdatePersonalization) => {
    const formData = new FormData();

    formData.append('cssColor', values.cssColor === COLOR_PRIMARY ? '' : values.cssColor || '');
    if (values.file) {
      formData.append('file', values.file || '');
    }
    if (!!user?.doctor?.practice?.file?.realPath && !values.file && !fileUrl) {
      await deletePracticeLogo.fetch(`${user?.doctor?.practice.id || ''}/logo`);
    }

    updatePracticeBranding.fetch(formData, user?.doctor?.practice?.id)
      .then(() => {
        setFileList([]);
        form.setFieldValue('file', null);
        message.success('Personalization settings updated successfully!');

        getUser();
      });
  };

  const props: UploadProps = {
    multiple: false,
    showUploadList: {
      showPreviewIcon: false,
    },
    beforeUpload: (file) => {
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        message.error('You can only upload JPG/PNG file!');

        return false;
      }

      if (!(file.size / 1024 / 1024 < 10)) {
        message.error('Image must smaller than 10MB!');

        return false;
      }

      const image = new Image();

      image.src = URL.createObjectURL(file);

      /* eslint-disable consistent-return */
      image.onload = () => {
        if (image.width < 32 || image.height < 32) {
          message.error('Image dimensions must be at least 32x32 pixels.');

          return false;
        }
      };

      return false;
    },
    onChange: (e) => {
      const isCorrectFileType = ['image/jpeg', 'image/png'].includes(e.fileList?.[0]?.type || '');

      if (isCorrectFileType || !e.fileList.length) { setFileList(e.fileList); }

      if (e.fileList.length) {
        if (isCorrectFileType) {
          form.setFieldValue('file', e.file);
        } else {
          form.setFieldValue('file', null);
        }
      } else {
        form.setFieldValue('file', null);
      }
      /** Clear file url when change file */
      setFileUrl('');
    },
    fileList,
    maxCount: 1,
    listType: 'picture-card',
    accept: '.jpg,.png,.jpeg',
  };

  useEffect(() => {
    if (updatePracticeBranding.error) {
      message.error(getMessageInError(updatePracticeBranding.error));
      updatePracticeBranding.clearError();
    }
  }, [updatePracticeBranding.error]);

  useEffect(() => {
    if (deletePracticeLogo.error) {
      message.error(getMessageInError(deletePracticeLogo.error));
      deletePracticeLogo.clearError();
    }
  }, [deletePracticeLogo.error]);

  return (
    <Card
      size="default"
      title="Update settings"
      style={{ borderRadius: '10px' }}
      extra={(
        <Button type="primary" htmlType="submit" loading={updatePracticeBranding.loading} onClick={form.submit}>
          Save
        </Button>
    )}
    >
      <Form
        form={form}
        name="personalization-settings-form"
        layout="vertical"
        initialValues={initialValues}
        autoComplete="off"
        onFinish={handleSubmit}
        disabled={updatePracticeBranding.loading}
      >
        <Row gutter={12}>
          <Loading visible={deletePracticeLogo.loading || updatePracticeBranding.loading} absolute delay={100} />

          <Col>
            <Form.Item name="cssColor" label="Primary color">
              <ColorPicker showText onChange={handleColorChange} />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label=" ">
              <Button ghost htmlType="button" onClick={() => form.setFieldValue('cssColor', COLOR_PRIMARY)}>
                Reset default
              </Button>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="file"
              label="Logo image"
              extra={(
                <Typography.Text className={styles.text}>
                  File requirement: JPG or PNG. Up to 10 MB.
                </Typography.Text>
              )}
            >
              <Upload {...props} className={styles.upload}>
                {fileList.length >= 1 ? null : (
                  <>
                    {fileUrl && !fileList.length ? (
                      <div className={styles.overlay}>
                        <DeleteOutlined
                          className={styles.btnOverlay}
                          onClick={(e) => {
                            e.stopPropagation();
                            setFileUrl('');
                          }}
                          title="Delete uploaded image"
                        />
                        <img src={fileUrl} alt="logo" className={clsx(styles.uploadedImg)} style={{ width: '100%' }} />
                      </div>
                    ) : null}
                    {(!fileList.length && !fileUrl) ? (
                      <Space direction="vertical">
                        <PlusOutlined />
                        <Typography.Text>Upload </Typography.Text>
                      </Space>
                    ) : null}
                  </>
                )}

              </Upload>
            </Form.Item>
          </Col>
        </Row>

      </Form>

    </Card>
  );
};

export default connect((state: RootState) => ({
  user: state[authModuleName].user,
}), {
  getUser: getUserAction,
})(UpdateForm);
