import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Request from '../Request';

const RequestPage: React.FC = () => {
  const navigate = useNavigate();
  const { id: practiceId = '' } = useParams<{ id: string; }>();

  const handleDrawerClose = () => {
    navigate({ pathname: '/practices' }, { replace: true });
  };

  return (
    <Request
      practiceId={practiceId}
      drawerOpen
      setDrawerOpen={handleDrawerClose}
    />
  );
};

export default RequestPage;
