import { FetchSuccess, JsonResult, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

export interface NotificationStatus {
  saw?: boolean;
  removed?: boolean;
}

export interface Notification {
  id: string;
  link: string;
  title: string;
  message: string;
  data: JsonResult;
  type: string;
  entity: string;
  entityId: string;
  createdAt: string;
  status: NotificationStatus;
}

export interface NotificationsGetParams {
  page?: number;
  take?: number;
  saw?: boolean;
  removed?: boolean;
  entity?: string[];
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'createdAt' | 'id';
}

export function useNotificationsGet<D = PagingDataResponse<Notification>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, NotificationsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, NotificationsGetParams, DD>(
    'notifications',
    { decorateData, autoStart: false, startStateLoading: false },
  );
}

export const useNotificationId = (): FetchGetId<Notification> => useFetchGetId(
  'notifications',
  '',
  { autoStart: false, startStateLoading: false },
);

export const useUpdateNotificationStatus = (): FetchUpdate<
  FetchSuccess,
  DefaultFetchError,
  NotificationStatus
> => (
  useFetchUpdate('notifications')
);
