export const pageDefault = 1;

export const pageSizeDefault = 10;

export const pageSizePossible = [10, 20, 30, 50];

export const pagingParamList = ['page', 'pageSize'];

export const dateFormat = 'YYYY-MM-DD';
export const dateFormatISO = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const dateFormatShort = 'D/M/YY';

export const timeFormat12 = 'h:mm a';

export const stringDateFormat = 'dddd, MMMM DD';

export const timeFormat = 'HH:mm';
