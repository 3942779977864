import { combineReducers, Reducer } from 'redux';
import local, { State as localState, moduleName as localModule } from './ducks/local';
import auth, { State as authState, moduleName as authModule } from './ducks/auth';
import user, { State as userState, moduleName as userModule } from './ducks/user';
import popover, { State as popoverState, moduleName as popoverModule } from './ducks/popover';
import practice, { State as practiceState, moduleName as practiceModule } from './ducks/practice';
import doctor, { State as doctorState, moduleName as doctorModule } from './ducks/doctor';
import lab, { State as labState, moduleName as labModule } from './ducks/lab';
import labStaff, { State as labStaffState, moduleName as labStaffModule } from './ducks/labStaff';
import service, { State as serviceState, moduleName as serviceModule } from './ducks/service';
import patient, { State as patientState, moduleName as patientModule } from './ducks/patient';
import priceLevel, { State as priceLevelState, moduleName as priceLevelModule } from './ducks/priceLevel';
import cases, { State as casesState, moduleName as casesModule } from './ducks/cases';
import events, { State as eventsState, moduleName as eventsModule } from './ducks/events';

export interface RootState {
  [localModule]: localState;
  [authModule]: authState;
  [userModule]: userState;
  [popoverModule]: popoverState;
  [practiceModule]: practiceState;
  [doctorModule]: doctorState;
  [labModule]: labState;
  [labStaffModule]: labStaffState;
  [serviceModule]: serviceState;
  [patientModule]: patientState;
  [priceLevelModule]: priceLevelState;
  [casesModule]: casesState;
  [eventsModule]: eventsState;
}

export default function AppReducer(): Reducer {
  return combineReducers({
    [localModule]: local,
    [authModule]: auth,
    [userModule]: user,
    [popoverModule]: popover,
    [practiceModule]: practice,
    [doctorModule]: doctor,
    [labModule]: lab,
    [labStaffModule]: labStaff,
    [serviceModule]: service,
    [patientModule]: patient,
    [priceLevelModule]: priceLevel,
    [casesModule]: cases,
    [eventsModule]: events,
  });
}
