import { App, Button, Col, Form, Row, Space } from 'antd';
import Icon from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from '../../../../store';
import { createCase as actionCreateCase, CreateCase, moduleName as casesModule } from '../../../../store/ducks/cases';
import { User, moduleName as authModuleName } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import DrawerModal from '../../../Common/DrawerModal';
import { Send } from '../../../Common/Icon';
import { Error as ErrorCommon } from '../../../../store/ducks/common';
import { useContextCaseFlow } from '../../../../context/caseFlow';
import AdminCaseCreateForm from '../Forms/AdminCaseCreateForm';

interface AdminCaseCreate {
  title: string;
  drawerOpen: boolean;
  close: () => void;
  disabled?: {
    [key: string]: boolean;
  };
  handleSave?: (body: CreateCase) => void;
  loading: boolean;
  error: ErrorCommon | null;
  createCase: (payload: CreateCase) => Action;
  user: User | null;
}

const AdminCaseCreate = (props: AdminCaseCreate): JSX.Element => {
  const { message } = App.useApp();
  const {
    handleSave,
    title,
    drawerOpen,
    close,
    disabled,
    loading,
    error,
    createCase,
  } = props;

  const { clearContextData } = useContextCaseFlow();

  const [patientSelectForm] = Form.useForm();

  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);

  /** Handling close/next/back/submit buttons  */
  const handleClose = () => {
    close();
    patientSelectForm.resetFields();
    clearContextData();
  };

  useEffect(() => {
    if (!loading && !error) {
      /** close modal, when request finish without errors */
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    if (error?.message && drawerOpen) {
      message.error(error.message, 5);
    }
  }, [error]);

  const handleSubmit = () => {
    patientSelectForm.validateFields().then((values) => {
      const body = ({ ...values, teeth: { services: [], teeth: [] } });

      if (handleSave) {
        handleSave(body);
      } else {
        createCase(body);
      }
    });
  };

  return (

    <div>
      <DrawerModal
        title={title}
        open={drawerOpen}
        onClose={handleClose}
        isFormChanged={isFormTouched}
        extra={(
          <Space>
            <Button
              type="primary"
              icon={<Icon component={Send} />}
              loading={loading}
              onClick={handleSubmit}
              disabled={false}
              id="submitBtn"
            >
              Complete Order
            </Button>
          </Space>
        )}
      >
        <Row justify="center">
          <Col span={24} md={20} lg={18} xxl={12}>
            <div>
              <AdminCaseCreateForm
                form={patientSelectForm}
                disabled={disabled}
                onFieldsChange={() => setIsFormTouched(true)}
              />
            </div>
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[casesModule].caseLoading,
  error: state[casesModule].caseError,
  user: state[authModuleName].user,
});

const mapDispatchToProps = {
  createCase: actionCreateCase,
};

AdminCaseCreate.defaultProps = {
  isAdmin: false,
  disabled: undefined,
  initialData: undefined,
  handleSave: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCaseCreate);
