import { LeftOutlined, RightOutlined } from '@ant-design/icons/lib';
import { App, Button, Col, Form, Row, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LabStatuses } from '../../../../enums/lab';
import { useConfirmLab } from '../../../../hooks/labs';
import { Action } from '../../../../store';
import { moduleName as authModuleName } from '../../../../store/ducks/auth';
import { updateLab as actionUpdateLab, moduleName as labModuleName, UpdateLab } from '../../../../store/ducks/lab';
import { RootState } from '../../../../store/reducers';
import { JsonResult } from '../../../../types';
import {
  PreparedLabInitialData,
  prepareLabBody,
  prepareLabInitialData,
} from '../../../../utils/prepareData';
import DrawerModal from '../../../Common/DrawerModal';
import LabForm from '../Forms/LabForm';
import LabManagerInfo from '../Forms/LabManagerInfo';
import { Error } from '../../../../store/ducks/common';
import { getMessageInError } from '../../../../hooks/fetch';

interface LabCompleteRegistration {
  refreshToken: string | null;
  loading: boolean;
  error: Error | null;
  updateLab: (payload: UpdateLab) => Action;
}

const LabCompleteRegistration: React.FC<LabCompleteRegistration> = (props): React.JSX.Element => {
  const { message } = App.useApp();
  const {
    refreshToken,
    loading,
    error,
    updateLab,
  } = props;

  const navigate = useNavigate();
  const { token = '' } = useParams<{ token: string; }>();

  const [labForm] = Form.useForm();
  const [managerForm] = Form.useForm();

  const totalSteps = 2;
  const [step, setStep] = useState<number>(1);

  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [labInfo, setLabInfo] = useState<JsonResult>({});
  const confirmLab = useConfirmLab();

  const fetchConfirmLab = () => {
    confirmLab.fetch({ secretKey: token });
  };

  useEffect(() => {
    if (token) {
      fetchConfirmLab();
    }
  }, [token]);

  const handleClose = () => {
    if (error) return;

    navigate({ pathname: '/sign-in' }, { replace: true });
  };

  useEffect(() => {
    if (!loading && !error && isFormSubmitted && refreshToken) {
      message.success('Lab has been updated!');
      setIsFormSubmitted(false);
      navigate('/staff/services');
    }
  }, [loading, isFormSubmitted, refreshToken]);

  const [initialData, setInitialData] = useState<PreparedLabInitialData | undefined>();

  useEffect(() => {
    if (confirmLab.data) {
      setInitialData(prepareLabInitialData(confirmLab.data));
    }
  }, [confirmLab.data]);

  useEffect(() => {
    if (!initialData) return;
    labForm.setFieldsValue(initialData.lab);
    managerForm.setFieldsValue(initialData.manager);
  }, [initialData]);

  useEffect(() => {
    if (error?.message) {
      message.error(error.message, 5);
    }
  }, [error]);

  const handleNext = () => {
    if (step === 1) {
      labForm.validateFields().then((values: JsonResult) => {
        setLabInfo(values);
        setStep((prev) => prev + 1);
      });
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const handleSubmit = () => {
    managerForm.validateFields().then((values) => {
      const body = prepareLabBody(labInfo, values, LabStatuses.pending);

      updateLab({ id: initialData?.id || '', secretKey: token, ...body });

      setIsFormSubmitted(true);
    });
  };

  useEffect(() => {
    if (confirmLab.error) {
      message.error(getMessageInError(confirmLab.error));
      confirmLab.clearError();
    }
  }, [confirmLab.error]);

  return (
    <div>
      <DrawerModal
        title="Complete registration form"
        open
        onClose={handleClose}
        withSteps
        currentStep={step}
        totalSteps={totalSteps}
        isFormChanged={isFormTouched}
        extra={(
          <Space>
            {step > 1 && (
              <Button
                ghost
                onClick={() => setStep((prev) => prev - 1)}
              >
                <LeftOutlined style={{ fontSize: 12 }} />
                Back
              </Button>
            )}
            {step < totalSteps && (
              <Button
                ghost
                onClick={handleNext}
                id="nextBtn"
              >
                Next
                <RightOutlined style={{ fontSize: 12 }} />
              </Button>
            )}
            {step === totalSteps && (
              <Button
                type="primary"
                icon={<CheckOutlined />}
                loading={loading}
                onClick={handleSubmit}
                id="submitBtn"
              >
                Complete registration
              </Button>
            )}
          </Space>
        )}
      >
        <Row justify="center">
          <Col span={24} md={20} lg={16} xxl={12}>
            <div>
              {step === 1 && (
                <LabForm
                  form={labForm}
                  initialData={initialData?.lab}
                  onFieldsChange={() => setIsFormTouched(true)}
                  requiredFieldsType="update"
                />
              )}
              {step === 2 && (
                <LabManagerInfo
                  hasPassword
                  initialData={initialData?.manager}
                  form={managerForm}
                />
              )}
            </div>
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  lab: state[labModuleName].loading,
  loading: state[labModuleName].loading,
  error: state[labModuleName].error,
  refreshToken: state[authModuleName].refreshToken,
});

const mapDispatchToProps = {
  updateLab: actionUpdateLab,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabCompleteRegistration);
