export enum ServiceStatuses {
  active = 'active',
  deactivated = 'deactivated',
}

export type ServiceStatus = 'active' | 'deactivated';

export enum AdditionalOptionsTypes {
  perTooth = 'per tooth',
  fixedPrice = 'fixed price',
}

export type AdditionalOptionsType = 'per tooth' | 'fixed price';
