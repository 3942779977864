import { Form, FormInstance, Input, Typography } from 'antd';
import React from 'react';
import { JsonResult } from '../../../../../types';
import { email, required } from '../../../../../utils/inputRules';
import ContentCard from '../../../../Common/ContentCard';

interface EmailForm {
  title: string;
  form: FormInstance;
  loading?: boolean;
  initialData?: JsonResult;
}

const EmailForm: React.FC<EmailForm> = (props) => {
  const { title, form, loading, initialData } = props;

  return (
    <div>
      <Typography.Title level={4}>{title}</Typography.Title>
      <ContentCard loading={loading}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialData}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[...required, ...email]}
          >
            <Input
              placeholder="Type here"
            />
          </Form.Item>
        </Form>
      </ContentCard>
    </div>
  );
};

EmailForm.defaultProps = {
  loading: false,
  initialData: undefined,
};

export default EmailForm;
