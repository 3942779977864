import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { UserRoles } from '../../../../enums/user';
import { User } from '../../../../hooks/users';
import {
  prepareDoctorBody,
  prepareLabStaffBody,
  prepareLabStaffServicesInitialData,
  prepareUserAdminBody,
  prepareUserInitialData,
} from '../../../../utils/prepareData';
import AdminInfoForm from '../../Users/Forms/AdminInfoForm';
import DoctorInfoForm from '../../Practices/Profile/Doctor/Forms/DoctorInfoForm';
import { UpdateDoctor, updateDoctor as actionUpdateDoctor } from '../../../../store/ducks/doctor';
import { Action } from '../../../../store';
import { RootState } from '../../../../store/reducers';
import { UpdateUser, updateUser as actionUpdateUser } from '../../../../store/ducks/user';
import { UpdateLabStaff, updateLabStaff as actionUpdateLabStaff } from '../../../../store/ducks/labStaff';
import LabStaffInfoForm from '../../Labs/Profile/Staff/Forms/LabStaffInfo';
import { PropEditMode } from '../../../Common/ControlsEditMode';
import Loading from '../../../Common/Loading';
import { JsonResult } from '../../../../types';
import { isRoleEnough } from '../../../../utils/auth';
import LabStaffServices from '../../Labs/Profile/Staff/Forms/LabStaffServices';

interface ProfileForm {
  user: User;
  loading?: boolean;
  cardLoading?: boolean;
  updateUser: (payload: UpdateUser) => Action;
  updateDoctor: (payload: UpdateDoctor) => Action;
  updateLabStaff: (payload: UpdateLabStaff) => Action;
}

const ProfileForm = (props: ProfileForm) => {
  const [form] = Form.useForm();
  const [servicesForm] = Form.useForm();
  const { user, loading, cardLoading, updateUser, updateDoctor, updateLabStaff } = props;

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const initialData = prepareUserInitialData(user);

  const handleCancel = () => {
    form.resetFields();
    setIsFormValid(true);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (user?.role === UserRoles.doctor || user?.role === UserRoles.practiceManager) {
        const body = prepareDoctorBody(values, user?.role, values?.userId);

        updateDoctor(body);
      }
      if (user?.role === UserRoles.admin || user?.role === UserRoles.root) {
        const body = prepareUserAdminBody(values);

        updateUser(body);
      }
      if (user?.role === UserRoles.labStaff || user?.role === UserRoles.labManager) {
        const body = prepareLabStaffBody(values, user?.role, values?.userId);

        updateLabStaff(body);
      }
    });
  };

  const handleServicesSubmit = () => {
    servicesForm.validateFields().then((values) => {
      const body = prepareLabStaffBody(
        undefined,
        undefined,
        user?.id || undefined,
        values,
      );

      return updateLabStaff({ ...body, id: user?.labStaff?.id });
    });
  };

  const [servicesInitialValues, setServicesInitialValues] = useState<JsonResult | undefined>();

  useEffect(() => {
    if (user?.labStaff?.labServices) {
      setServicesInitialValues(prepareLabStaffServicesInitialData(user?.labStaff?.labServices));
    }
  }, [user?.labStaff?.labServices]);

  const handleServicesCancel = () => {
    servicesForm.resetFields();
    servicesForm.setFieldsValue(servicesInitialValues);
  };

  const editModeObj: PropEditMode = {
    onSubmit: handleSubmit,
    isFormValid,
    onCancel: handleCancel,
    loading,
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialData}
        name="profile-form"
        autoComplete="off"
        onFieldsChange={() => {
          setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length));
        }}
      >
        <Loading absolute visible={loading || false} style={{ marginBottom: '30px' }} />
        {(user?.role === UserRoles.admin || user?.role === UserRoles.root) && (
        <AdminInfoForm
          isProfileView
          cardLoading={cardLoading}
          editMode={editModeObj}
        />
        )}
        {(user?.role === UserRoles.doctor || user?.role === UserRoles.practiceManager) && (
        <DoctorInfoForm
          isProfileView
          cardLoading={cardLoading}
          editMode={editModeObj}
        />
        )}
        {(user?.role === UserRoles.labStaff || user?.role === UserRoles.labManager) && (
        <LabStaffInfoForm
          isProfileView
          cardLoading={cardLoading}
          editMode={editModeObj}
          labId={user?.labStaff?.lab?.id}
        />
        )}
      </Form>
      {user?.labStaff?.lab?.id && isRoleEnough('lab staff') && (
        <LabStaffServices
          form={servicesForm}
          labId={user?.labStaff?.lab?.id}
          editMode={{ onSubmit: handleServicesSubmit, onCancel: handleServicesCancel }}
          initialValues={servicesInitialValues}
        />
      )}
    </>
  );
};

ProfileForm.defaultProps = {
  loading: false,
  cardLoading: false,
};

export default connect((state: RootState) => ({}), {
  updateUser: actionUpdateUser,
  updateDoctor: actionUpdateDoctor,
  updateLabStaff: actionUpdateLabStaff,
})(ProfileForm);
