import { SelectProps } from 'antd/lib/select';
import { App, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import { usePracticeId, usePracticesGet, PracticesGetParams } from '../../../../hooks/practices';
import NotFoundContent from '../../NotFoundContent';
import { PracticeStatus } from '../../../../enums/practice';

interface SelectPractice extends SelectProps<string | string[], Option> {
  onlyStatus?: PracticeStatus;
  allowAll?: boolean;
}

const SelectPractice: React.FC<SelectPractice> = ({ onlyStatus, onClear, allowAll, ...props }): JSX.Element => {
  const { message } = App.useApp();
  const practicesGet = usePracticesGet();
  const practiceById = usePracticeId();

  const [options, setOptions] = useState<Option[]>([]);
  const [practicesName, setPracticesName] = useState<string>('');

  const fetch = () => {
    const params: PracticesGetParams = { page: 1, take: 100, orderBy: 'ASC', orderByColumn: 'name' };

    if (onlyStatus) {
      params.status = onlyStatus;
    }

    if (practicesName) {
      params.name = practicesName;
    }

    practicesGet.fetch(params);
  };

  useEffect(() => {
    if (practicesName && practicesName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }
    if (practicesName === '') {
      fetch();
    }

    return undefined;
  }, [practicesName]);

  useEffect(() => {
    if (practicesGet.data?.data.length) {
      setOptions(practicesGet.data.data.map(({ name, id }): Option => ({ label: name, value: id })));
    }
  }, [practicesGet.data]);

  useEffect(() => {
    if (options.length && props.value) {
      const current = options.find(({ value }) => value === props.value);

      if (!current) {
        practiceById.fetch(undefined, `${props.value}`)
          .then((res) => setOptions([
            ...options,
            { label: res?.name || '', value: props.value as string },
          ]));
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (practicesGet.error) {
      message.error(getMessageInError(practicesGet.error));
      practicesGet.clearError();
    }
  }, [practicesGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  const handleClear = () => {
    setPracticesName('');

    if (!onClear) return;

    onClear();
  };

  const optionsList = allowAll
    ? [{ label: 'All', value: '' }, ...options]
    : options;

  return (
    <Select
      loading={practicesGet.loading || practiceById.loading}
      options={optionsList}
      onSearch={(name) => setPracticesName(name)}
      showSearch
      allowClear
      onClear={handleClear}
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Practice not found." />}
      value={practicesGet.loading || practiceById.loading ? 'Loading...' : props.value}
      {...props}
    />
  );
};

SelectPractice.defaultProps = {
  onlyStatus: undefined,
  allowAll: false,
};

export default SelectPractice;
