import { Content } from 'antd/es/layout/layout';
import { useCallback, useState } from 'react';
import { ModalState, ModalTypes } from '../../../types';
import PriceLevelDrawer from './Drawer';
import TablePriceLevels from './Table';

const PriceLevels = () => {
  const [modal, setModal] = useState<ModalState | null>(null);

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <Content>
      <PriceLevelDrawer
        priceLevelId={modal?.id}
        drawerOpen={modal?.key === 'priceLevel'
          && (modal?.type === ModalTypes.create || modal?.type === ModalTypes.update)}
        setDrawerOpen={closeModal}
        onReload={modal?.onReload}
      />
      <TablePriceLevels openModal={openModal} />
    </Content>
  );
};

export default PriceLevels;
