import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { alertDelayError, name as appName } from '../../config';
import { PracticeStatus } from '../../enums/practice';
import { CreateAddress } from '../../hooks/addresses';
import { Practice } from '../../hooks/practices';
import { cancelableLocationSaga, defaultResponseProcessing, Error, FetchResponse } from './common';
import { Action } from '../index';
import { fetchSaga } from './auth';
import { createLocationSearch } from '../../utils';
import { PagingDataResponse } from '../../types';
import { CreateDoctor } from './doctor';

/**
 * Constants
 * */
export const moduleName = 'practice';
const prefix = `${appName}/${moduleName}`;

export const PRACTICE_CREATE = `${prefix}/PRACTICE_CREATE`;
export const PRACTICE_CREATE_START = `${prefix}/PRACTICE_CREATE_START`;
export const PRACTICE_CREATE_SUCCESS = `${prefix}/PRACTICE_CREATE_SUCCESS`;
export const PRACTICE_CREATE_ERROR = `${prefix}/PRACTICE_CREATE_ERROR`;
export const PRACTICE_CREATE_ERROR_RESET = `${prefix}/PRACTICE_CREATE_ERROR_RESET`;

export const PRACTICE_UPDATE = `${prefix}/PRACTICE_UPDATE`;
export const PRACTICE_UPDATE_START = `${prefix}/PRACTICE_UPDATE_START`;
export const PRACTICE_UPDATE_SUCCESS = `${prefix}/PRACTICE_UPDATE_SUCCESS`;
export const PRACTICE_UPDATE_ERROR = `${prefix}/PRACTICE_UPDATE_ERROR`;
export const PRACTICE_UPDATE_ERROR_RESET = `${prefix}/PRACTICE_UPDATE_ERROR_RESET`;

export const PRACTICE_REQUEST_START = `${prefix}/PRACTICE_REQUEST_START`;
export const PRACTICE_REQUEST_SUCCESS = `${prefix}/PRACTICE_REQUEST_SUCCESS`;
export const PRACTICE_REQUEST_ERROR = `${prefix}/PRACTICE_REQUEST_ERROR`;
export const PRACTICE_REQUEST_ERROR_RESET = `${prefix}/PRACTICE_REQUEST_ERROR_RESET`;

export const PRACTICE_ACCEPT = `${prefix}/PRACTICE_ACCEPT`;
export const PRACTICE_REJECT = `${prefix}/PRACTICE_REJECT`;

/**
 * Reducer
 * */

export interface TablePracticeRow {
  id: string | undefined;
  name: string | undefined;
  status: string | undefined;
  email: string | undefined;
  phone: string | undefined;
  address: string | undefined;
}

export interface State {
  init: boolean;

  data: PagingDataResponse<TablePracticeRow> | null;

  practice: Practice | null;
  loading: boolean;
  error: Error | null;
}

const defaultState: State = {
  init: false,

  data: null,

  practice: null,
  loading: false,
  error: null,
};

export default function reducer(
  state = defaultState,
  action: Action = { type: 'undefined' },
): State {
  const { type, payload } = action;

  switch (type) {
    case PRACTICE_CREATE_START:
      return { ...state, loading: true, error: null };
    case PRACTICE_CREATE_SUCCESS:
      return { ...state, loading: false };
    case PRACTICE_CREATE_ERROR:
      return { ...state, loading: false, error: payload };
    case PRACTICE_CREATE_ERROR_RESET:
      return { ...state, loading: false, error: null };

    case PRACTICE_UPDATE_START:
      return { ...state, loading: true, error: null };
    case PRACTICE_UPDATE_SUCCESS:
      return { ...state, loading: false, practice: payload };
    case PRACTICE_UPDATE_ERROR:
      return { ...state, loading: false, error: payload };
    case PRACTICE_UPDATE_ERROR_RESET:
      return { ...state, loading: false, error: null };

    case PRACTICE_REQUEST_START:
      return { ...state, loading: true, error: null };
    case PRACTICE_REQUEST_SUCCESS:
      return { ...state, loading: false, practice: payload };
    case PRACTICE_REQUEST_ERROR:
      return { ...state, loading: false, error: payload };
    case PRACTICE_REQUEST_ERROR_RESET:
      return { ...state, loading: false, error: null };

    default:
      return { ...state };
  }
}
/**
 * Action Creators
 * */

export interface PracticeCreatePayload {
  url?: string;
  authorization?: boolean;
  isAdmin?: boolean;
  name: string;
  status?: PracticeStatus;
  email: string;
  phone: string;
  note: string;
  accountNumber: string;
  doctors?: CreateDoctor[];
  addresses: CreateAddress[];
}

export interface PracticeUpdatePayload {
  id?: string | number;
  priceLevel?: string;
  secretKey?: string;
  name?: string;
  status?: PracticeStatus;
  email?: string;
  phone?: string;
  note?: string;
  accountNumber?: string;
  doctors?: CreateDoctor[];
  addresses?: CreateAddress[];

  cssColor?: string;
  file?: File; // To send file need to send data as 'form data'
}

export interface PracticeReject {
  id?: string | number;
  reason?: string;
}

export interface PracticeAccept {
  id?: string | number;
  note?: string;
  accountNumber?: string;
}

export const practiceCreate = (payload: PracticeCreatePayload): Action => ({
  type: PRACTICE_CREATE,
  payload,
});

export const practiceUpdate = (payload: PracticeUpdatePayload): Action => ({
  type: PRACTICE_UPDATE,
  payload,
});

export const practiceAccept = (payload: PracticeAccept): Action => ({
  type: PRACTICE_ACCEPT,
  payload,
});

export const practiceReject = (payload: PracticeReject): Action => ({
  type: PRACTICE_REJECT,
  payload,
});

/**
 * Helper functions
 */

/**
 * Sagas
 */

export function* practiceCreateSaga({ payload }: { payload: PracticeCreatePayload; }): Generator {
  yield put({
    type: PRACTICE_CREATE_START,
  });

  const { isAdmin, url, authorization, ...body } = payload;

  const requestUrl = isAdmin
    ? '/practices'
    : '/practices/create-user';

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}${url || requestUrl}`,
    {
      method: 'POST',
      body,
    },
    { authorization: !!authorization },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    PRACTICE_CREATE_SUCCESS,
    PRACTICE_CREATE_ERROR,
    false,
  );
}

export function* practiceUpdateSaga({ payload }: { payload: PracticeUpdatePayload; }): Generator {
  yield put({
    type: PRACTICE_UPDATE_START,
  });

  const { id, secretKey, ...data } = payload;

  const response = secretKey
    ? (yield call(
      fetchSaga,
      `${process.env.REACT_APP_API_URL}/practices/update-secret/${id}${createLocationSearch({ secretKey })}`,
      {
        method: 'PATCH',
        body: { ...data },
      },
      {},
    )) as FetchResponse
    : (yield call(
      fetchSaga,
      `${process.env.REACT_APP_API_URL}/practices/${id}`,
      {
        method: 'PATCH',
        body: { ...data },
      },
      {},
    )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    PRACTICE_UPDATE_SUCCESS,
    PRACTICE_UPDATE_ERROR,
    false,
    () => data,
  );
}

export function* practiceAcceptSaga({ payload }: { payload: PracticeAccept; }): Generator {
  yield put({
    type: PRACTICE_REQUEST_START,
  });

  const { id, ...body } = payload;

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/practices/accept/${id}`,
    {
      method: 'POST',
      body,
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    PRACTICE_REQUEST_SUCCESS,
    PRACTICE_REQUEST_ERROR,
    false,
  );
}

export function* practiceRejectSaga({ payload }: { payload: PracticeReject; }): Generator {
  yield put({
    type: PRACTICE_REQUEST_START,
  });

  const { id, ...body } = payload;

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/practices/reject/${id}`,
    {
      method: 'POST',
      body,
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    PRACTICE_REQUEST_SUCCESS,
    PRACTICE_REQUEST_ERROR,
    false,
  );
}

export function* saga(): Generator {
  yield takeLatest(
    PRACTICE_CREATE,
    cancelableLocationSaga.bind(
      null,
      practiceCreateSaga,
      PRACTICE_CREATE_ERROR,
      false,
    ),
  );
  yield takeLatest(PRACTICE_CREATE_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: PRACTICE_CREATE_ERROR_RESET,
    });
  });
  yield takeLatest(
    PRACTICE_UPDATE,
    cancelableLocationSaga.bind(
      null,
      practiceUpdateSaga,
      PRACTICE_UPDATE_ERROR,
      false,
    ),
  );
  yield takeLatest(PRACTICE_UPDATE_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: PRACTICE_UPDATE_ERROR_RESET,
    });
  });
  yield takeLatest(
    PRACTICE_ACCEPT,
    cancelableLocationSaga.bind(
      null,
      practiceAcceptSaga,
      PRACTICE_REQUEST_ERROR,
      false,
    ),
  );
  yield takeLatest(PRACTICE_REQUEST_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: PRACTICE_REQUEST_ERROR_RESET,
    });
  });
  yield takeLatest(
    PRACTICE_REJECT,
    cancelableLocationSaga.bind(
      null,
      practiceRejectSaga,
      PRACTICE_REQUEST_ERROR,
      false,
    ),
  );
  yield takeLatest(PRACTICE_REQUEST_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: PRACTICE_REQUEST_ERROR_RESET,
    });
  });
}
