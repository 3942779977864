import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { DefaultFetchError, FetchCreate } from '../hooks/fetch';
import { IGetRoomUsersParams, useGetRoomUsers } from '../hooks/chats';
import { User } from '../hooks/users';

interface IRoomUsers {
  [roomId: string]: User[];
}

interface ChatsContext {
  listUsers: User[];
  handleListUsers: (users: User[]) => void;
  roomUsers: IRoomUsers | null;
  handleRoomUsers: (roomData: IRoomUsers) => void;
  getRoomUsersInContext: FetchCreate<User[], DefaultFetchError, IGetRoomUsersParams> | null;
}

export const defaultValue: ChatsContext = {
  listUsers: [],
  handleListUsers: (): undefined => undefined,
  roomUsers: null,
  handleRoomUsers: (): undefined => undefined,
  getRoomUsersInContext: null,
};

export const ChatsContext = createContext<ChatsContext>(defaultValue);

const ChatsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const getRoomUsersInContext = useGetRoomUsers();
  const [listUsers, setListUsers] = useState<User[]>([]);

  const handleListUsers = (listUsers: User[]) => {
    setListUsers(listUsers);
  };

  /** Saves users from rooms. { ...[roomId: string]: User[] } */
  const [roomUsers, setRoomUsers] = useState<IRoomUsers | null>(null);

  const handleRoomUsers = (roomData: IRoomUsers) => {
    setRoomUsers((prevState) => ({
      ...prevState,
      ...roomData,
    }));
  };

  return (
    <ChatsContext.Provider value={{
      listUsers,
      handleListUsers,
      roomUsers,
      handleRoomUsers,
      getRoomUsersInContext }}
    >
      {children}
    </ChatsContext.Provider>
  );
};

export default ChatsProvider;

export const useContextChats = (): ChatsContext => useContext(ChatsContext);
