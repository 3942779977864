import { ControlFilled } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Notification } from '../../../../../hooks/notifications';
import { BuildingHouse } from '../../../Icon';
import styles from '../../index.module.scss';

interface NotificationRow {
  item: Notification;
}

const NotificationRow: React.FC<NotificationRow> = ({ item }) => {
  const getNotificationIcon = () => {
    switch (item.entity) {
      case 'practices':
        return <BuildingHouse />;
      case 'services':
        return <ControlFilled />;
      default:
        return <BuildingHouse />;
    }
  };

  return (
    <Link className={styles.notificationRow} to={item.link}>
      <div className={styles.notificationIconWrapper}>
        <div className={styles.notificationIcon}>
          {getNotificationIcon()}
        </div>
        {!item.status.saw && (
          <div className={styles.notificationBadgeWrapper}>
            <div className={styles.notificationBadge} />
          </div>
        )}

      </div>
      <div className={styles.notificationContent}>
        <Typography.Title level={5}>{item.title}</Typography.Title>
        <Typography dangerouslySetInnerHTML={{ __html: item.message }} />
      </div>
      <div className={styles.notificationTime}>
        {dayjs(item.createdAt).format('h:mm a')}
      </div>
    </Link>
  );
};

export default NotificationRow;
