import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useState } from 'react';
import PracticeRequest from './Request';

import TablePractices from './Table';
import { ModalState, ModalTypes } from '../../../types';

const Practices: React.FC = () => {
  const [modal, setModal] = useState<ModalState | null>(null);

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <Content>
      <TablePractices openModal={openModal} />
      <PracticeRequest
        practiceId={modal?.id}
        drawerOpen={modal?.type === ModalTypes.info && modal.key === 'practice' && !!modal?.id}
        setDrawerOpen={closeModal}
      />
    </Content>
  );
};

export default Practices;
