import { SelectProps } from 'antd/lib/select';
import { App, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import NotFoundContent from '../../NotFoundContent';
import { useAvailableReceiversGet } from '../../../../hooks/case/cases';

interface SelectPracticeRecipient extends SelectProps<string | string[], Option> {
  caseId: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggerReload?: any;
}

const SelectPracticeRecipient: React.FC<SelectPracticeRecipient> = ({
  caseId,
  triggerReload,
  onClear,
  ...props
}): React.JSX.Element => {
  const { message } = App.useApp();
  const availableRecipientsGet = useAvailableReceiversGet();

  const [options, setOptions] = useState<Option[]>([]);

  const fetch = () => {
    availableRecipientsGet.fetch(undefined, `${caseId}/available-recipients`);
  };

  useEffect(() => {
    if (caseId) {
      fetch();
    }

    return undefined;
  }, [caseId, triggerReload]);

  useEffect(() => {
    if (availableRecipientsGet.data?.length) {
      setOptions(availableRecipientsGet.data.map(({ user, id }): Option => (
        { label: `${user?.firstName} ${user?.lastName}`, value: id })));
    }
  }, [availableRecipientsGet.data]);

  useEffect(() => {
    if (availableRecipientsGet.error) {
      message.error(getMessageInError(availableRecipientsGet.error));
      availableRecipientsGet.clearError();
    }
  }, [availableRecipientsGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  const handleClear = () => {
    if (!onClear) return;

    onClear();
  };

  return (
    <Select
      loading={availableRecipientsGet.loading}
      options={options}
      allowClear
      onClear={handleClear}
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Doctor not found." />}
      {...props}
      value={availableRecipientsGet.loading ? 'Loading...' : props.value}
    />
  );
};

SelectPracticeRecipient.defaultProps = {
  triggerReload: '',
};

export default SelectPracticeRecipient;
