import {
  DefaultFetchError,
  FetchGet,
  useFetchGet,
} from '../fetch';
import { CaseService } from './cases';

export interface Calendar {
  id: number;
  note: string | null;
  manufacturer: string | null;
  family: string | null;
  shade: string | null;
  status: string;
  teeth: {
    services: [
      {
        id: string; // uuid
      }
    ];
    teeth: [
      {
        index: 7;
        isMissing: boolean;
        toBeExtracted: boolean;
        materials: string[]; // uuid
        isBridge: false;
      }
    ];
  };
  printingMaterialId: string; // uuid
  dueDate: string;
  totalPrice: 100;
  labTotalPrice: 0;
  practicePrice: {
    services: [
      {
        name: string;
        materials: [
          {
            name: string;
            price: 100;
          }
        ];
        totalPrice: 100;
        additionalOptions: [];
      }
    ];
    fullPrice: 100;
  };
  createdAt: string;
  updatedAt: string;
  caseServices: CaseService[];
  practice: {
    id: string;
    name: string;
    email: string;
    cssColor: string;
    accountNumber: number;
    phone: string;
    status: string;
    addresses: {
      id: string;
      street: string;
      apartment: string;
      cityString: string;
      name: string | null;
      code: number;
      status: string;
      sameAsBilling: boolean;
      asShipping: boolean;
    }[];
  };
  address: string | null;
  patient: {
    id: string;
    firstName: string;
    lastName: string;
    email: string | null;
    removed: boolean;
    phone: string | null;
    birthday: string | null;
    address: string | null;
    note: string | null;
    createdAt: string;
    updatedAt: string;
  };
  doctor: {
    id: string;
    note: string | null;
    nickname: string;
    specialty: string;
    position: string;
    removed: boolean;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      role: string;
      email: string;
      phone: string;
      status: string;
    };
  };
  chats: {
    id: string;
    type: string;
  }[];
}

export interface CalendarMonthGetParams {
  clients?: string[];
  limit?: number;
  dueDateMonth?: string;
  practice?: string;
  doctor?: string;
  status?: string;
}

export interface CalendarMonthResponse {
  [key: number]: Calendar[];
}

export function useCalendarMonthGet<D = CalendarMonthResponse, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, CalendarMonthGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, CalendarMonthGetParams, DD>('cases/full', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

interface CalendarDayGetParams extends CalendarMonthGetParams {
  day: string;
}

export function useCalendarDayGet<D = Calendar[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, CalendarDayGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, CalendarDayGetParams, DD>('cases/day', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}
