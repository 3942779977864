import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/Users/PageUserId';
import { displayName } from '../../../config';

function UserId(): React.JSX.Element {
  document.title = `${displayName}: User Info`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}

export default UserId;
