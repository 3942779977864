import React, { ReactNode } from 'react';
import { BrowserRouter, Navigate, Route as PublicRoute, RouteProps, Routes } from 'react-router-dom';

import { connect } from 'react-redux';
import PracticeCompleteRegistration from '../pages/practices/completeRegistration';
import CompleteDoctorRegistration from '../pages/doctors/completeRegistration';
import CompleteAdminRegistration from '../pages/users/completeRegistration';
import LabCompleteRegistration from '../pages/labs/completeRegistration';
import LabStaffCompleteRegistration from '../pages/labs/profile/completeRegistration';
import Main from './main';
import Auth from '../pages/auth';
import SignIn from '../pages/signIn';
import ForgotPassword from '../pages/forgotPassword';
import NewPassword from '../pages/newPassword';
import { RootState } from '../store/reducers';
import { moduleName, User } from '../store/ducks/auth';
import { UserRole } from '../enums/user';
import { getRedirectRoutePath } from '../utils/auth';

export interface Route {
  bind: RouteProps;
  name?: string;
  icon?: ReactNode;
  parent?: Route;
  hidden?: boolean;
  onClick?: () => void;
  children?: Route[];
  roleNeeded?: UserRole | UserRole[];
  // if roleNeeded used as array, then it always strictRoleComparison: true
  strictRoleComparison?: boolean;
  privateRoute?: boolean;
}

export function createChildrenRoutes(initialRoutes: Route[]): Route[] {
  const list: Route[] = [
    ...initialRoutes,
  ];

  function addChildren(route: Route, parent?: Route): void {
    let newRoute = route;

    if (parent) {
      newRoute = {
        ...route,
        parent,
        bind: {
          ...route.bind,
          path: `${parent.bind.path}${route.bind.path}`,
        },
      };
      list.push(newRoute);
    }

    if (newRoute.children) {
      newRoute.children.forEach((child) => addChildren(child, newRoute));
    }
  }

  list.forEach((route) => addChildren(route, undefined));

  return list;
}

const Route: React.FC<{ authorized: boolean; user: User | null; }> = ({ authorized, user }) => {
  const routes: Route[] = createChildrenRoutes([
    {
      bind: {
        path: '/sign-in',
        element: <SignIn />,
      },
      name: 'Sign In',
    },
    {
      bind: {
        path: '/complete-registration/practice/:token',
        element: <PracticeCompleteRegistration />,
      },
      name: 'Practice Complete Registration',
    },
    {
      bind: {
        path: '/complete-registration/lab/:token',
        element: <LabCompleteRegistration />,
      },
      name: 'Lab Complete Registration',
    },
    {
      bind: {
        path: '/complete-registration/practice-manager/:token',
        element: <CompleteDoctorRegistration />,
      },
      name: 'Complete registration',
    },
    {
      bind: {
        path: '/complete-registration/doctor/:token',
        element: <CompleteDoctorRegistration />,
      },
      name: 'Complete registration',
    },
    {
      bind: {
        path: '/complete-registration/lab-manager/:token',
        element: <LabStaffCompleteRegistration />,
      },
      name: 'Complete registration',
    },
    {
      bind: {
        path: '/complete-registration/lab-staff/:token',
        element: <LabStaffCompleteRegistration />,
      },
      name: 'Complete registration',
    },
    {
      bind: {
        path: '/complete-registration/admin/:token',
        element: <CompleteAdminRegistration />,
      },
      name: 'Complete registration',
    },
    {
      bind: {
        path: '/forgot-password',
        element: <ForgotPassword />,
      },
      name: 'Forgot Password',
    },
    {
      bind: {
        path: '/reset-password/:token',
        element: <NewPassword />,
      },
      name: 'New Password',
    },
    {
      privateRoute: true,
      bind: {
        path: '/*',
        element: <Main />,
      },
    },
  ]);

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ privateRoute, bind: { element, ...bind } }) => (
          <PublicRoute
            key={`${bind.path}`}
            {...bind}
            element={(privateRoute && authorized) || !privateRoute ? element : <Auth />}
          />
        ))}
        <PublicRoute path="*" element={<Navigate to={getRedirectRoutePath(user)} replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  user: state[moduleName].user,
}))(Route);
