import { FormInstance } from 'antd/es/form';
import React, { useEffect } from 'react';
import { Col, Form, Input, Row, Tooltip, Typography } from 'antd';
import { JsonResult } from '../../../../../types';

import ContentCard from '../../../../Common/ContentCard';
import { useContextCaseFlow } from '../../../../../context/caseFlow';
import SelectImplantManufacturer from '../../../../Common/Selects/SelectImplantManufacturer';

interface ImplantInfoForm {
  initialData?: JsonResult;
  disabled?: boolean;
  handleChange?: (field: JsonResult[]) => void;
  children?: React.ReactNode;
  form: FormInstance;
  selectTreatmentPlanForm?: FormInstance;
}

const ImplantInfoForm: React.FC<ImplantInfoForm> = (props) => {
  const {
    initialData,
    handleChange,
    children,
    form,
    selectTreatmentPlanForm,
  } = props;
  const { foundRestorativeService, implantInfo } = useContextCaseFlow();

  const servicesArrValues: string[] = Form.useWatch('services', selectTreatmentPlanForm);

  useEffect(() => {
    /** Used on case update. When we set data in context for this field, we updates form values */
    form.setFieldsValue(implantInfo);
  }, [implantInfo]);

  const isRestorativeSelected = foundRestorativeService ? servicesArrValues?.includes(foundRestorativeService.id || '')
    : false;

  return (
    <div>
      <Row justify="space-between" className="case-card-heading">
        <div className="typography-wrapper">
          <Typography.Title level={4} style={{ marginBottom: 0 }}>Implant Information</Typography.Title>
        </div>
      </Row>
      <ContentCard paddingSize="small">
        <Form
          form={form}
          layout="vertical"
          initialValues={initialData}
          onFieldsChange={handleChange}
        >
          <Row gutter={15}>
            <Col span={8}>
              <Form.Item
                name="manufacturer"
                label="Implant manufacturer"
              >
                <SelectImplantManufacturer />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="family"
                label="Implant family"
              >
                <Input placeholder="Type here" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Tooltip
                placement="topLeft"
                title={!isRestorativeSelected ? 'Restorative plan need to be selected to chose this option!' : ''}
              >
                <Form.Item
                  name="shade"
                  label="Teeth shade"
                >
                  <Input placeholder="Type here" disabled={!isRestorativeSelected} />
                </Form.Item>
              </Tooltip>
            </Col>
          </Row>
        </Form>
        {children}
      </ContentCard>
    </div>
  );
};

ImplantInfoForm.defaultProps = {
  initialData: {},
  disabled: false,
  handleChange: () => undefined,
  children: null,
  selectTreatmentPlanForm: undefined,
};

export default ImplantInfoForm;
