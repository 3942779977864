import React, { Key, useCallback, useEffect, useRef } from 'react';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { App, Button, FormInstance, Row, Space } from 'antd';
import { connect } from 'react-redux';
import Icon, { CheckOutlined } from '@ant-design/icons';
import { LabStatus, LabStatuses, LabStatusesOptions } from '../../../../enums/lab';
import { TableLabRow, useTableLabCalcCaseRow } from '../../../../hooks/labs';
import Status from '../../../Common/Status';
import { LabServiceTable } from '../../../../hooks/services';

import Table from '../../../Common/Table';
import { getMessageInError } from '../../../../hooks/fetch';
import { ModalState } from '../../../../types';
import { formatPhoneNumber, getSorterParams, queryFilterParams } from '../../../../utils';
import { Error } from '../../../../store/ducks/common';
import { RootState } from '../../../../store/reducers';
import { moduleName } from '../../../../store/ducks/cases';
import ExpandedRowItem from './ExpandedRowItem';
import { Close } from '../../../Common/Icon';
import { useContextCaseFlow } from '../../../../context/caseFlow';
import { IDueDateSize } from '../AssignLab';
import PriceBlock from '../../../Common/PriceBlock';

interface AssignLabTable {
  caseId: string;
  propPriceParentServices?: string[];
  dueDates?: IDueDateSize[];
  params?: Record<string, string>;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  onRowAction?: (lab: TableLabRow) => void;
  isActionDisabled?: boolean;
  // redux props \/
  loading: boolean;
  error: Error | null;
}

function AssignLabTable({
  params, selectedRows, onRowSelection, onRowAction, caseId, propPriceParentServices, loading, error, isActionDisabled,
  dueDates,
}: AssignLabTable): React.JSX.Element {
  const { message } = App.useApp();
  const { caseByIdInContext, calcPriceByLabService } = useContextCaseFlow();
  const formRef = useRef<FormInstance>();
  const labsGet = useTableLabCalcCaseRow();
  const actionRef = useRef<ActionType>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (caseId) {
      actionRef.current?.reload();
    }
  }, [caseId]);

  useEffect(() => {
    if (labsGet.error) {
      message.error(getMessageInError(labsGet.error));
      labsGet.clearError();
    }
  }, [labsGet.error]);

  useEffect(() => {
    /** reload table data, after create/update request finished without errors */
    if (!loading && !error) {
      actionRef.current?.reload();
    }
  }, [loading, params]);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableLabRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return labsGet.fetch({
      ...newParams,
      ...params,
      labServices: newParams.labServices?.split(','),
      services: params?.services ? params.services.split(',') : undefined,
      dueDates: params?.dueDates ? params.dueDates.split(',') : undefined,
      serviceAdditionalOptions: params?.serviceAdditionalOptions
        ? params.serviceAdditionalOptions.split(',')
        : undefined,
    }, caseId).then((data) => {
      if (data) {
        const { labs, total } = data;

        return ({ data: labs || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  };

  const columns: ProColumns<TableLabRow>[] = [
    {
      title: 'Lab Name',
      dataIndex: 'name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Contact Phone',
      dataIndex: 'phone',
      sorter: true,
      ellipsis: true,
      renderText: (phone) => formatPhoneNumber(phone, 'international'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      renderText: (status: LabStatus) => (
        <Status status={LabStatuses[status]} />
      ),
      valueEnum: LabStatusesOptions,
    },
    {
      title: 'Services',
      dataIndex: 'labServices',
      sorter: false,
      ellipsis: true,
      renderText: (services: LabServiceTable[]) => (
        <div>
          {services?.length ? (
            <Space direction="vertical">
              {services?.filter((labService) => (
                propPriceParentServices?.length
                  ? propPriceParentServices?.includes(labService?.service?.id || '') : true
              ))
                ?.sort((a, b) => a.service?.name < b.service?.name ? 1 : -1)
                ?.map((item: LabServiceTable) => (
                  <div key={item?.id}>
                    {item.service?.name}
                  </div>
                ))}
            </Space>
          ) : ' - '}
        </div>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      sorter: false,
      ellipsis: true,
      render: (_, row) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {row.labServices.length ? (
            <Space direction="vertical">
              {row.labServices?.filter((labService) => (
                propPriceParentServices?.length
                  ? propPriceParentServices?.includes(labService?.service?.id || '') : true
              ))
                ?.sort((a, b) => a.service?.name < b.service?.name ? 1 : -1)
                ?.map((labService) => (
                  <div key={labService.id}>
                    $
                    {labService.labMaterials?.[0]?.price || 0}
                    {/* show price not of first material, add find() */}
                  </div>
                ))}
            </Space>
          ) : ' - '}
        </div>
      ),
    },
    {
      title: 'Total Price',
      dataIndex: 'totalPrice',
      sorter: false,
      ellipsis: true,
      render: (_, row) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {row.labServices.length ? (
            <Space direction="vertical">
              {row.labServices?.filter((labService) => (
                propPriceParentServices?.length
                  ? propPriceParentServices?.includes(labService?.service?.id || '') : true
              ))
                ?.sort((a, b) => a.service?.name < b.service?.name ? 1 : -1)
                ?.map((labService) => {
                  const calcPrices = calcPriceByLabService(labService, dueDates || []);

                  return (
                    <div key={labService.id}>
                      <PriceBlock
                        price={Number(calcPrices?.labTotalPrice) || 0}
                        leadTimePrice={Number(calcPrices?.leadTimesPrice) || 0}
                        wrap
                        cutIfNeeded
                      />
                    </div>
                  );
                })}
            </Space>
          ) : ' - '}
        </div>
      ),
    },
    {
      title: '',
      align: 'center',
      hideInSetting: true,
      width: 178,
      renderText: (_, row) => {
        const caseService = propPriceParentServices?.length
          ? caseByIdInContext?.data?.caseServices?.find((caseService) => (
            propPriceParentServices?.includes(caseService?.labService?.service?.id || '')))
          : caseByIdInContext?.data?.caseServices?.find((caseService) => (
            caseService?.labService?.lab?.id === row.id));

        return (
          caseService && caseService?.status !== 'declined' ? (
            <Button type="default" disabled icon={<Icon component={Close} />}>
              Proposal Sent
            </Button>
          ) : (
            <Row justify="center">
              {caseService?.status === 'declined' && caseService?.labService?.lab?.id === row.id && (
                <Status status="declined" />
              )}
              <Button
                type="primary"
                onClick={() => onRowAction?.(row)}
                icon={<CheckOutlined />}
                disabled={isActionDisabled}
              >
                Send Proposal
              </Button>
            </Row>
          )
        );
      },
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  const expandedRowRender = (row: TableLabRow | undefined) => (
    <ExpandedRowItem row={row} />
  );

  return (
    <Table<TableLabRow>
      formRef={formRef}
      columns={columns}
      loading={loading || undefined}
      request={tableRequest}
      actionRef={actionRef}
      rowSelection={!!onRowSelection && rowSelection}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
      expandable={{ expandedRowRender }}
      search={false}
    />
  );
}

AssignLabTable.defaultProps = {
  params: {},
  isActionDisabled: false,
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
  onRowAction: () => undefined,
  propPriceParentServices: [],
  dueDates: [],
};

export default connect((state: RootState) => ({
  loading: state[moduleName].caseLoading,
  error: state[moduleName].caseError,
}))(AssignLabTable);
