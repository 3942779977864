import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { App, FormInstance } from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import clsx from 'clsx';
import React, { ReactNode, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { LabStatus } from '../../../../enums/lab';
import { Report, useTablePracticeInvoiceReport } from '../../../../hooks/reports';
import { JsonResult } from '../../../../types';
import { getSorterParams, queryFilterParams } from '../../../../utils';
import ContentCard from '../../../Common/ContentCard';
import Status from '../../../Common/Status';
import Table from '../../../Common/Table';
import { getMessageInError } from '../../../../hooks/fetch';
import PriceBlock from '../../../Common/PriceBlock';

interface PracticeInvoice {
  params: JsonResult | null;
}

const serviceLineHeight = 25;

const PracticeInvoice: React.FC<PracticeInvoice> = (props) => {
  const { message } = App.useApp();
  const { params } = props;
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();
  const generatePracticeInvoiceReport = useTablePracticeInvoiceReport();

  useEffect(() => {
    if (!params) return;

    actionRef.current?.reload();
  }, [params]);

  const getServiceOptionsCount = (service: JsonResult) => (
    ((service?.materials?.length || 0) + (service?.additionalOptions?.length || 0)) || 1);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<Report>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    return generatePracticeInvoiceReport.fetch({
      ...newParams,
      ...params,
      lab: undefined,
    }).then((data) => {
      if (data) {
        const { reports, total } = data;

        return ({ data: reports || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  useEffect(() => {
    if (generatePracticeInvoiceReport.error) {
      message.error(getMessageInError(generatePracticeInvoiceReport.error));
      generatePracticeInvoiceReport.clearError();
    }
  }, [generatePracticeInvoiceReport.error]);

  const columns: ProColumns<Report>[] = [
    ...(!params?.doctor
      ? [{
        title: 'Doctor',
        sorter: false,
        ellipsis: true,
        render: (_: ReactNode, row: Report) => `${row.doctor?.user?.firstName} ${row.doctor?.user?.lastName}`,
      }]
      : []),
    {
      title: 'Case',
      sorter: false,
      dataIndex: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: false,
      renderText: (status: LabStatus) => (
        <Status status={status} />
      ),
    },
    {
      title: 'Services',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.practicePrice?.services?.length
        ? row?.practicePrice?.services?.map((service: JsonResult) => (
          <div
            key={`${service?.name}-service`}
            style={{ height: serviceLineHeight * getServiceOptionsCount(service), marginBottom: 10 }}
          >
            {service?.name}
          </div>
        ))
        : '-',
    },
    {
      title: 'Options',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.practicePrice?.services?.length
        ? row?.practicePrice?.services?.map((service: JsonResult) => (
          <div key={`${service?.name}-options`} style={{ marginBottom: 10 }}>
            {service?.materials?.map((material: JsonResult) => (
              <div key={`${material?.name}-material`} style={{ height: serviceLineHeight }}>
                {material?.name}
              </div>
            ))}
            {service?.additionalOptions?.map((additionalOption: JsonResult) => (
              <div key={`${additionalOption?.name}-additionalOption`} style={{ height: serviceLineHeight }}>
                {additionalOption?.name}
              </div>
            ))}
          </div>
        ))
        : '-',
    },
    {
      title: 'Practices total price',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.practicePrice?.services?.length
        ? row?.practicePrice?.services?.map((service: JsonResult) => (
          <div key={`${service?.name}-options-price`} style={{ marginBottom: 10 }}>
            {service?.materials?.map((material: JsonResult) => (
              <div key={`${material?.name}-material-price`} style={{ height: serviceLineHeight }}>
                $
                {material?.price}
              </div>
            ))}
            {service?.additionalOptions?.map((additionalOption: JsonResult) => (
              <div key={`${additionalOption?.name}-additionalOption-price`} style={{ height: serviceLineHeight }}>
                $
                {additionalOption?.price}
              </div>
            ))}
          </div>
        ))
        : '-',
    },
    {
      title: 'Total per service',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.practicePrice?.services?.length
        ? row?.practicePrice?.services?.map((service: JsonResult) => (
          <div
            key={`${service?.name}-price`}
            style={{ height: serviceLineHeight * getServiceOptionsCount(service), marginBottom: 10 }}
          >
            $
            {service?.totalPrice}
          </div>
        ))
        : '-',
    },
    {
      title: 'Total per case',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.practicePrice?.fullPrice ? (
        <PriceBlock price={row?.practicePrice?.fullPrice || 0} leadTimePrice={row?.leadTimePrice || 0} wrap />
      ) : '-',
    },
    {
      title: 'Due date',
      sorter: false,
      ellipsis: true,
      render: (_, row) => row?.dueDate ? dayjs(row.dueDate).format('MMM DD, YYYY') : '-',
    },
  ];

  return (
    <ContentCard paddingSize="small">
      <Table<Report>
        formRef={formRef}
        columns={columns}
        className={clsx('simpleList', 'listWithoutFilters')}
        columnsState={{ persistenceKey: 'pro-table-practice-report', persistenceType: 'localStorage' }}
        request={params ? tableRequest : undefined}
        actionRef={actionRef}
        form={{ ignoreRules: false }}
        rowClassName="cursor-pointer"
        showSorterTooltip={false}
      />
    </ContentCard>
  );
};

export default PracticeInvoice;
