import React from 'react';
import Svg, { AnyProps } from '../index';

/* eslint-disable max-len */

const getTransformForTooth = (row?: 'top' | 'bottom', is24n23Tooth?: boolean, is26n25Tooth?: boolean): string => {
  if (row === 'top') return 'translate(50%, 0)';
  if (row === 'bottom') {
    if (is24n23Tooth) {
      return 'translate(25%, -100%)';
    }
    if (is26n25Tooth) {
      return 'translate(75%, -100%)';
    }

    return 'translate(50%, -100%)';
  }

  return 'translate(50%, 0)';
};

export interface CircleSvg extends AnyProps {
  row?: 'top' | 'bottom';
  is24n23Tooth?: boolean;
  is26n25Tooth?: boolean;
}

export const CircleSvg = ({ row, is24n23Tooth, is26n25Tooth, ...props }: CircleSvg): JSX.Element => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="#FFFFFF"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      position: 'absolute',
      top: '50%',
      right: '50%',
      // transform: props?.row === 'bottom' ? 'translate(50%, -100%)' : 'translate(50%, 0)',
      transform: getTransformForTooth(row, is24n23Tooth, is26n25Tooth),
    }}
    {...props}
  >
    <circle
      opacity="0.7"
      cx="6.5"
      cy="6.5"
      r="6.5"
    />
  </svg>
);

CircleSvg.defaultProps = {
  row: 'top',
  is24n23Tooth: false,
  is26n25Tooth: false,
};

// size before synchronyze row 1 and 2. width: 37 height: 30
export const Tooth1 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="43" height="32" viewBox="-3 0 44 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M36.303 18.8737C36.303 22.5168 34.865 27.6906 30.1913 28.9375C19.8553 30.0062 20.5743 25.286 16.7994 25.286C15.1816 25.286 12.8448 31.0749 6.10388 27.1116C2.10218 24.7587 0.0820312 20.1778 0.0820312 15.5785C0.0820312 7.11849 8.05893 0.705566 17.7881 0.705566C26.9557 0.705566 36.303 9.43341 36.303 18.8737Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 39 30
export const Tooth2 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="42" height="32" viewBox="-1.5 0 43 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M38.2531 19.2084C38.2531 22.8864 34.7051 28.1787 29.7788 29.4374C18.8841 30.5164 20.7341 25.2914 16.7552 25.2914C15.3279 25.2914 11.1354 28.8789 6.14002 25.6821C2.5719 23.3987 0.0742188 20.525 0.0742188 15.8816C0.0742188 7.34052 5.5156 -3.09958 21.5721 1.22667C35.3986 0.776024 38.2531 9.67762 38.2531 19.2084Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 42 31
export const Tooth3 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="47" height="33" viewBox="-2.5 0 48 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M41.7853 16.687C42.5135 20.9031 41.2477 26.5546 35.5059 29.3354C24.8925 32.2583 24.9167 25.8735 20.9737 26.5546C19.5594 26.7988 18.6414 31.3089 9.85031 29.8736C5.65845 29.1892 1.42476 25.5678 0.789157 21.8899C0.162368 14.1753 1.5981 -2.77895 21.582 1.49262C36.9418 -3.676 40.1708 7.33962 41.7853 16.687Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 29 36
export const Tooth4 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="29" height="38" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M28.8902 23.2555C28.8902 30.0916 22.9307 35.0951 16.0268 35.0951C9.12295 35.0951 0.990234 31.4177 0.990234 23.2555C0.990234 10.0705 6.85875 0.832031 13.7626 0.832031C23.3645 0.832031 28.8902 16.4195 28.8902 23.2555Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 30 36
export const Tooth5 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="30" height="38" viewBox="-1 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M29.2192 22.4276C29.9402 29.2329 20.3869 35.5846 15.2497 35.5846C10.1125 35.5846 0.378906 30.1403 0.378906 21.7017C0.378906 8.36326 7.11894 0.832031 13.9879 0.832031C23.5413 0.832031 29.2192 15.512 29.2192 22.4276Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 32 43
export const Tooth6 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="32" height="45" viewBox="-1 0 33 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M31.0087 27.2359C31.0087 34.8906 24.4977 42.0949 18.4388 42.0949C12.38 42.0949 10.5752 39.8084 7.04459 37.5021C3.36599 35.0992 0.171875 34.1701 0.171875 28.2265C0.171875 13.5476 5.14555 0.489746 14.7312 0.489746C26.035 0.489746 31.0087 19.5813 31.0087 27.2359Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 27 37
export const Tooth7 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="27" height="39" viewBox="0 0 28 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M26.3776 27.0886C26.3776 34.7197 24.0462 36.605 18.0383 36.605C12.0304 36.605 9.25057 36.4255 5.75343 35.7073C2.10575 33.3117 0.61219 33.0121 0.462877 27.0886C0.0147954 9.31264 5.12643 0.873535 12.12 0.873535C23.0598 0.873535 26.3776 19.4575 26.3776 27.0886Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 33 41
export const Tooth8 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="33" height="43" viewBox="-1 0 34 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M31.9624 31.728C33.2116 40.2667 29.3333 40.6262 21.4344 40.6262C13.5355 40.6262 10.9053 40.6262 6.2643 39.9072C1.87305 37.1835 0.587504 36.3613 0.408061 29.6264C-0.156192 8.44882 7.03582 0 15.4551 0C28.662 0 30.7132 23.1893 31.9624 31.728Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 33 41
export const Tooth9 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="33" height="43" viewBox="0 0 34 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.854427 31.728C-0.414005 40.2667 3.52396 40.6262 11.5444 40.6262C19.5648 40.6262 22.2354 40.6262 26.9479 39.9072C31.4067 37.1835 32.712 36.3613 32.8942 29.6264C33.4671 8.44882 26.1645 0 17.6157 0C4.2056 0 2.12286 23.1893 0.854427 31.728Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 27 37
export const Tooth10 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="27" height="39" viewBox="0 0 28 37" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.925137 26.5779C0.925137 34.209 3.25656 36.0943 9.26447 36.0943C15.2724 36.0943 18.0522 35.9147 21.5493 35.1965C25.197 32.801 26.6905 32.5013 26.8399 26.5779C27.2879 8.80189 22.1763 0.362793 15.1827 0.362793C4.24293 0.362793 0.925137 18.9468 0.925137 26.5779Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 31 43
export const Tooth11 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="31" height="45" viewBox="0 0 32 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.291367 27.2359C0.291367 34.8906 6.69901 42.0949 12.6617 42.0949C18.6243 42.0949 20.4005 39.8084 23.875 37.5021C27.4953 35.0992 30.6387 34.1701 30.6387 28.2265C30.6387 13.5476 25.7439 0.489746 16.3105 0.489746C5.18609 0.489746 0.291367 19.5813 0.291367 27.2359Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 30 35
export const Tooth12 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="30" height="37" viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.593311 21.6127C-0.127695 28.3222 9.42565 34.5844 14.5628 34.5844C19.7 34.5844 29.4336 29.2168 29.4336 20.897C29.4336 7.74645 22.6936 0.321289 15.8246 0.321289C6.27124 0.321289 0.593311 14.7945 0.593311 21.6127Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 28 36
export const Tooth13 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="30" height="38" viewBox="-1 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.413748 23.5759C0.413748 30.5096 6.26864 35.5846 13.0514 35.5846C19.8342 35.5846 27.8242 31.8546 27.8242 23.5759C27.8242 10.2025 22.0587 0.832031 15.2759 0.832031C5.84244 0.832031 0.413748 16.6421 0.413748 23.5759Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 42 31
export const Tooth14 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="47" height="33" viewBox="-2.5 0 48 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.208873 16.8662C-0.519338 21.0823 0.746467 26.7338 6.48822 29.5146C17.1016 32.4375 17.0775 26.0527 21.0204 26.7338C22.4348 26.978 23.3527 31.4881 32.1438 30.0528C36.3357 29.3684 40.5694 25.747 41.205 22.0691C41.8318 14.3545 40.396 -2.59975 20.4122 1.67182C5.0523 -3.4968 1.82335 7.51882 0.208873 16.8662Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 40 30
export const Tooth15 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="42" height="32" viewBox="-1 0 43 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.562126 19.2084C0.562126 22.8864 4.15559 28.1787 9.14505 29.4374C20.1794 30.5164 18.3057 25.2914 22.3357 25.2914C23.7812 25.2914 28.0275 28.8789 33.0869 25.6821C36.7008 23.3987 39.2305 20.525 39.2305 15.8816C39.2305 7.34052 33.7193 -3.09958 17.4569 1.22667C3.45322 0.776024 0.562126 9.67762 0.562126 19.2084Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 37 30
export const Tooth16 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="43" height="32" viewBox="-3 0 44 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.509487 19.187C0.509487 22.8929 1.94754 28.1559 6.62122 29.4242C16.9572 30.5114 16.2382 25.7098 20.0131 25.7098C21.6309 25.7098 23.9677 31.5985 30.7086 27.5668C34.7103 25.1734 36.7305 20.5136 36.7305 15.835C36.7305 7.22906 28.7536 0.705566 19.0244 0.705566C9.85684 0.705566 0.509487 9.58389 0.509487 19.187Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// line two

// 43 26
export const Tooth17 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="43" height="28" viewBox="0 0 44 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M41.9633 13.4079C41.9633 9.60459 43.2568 1.50219 33.2988 3.54953C26.1752 -0.271287 21.7797 1.83594 18.5503 3.18151C14.6023 1.56682 12.1172 0.0630927 6.52887 0.759485C2.3183 1.28419 0.149891 5.12344 0.15921 8.4741C0.158685 11.3504 0.784753 16.727 4.28324 22.7372C11.8181 21.4813 15.5861 22.6475 19.34 23.9348C27.0884 26.3848 33.9744 26.0637 37.5678 23.9348C41.2025 21.7814 41.9633 17.9663 41.9633 13.4079Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 42 29
export const Tooth18 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="42" height="31" viewBox="0 0 43 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.581562 11.2583C0.581562 7.5608 2.22504 2.19937 7.55336 0.933912C16.3128 0.0282631 17.6535 6.09612 20.6031 6.09612C22.1226 6.09612 29.9481 0.165407 35.3511 3.37916C39.2104 5.6747 41.6973 9.12617 41.6973 13.7942C41.6973 22.3806 35.3511 33.175 19.9774 26.8355C4.15684 30.0958 0.581562 23.6657 0.581562 11.2583Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 47 31
export const Tooth19 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="47" height="33" viewBox="0 0 48 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M46.5046 15.7424C47.2328 11.5262 47.6834 2.13167 36.6497 4.46399C28.666 -1.99476 21.5793 2.04196 18.6181 3.92576C14.9411 1.86255 13.5956 -0.552143 7.40496 0.247865C2.74059 0.850639 0.408464 5.09193 0.408618 8.85952C0.408488 17.2021 0.409221 32.0931 21.1298 27.4284C29.7029 30.1598 37.5081 31.2685 41.4938 28.8637C45.5254 26.4313 45.6927 20.4428 46.5046 15.7424Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 30 35
export const Tooth20 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="30" height="37" viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.16108 16.4862C0.588383 12.3767 0.435951 7.63692 6.59276 4.42039C10.0333 2.09733 12.8354 0.310368 14.7118 0.310368C18.4385 0.310368 21.1699 3.5314 24.6104 5.16235C28.3227 6.92213 29.9976 10.2878 29.759 15.0566C29.227 25.6878 24.0836 34.084 15.7399 34.084C6.23301 34.084 2.31457 24.7633 1.16108 16.4862Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 29 33
export const Tooth21 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="30" height="35" viewBox="-0.5 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.708574 14.8295C0.15991 10.9582 2.1412 7.12199 6.26002 4.88145C9.18739 2.19403 11.8046 0.31074 13.6023 0.31074C17.1725 0.31074 19.3078 2.62932 22.198 5.41918C24.9738 7.30124 28.2466 9.70975 28.018 14.2021C27.5084 24.217 22.5807 32.1265 14.5872 32.1265C4.55341 32.1265 1.81366 22.6268 0.708574 14.8295Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 29 40
export const Tooth22 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="31" height="42" viewBox="-1 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.507915 16.805C-0.652281 7.00555 9.88045 0.352718 13.6288 0.352718C17.3771 0.352718 19.8748 2.78011 22.9092 6.28633C24.7776 8.444 29.0389 12.3279 28.7989 17.9737C28.2638 30.5602 23.8908 40 15.4986 40C4.96446 40 1.66811 26.6044 0.507915 16.805Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 21 36
export const Tooth23 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="27" height="38" viewBox="-1 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.0495701 9.05022C0.0495701 2.21535 0.980923 0.883888 6.12713 0.883888C11.2733 0.883888 15.9589 1.26027 16.763 1.41648C17.5671 1.57268 20.8555 2.97924 20.5925 8.47543C19.8774 23.4184 19.8016 32.7503 10.3278 35.147C1.21146 35.147 0.0495701 15.8851 0.0495701 9.05022Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 22 34
export const Tooth24 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="33" height="37" viewBox="-1 0 33 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.771498 7.85711C-0.0475674 1.03975 2.45267 0.394295 7.6317 0.394295C12.8107 0.394295 16.6233 0.600033 17.4325 0.752705C18.2418 0.90538 21.9171 1.92174 21.6524 7.29372C20.9328 21.899 18.5952 33.189 11.5948 33.189C1.24143 33.189 1.59056 14.6745 0.771498 7.85711Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 22 35
export const Tooth25 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="33" height="37" viewBox="-10 0 33 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M21.041 8.47902C21.8601 1.55991 19.3598 0.904823 14.1808 0.904823C9.00177 0.904823 5.18922 1.11363 4.37998 1.26859C3.57074 1.42354 -0.1046 2.45507 0.160091 7.90722C0.879729 22.7305 3.21732 34.189 10.2177 34.189C20.5711 34.189 20.2219 15.3981 21.041 8.47902Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 21 36
export const Tooth26 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="27" height="38" viewBox="-5 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M20.7629 9.05022C20.7629 2.21535 19.8316 0.883888 14.6854 0.883888C9.53916 0.883888 4.85364 1.26027 4.04953 1.41648C3.24542 1.57268 -0.0430048 2.97924 0.220009 8.47543C0.935087 23.4184 1.01086 32.7503 10.4847 35.147C19.601 35.147 20.7629 15.8851 20.7629 9.05022Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 29 40
export const Tooth27 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="32" height="42" viewBox="-1.5 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M28.3046 16.805C29.4648 7.00555 18.9321 0.352718 15.1837 0.352718C11.4354 0.352718 8.93768 2.78011 5.90332 6.28633C4.03485 8.444 -0.226355 12.3279 0.0136379 17.9737C0.548658 30.5602 4.9217 40 13.3139 40C23.848 40 27.1444 26.6044 28.3046 16.805Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 29 33
export const Tooth28 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="30" height="35" viewBox="-0.5 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M28.1039 14.8295C28.6526 10.9582 26.6713 7.12199 22.5525 4.88145C19.6251 2.19403 17.0079 0.31074 15.2102 0.31074C11.64 0.31074 9.50474 2.62932 6.61451 5.41918C3.83874 7.30124 0.565946 9.70975 0.794539 14.2021C1.30415 24.217 6.23176 32.1265 14.2253 32.1265C24.2591 32.1265 26.9988 22.6268 28.1039 14.8295Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 29 35
export const Tooth29 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="29" height="37" viewBox="-1 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M28.6514 16.4862C29.2241 12.3767 29.3765 7.63692 23.2197 4.42039C19.7792 2.09733 16.9771 0.310368 15.1007 0.310368C11.374 0.310368 8.64258 3.5314 5.20208 5.16235C1.48981 6.92213 -0.185071 10.2878 0.0535351 15.0566C0.585463 25.6878 5.72891 34.084 14.0726 34.084C23.5795 34.084 27.4979 24.7633 28.6514 16.4862Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 47 31
export const Tooth30 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="47" height="33" viewBox="0 0 48 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.432898 15.6765C-0.29531 11.4603 -0.745922 2.06575 10.2878 4.39808C18.2715 -2.06068 25.3582 1.97604 28.3194 3.85985C31.9964 1.79663 33.3419 -0.618061 39.5325 0.181947C44.1969 0.784721 46.529 5.02601 46.5289 8.79361C46.529 17.1362 46.5283 32.0272 25.8077 27.3625C17.2346 30.0939 9.42942 31.2025 5.44371 28.7978C1.4121 26.3654 1.24476 20.3769 0.432898 15.6765Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 42 29
export const Tooth31 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="42" height="31" viewBox="-1 0 43 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M41.2309 11.2583C41.2309 7.5608 39.5875 2.19937 34.2591 0.933912C25.4997 0.0282631 24.159 6.09612 21.2094 6.09612C19.6899 6.09612 11.8644 0.165407 6.46135 3.37916C2.60206 5.6747 0.115234 9.12617 0.115234 13.7942C0.115234 22.3806 6.46135 33.175 21.8351 26.8355C37.6557 30.0958 41.2309 23.6657 41.2309 11.2583Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

// 43 26
export const Tooth32 = ({ ...props }: AnyProps): JSX.Element => (
  <Svg width="43" height="28" viewBox="0 0 44 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0.972245 13.342C0.972246 9.53867 -0.321289 1.43627 9.63672 3.48361C16.7603 -0.337205 21.1558 1.77002 24.3852 3.11559C28.3332 1.50091 30.8184 -0.00282523 36.4067 0.693567C40.6172 1.21827 42.7857 5.05752 42.7763 8.40818C42.7769 11.2845 42.1508 16.6611 38.6523 22.6713C31.1174 21.4154 27.3494 22.5816 23.5956 23.8689C15.8472 26.3189 8.96111 25.9978 5.36779 23.8689C1.73307 21.7155 0.972246 17.9004 0.972245 13.342Z" fill="transparent" stroke="white" strokeWidth="2px" />
  </Svg>
);

export const teethIconsRow1: Record<string, React.FC> = {
  Tooth1,
  Tooth2,
  Tooth3,
  Tooth4,
  Tooth5,
  Tooth6,
  Tooth7,
  Tooth8,
  Tooth9,
  Tooth10,
  Tooth11,
  Tooth12,
  Tooth13,
  Tooth14,
  Tooth15,
  Tooth16,
};

export const teethIconsRow2: Record<string, React.FC> = {
  Tooth32,
  Tooth31,
  Tooth30,
  Tooth29,
  Tooth28,
  Tooth27,
  Tooth26,
  Tooth25,
  Tooth24,
  Tooth23,
  Tooth22,
  Tooth21,
  Tooth20,
  Tooth19,
  Tooth18,
  Tooth17,
};
