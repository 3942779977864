import { App, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLabId } from '../../../../hooks/labs';
import { LabService, useServicesGet } from '../../../../hooks/services';
import { UpdateLab } from '../../../../store/ducks/lab';
import {
  prepareLabServicesBody,
  prepareLabServicesInitialDataFromServices,
} from '../../../../utils/prepareData';

import DrawerModal, { IFormChangedProps } from '../../../Common/DrawerModal';
import Loading from '../../../Common/Loading';
import LabServicesForm from '../Forms/LabServicesForm';
import { Error } from '../../../../store/ducks/common';

interface LabServices {
  labId?: string | number;
  isOpen: boolean;
  loading?: boolean;
  error?: Error | null;
  close: () => void;
  handleSubmit: (body: UpdateLab) => void;
}

const LabServices = (props: LabServices) => {
  const { message } = App.useApp();
  const { labId, isOpen, close, handleSubmit, loading, error } = props;
  const servicesGet = useServicesGet();
  const labById = useLabId();
  const servicesState = useState<LabService[] | undefined>();
  const [services, setServices] = servicesState;

  useEffect(() => {
    if (!isOpen) return;

    if (labId) {
      labById.fetch(undefined, labId);
    }
    if (!services) {
      servicesGet.fetch({ status: 'active' });
    }
  }, [isOpen, labId]);

  useEffect(() => {
    if (!isOpen) return;
    if (!servicesGet.data?.data || !labById.data) return;

    setServices(prepareLabServicesInitialDataFromServices(servicesGet.data?.data, labById.data?.labServices));
  }, [labById.data, servicesGet.data]);

  const handleClose = () => {
    close();
  };

  const onSubmit = (servicesBody: LabService[]) => {
    handleSubmit({ labServices: prepareLabServicesBody(servicesBody) });
  };

  useEffect(() => {
    if (isOpen && !loading && !error) {
      message.success('Services updated!');
    }
  }, [loading]);

  const [isObjEqualFormProps, setIsObjEqualFormProps] = useState<IFormChangedProps>();

  return (
    <div>
      <DrawerModal
        title="Services"
        open={isOpen}
        onClose={handleClose}
        isFormChanged={false}
        checkIsObjEqual
        isObjEqualFormProps={isObjEqualFormProps}
        destroyOnClose
        afterOpenChange={(open) => !open && setIsObjEqualFormProps(undefined)}
      >
        <Row justify="center">
          <Col span={24} md={22} lg={21} xxl={17}>
            <Loading absolute visible={servicesGet.loading || labById.loading || !!loading} />
            <LabServicesForm
              servicesState={servicesState}
              editMode={{
                onSubmit,
              }}
              setIsObjEqualFormProps={setIsObjEqualFormProps}
            />
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

LabServices.defaultProps = {
  labId: undefined,
  labServices: undefined,
  loading: false,
  error: null,
};

export default LabServices;
