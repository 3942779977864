import { Content } from 'antd/es/layout/layout';
import React, { useState } from 'react';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import ChatsList from './ChatsList';
import MainContent from './Main';

import styles from './index.module.scss';

const Chat: React.FC = () => {
  const [isChatsListOpen, setIsChatsListOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleToggleListOpen = () => {
    setIsChatsListOpen((prevState) => !prevState);
  };

  const handleChangeActiveChat = (chatId: string) => {
    setSearchParams({
      id: chatId,
    });
  };

  return (
    <Content>
      <div className={clsx(styles.contentWrapper, { [styles.collapsed]: !isChatsListOpen })}>
        <MainContent isChatsListOpen={isChatsListOpen} toggleChatsListOpen={handleToggleListOpen} />
        <ChatsList
          toggleChatsListOpen={handleToggleListOpen}
          changeActiveChat={handleChangeActiveChat}
          activeId={searchParams.get('id') || undefined}
        />
      </div>
    </Content>
  );
};

export default Chat;
