import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Typography } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import ContentCard from '../../../../../Common/ContentCard';
import { PropEditMode } from '../../../../../Common/ControlsEditMode';
import { required } from '../../../../../../utils/inputRules';
import SelectPatient from '../../../../../Common/Selects/SelectPatient';
import PatientCreateButton from '../../../../Patients/CreateButton';
import SelectDoctor from '../../../../../Common/Selects/SelectDoctor';

interface PatientFormItems {
  isManager?: boolean;
  loading?: boolean;
  disabled?: boolean;
  editMode?: PropEditMode;
  requiredFieldsType?: 'create' | 'update';
  showTitle?: boolean;
  patientCreateResponseId?: string;
}

const PatientFormItems: React.FC<PatientFormItems> = (props) => {
  const { isManager, loading, disabled, showTitle, patientCreateResponseId } = props;
  const form = useFormInstance();

  const [doctor, setDoctor] = useState<string>('');

  const doctorValue = Form.useWatch('doctor', form);

  useEffect(() => {
    setDoctor(doctorValue);
  }, [doctorValue]);

  const handleClear = (field: string) => {
    if (field === 'doctor') {
      form?.setFields([{ name: 'patient', value: null }]);
    }
  };

  return (
    <div>
      {showTitle && (<Typography.Title level={4}>Patient Information</Typography.Title>)}
      <ContentCard loading={loading}>
        <Row gutter={15}>
          {isManager && (
            <Col span={24}>
              <Form.Item
                label="Select Doctor"
                name="doctor"
                rules={required}
              >
                <SelectDoctor
                  disabled={disabled}
                  onChange={(value) => {
                    setDoctor(value as string);
                    handleClear('doctor');
                  }}
                  onClear={() => handleClear('doctor')}
                  onlyStatus="active"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              label="Select Patient"
              name="patient"
              rules={required}
            >
              <SelectPatient
                triggerFetchDependency={patientCreateResponseId}
                disabled={disabled || (isManager && !(doctor || form?.getFieldValue('doctor')))}
                onlyActive
                doctor={isManager ? doctor || form?.getFieldValue('doctor') : undefined}
                requestOnlyWithDoctor={isManager}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <PatientCreateButton
                key="addButton"
                id="addPatientButton"
                title="Case Creation"
                icon={<PlusOutlined />}
                type="default"
              >
                Add New Patient
              </PatientCreateButton>
            </Form.Item>
          </Col>
        </Row>
      </ContentCard>
    </div>
  );
};

PatientFormItems.defaultProps = {
  isManager: false,
  loading: false,
  disabled: false,
  editMode: undefined,
  requiredFieldsType: 'create',
  showTitle: false,
  patientCreateResponseId: undefined,
};

export default PatientFormItems;
