import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { alertDelaySuccess, name as appName } from '../../config';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse, Error } from './common';
import { Action } from '../index';
import { fetchSaga } from './auth';
import { Event } from '../../hooks/events';

/**
 * Constants
 * */
export const moduleName = 'events';
const prefix = `${appName}/${moduleName}`;

export const CREATE_EVENT = `${prefix}/CREATE_EVENT`;
export const CREATE_EVENT_START = `${prefix}/CREATE_EVENT_START`;
export const CREATE_EVENT_SUCCESS = `${prefix}/CREATE_EVENT_SUCCESS`;
export const CREATE_EVENT_ERROR = `${prefix}/CREATE_EVENT_ERROR`;
export const CREATE_EVENT_ERROR_RESET = `${prefix}/CREATE_EVENT_ERROR_RESET`;

export const UPDATE_EVENT = `${prefix}/UPDATE_EVENT`;
export const UPDATE_EVENT_START = `${prefix}/UPDATE_EVENT_START`;
export const UPDATE_EVENT_SUCCESS = `${prefix}/UPDATE_EVENT_SUCCESS`;
export const UPDATE_EVENT_ERROR = `${prefix}/UPDATE_EVENT_ERROR`;
export const UPDATE_EVENT_ERROR_RESET = `${prefix}/UPDATE_EVENT_ERROR_RESET`;

export const DELETE_EVENT = `${prefix}/DELETE_EVENT`;
export const DELETE_EVENT_START = `${prefix}/DELETE_EVENT_START`;
export const DELETE_EVENT_SUCCESS = `${prefix}/DELETE_EVENT_SUCCESS`;
export const DELETE_EVENT_ERROR = `${prefix}/DELETE_EVENT_ERROR`;
export const DELETE_EVENT_ERROR_RESET = `${prefix}/DELETE_EVENT_ERROR_RESET`;

export const CLEAR_EVENT_DATA = `${prefix}/CLEAR_EVENT_DATA`;

/**
 * Reducer
 * */
export interface State {
  event: Event | null;

  eventCreateLoading: boolean;
  // eventError: Error | null;
  eventCreateError: Error | null;

  eventUpdateLoading: boolean;
  eventUpdateError: Error | null;

  eventDeleteLoading: boolean;
  eventDeleteError: Error | null;
}

const defaultState: State = {
  event: null,

  eventCreateLoading: false,
  // eventError: null,
  eventCreateError: null,

  eventUpdateLoading: false,
  eventUpdateError: null,

  eventDeleteLoading: false,
  eventDeleteError: null,
};

export interface ICreateEvent {
  name: string;
  eventDate: string;
  repeat: boolean;
}

export interface IUpdateEvent {
  id?: number;
  name?: string;
  eventDate?: string;
  repeat?: boolean;
}

export default function reducer(
  state = defaultState,
  action: Action = { type: 'undefined' },
): State {
  const { type, payload } = action;

  switch (type) {
    case CREATE_EVENT_START:
      return { ...state, eventCreateLoading: true };
    case CREATE_EVENT_SUCCESS:
      return { ...state, eventCreateLoading: false };
    case CREATE_EVENT_ERROR:
      return { ...state, eventCreateLoading: false, eventCreateError: payload };
    case CREATE_EVENT_ERROR_RESET:
      return { ...state, eventCreateError: null };

    case UPDATE_EVENT_START:
      return { ...state, eventUpdateLoading: true };
    case UPDATE_EVENT_SUCCESS:
      return { ...state, eventUpdateLoading: false, event: payload };
    case UPDATE_EVENT_ERROR:
      return { ...state, eventUpdateLoading: false, eventUpdateError: payload };
    case UPDATE_EVENT_ERROR_RESET:
      return { ...state, eventUpdateError: null };

    case DELETE_EVENT_START:
      return { ...state, eventDeleteLoading: true };
    case DELETE_EVENT_SUCCESS:
      return { ...state, eventDeleteLoading: false, event: payload };
    case DELETE_EVENT_ERROR:
      return { ...state, eventDeleteLoading: false, eventDeleteError: payload };
    case DELETE_EVENT_ERROR_RESET:
      return { ...state, eventDeleteError: null };

    case CLEAR_EVENT_DATA:
      return { ...state, event: null };

    default:
      return { ...state };
  }
}

/**
 * Action Creators
 * */

export const createEvent = (payload: ICreateEvent): Action => ({
  type: CREATE_EVENT,
  payload,
});

export const updateEvent = (payload: IUpdateEvent): Action => ({
  type: UPDATE_EVENT,
  payload,
});

export const deleteEvent = (payload: { id: number; }): Action => ({
  type: DELETE_EVENT,
  payload,
});

export const clearEventData = (): Action => ({
  type: CLEAR_EVENT_DATA,
});

/**
 * Helper functions
 */

/**
 * Sagas
 */
function* createEventSaga({ payload }: { payload: ICreateEvent; }): Generator {
  yield put({
    type: CREATE_EVENT_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/events`,
    {
      method: 'POST',
      body: { ...payload },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    CREATE_EVENT_SUCCESS,
    CREATE_EVENT_ERROR,
    false,
  );
}

function* updateEventSaga({ payload: { id, ...data } }: { payload: IUpdateEvent; }): Generator {
  yield put({
    type: UPDATE_EVENT_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/events/${id}`,
    {
      method: 'PATCH',
      body: { ...data },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_ERROR,
    false,
    () => data,
  );
}

function* deleteEventSaga({ payload: { id } }: { payload: { id: number; }; }): Generator {
  yield put({
    type: DELETE_EVENT_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/events/${id}`,
    {
      method: 'DELETE',
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_ERROR,
    false,
  );
}

export function* saga(): Generator {
  yield takeLatest(
    CREATE_EVENT,
    cancelableLocationSaga.bind(
      null,
      createEventSaga,
      CREATE_EVENT_ERROR,
      false,
    ),
  );
  yield takeLatest(CREATE_EVENT_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: CREATE_EVENT_ERROR_RESET,
    });
  });

  yield takeLatest(
    UPDATE_EVENT,
    cancelableLocationSaga.bind(
      null,
      updateEventSaga,
      UPDATE_EVENT_ERROR,
      false,
    ),
  );
  yield takeLatest(UPDATE_EVENT_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: UPDATE_EVENT_ERROR_RESET,
    });
  });

  yield takeLatest(
    DELETE_EVENT,
    cancelableLocationSaga.bind(
      null,
      deleteEventSaga,
      DELETE_EVENT_ERROR,
      false,
    ),
  );
  yield takeLatest(DELETE_EVENT_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: DELETE_EVENT_ERROR_RESET,
    });
  });
}
