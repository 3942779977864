import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { App, Button, Col, Progress, Row, Typography } from 'antd';

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Loading from '../../Loading';
import styles from './index.module.scss';
import { useDownloadFile } from '../../../../hooks/case/cases';
import { getMessageInError } from '../../../../hooks/fetch';
import { useContextFileDownload } from '../../../../context/fileDownload';
import { IPercentCompleted } from '../index';
import { downloadFromAnchor } from '../../../../utils';

interface FileRow {
  name: string;
  fileId?: string;
  url?: string;
  type?: string;
  actions: {
    download?: () => void;
    preview?: () => void;
    remove?: () => void;
  };
  hideControls?: boolean;
  loading?: boolean;
  caseId?: string;
  caseServiceId?: string;
  commentView?: boolean;
  error?: boolean;
  percentCompletedProp?: IPercentCompleted;
}

const FileRow: React.FC<FileRow> = (props) => {
  const { message } = App.useApp();
  const { isAllDownloaded, setIsAllDownloaded } = useContextFileDownload();
  const {
    name, fileId, url, type, actions, hideControls, loading, caseId, caseServiceId, commentView, error,
    percentCompletedProp,
  } = props;
  const splitFileName = name.split('.');
  const fileName = splitFileName.slice(0, splitFileName.length - 1).join('.');
  const fileExtension = splitFileName[splitFileName.length - 1];
  const [percentCompleted, setPercentCompleted] = useState<number | null>(null);

  const controller = new AbortController();

  // eslint-disable-next-line max-len
  const downloadFile = useDownloadFile((progressEvent) => { setPercentCompleted(Math.round((progressEvent.loaded * 100) / progressEvent.total)); }, controller);

  useEffect(() => {
    /** If any of FileRow's set 'AllDownloaded' State to true, but in one another downloadFile still in progress,
     * then set it back to false */
    if (isAllDownloaded && downloadFile.loading) {
      setIsAllDownloaded(false);
    }
  }, [isAllDownloaded]);

  /** When component unmounts - abort file download request */
  useEffect(() => () => controller.abort(), []);

  const getRequestLink = (): string => {
    if (commentView) return `${fileId}`;
    if (caseId) return `${fileId}/cases/${caseId}`;
    if (caseServiceId) return `${fileId}/case-services/${caseServiceId}`;

    return '';
  };

  const handleDownloadFile = () => {
    if (downloadFile.loading) return;

    setIsAllDownloaded(false);
    downloadFile.fetch(
      undefined,
      getRequestLink(),
      // caseId ? `${fileId}/cases/${caseId}` : `${fileId}/case-services/${caseServiceId}`,
    ).then((response) => {
      if (!response) return;

      downloadFromAnchor(response, name, type);
    }).finally(() => {
      setPercentCompleted(null);
      setIsAllDownloaded(true);
    });
  };

  useEffect(() => {
    if (downloadFile.error) {
      message.error(getMessageInError(downloadFile.error));
      downloadFile.clearError();
    }
  }, [downloadFile.error]);

  return (
    <Row className={styles.row} title={percentCompletedProp?.percent === 100 && loading ? 'Saving uploaded file' : ''}>
      {!!(percentCompletedProp || percentCompleted) && (
        <Progress
          percent={percentCompletedProp?.percent || percentCompleted || 0}
          strokeColor="var(--color-main-orange)"
        />
      )}
      <Col className={styles.iconCol}>
        {loading
          ? <Loading size="small" visible={loading} />
          : <PaperClipOutlined className={styles.icon} />}
      </Col>
      <Col className={styles.content}>
        <Typography className={styles.text}>
          {url ? (
            <Button
              type="link"
              onClick={handleDownloadFile}
              className={styles.fileName}
            >
              {fileName.length > 29 ? `${fileName?.slice(0, 28)}...` : fileName}
            </Button>
          ) : (
            <Typography className={clsx(styles.fileName, {
              [styles.errorFile]: error,
            })}
            >
              {fileName}
            </Typography>
          )}
          <span
            className={clsx(styles.extension, {
              [styles.errorFile]: error,
            })}
            onClick={handleDownloadFile}
          >
            .
            {fileExtension}
          </span>
        </Typography>
      </Col>
      {!hideControls && (
        <Col className={styles.iconCol}>
          <Button
            type="link"
            className={styles.delete}
            onClick={actions.remove}
            icon={<DeleteOutlined className={styles.icon} />}
          />
        </Col>
      )}
    </Row>
  );
};

FileRow.defaultProps = {
  hideControls: false,
  fileId: undefined,
  url: undefined,
  type: undefined,
  loading: false,
  caseId: undefined,
  caseServiceId: undefined,
  commentView: false,
  error: false,
  percentCompletedProp: undefined,
};

export default FileRow;
