import React, {
  createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState,
} from 'react';
import { SelectedState } from '../Day';

interface CalendarContextProps {
  selected: SelectedState | undefined;
  setSelected: Dispatch<SetStateAction<SelectedState | undefined>>;
}

const defaultValue: CalendarContextProps = {
  selected: undefined,
  setSelected: () => {
    // default
  },
};

export const CalendarContext = createContext<CalendarContextProps>(defaultValue);

function CalendarProvider({ children }: PropsWithChildren) {
  const [selected, setSelected] = useState<SelectedState | undefined>();

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CalendarContext.Provider value={{
      selected,
      setSelected,
    }}
    >
      {children}
    </CalendarContext.Provider>
  );
}

export default CalendarProvider;

export const useContextCalendar = (): CalendarContextProps => useContext(CalendarContext);
