import React, { useEffect, useRef } from 'react';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { App, Button, FormInstance, Space, Tooltip } from 'antd';
import { connect } from 'react-redux';
import Icon, { CheckOutlined } from '@ant-design/icons';
import { LabStatusesOptions } from '../../../../enums/lab';
import { TableLabStaffRow, useTableLabStaffRow } from '../../../../hooks/labStaff';
import Status from '../../../Common/Status';
import { LabServiceTable } from '../../../../hooks/services';

import Table from '../../../Common/Table';
import { getMessageInError } from '../../../../hooks/fetch';
import { ModalState } from '../../../../types';
import { formatPhoneNumber, getSorterParams, queryFilterParams } from '../../../../utils';
import { Error } from '../../../../store/ducks/common';
import { RootState } from '../../../../store/reducers';
import { moduleName } from '../../../../store/ducks/cases';
import { Case } from '../../../../hooks/case/cases';
import { Close } from '../../../Common/Icon';
import { useContextCaseFlow } from '../../../../context/caseFlow';
import { CaseServiceStatus } from '../../../../enums/case';

export const isAbleToUnassignCaseService = (caseServiceStatus: CaseServiceStatus): boolean => (
  caseServiceStatus !== 'completed' && caseServiceStatus !== 'pending' && caseServiceStatus !== 'ready for approval'
);

interface AssignStaffTable {
  labIds?: string[] | null;
  params?: Record<string, string>;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  onRowAction?: (lab: TableLabStaffRow, type?: 'assign' | 'unassign' | 'unassignById', itemId?: string) => void;
  isActionDisabled?: boolean;
  // redux props \/
  loading: boolean;
  error: Error | null;
  response: Case | null;
}

function AssignStaffTable({
  labIds, params, onRowAction, loading, error, isActionDisabled,
}: AssignStaffTable): React.JSX.Element {
  const { message } = App.useApp();
  const { caseByIdInContext } = useContextCaseFlow();
  const formRef = useRef<FormInstance>();
  const labStaffGet = useTableLabStaffRow();
  const actionRef = useRef<ActionType>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (labStaffGet.error) {
      message.error(getMessageInError(labStaffGet.error));
      labStaffGet.clearError();
    }
  }, [labStaffGet.error]);

  useEffect(() => {
    /** reload table data, after create/update request finished without errors */
    if (!loading && !error) {
      actionRef.current?.reload();
    }
  }, [loading, params]);

  const getActionButtonTableRow = (row: TableLabStaffRow): JSX.Element => {
    const staffCaseService = caseByIdInContext?.data?.caseServices
      ?.find((caseService) => caseService?.labStaff?.id === row.id);

    const arrOfUnassignedStaffCaseServices = caseByIdInContext?.data?.caseServices
      ?.filter((caseService) => caseService.labStaff?.id !== row.id) || [];
    const arrOfStaffCaseServices = caseByIdInContext?.data?.caseServices
      ?.filter((caseService) => caseService.labStaff?.id === row.id) || [];

    /** If staff at least assigned to one caseService, then: */
    if (staffCaseService) {
      /** If all services assigned to staff, and all is in status, in which is unable to unassign, then this btn: */
      if (caseByIdInContext?.data?.caseServices
        ?.every((caseServ) => caseServ?.labStaff && !isAbleToUnassignCaseService(caseServ.status))) {
        return (
          <Button disabled type="default" icon={<CheckOutlined />}>
            Assigned
          </Button>
        );
      }

      /** If this staff have assigned service, but still not all services assigned:  */
      return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {arrOfUnassignedStaffCaseServices.map((staffCaseServ) => (
            <Tooltip key={staffCaseServ.id} title={!params?.services && 'Please, select any service first'}>
              <Button
                type="primary"
                onClick={() => onRowAction?.(row, 'assign')}
                icon={<CheckOutlined />}
                disabled={isActionDisabled}
              >
                {`Assign ${staffCaseServ?.labService?.service?.name?.split(' ')?.[0] || ''}`}
              </Button>
            </Tooltip>
          ))}
          {arrOfStaffCaseServices.map((staffCaseServ) => (
            /** Show Unassign button only for services which can be unassigned */
            isAbleToUnassignCaseService(staffCaseServ.status) ? (
              <Button
                key={staffCaseServ.id}
                type="default"
                onClick={() => onRowAction?.(row, 'unassignById', staffCaseServ.id)}
                icon={<Icon component={Close} />}
              >
                {`Unassign ${staffCaseServ?.labService?.service?.name?.split(' ')?.[0]}`}
              </Button>
            ) : null
          ))}
        </div>
      );
    }

    /** If staff not assigned to any service, then just Assign btn shown: */
    return (
      <Tooltip title={!params?.services && 'Please, select any service first'}>
        <Button
          type="primary"
          onClick={() => onRowAction?.(row, 'assign')}
          icon={<CheckOutlined />}
          disabled={isActionDisabled}
        >
          Assign
        </Button>
      </Tooltip>
    );
  };

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableLabStaffRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return labStaffGet.fetch({
      ...newParams,
      ...params,
      services: params?.services ? params.services.split(',') : undefined,
      serviceAdditionalOptions: params?.serviceAdditionalOptions
        ? params.serviceAdditionalOptions.split(',')
        : undefined,
      labs: labIds || undefined,
    }).then((data) => {
      if (data) {
        const { labStaff, total } = data;

        return ({ data: labStaff || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  };

  const columns: ProColumns<TableLabStaffRow>[] = [
    {
      title: 'Full Name',
      dataIndex: 'name',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => `${row?.user?.firstName} ${row?.user?.lastName}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => row?.user?.email,
    },
    {
      title: 'Contact Phone',
      dataIndex: 'phone',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => formatPhoneNumber(row?.user?.phone || null, 'international'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      renderText: (_, row) => (
        <Status status={row?.user?.status} />
      ),
      valueEnum: LabStatusesOptions,
    },
    {
      title: 'Services',
      dataIndex: 'labServices',
      sorter: false,
      ellipsis: true,
      renderText: (services: LabServiceTable[]) => (
        <div>
          {services?.length ? (
            <Space direction="vertical">
              {services?.sort((a, b) => a.service?.name < b.service?.name ? 1 : -1)
                ?.map((item: LabServiceTable) => (
                  <div key={item?.id}>
                    {item.service?.name}
                  </div>
                ))}
            </Space>
          ) : ' - '}
        </div>
      ),
    },
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: true,
      ellipsis: true,
    },
    {
      title: '',
      align: 'center',
      hideInSetting: true,
      width: 208,
      renderText: (_, row) => getActionButtonTableRow(row),
    },
  ];

  return (
    <Table<TableLabStaffRow>
      formRef={formRef}
      columns={columns}
      loading={loading || undefined}
      request={tableRequest}
      actionRef={actionRef}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
      search={false}
      dataSource={undefined}
    />
  );
}

AssignStaffTable.defaultProps = {
  params: {},
  labIds: undefined,
  openModal: undefined,
  selectedRows: [],
  isActionDisabled: false,
  onRowSelection: undefined,
  onRowAction: () => undefined,
};

export default connect((state: RootState) => ({
  loading: state[moduleName].caseLoading,
  error: state[moduleName].caseError,
  response: state[moduleName].case,
}))(AssignStaffTable);
