import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/Profile';
import { displayName } from '../../../config';

const Profile: React.FC = (): React.JSX.Element => {
  document.title = `${displayName}: Profile`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default Profile;
