import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useState } from 'react';

import LabsTable from './Table';
import { ModalState } from '../../../types';

const Labs: React.FC = () => {
  const [modal, setModal] = useState<ModalState | null>(null);

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <Content>
      <LabsTable openModal={openModal} />
    </Content>
  );
};

export default Labs;
