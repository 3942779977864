import { EditOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { Button, Checkbox, Col, Dropdown, Form, Row, Typography } from 'antd';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AddressStatus } from '../../../../../enums/address';
import { CreateAddress } from '../../../../../hooks/addresses';
import { JsonResult } from '../../../../../types';
import { Location, Delete, More } from '../../../../Common/Icon';
import styles from './index.module.scss';
import Address from '../../../../Common/Address';
import ContentCard from '../../../../Common/ContentCard';
import { initialCountryData } from '../../../../../utils/prepareData';

const prepareAddressString = (address: CreateAddress | undefined): string | null => {
  if (!address) return null;

  const street = address.street ? address.street : '-';
  const apartment = address.apartment ? address.apartment : '-';
  // const city = address.city ? JSON.parse(address.city).name : '-';
  const city = address.cityString || '-';
  const state = address.state ? JSON.parse(address.state).name : '-';
  const code = address.code ? address.code : '-';

  return `${street}, ${apartment}, ${city}, ${state} ${code}`;
};

interface ShippingAddress {
  loading?: boolean;
  disabled?: boolean;
  addressesState: [CreateAddress[], Dispatch<SetStateAction<CreateAddress[]>>];
  sameAsBillingState: [boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>];
}

const initialFormDataState = { country: initialCountryData };

const ShippingAddress = (props: ShippingAddress) => {
  const { loading, disabled, addressesState, sameAsBillingState } = props;
  const [addresses, setAddresses] = addressesState;
  const [sameAsBilling, setSameAsBilling] = sameAsBillingState;

  const [formData, setFormData] = useState<JsonResult>(initialFormDataState);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isCreationOpen, setIsCreationOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();

  const handleAddressCreate = (values: CreateAddress) => {
    setAddresses((prev) => [...prev, { ...values, status: AddressStatus.ACTIVE, asShipping: true }]);
    setFormData(initialFormDataState);
    form.resetFields();
  };

  const handleCreationCancel = () => {
    setIsCreationOpen(false);
    setFormData(initialFormDataState);
    form.resetFields();
  };

  const handleCreationSubmit = () => {
    form.validateFields().then((values: JsonResult) => {
      handleAddressCreate(values);
      setIsCreationOpen(false);
    });
  };

  const handleAddressEnableChange = (isChecked: boolean, index: number) => {
    const editedAddresses = [...addresses];

    editedAddresses[index].status = isChecked ? AddressStatus.ACTIVE : AddressStatus.DEACTIVATED;

    setAddresses(editedAddresses);
  };

  const handleDelete = (index: number) => {
    const editedAddresses = [...addresses];

    editedAddresses.splice(index, 1);

    setAddresses(editedAddresses);
  };

  const handleEdit = (index: number) => {
    setFormData(addresses[index]);
    setEditIndex(index);
  };

  useEffect(() => {
    form.resetFields();
    formEdit.resetFields();
  }, [formData]);

  const handleAddressEdit = (values: CreateAddress) => {
    if (editIndex === null) return;

    const editedAddresses = [...addresses];

    editedAddresses.splice(editIndex, 1, { ...values, status: AddressStatus.ACTIVE, asShipping: true });

    setEditIndex(null);
    setFormData(initialFormDataState);
    setAddresses(editedAddresses);
  };

  const handleEditCancel = () => {
    setEditIndex(null);
    setFormData(initialFormDataState);
  };

  const handleEditSave = () => {
    formEdit.validateFields().then((values: JsonResult) => {
      handleAddressEdit(values);
    });
  };

  const menuItems = (index: number) => ([
    {
      label: 'Edit',
      key: '1',
      icon: <EditOutlined />,
      onClick: () => {
        handleEdit(index);
      },
    },
    {
      label: 'Delete',
      key: '2',
      icon: <Delete />,
      danger: true,
      onClick: () => {
        handleDelete(index);
      },
    },
  ]);

  return (
    <div>
      <Typography.Title level={4}>Shipping Address</Typography.Title>
      <ContentCard loading={loading}>
        {isCreationOpen ? (
          <Form
            form={form}
            layout="vertical"
            initialValues={formData}
            autoComplete="off"
          >
            <Address
              form={form}
              editMode={{
                onSubmit: handleCreationSubmit,
                onCancel: handleCreationCancel,
              }}
              isEditingOpen
              hasName
              isShipping
              subClassName="shipping"
            />
          </Form>
        ) : (
          <div>
            {addresses.map((address, index) => (
              // eslint-disable-next-line
              <div key={index}>
                {editIndex === index ? (
                  <div className={styles.editForm}>
                    <Form
                      form={formEdit}
                      layout="vertical"
                      initialValues={formData}
                      onFinish={handleAddressEdit}
                      autoComplete="off"
                    >
                      <Address
                        form={formEdit}
                        editMode={{
                          onSubmit: handleEditSave,
                          onCancel: handleEditCancel,
                        }}
                        isEditingOpen
                        hasName
                        isShipping
                        subClassName="shipping"
                      />
                    </Form>
                  </div>
                ) : (
                  <Row className={styles.addressTile}>
                    <Col span={1} className={styles.location}>
                      <Location />
                    </Col>
                    <Col span={22} className={styles.addressCol}>
                      <Typography.Title level={5} className={styles.addressTitle} style={{ paddingLeft: '4px' }}>
                        {address.name}
                      </Typography.Title>
                      <Typography className={styles.addressLine}>
                        {prepareAddressString(address)}
                      </Typography>
                      <Typography className={styles.addressCountry}>
                        {address.country && JSON.parse(address.country).name}
                      </Typography>
                    </Col>
                    <Col span={1} className={styles.addressControls}>
                      <Checkbox
                        checked={address.status === AddressStatus.ACTIVE}
                        onChange={(e) => handleAddressEnableChange(e.target.checked, index)}
                        disabled={disabled}
                      />
                      <Dropdown
                        trigger={['click']}
                        key="button"
                        menu={{ items: menuItems(index) }}
                        overlayStyle={{ backgroundColor: 'transparent' }}
                        disabled={disabled}
                      >
                        <More className={styles.more} />
                      </Dropdown>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
            <Row className={styles.row}>
              <Col span={16}>
                <Checkbox
                  checked={!!sameAsBilling}
                  onChange={(e) => setSameAsBilling(e.target.checked)}
                  disabled={disabled}
                >
                  Same as a Billing Address
                </Checkbox>
              </Col>
              <Col span={8}>
                <Button
                  ghost
                  icon={<PlusOutlined />}
                  onClick={() => setIsCreationOpen(true)}
                  disabled={disabled || editIndex !== null}
                >
                  Add Shipping Address
                </Button>
              </Col>
            </Row>
          </div>
        )}

      </ContentCard>
    </div>
  );
};

ShippingAddress.defaultProps = {
  loading: false,
  disabled: false,
};

export default ShippingAddress;
