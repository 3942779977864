import React from 'react';
import { Layout } from 'antd';

import Content from '../../../components/Pages/Practices/Profile';
import { displayName } from '../../../config';

const PracticeProfile: React.FC = (): React.JSX.Element => {
  document.title = `${displayName}: Practice info`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default PracticeProfile;
