import { Content } from 'antd/es/layout/layout';
import React from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CaseInfo from '../Info';

function PageCaseId(): JSX.Element {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { id = '' } = useParams<{ id: string; }>();
  const closeModal = () => navigate({ pathname: searchParams.get('fromPage') && searchParams.get('fromPage') !== '/'
    ? `/${searchParams.get('fromPage')}` : '/cases' });

  return (
    <Content>
      <CaseInfo
        title="Case Info"
        drawerOpen
        close={closeModal}
        id={id || ''}
        style={{ transition: 'all 0s' }}
      />
    </Content>
  );
}

export default PageCaseId;
