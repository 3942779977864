import React, {
  createContext, PropsWithChildren, useContext, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { isRoleEnough } from '../utils/auth';

interface CasesCalendarContextProps {
  filtersOpen: boolean;
  toggleFiltersOpen: () => void;
}

const defaultValue: CasesCalendarContextProps = {
  filtersOpen: false,
  toggleFiltersOpen: () => undefined,
};

export const CasesCalendarContext = createContext<CasesCalendarContextProps>(defaultValue);

function CasesCalendarProvider({ children }: PropsWithChildren) {
  const [filtersOpen, setFiltersOpen] = useState(defaultValue.filtersOpen);
  const location = useLocation();

  const toggleFiltersOpen = () => {
    setFiltersOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (location.search || isRoleEnough('admin')) {
      setFiltersOpen(true);
    }
  }, [location]);

  return (
    <CasesCalendarContext.Provider value={{
      filtersOpen,
      toggleFiltersOpen,
    }}
    >
      {children}
    </CasesCalendarContext.Provider>
  );
}

export default CasesCalendarProvider;

export const useContextCasesCalendar = (): CasesCalendarContextProps => useContext(CasesCalendarContext);
