export enum PracticeStatuses {
  active = 'active',
  request = 'request',
  pending = 'pending',
  archived = 'archived',
  deactivated = 'deactivated',
}

export type PracticeStatus = 'active' | 'request' | 'pending' | 'archived' | 'deactivated';

export enum PracticeStatusesOptions {
  active = 'Active',
  request = 'Request',
  pending = 'Pending',
  archived = 'Archived',
  deactivated = 'Deactivated',
}

export enum PracticeRolesOptions {
  'practice manager' = 'Practice Manager',
  'doctor' = 'Doctor',
}
