import { SaveFilled } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, Space } from 'antd';
import React from 'react';
import styles from '../index.module.scss';

interface RejectForm {
  form: FormInstance;
  onCancel: () => void;
  onSave: () => void;
}

const RejectForm: React.FC<RejectForm> = (props) => {
  const { form, onCancel, onSave } = props;

  return (
    <div style={{ width: 250 }}>
      <Form
        form={form}
        layout="vertical"
      >
        <Form.Item name="rejectionReason" label="Specify rejection reason">
          <Input.TextArea rows={4} placeholder="Rejection reason" />
        </Form.Item>
      </Form>
      <Space className={styles.row}>
        <Button
          ghost
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="default"
          icon={<SaveFilled />}
          onClick={onSave}
        >
          Save
        </Button>
      </Space>
    </div>
  );
};

export default RejectForm;
