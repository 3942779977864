import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { SortOrder } from 'antd/es/table/interface';
import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { App } from 'antd';
import { getMessageInError } from '../../../../hooks/fetch';
import { TablePracticeRow, useTablePracticeRow } from '../../../../hooks/practices';
import { getSorterParams, queryFilterParams } from '../../../../utils';

import Table from '../../../Common/Table';

interface AssignedPracticesTable {
  priceLevel?: string | number;
}

const AssignedPracticesTable: React.FC<AssignedPracticesTable> = ({ priceLevel }): React.JSX.Element => {
  const { message } = App.useApp();
  const practicesGet = useTablePracticeRow();
  const actionRef = useRef<ActionType>();

  useEffect(() => {
    if (!priceLevel) return;

    actionRef.current?.reload();
  }, [priceLevel]);

  useEffect(() => {
    if (practicesGet.error) {
      message.error(getMessageInError(practicesGet.error));
      practicesGet.clearError();
    }
  }, [practicesGet.error]);

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TablePracticeRow>>> => {
    const params = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    return practicesGet.fetch({ ...params, priceLevel })
      .then((data) => {
        if (data) {
          const { practices, total } = data;

          return ({ data: practices || [], success: true, total });
        }

        return ({ data: [], success: false, total: 0 });
      });
  };

  const columns: ProColumns<TablePracticeRow>[] = [
    {
      title: '#',
      dataIndex: 'accountNumber',
      hideInForm: true,
      ellipsis: false,
      sorter: false,
      width: 'fit-content',
    },
    {
      title: 'Associated Practices',
      dataIndex: 'name',
      sorter: false,
      hideInForm: true,
      render: (_, row) => (
        <Link to={`/practices/${row.id}`}>{row.name}</Link>
      ),
    },
  ];

  return (
    <Table<TablePracticeRow>
      columns={columns}
      className={clsx('simpleList', 'listWithoutFilters')}
      request={tableRequest}
      actionRef={actionRef}
      rowClassName="cursor-pointer"
      showSorterTooltip={false}
      toolBarRender={false}
    />
  );
};

AssignedPracticesTable.defaultProps = {
  priceLevel: undefined,
};

export default AssignedPracticesTable;
