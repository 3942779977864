import { AdditionalOptionsType } from '../enums/services';
import { PagingDataResponse } from '../types';
import { DefaultFetchError, FetchGet, useFetchGet } from './fetch';
import { MaterialType } from '../enums/case';

export interface IPrice {
  id: string | undefined;
  label: string;
  maxRange: number;
  minRange: number;
  price: number;
  type: 'per tooth' | 'fixed price';
  dateSize?: number;
  expeditedDate?: number;
  increasePercent?: number;
}

export interface Material {
  id?: string;
  name?: string;
  isActive: boolean;
  price: number | null;
  type: AdditionalOptionsType;
  prices: IPrice[];
  material?: {
    id?: string;
    name?: string;
    materialType?: MaterialType;
    price?: number;
    type?: AdditionalOptionsType;
    isActive?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } | any;
}

export interface MaterialCreate {
  name: string;
  isActive: boolean;
  price: number;
  type: AdditionalOptionsType;
}

export interface MaterialTableRow {
  key: string;
  id: string;
  name: string;
  status: string;
}

export interface MaterialsTableData {
  services: MaterialTableRow[];
  total: number;
}

export interface MaterialsGetParams {
  page?: number;
  take?: number;
  name?: string;
  type?: string;
  isActive?: 'per tooth' | 'fixed price';
  service?: string;
  orderBy?: 'ASC';
  orderByColumn?: 'createdAt' | 'id';
}

export function useMaterialsGet<D = PagingDataResponse<Material>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, MaterialsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, MaterialsGetParams, DD>(
    'materials',
    { decorateData, autoStart: false },
  );
}
