import React, {
  useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import {
  CloseOutlined,
} from '@ant-design/icons';
import {
  Alert, Badge, Typography,
} from 'antd';
import { NavLink } from 'react-router-dom';
import { useContextCalendar } from '../../Context';
import {
  Calendar,
  CalendarMonthGetParams,
  CalendarMonthResponse,
  useCalendarDayGet,
} from '../../../../../hooks/case/calendar';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import Loading from '../../../Loading';
import { DefaultFetchError, FetchGet, getMessageInError } from '../../../../../hooks/fetch';
import { isRoleEnough } from '../../../../../utils/auth';
import { UserRoles } from '../../../../../enums/user';
import { getCaseStatusLabel } from '../../../../Pages/Cases/Table';

import styles from './index.module.scss';

const dayBoxWidth = 246;
const caseBoxWidth = 367;

interface DetailProps {
  item: Calendar;
}

function Detail({ item }: DetailProps) {
  return (
    <div className={styles.content}>
      <div className={styles.row}>
        <NavLink to={`/cases/${item.id}?fromPage=cases/calendar`}>
          Case #
          {item.id}
        </NavLink>
        <span />
      </div>
      <div className={styles.row} style={{ textTransform: 'capitalize' }}>
        <span>Status</span>
        <span>
          <Badge
            color={`rgb(var(--color-status-${item?.status.toLowerCase().replaceAll(' ', '-')}))`}
            text={getCaseStatusLabel(item?.status, item?.caseServices)}
          />
        </span>
      </div>
      {isRoleEnough(UserRoles.admin) ? (
        <>
          <div className={styles.row}>
            <span>Practice</span>
            <span>{item.practice.name}</span>
          </div>
          <div className={styles.row}>
            <span>Doctor</span>
            <span>
              <NavLink to={`/users/${item.doctor.id}?role=${item.doctor.user.role}&fromPage=cases/calendar`}>
                {item.doctor.user.firstName}
                {' '}
                {item.doctor.user.lastName}
              </NavLink>
            </span>
          </div>
        </>
      ) : (
        <div className={styles.row}>
          <span>Patient</span>
          <span>
            {item.patient.firstName}
            {' '}
            {item.patient.lastName}
          </span>
        </div>
      )}
      <div className={styles.row}>
        <span>Service</span>
        <span>
          {isRoleEnough('admin') ? (
            <NavLink to="/services">
              {item.practicePrice?.services?.map(({ name }) => name).join(' / ')}
            </NavLink>
          ) : (
            item.practicePrice?.services?.map(({ name }) => name).join(' / ')
          )}
        </span>
      </div>
      <div className={styles.row}>
        <span>Price</span>
        <span>{item.totalPrice ? `$${item.totalPrice}` : '-'}</span>
      </div>
    </div>
  );
}

interface ModalProps {
  contentId: string;
  paddingLeft?: number;
  paddingTop?: number;
  caseMonthGet: FetchGet<CalendarMonthResponse, CalendarMonthGetParams, DefaultFetchError, CalendarMonthResponse>;
}

function Modal({ caseMonthGet, contentId }: ModalProps) {
  const { selected, setSelected } = useContextCalendar();
  const [selectedCalendar, setSelectedCalendar] = useState<number | undefined>();
  const refDayModal = useRef<HTMLDivElement | null>(null);
  const refCalendarModal = useRef<HTMLDivElement | null>(null);
  const [, , paramsWithoutTableProps] = useSearchParams();
  const caseDayGet = useCalendarDayGet();
  const selectedCalendarData = caseDayGet.data
    && selectedCalendar
    && caseDayGet.data.find(({ id }) => id === selectedCalendar);

  const caseDayFetch = (reloadMonth = false) => {
    const month = (selected?.date?.get('month') || 0) + 1;
    const day = selected?.date.get('date') || 1;

    const props = {
      ...paramsWithoutTableProps,
    };

    if (reloadMonth) {
      caseMonthGet.fetch(props);
    }

    caseDayGet.fetch({
      ...props,
      day: `${selected?.date?.get('year') || 1}-${
        month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}T00:00:00.000Z`,
    });
  };

  useEffect(() => {
    caseDayGet.clearResponse();
    if (selected === undefined) {
      onCloseDayModal();
    }

    if (selected?.date) {
      setSelectedCalendar(undefined);

      if (caseMonthGet.data && caseMonthGet.data[selected?.date.get('date')]?.length) {
        caseDayFetch();
      }
    }
  }, [selected?.date]);

  useEffect(() => {
    if (selected?.date && caseMonthGet.data && !caseMonthGet.data[selected?.date.get('date')]?.length) {
      onCloseCalendarModal();
    }
  }, [selected?.date, caseMonthGet.data]);

  useEffect(() => {
    if (selected?.ref?.current && refDayModal.current) {
      const dayContent = selected?.ref.current.closest('td');

      if (dayContent) {
        const layoutContent = refDayModal.current.closest(contentId);
        const dayModal = refDayModal.current;

        if (dayModal) {
          Object.assign(dayModal.style, {
            width: `${dayBoxWidth}px`,
          });
        }

        const caseModal = refCalendarModal.current;

        if (selectedCalendar && caseModal) {
          Object.assign(caseModal.style, {
            width: `${caseBoxWidth}px`,
          });
        }

        const onResize = () => {
          if (dayModal && layoutContent) {
            const sizeDayModal = dayModal.getBoundingClientRect();
            const sizeDayContent = dayContent.getBoundingClientRect();
            const sizeLayoutContent = layoutContent.getBoundingClientRect();

            const dayOffsetLeft = dayContent.offsetLeft;
            const dayRight = dayOffsetLeft + sizeDayContent.width;
            const dayOffsetTop = dayContent.offsetTop;
            const dayBottom = dayContent.offsetTop + sizeDayContent.height;

            let left = dayOffsetLeft;
            let top = dayOffsetTop;

            if (dayOffsetLeft > dayBoxWidth) {
              left -= dayBoxWidth;
            } else {
              left = dayRight;
            }

            if (sizeLayoutContent.height <= dayBottom + sizeDayModal.height) {
              top = dayBottom - sizeDayModal.height;
            }

            Object.assign(dayModal.style, {
              transform: `translate(${left}px, ${top}px)`,
            });

            if (selectedCalendar && caseModal) {
              let caseLeft = left - caseBoxWidth;
              const caseTop = top;

              if (left < caseBoxWidth) {
                if (dayOffsetLeft <= dayBoxWidth) {
                  caseLeft = left + dayBoxWidth;
                } else {
                  caseLeft = dayRight;
                }
              }

              Object.assign(caseModal.style, {
                transform: `translate(${caseLeft}px, ${caseTop}px)`,
              });
            }
          }
        };

        onResize();

        window.addEventListener('resize', onResize, false);

        return () => window.removeEventListener('resize', onResize);
      }
    }

    return () => {
      // default
    };
  }, [selectedCalendar, selected?.ref, refDayModal.current]);

  const onCloseCalendarModal = () => {
    if (refCalendarModal.current) {
      Object.assign(refCalendarModal.current?.style, {
        transform: 'translate(-999999px, -999999px)',
      });
    }
    setSelectedCalendar(undefined);
  };

  const onCloseDayModal = () => {
    if (refDayModal.current) {
      Object.assign(refDayModal.current?.style, {
        transform: 'translate(-999999px, -999999px)',
      });
    }
    setSelected(undefined);
    onCloseCalendarModal();
  };

  return (
    <>
      <div className={clsx(styles.day, { [styles.disable]: selected === undefined })} ref={refDayModal}>
        <div className={styles.header}>
          <h4>{selected ? `${selected.date.format('dddd')} ${selected.date.get('D')}` : null}</h4>
          <CloseOutlined onClick={onCloseDayModal} />
        </div>
        <div className={styles.content}>
          {caseDayGet.error ? (
            <Alert
              type="error"
              className={styles.alert}
              message={getMessageInError(caseDayGet.error)}
              closable
              onClose={caseDayGet.clearError}
            />
          ) : null}

          <ul>
            {caseDayGet.loading ? (
              <div className={styles.loading}>
                <Loading visible />
              </div>
            ) : (
              caseDayGet.data?.map(({
                id, status, doctor,
              }) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={id}
                  onClick={() => setSelectedCalendar(id)}
                  className={clsx(styles.item, { [styles.active]: selectedCalendar === id })}
                >
                  <Typography.Text ellipsis>
                    <Badge
                      color={`rgb(var(--color-status-${status.toLowerCase().replaceAll(' ', '-')}))`}
                      text={`#${id}`}
                    />
                    {' '}
                    {doctor.user.firstName}
                    {' '}
                    {doctor.user.lastName}
                  </Typography.Text>
                </li>
              ))
            )}
          </ul>
        </div>
      </div>

      {selectedCalendarData ? (
        <div
          className={clsx(styles.case, { [styles.disable]: selectedCalendar === undefined })}
          ref={refCalendarModal}
        >
          <div className={styles.header}>
            <h4>{selected ? `${selected.date.format('dddd')} ${selected.date.get('D')}` : null}</h4>
            <div>
              <CloseOutlined onClick={onCloseCalendarModal} />
            </div>
          </div>
          <Detail item={selectedCalendarData} />
        </div>
      ) : null}
    </>
  );
}

Modal.defaultProps = {
  action: true,
  paddingLeft: 0,
  paddingTop: 0,
};

export default Modal;
