import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { App } from 'antd';
import { Dayjs } from 'dayjs';
import { FetchError, JsonResult, PagingDataResponse } from '../types';
import { FetchGet, FetchGetId, getMessageInError } from '../hooks/fetch';
import { LabService, Service, ServicesGetParams, useServicesGet } from '../hooks/services';
import { IPrice, Material, MaterialsGetParams, useMaterialsGet } from '../hooks/materials';
import { Case, CaseService, Tooth, useCaseId } from '../hooks/case/cases';
import { Practice, usePracticePriceLevelServiceId } from '../hooks/practices';
import { Patient, usePatientId } from '../hooks/patients';
import { PriceLevelService } from '../hooks/priceLevels';
import { CaseServiceStatus, MaterialType } from '../enums/case';
import { AdditionalOption } from '../hooks/additionalOptions';
import { EventsGetParams, useEventsGet, Event } from '../hooks/events';
import { IDueDateSize } from '../components/Pages/Cases/AssignLab';

export interface ISelectTreatmentPlan {
  services?: string[];
  dueDate?: Dayjs;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface IImplantInfo {
  manufacturer?: string;
  family?: string;
  shade?: string;
}

export interface IPrintingInfo {
  address?: string;
  material?: string;
}

interface ICalcTotalPrice {
  labTotalPrice: string;
  leadTimesPrice: string;
}

interface CaseFlowContext {
  servicesGetInContext: FetchGet<PagingDataResponse<Service>, ServicesGetParams, FetchError, PagingDataResponse<
      Service>> | null;
  materialsGetInContext: FetchGet<PagingDataResponse<Material>, MaterialsGetParams, FetchError, PagingDataResponse<
    Material>> | null;
  materialCrownId: string;
  materialImplantId: string;
  foundSurgicalService: PriceLevelService | null;
  foundRestorativeService: PriceLevelService | null;
  foundPrintingService: PriceLevelService | null;
  // Form values
  patientInfo: JsonResult;
  selectTreatmentPlan: ISelectTreatmentPlan;
  teethValues: Tooth[];
  historyTeethValues: Array<Tooth[]>;
  futureTeethValues: Array<Tooth[]>;
  implantInfo: IImplantInfo;
  printingInfo: IPrintingInfo;
  // Handle Form values
  setPatientInfo: React.Dispatch<React.SetStateAction<JsonResult>>;
  setSelectTreatmentPlan: React.Dispatch<React.SetStateAction<ISelectTreatmentPlan>>;
  setTeethValues: React.Dispatch<React.SetStateAction<Tooth[]>>;
  setHistoryTeethValues: React.Dispatch<React.SetStateAction<Tooth[][]>>;
  setFutureTeethValues: React.Dispatch<React.SetStateAction<Tooth[][]>>;
  setImplantInfo: React.Dispatch<React.SetStateAction<IImplantInfo>>;
  setPrintingInfo: React.Dispatch<React.SetStateAction<IPrintingInfo>>;
  // Request practiceById
  practicePriceLevelServiceInContext: FetchGetId<Practice, FetchError, undefined, Practice> | null;
  caseByIdInContext: FetchGetId<Case, FetchError, undefined, Case> | null;
  eventsListGetInContext: FetchGet<PagingDataResponse<Event>, EventsGetParams, FetchError, PagingDataResponse<Event>>
    | null;
  patientByIdInContext: FetchGetId<Patient, FetchError, undefined, Patient> | null;
  clearContextData: () => void;
  // Useful functions
  isPriceLevelServiceAssigned: (
    priceParentServiceId: string | undefined,
    falseOnStatuses?: CaseServiceStatus[]
  ) => boolean;
  getCaseServiceByPriceParentServiceId: (priceParentServiceId: string | undefined) => CaseService | undefined;
  // eslint-disable-next-line max-len
  getPracticePriceLevelByPriceParentServiceId: (priceParentServiceId: string | undefined) => PriceLevelService | undefined;
  isPriceLevelServiceStaffAssigned: (priceParentServiceId: string | undefined) => boolean;
  getCaseServiceByPriceLevelServiceId: (priceParentServiceId: string | undefined) => CaseService | undefined;
  getServiceNameByPriceLevelServiceId: (priceParentServiceId: string | undefined) => string | undefined;
  calcPriceByLabService: (labMaterial: LabService | undefined, dueDates: IDueDateSize[]) =>
    ICalcTotalPrice;
}

export const initialTeethValues: Tooth[] = [
  // {
  //   index: 12,
  //   isMissing: true,
  //   isBridge: false,
  //   materials: [],
  //   toBeExtracted: false,
  // },
];

const defaultValue = {
  servicesGetInContext: null,
  materialsGetInContext: null,
  materialCrownId: '',
  materialImplantId: '',
  foundSurgicalService: null,
  foundRestorativeService: null,
  foundPrintingService: null,
  // Form values
  patientInfo: {},
  selectTreatmentPlan: {},
  teethValues: [],
  historyTeethValues: [],
  futureTeethValues: [],
  implantInfo: {},
  printingInfo: {},
  // Handle Form values
  setPatientInfo: () => undefined,
  setSelectTreatmentPlan: () => undefined,
  setTeethValues: () => undefined,
  setHistoryTeethValues: () => undefined,
  setFutureTeethValues: () => undefined,
  setImplantInfo: () => undefined,
  setPrintingInfo: () => undefined,
  // Request practiceById
  practicePriceLevelServiceInContext: null,
  caseByIdInContext: null,
  eventsListGetInContext: null,
  patientByIdInContext: null,
  clearContextData: () => undefined,
  isPriceLevelServiceAssigned: () => false,
  isPriceLevelServiceStaffAssigned: () => false,
  getCaseServiceByPriceParentServiceId: () => undefined,
  getPracticePriceLevelByPriceParentServiceId: () => undefined,
  getCaseServiceByPriceLevelServiceId: () => undefined,
  getServiceNameByPriceLevelServiceId: () => undefined,
  calcPriceByLabService: () => ({
    labTotalPrice: '0',
    leadTimesPrice: '0',
  }),
};

export const CaseFlowContext = createContext<CaseFlowContext>(defaultValue);

function CaseFlowProvider({ children }: PropsWithChildren) {
  const { message } = App.useApp();
  const servicesGetInContext = useServicesGet();
  const materialsGetInContext = useMaterialsGet();
  const practicePriceLevelServiceInContext = usePracticePriceLevelServiceId();
  const caseByIdInContext = useCaseId();
  const patientByIdInContext = usePatientId();
  // Added new item for disabling date:
  const eventsListGetInContext = useEventsGet();

  const [patientInfo, setPatientInfo] = useState<JsonResult>({ });
  const [selectTreatmentPlan, setSelectTreatmentPlan] = useState<ISelectTreatmentPlan>({});

  const [teethValues, setTeethValues] = useState<Tooth[]>(initialTeethValues);
  const [historyTeethValues, setHistoryTeethValues] = useState<Array<Tooth[]>>([]);
  const [futureTeethValues, setFutureTeethValues] = useState<Array<Tooth[]>>([]);

  const [implantInfo, setImplantInfo] = useState<IImplantInfo>({});
  const [printingInfo, setPrintingInfo] = useState<IPrintingInfo>({});

  const handleClearContextData = () => {
    setPatientInfo({});
    setSelectTreatmentPlan({});
    setTeethValues(initialTeethValues);
    setHistoryTeethValues([]);
    setFutureTeethValues([]);
    setImplantInfo({});
    setPrintingInfo({});
  };

  useEffect(() => {
    servicesGetInContext.fetch({ orderByColumn: 'createdAt', orderBy: 'ASC', status: 'active' });
    materialsGetInContext.fetch({ orderByColumn: 'createdAt', orderBy: 'ASC', take: 100 });
  }, []);

  useEffect(() => {
    if (patientInfo?.patient) {
      /** when we set patient, we do request to get patient by id, to set drawer header center content */
      patientByIdInContext.fetch(undefined, patientInfo.patient);
    }
  }, [patientInfo?.patient]);

  useEffect(() => {
    if (patientByIdInContext.data?.doctor?.practice) {
      /** when we got patient info, we do request to get practice by id, to get price levels of this practice */
      practicePriceLevelServiceInContext.fetch(
        undefined,
        `${patientByIdInContext.data?.doctor?.practice?.id}/price-level-service`,
        // `423523423dsaf32rff/price-level-service`,
      );
    }
  }, [patientByIdInContext.data]);

  const [foundSurgicalService, setFoundSurgicalService] = useState<PriceLevelService | null>(null);
  const [foundRestorativeService, setFoundRestorativeService] = useState<PriceLevelService | null>(null);
  const [foundPrintingService, setFoundPrintingService] = useState<PriceLevelService | null>(null);

  useEffect(() => {
    /** If caseByIdInContext have data, then take it from caseByIdInContext, in other way from pracPriceL */
    if (caseByIdInContext.data?.practice?.priceLevelServices?.length) {
      setFoundSurgicalService(caseByIdInContext.data?.practice?.priceLevelServices
        ?.find((item) => item.service?.serviceType === 'surgical') || null);
      setFoundRestorativeService(caseByIdInContext.data?.practice?.priceLevelServices
        ?.find((item) => item.service?.serviceType === 'restorative') || null);
      setFoundPrintingService(caseByIdInContext.data?.practice?.priceLevelServices
        ?.find((item) => item.service?.serviceType === 'printing') || null);

      return;
    }
    if (practicePriceLevelServiceInContext.data?.priceLevel) {
      setFoundSurgicalService(practicePriceLevelServiceInContext.data?.priceLevel
        ?.priceLevelServices?.find((item) => item.service?.serviceType === 'surgical') || null);
      setFoundRestorativeService(practicePriceLevelServiceInContext.data?.priceLevel
        ?.priceLevelServices?.find((item) => item.service?.serviceType === 'restorative') || null);
      setFoundPrintingService(practicePriceLevelServiceInContext.data?.priceLevel
        ?.priceLevelServices?.find((item) => item.service?.serviceType === 'printing') || null);
    }
  }, [caseByIdInContext.data, practicePriceLevelServiceInContext]);

  useEffect(() => {
    if ((servicesGetInContext.data || caseByIdInContext.data)
      && !servicesGetInContext.loading && !caseByIdInContext.loading) {
      eventsListGetInContext.fetch({ take: 100 });
    }
  }, [servicesGetInContext.data, caseByIdInContext.data]);

  const findMaterialByType = (materialType: MaterialType): Material | undefined => (
    (caseByIdInContext.data?.practice?.priceLevelServices
      || practicePriceLevelServiceInContext.data?.priceLevel?.priceLevelServices)
      ?.find((service) => (
        service?.priceLevelMaterials?.some((material) => material?.material?.materialType === materialType)
      ))?.priceLevelMaterials
      ?.find((priceLevelMaterial) => priceLevelMaterial.material?.materialType === materialType)
  );
  const materialCrownId = findMaterialByType('crown')?.id || 'crown';
  const materialImplantId = findMaterialByType('implant')?.id || 'implant';

  /** Use this func to check is priceLevelSerivice.service.id assigned to any lab or not (and if 'declined'(status)) */
  const isPriceLevelServiceAssigned = (
    priceParentServiceId: string | undefined,
    falseOnStatuses?: CaseServiceStatus[],
  ) => (
    !!caseByIdInContext?.data?.caseServices?.find((caseService) => (
      caseService?.labService?.service?.id === priceParentServiceId
      && !falseOnStatuses?.some((status) => caseService.status === status)
    ))
  );

  const getCaseServiceByPriceParentServiceId = (priceParentServiceId: string | undefined): CaseService | undefined => (
    caseByIdInContext?.data?.caseServices?.find((caseService) => (
      caseService?.labService?.service?.id === priceParentServiceId
    ))
  );

  // eslint-disable-next-line max-len
  const getPracticePriceLevelByPriceParentServiceId = (priceParentServiceId: string | undefined): PriceLevelService | undefined => (
    // This function work only with pages which use request caseById
    caseByIdInContext?.data?.practice?.priceLevelServices?.find((priceLevel) => (
      priceLevel?.service?.id === priceParentServiceId
    ))
  );

  const getCaseServiceByPriceLevelServiceId = (priceLevelServiceId: string | undefined) => {
    const priceLevelService = (caseByIdInContext.data?.practice?.priceLevelServices
      || practicePriceLevelServiceInContext.data?.priceLevel?.priceLevelServices)
      ?.find((service) => service.id === priceLevelServiceId);

    return caseByIdInContext?.data?.caseServices
      ?.find((service) => service?.labService?.service?.id === priceLevelService?.service?.id);
  };

  const isPriceLevelServiceStaffAssigned = (priceParentServiceId: string | undefined) => (
    !!caseByIdInContext?.data?.caseServices?.find((caseService) => (
      caseService?.labService?.service?.id === priceParentServiceId
    ))?.labStaff?.id
  );

  const getServiceNameByPriceLevelServiceId = (priceLevelServiceId: string | undefined) => {
    const priceLevelService = (caseByIdInContext.data?.practice?.priceLevelServices
      || practicePriceLevelServiceInContext.data?.priceLevel?.priceLevelServices)
      ?.find((service) => service.id === priceLevelServiceId);

    return priceLevelService?.service?.name?.split(' ')?.[0];
  };

  const getPriceLevelServiceByLabServiceParentId = (labServiceParentId: string | undefined) => (
    (caseByIdInContext.data?.practice?.priceLevelServices
      || practicePriceLevelServiceInContext.data?.priceLevel?.priceLevelServices)
      ?.find((priceService) => priceService?.service?.id === labServiceParentId)
  );

  const calcPriceForSpecialService = (
    labServiceProp: LabService | undefined,
    foundServiceProp: PriceLevelService | null,
    teethValuesProp: Tooth[],
    dateSizeProp?: string,
  ): ICalcTotalPrice => {
    let totalMaterialsPrice = 0;
    let totalAdditionalOptionsPrice = 0;

    const labMaterialFound = labServiceProp?.labMaterials?.find((labMaterial) => (
      teethValuesProp?.some((tooth) => tooth.materials?.some((teethMaterial) => (
        foundServiceProp?.priceLevelMaterials?.find((priceMaterial) => (
          priceMaterial?.id === teethMaterial
        ))?.material?.id === labMaterial.material?.id
      )))
    ));
    const labMaterialPrices: IPrice[] | undefined = labMaterialFound?.prices;
    const labMaterialPriceMatch: IPrice | undefined = labMaterialPrices?.find((price) => (
      price.minRange <= teethValuesProp.length && price.maxRange >= teethValuesProp.length
    ));
    /** If selected dateSize count less than dateSize from materialPrice then it's expedited price */
    const isExpeditedPrice: boolean = Number(dateSizeProp) < (labMaterialPriceMatch?.dateSize || 0)
      && dateSizeProp !== '0';

    /** Variables for Additional options calc: */
    const foundCaseTeethService = caseByIdInContext.data?.teeth.services?.find((caseService) => (
      caseService?.id === foundServiceProp?.id));
    const foundAdditionalOptions: AdditionalOption[] | undefined = foundServiceProp?.priceLevelAdditionalOptions
      ?.filter((priceAdditionalOption) => foundCaseTeethService?.additionalOptions
        ?.some((option) => option?.id === priceAdditionalOption?.id));

    const foundAdditionalOptionsParentIds: string[] | undefined = foundAdditionalOptions
      ?.map((item) => item?.additionalOption?.id);

    /** Returning actual additional options which need to calculate from lab: */
    const foundLabAdditionalOptions: AdditionalOption[] | undefined = labServiceProp?.labAdditionalOptions
      ?.filter((labAdditionalOption) => (
        foundAdditionalOptionsParentIds?.includes(labAdditionalOption?.additionalOption?.id)
      ));

    /** Materials calculation */
    if (labMaterialPrices?.length && labMaterialPriceMatch) {
      if (labMaterialPriceMatch.type === 'fixed price') {
        totalMaterialsPrice = labMaterialPriceMatch.price || 0;
      }
      if (labMaterialPriceMatch.type === 'per tooth') {
        totalMaterialsPrice = (labMaterialPriceMatch.price || 0) * teethValuesProp.length;
      }
    }
    /** If no prices[] on material - then calculate simply: */
    if (!labMaterialPriceMatch) {
      if (labServiceProp?.labMaterials?.[0]?.type === 'fixed price') {
        totalMaterialsPrice = labServiceProp?.labMaterials?.[0]?.price || 0;
      } else {
        totalMaterialsPrice = (labServiceProp?.labMaterials?.[0]?.price || 0) * teethValuesProp.length;
      }
    }

    /** AdditionalOptions calc */
    if (foundLabAdditionalOptions?.length) {
      foundLabAdditionalOptions.forEach((labAdditionalOption) => {
        /** For each selected additional option finding matching price from prices[], then adding price to total  */
        const labAdditionalOptionPriceMatch: IPrice | undefined = labAdditionalOption.prices?.find((price) => (
          price.minRange <= teethValuesProp.length && price.maxRange >= teethValuesProp.length
        ));

        if (labAdditionalOption?.prices?.length && labAdditionalOptionPriceMatch) {
          if (labAdditionalOptionPriceMatch?.type === 'fixed price') {
            totalAdditionalOptionsPrice += labAdditionalOptionPriceMatch.price || 0;
          }
          if (labAdditionalOptionPriceMatch?.type === 'per tooth') {
            totalAdditionalOptionsPrice += (labAdditionalOptionPriceMatch.price || 0) * teethValuesProp.length;
          }
        }
        /** If no prices[] on labAdditionalOption - then calculate simply: */
        if (!labAdditionalOptionPriceMatch) {
          if (labAdditionalOption?.type === 'fixed price') {
            totalAdditionalOptionsPrice += labAdditionalOption.price || 0;
          } else {
            totalAdditionalOptionsPrice += (labAdditionalOption.price || 0) * teethValuesProp.length;
          }
        }
      });
    }

    return {
      labTotalPrice: (totalMaterialsPrice + totalAdditionalOptionsPrice)?.toString() || '0',
      leadTimesPrice: isExpeditedPrice && labMaterialPriceMatch?.increasePercent
        ? ((totalMaterialsPrice + totalAdditionalOptionsPrice) * (labMaterialPriceMatch.increasePercent / 100))
          ?.toString()
        : '0',
    };
  };

  const calcPriceByLabService = (
    labService: LabService | undefined,
    dueDates: IDueDateSize[],
  ): ICalcTotalPrice => {
    const teethImplants = teethValues.filter((tooth) => (
      tooth.materials?.includes(materialImplantId)));

    const teethCrowns = teethValues.filter((tooth) => (
      tooth.materials?.includes(materialCrownId)));

    /** If service is Surgical */
    if (getPriceLevelServiceByLabServiceParentId(labService?.service?.id)?.id === foundSurgicalService?.id) {
      return calcPriceForSpecialService(
        labService,
        foundSurgicalService,
        teethImplants,
        dueDates?.find((date) => date.parentServiceId === foundSurgicalService?.service?.id)?.dateSize?.toString()
        || '0',
      );
    }

    /** If service is Restorative */
    if (getPriceLevelServiceByLabServiceParentId(labService?.service?.id)?.id === foundRestorativeService?.id) {
      const topArch = teethCrowns.filter((tooth) => tooth.index <= 16);
      const bottomArch = teethCrowns.filter((tooth) => tooth.index >= 17);

      const resultCalcTopArch = calcPriceForSpecialService(
        labService,
        foundRestorativeService,
        topArch,
        dueDates?.find((date) => date.parentServiceId === foundRestorativeService?.service?.id)?.dateSize?.toString()
        || '0',
      );

      const resultCalcBottomArch = calcPriceForSpecialService(
        labService,
        foundRestorativeService,
        bottomArch,
        dueDates?.find((date) => date.parentServiceId === foundRestorativeService?.service?.id)?.dateSize?.toString()
        || '0',
      );

      const sumPrices: ICalcTotalPrice = {
        labTotalPrice: (+resultCalcTopArch.labTotalPrice + +resultCalcBottomArch.labTotalPrice).toString(),
        leadTimesPrice: (+resultCalcTopArch.leadTimesPrice + +resultCalcBottomArch.leadTimesPrice).toString(),
      };

      return sumPrices;
    }

    /** If service is Printing */
    if (getPriceLevelServiceByLabServiceParentId(labService?.service?.id)?.id === foundPrintingService?.id) {
      return calcPriceForSpecialService(
        labService,
        foundPrintingService,
        teethValues,
        dueDates?.find((date) => date.parentServiceId === foundPrintingService?.service?.id)?.dateSize?.toString()
        || '0',
      );
    }

    /** By default return price of first material without calculation */
    return {
      labTotalPrice: labService?.labMaterials?.[0]?.price?.toString() || '0',
      leadTimesPrice: '0',
    };
  };

  useEffect(() => {
    if (servicesGetInContext.error) {
      message.error(getMessageInError(servicesGetInContext.error));
      servicesGetInContext.clearError();
    }
  }, [servicesGetInContext.error]);

  useEffect(() => {
    if (materialsGetInContext.error) {
      message.error(getMessageInError(materialsGetInContext.error));
      materialsGetInContext.clearError();
    }
  }, [materialsGetInContext.error]);

  useEffect(() => {
    if (caseByIdInContext.error) {
      message.error(getMessageInError(caseByIdInContext.error));
      caseByIdInContext.clearError();
    }
  }, [caseByIdInContext.error]);

  useEffect(() => {
    if (eventsListGetInContext.error) {
      message.error(getMessageInError(eventsListGetInContext.error));
      eventsListGetInContext.clearError();
    }
  }, [eventsListGetInContext.error]);

  useEffect(() => {
    if (patientByIdInContext.error) {
      message.error(getMessageInError(patientByIdInContext.error), 5);
      patientByIdInContext.clearError();
    }
  }, [patientByIdInContext.error]);

  useEffect(() => {
    if (practicePriceLevelServiceInContext.error) {
      message.error(getMessageInError(practicePriceLevelServiceInContext.error));
      practicePriceLevelServiceInContext.clearError();
    }
  }, [practicePriceLevelServiceInContext.error]);

  return (
    <CaseFlowContext.Provider
      value={{
        servicesGetInContext,
        materialsGetInContext,
        materialCrownId,
        materialImplantId,
        foundSurgicalService,
        foundRestorativeService,
        foundPrintingService,
        // Form values
        patientInfo,
        selectTreatmentPlan,
        teethValues,
        historyTeethValues,
        futureTeethValues,
        implantInfo,
        printingInfo,
        // Handle Form values
        setPatientInfo,
        setSelectTreatmentPlan,
        setTeethValues,
        setHistoryTeethValues,
        setFutureTeethValues,
        setImplantInfo,
        setPrintingInfo,
        // Requests byId
        practicePriceLevelServiceInContext,
        caseByIdInContext,
        eventsListGetInContext,
        patientByIdInContext,
        clearContextData: handleClearContextData,
        // Useful functions
        isPriceLevelServiceAssigned,
        isPriceLevelServiceStaffAssigned,
        getCaseServiceByPriceParentServiceId,
        getPracticePriceLevelByPriceParentServiceId,
        getCaseServiceByPriceLevelServiceId,
        getServiceNameByPriceLevelServiceId,
        calcPriceByLabService,
      }}
    >
      {children}
    </CaseFlowContext.Provider>
  );
}

export default CaseFlowProvider;

export const useContextCaseFlow = (): CaseFlowContext => useContext(CaseFlowContext);
