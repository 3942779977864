import { LeftOutlined, RightOutlined } from '@ant-design/icons/lib';
import { App, Button, Col, Form, Row, Space } from 'antd';
import Icon from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { LabStatuses } from '../../../../enums/lab';
import { Action } from '../../../../store';
import { createLab as actionCreateLab, CreateLab, moduleName } from '../../../../store/ducks/lab';
import { RootState } from '../../../../store/reducers';
import { JsonResult } from '../../../../types';
import { PreparedLabInitialData, prepareLabBody } from '../../../../utils/prepareData';
import DrawerModal from '../../../Common/DrawerModal';
import { Send } from '../../../Common/Icon';
import LabForm from '../Forms/LabForm';
import LabManagerInfo from '../Forms/LabManagerInfo';
import { Error } from '../../../../store/ducks/common';

interface LabCreate {
  title: string;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  isAdmin?: boolean;
  initialData?: PreparedLabInitialData;
  disabled?: {
    [key: string]: boolean;
  };
  handleSave?: (body: CreateLab) => void;
  loading: boolean;
  error: Error | null;
  createLab: (payload: CreateLab) => Action;
}

const LabCreate = (props: LabCreate): React.JSX.Element => {
  const { message } = App.useApp();
  const {
    initialData,
    handleSave,
    title,
    drawerOpen,
    setDrawerOpen,
    isAdmin,
    disabled,
    loading,
    error,
    createLab,
  } = props;

  const [labForm] = Form.useForm();
  const [managerForm] = Form.useForm();
  const totalSteps = 2;
  const [step, setStep] = useState<number>(1);

  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [labInfo, setLabInfo] = useState<JsonResult>({});

  const handleClose = () => {
    setDrawerOpen(false);
    labForm.resetFields();
    managerForm.resetFields();
  };

  useEffect(() => {
    if (!loading && !error) {
      /** close modal, when request finish without errors */
      handleClose();
    }
  }, [loading]);

  const handleManagerFormChange = (field: JsonResult[]) => {
    if (field[0].name[0] === 'email') {
      setEmail(field[0].value);
    }
  };

  useEffect(() => {
    if (!drawerOpen) return;
    setStep(1);
  }, [drawerOpen]);

  useEffect(() => {
    setEmail(managerForm.getFieldValue('email'));
  }, [managerForm?.getFieldsValue()]);

  useEffect(() => {
    if (error?.message) {
      message.error(error.message, 5);
    }
  }, [error]);

  const handleNext = () => {
    labForm.validateFields().then((values: JsonResult) => {
      setLabInfo(values);
      setStep((prev) => prev + 1);
    });
  };

  const handleSubmit = () => {
    managerForm.validateFields().then((values) => {
      const body = prepareLabBody(labInfo, values, LabStatuses.pending);

      if (handleSave) {
        handleSave(body);
      } else {
        createLab(body);
      }
    });
  };

  return (
    <div>
      <DrawerModal
        title={title}
        open={drawerOpen}
        onClose={handleClose}
        withSteps
        currentStep={step}
        totalSteps={totalSteps}
        isFormChanged={isFormTouched}
        extra={(
          <Space>
            {step > 1 && (
              <Button
                ghost
                onClick={() => setStep((prev) => prev - 1)}
              >
                <LeftOutlined style={{ fontSize: 12 }} />
                Back
              </Button>
            )}
            {step < totalSteps && (
              <Button
                type="default"
                onClick={handleNext}
                id="nextBtn"
              >
                Next
                <RightOutlined style={{ fontSize: 12 }} />
              </Button>
            )}
            {step === totalSteps && (
              <Button
                type="primary"
                icon={<Icon component={Send} />}
                loading={loading}
                onClick={handleSubmit}
                disabled={!email}
                id="submitBtn"
              >
                {isAdmin && 'Send Registration Link'}
                {!isAdmin && 'Submit'}
              </Button>
            )}
          </Space>
        )}
      >
        <Row justify="center">
          <Col span={24} md={20} lg={16} xxl={12}>
            <div>
              {step === 1 && (
                <LabForm
                  isAdmin={isAdmin}
                  form={labForm}
                  disabled={disabled}
                  initialData={initialData?.lab}
                  onFieldsChange={() => setIsFormTouched(true)}
                />
              )}
              {step === 2 && (
                <LabManagerInfo
                  isAdmin={isAdmin}
                  hasPassword={!isAdmin}
                  disabled={disabled?.manager}
                  initialData={initialData?.manager}
                  form={managerForm}
                  handleChange={handleManagerFormChange}
                />
              )}
            </div>
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[moduleName].loading,
  error: state[moduleName].error,
});

const mapDispatchToProps = {
  createLab: actionCreateLab,
};

LabCreate.defaultProps = {
  isAdmin: false,
  disabled: undefined,
  initialData: undefined,
  handleSave: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabCreate);
