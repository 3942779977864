import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';
import { ModalStaticFunctions } from 'antd/es/modal/confirm';
import { ButtonProps } from 'antd/es/button';

interface Confirm {
  modal: Omit<ModalStaticFunctions, 'warn'>;
  onOk: () => void;
  title: string;
  content?: React.ReactNode;
  okText?: string;
  onCancel?: () => void;
  cancelText?: string;
  zIndex?: number;
  centered?: boolean;
  closable?: boolean;
  cancelButtonProps?: ButtonProps;
}

const confirm = ({
  // eslint-disable-next-line max-len
  modal, onOk, onCancel, title, content, okText = 'Confirm', cancelText = 'Cancel', zIndex = 1002, centered = true, closable, cancelButtonProps,
}: Confirm): void => {
  // eslint-disable-next-line max-len
  modal.confirm({ title, icon: <ExclamationCircleOutlined />, content, okText, cancelText, onOk, onCancel, zIndex, centered, closable, cancelButtonProps });
};

export default confirm;
