import { CalendarOutlined, ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Notification } from '../../../../../hooks/notifications';
import styles from '../../index.module.scss';

interface NotificationRow {
  item: Notification;
  markAsRead: (id: string) => void;
  remove: (id: string) => void;
}

const NotificationRow: React.FC<NotificationRow> = ({ item, markAsRead, remove }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDeleteClick = (e: any, id: string) => {
    e.preventDefault();
    remove(id);
  };

  return (
    <Link
      to={`/${item.link}?fromPage=notifications`}
      onClick={() => markAsRead(item.id)}
      className={clsx(styles.notificationRow, { [styles.notificationRowRead]: item.status.saw })}
    >
      <div className={styles.notificationRowContent}>
        <div className={styles.notificationBadgeWrapper}>
          <div className={styles.notificationBadge} />
        </div>
        <div className={styles.notificationContent}>
          <Typography.Title level={5}>{item.title}</Typography.Title>
          <Typography dangerouslySetInnerHTML={{ __html: item.message }} />
        </div>
        <div>
          <Button
            onClick={(e) => handleDeleteClick(e, item.id)}
            type="link"
            icon={<CloseOutlined style={{ fontSize: 12 }} />}
            className={styles.notificationDelete}
          />
        </div>
      </div>
      <div className={styles.notificationRowFooter}>
        <Space>
          <CalendarOutlined />
          <Typography>{dayjs(item.createdAt).format('YYYY-MM-DD')}</Typography>
        </Space>
        <Space>
          <ClockCircleOutlined />
          <Typography>{dayjs(item.createdAt).format('h:mm a')}</Typography>
        </Space>
      </div>
    </Link>
  );
};

export default NotificationRow;
