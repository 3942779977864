import { SelectProps } from 'antd/lib/select';
import { App, Select } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import {
  useServiceId,
  useServicesGet,
  ServicesGetParams,
} from '../../../../hooks/services';
import NotFoundContent from '../../NotFoundContent';

interface SelectServiceMulti extends SelectProps<string[], Option> {
  activeOnly?: 'active' | 'deactivated';
  selectOptions?: Option[];
  optionsLoading?: boolean;
}

const SelectServiceMulti: React.FC<SelectServiceMulti> = (
  { activeOnly, selectOptions, optionsLoading, ...props },
): React.JSX.Element => {
  const { message } = App.useApp();
  const servicesGet = useServicesGet();
  const serviceById = useServiceId();

  const [options, setOptions] = useState<Option[]>([]);
  const [servicesName, setServicesName] = useState<string | undefined>(undefined);

  const fetch = () => {
    const params: ServicesGetParams = { page: 1, take: 100 };

    if (activeOnly) {
      params.status = activeOnly;
    }

    if (servicesName) {
      params.name = servicesName;
    }

    servicesGet.fetch(params);
  };

  useEffect(() => {
    if (servicesName && servicesName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }

    if (servicesName === '') {
      fetch();
    }

    return undefined;
  }, [servicesName]);

  useEffect(() => {
    if (servicesGet.data?.data.length) {
      setOptions(servicesGet.data.data.map(({ name, id }): Option => ({ label: name, value: id })));
    }
  }, [servicesGet.data]);

  const ref = useRef<boolean>(true);

  useEffect(() => {
    if (options.length && props.value && ref.current) {
      ref.current = false;
      const current = props.value.filter((item) => !options.map(({ value }) => value).includes(item));

      if (current.length && !serviceById.loading) {
        current.forEach((value) => {
          serviceById.fetch(undefined, `${value}`)
            .then((res) => setOptions([
              ...options,
              { label: res?.name || '', value },
            ]));
        });
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (servicesGet.error) {
      message.error(getMessageInError(servicesGet.error));
      servicesGet.clearError();
    }
  }, [servicesGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      mode="multiple"
      loading={optionsLoading || servicesGet.loading || serviceById.loading}
      options={options?.length ? options : selectOptions}
      onSearch={(name) => setServicesName(name)}
      showSearch
      allowClear
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Service not found." />}
      {...props}
    />
  );
};

SelectServiceMulti.defaultProps = {
  activeOnly: undefined,
  selectOptions: [],
  optionsLoading: false,
};

export default SelectServiceMulti;
