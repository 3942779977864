import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { alertDelaySuccess, name as appName } from '../../config';
import { LabStaffMember } from '../../hooks/labStaff';
import { createLocationSearch } from '../../utils';
import { cancelableLocationSaga, defaultResponseProcessing, FetchResponse, Error } from './common';
import { Action } from '../index';
import { fetchSaga } from './auth';
import { CreateUserPayload, UpdateUserPayload } from '../../hooks/users';
import { CreateAddress } from '../../hooks/addresses';

/**
 * Constants
 * */
export const moduleName = 'lab-staff';
const prefix = `${appName}/${moduleName}`;

export const CREATE_LAB_STAFF = `${prefix}/CREATE_LAB_STAFF`;
export const CREATE_LAB_STAFF_START = `${prefix}/CREATE_LAB_STAFF_START`;
export const CREATE_LAB_STAFF_SUCCESS = `${prefix}/CREATE_LAB_STAFF_SUCCESS`;
export const CREATE_LAB_STAFF_ERROR = `${prefix}/CREATE_LAB_STAFF_ERROR`;
export const CREATE_LAB_STAFF_ERROR_RESET = `${prefix}/CREATE_LAB_STAFF_ERROR_RESET`;

export const UPDATE_LAB_STAFF = `${prefix}/UPDATE_LAB_STAFF`;
export const UPDATE_LAB_STAFF_START = `${prefix}/UPDATE_LAB_STAFF_START`;
export const UPDATE_LAB_STAFF_SUCCESS = `${prefix}/UPDATE_LAB_STAFF_SUCCESS`;
export const UPDATE_LAB_STAFF_ERROR = `${prefix}/UPDATE_LAB_STAFF_ERROR`;
export const UPDATE_LAB_STAFF_ERROR_RESET = `${prefix}/UPDATE_LAB_STAFF_ERROR_RESET`;

export const DELETE_LAB_STAFF = `${prefix}/DELETE_LAB_STAFF`;
export const DELETE_LAB_STAFF_START = `${prefix}/DELETE_LAB_STAFF_START`;
export const DELETE_LAB_STAFF_SUCCESS = `${prefix}/DELETE_LAB_STAFF_SUCCESS`;
export const DELETE_LAB_STAFF_ERROR = `${prefix}/DELETE_LAB_STAFF_ERROR`;
export const DELETE_LAB_STAFF_ERROR_RESET = `${prefix}/DELETE_LAB_STAFF_ERROR_RESET`;

export const CLEAR_LAB_STAFF_DATA = `${prefix}/CLEAR_LAB_STAFF_DATA`;

/**
 * Reducer
 * */
export interface State {
  labStaff: LabStaffMember | null;
  labStaffLoading: boolean;

  labStaffError: Error | null;

  labStaffCreateError: Error | null;
  labStaffUpdateError: Error | null;
}

const defaultState: State = {
  labStaff: null,
  labStaffLoading: false,

  labStaffError: null,

  labStaffCreateError: null,
  labStaffUpdateError: null,
};

export interface CreateLabStaff {
  position?: string;
  lab?: string;
  addresses?: CreateAddress[];
  labServices?: string[];
  labMaterials?: string[];
  labAdditionalOptions?: string[];
  user?: CreateUserPayload;
}

export interface UpdateLabStaff {
  id?: string;
  secretKey?: string;
  position?: string;
  lab?: string;
  addresses?: CreateAddress[];
  labServices?: string[];
  labMaterials?: string[];
  labAdditionalOptions?: string[];
  user?: UpdateUserPayload;
}

export default function reducer(
  state = defaultState,
  action: Action = { type: 'undefined' },
): State {
  const { type, payload } = action;

  switch (type) {
    case CREATE_LAB_STAFF_START:
      return { ...state, labStaffLoading: true };
    case CREATE_LAB_STAFF_SUCCESS:
      return { ...state, labStaffLoading: false };
    case CREATE_LAB_STAFF_ERROR:
      return { ...state, labStaffLoading: false, labStaffError: payload, labStaffCreateError: payload };
    case CREATE_LAB_STAFF_ERROR_RESET:
      return { ...state, labStaffError: null, labStaffCreateError: null };

    case UPDATE_LAB_STAFF_START:
      return { ...state, labStaffLoading: true };
    case UPDATE_LAB_STAFF_SUCCESS:
      return { ...state, labStaffLoading: false, labStaff: payload };
    case UPDATE_LAB_STAFF_ERROR:
      return { ...state, labStaffLoading: false, labStaffError: payload, labStaffUpdateError: payload };
    case UPDATE_LAB_STAFF_ERROR_RESET:
      return { ...state, labStaffError: null, labStaffUpdateError: payload };

    case CLEAR_LAB_STAFF_DATA:
      return { ...state, labStaff: null };

    default:
      return { ...state };
  }
}

/**
 * Action Creators
 * */

export const createLabStaff = (payload: CreateLabStaff): Action => ({
  type: CREATE_LAB_STAFF,
  payload,
});

export const updateLabStaff = (payload: UpdateLabStaff): Action => ({
  type: UPDATE_LAB_STAFF,
  payload,
});

export const clearLabStaffData = (): Action => ({
  type: CLEAR_LAB_STAFF_DATA,
});

/**
 * Helper functions
 */

/**
 * Sagas
 */
function* createLabStaffSaga({ payload }: { payload: CreateLabStaff; }): Generator {
  yield put({
    type: CREATE_LAB_STAFF_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/lab-staff`,
    {
      method: 'POST',
      body: { ...payload },
    },
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    CREATE_LAB_STAFF_SUCCESS,
    CREATE_LAB_STAFF_ERROR,
    false,
  );
}

function* updateLabStaffSaga({ payload }: { payload: UpdateLabStaff; }): Generator {
  yield put({
    type: UPDATE_LAB_STAFF_START,
  });

  const { id, secretKey, ...data } = payload;

  const response = secretKey
    ? (yield call(
      fetchSaga,
      `${process.env.REACT_APP_API_URL}/lab-staff/update-secret/${id}${createLocationSearch({ secretKey })}`,
      {
        method: 'PATCH',
        body: { ...data },
      },
      {},
    )) as FetchResponse
    : (yield call(
      fetchSaga,
      `${process.env.REACT_APP_API_URL}/lab-staff/${id}`,
      {
        method: 'PATCH',
        body: { ...data },
      },
      {},
    )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    UPDATE_LAB_STAFF_SUCCESS,
    UPDATE_LAB_STAFF_ERROR,
    false,
    () => data,
  );
}

export function* saga(): Generator {
  yield takeLatest(
    CREATE_LAB_STAFF,
    cancelableLocationSaga.bind(
      null,
      createLabStaffSaga,
      CREATE_LAB_STAFF_ERROR,
      false,
    ),
  );
  yield takeLatest(CREATE_LAB_STAFF_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: CREATE_LAB_STAFF_ERROR_RESET,
    });
  });

  yield takeLatest(
    UPDATE_LAB_STAFF,
    cancelableLocationSaga.bind(
      null,
      updateLabStaffSaga,
      UPDATE_LAB_STAFF_ERROR,
      false,
    ),
  );
  yield takeLatest(UPDATE_LAB_STAFF_ERROR, function* errorReset() {
    yield delay(alertDelaySuccess);
    yield put({
      type: UPDATE_LAB_STAFF_ERROR_RESET,
    });
  });
}
