import Icon from '@ant-design/icons';
import { App, Button, Col, Form, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { UserRoles } from '../../../../../../enums/user';
import { useConfirmLabStaff } from '../../../../../../hooks/labStaff';
import { Action } from '../../../../../../store';
import { Error } from '../../../../../../store/ducks/common';
import {
  moduleName,
  UpdateLabStaff,
  updateLabStaff as actionUpdateLabStaff,
} from '../../../../../../store/ducks/labStaff';
import { RootState } from '../../../../../../store/reducers';
import { capitalize } from '../../../../../../utils';
import {
  prepareLabStaffBody,
  prepareLabStaffInitialData,
  prepareLabStaffServicesInitialData,
} from '../../../../../../utils/prepareData';
import DrawerModal from '../../../../../Common/DrawerModal';
import { Send } from '../../../../../Common/Icon';
import LabStaffInfoForm from '../Forms/LabStaffInfo';
import LabStaffServices from '../Forms/LabStaffServices';
import { JsonResult } from '../../../../../../types';

interface CompleteLabStaffRegistration {
  labStaffLoading: boolean;
  labStaffError: Error | null;
  updateLabStaff: (payload: UpdateLabStaff) => Action;
}

const CompleteLabStaffRegistration: React.FC<CompleteLabStaffRegistration> = (props) => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { labStaffLoading, labStaffError, updateLabStaff } = props;
  const { token = '' } = useParams<{ token: string; }>();
  const [labId, setLabId] = useState<string>();
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const confirmLabStaff = useConfirmLabStaff();

  const [initialValues, setInitialValues] = useState<JsonResult | undefined>();
  const [servicesInitialValues, setServicesInitialValues] = useState<JsonResult | undefined>();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleDrawerClose = () => {
    if (labStaffError) return;

    navigate({ pathname: '/sign-in' }, { replace: true });
  };

  const fetchConfirmLabStaff = () => {
    confirmLabStaff.fetch({ secretKey: token });
  };

  useEffect(() => {
    if (!confirmLabStaff || !confirmLabStaff.data?.id) return;

    setInitialValues(prepareLabStaffInitialData(confirmLabStaff.data));
    setServicesInitialValues(prepareLabStaffServicesInitialData(confirmLabStaff.data?.labServices));
    setLabId(confirmLabStaff.data.lab?.id);
  }, [confirmLabStaff.data]);

  useEffect(() => {
    if (token) {
      fetchConfirmLabStaff();
    }
  }, [token]);

  useEffect(() => {
    if (labStaffError?.message) {
      message.error(labStaffError.message, 5);
    }
  }, [labStaffError]);

  const [form] = Form.useForm();
  const [servicesForm] = Form.useForm();

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const handleSubmit = () => {
    Promise.all([
      form.validateFields(),
      servicesForm.validateFields(),
    ]).then((values) => {
      const [staffValues, servicesValues] = values;
      const body = prepareLabStaffBody(
        staffValues,
        confirmLabStaff.data?.user?.role,
        staffValues?.userId,
        servicesValues,
      );

      setIsFormSubmitted(true);

      return updateLabStaff({ ...body, id: confirmLabStaff.data?.id, secretKey: token });
    });
  };

  useEffect(() => {
    if (!labStaffLoading && !labStaffError && isFormSubmitted) {
      message.success(`${capitalize(confirmLabStaff.data?.user?.role)} has been saved!`);
      handleDrawerClose();
    }
  }, [labStaffLoading]);

  return (
    <DrawerModal
      title="Complete Registration"
      open
      onClose={handleDrawerClose}
      extra={(
        <Space>
          <Button
            id="sendRegistrationLink"
            type="primary"
            icon={<Icon component={Send} />}
            disabled={!isFormValid}
            onClick={handleSubmit}
            loading={labStaffLoading}
          >
            Complete Registration
          </Button>
        </Space>
      )}
      destroyOnClose
      afterOpenChange={() => form.resetFields()}
    >
      <Row justify="center">
        <Col span={24} md={20} lg={16} xxl={12}>
          <div>
            <Form
              form={form}
              name="staff_create"
              layout="vertical"
              autoComplete="off"
              initialValues={initialValues}
              onFieldsChange={() => setIsFormValid(!form.getFieldsError().some(({ errors }) => errors.length))}
            >
              <LabStaffInfoForm formType="update" hasPassword />
              {confirmLabStaff.data?.user?.role === UserRoles.labStaff && (
                <LabStaffServices isPublic form={servicesForm} labId={labId} initialValues={servicesInitialValues} />
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </DrawerModal>
  );
};

const mapStateToProps = (state: RootState) => ({
  labStaffLoading: state[moduleName].labStaffLoading,
  labStaffError: state[moduleName].labStaffError,
});

const mapDispatchToProps = {
  updateLabStaff: actionUpdateLabStaff,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteLabStaffRegistration);
