import { PagingDataResponse } from '../types';
import {
  DefaultFetchError, FetchCreate,
  FetchGet,
  FetchGetId, useFetchCreate,
  useFetchGet,
  useFetchGetId,
} from './fetch';
import { Practice } from './practices';
import { User } from './users';

export interface DoctorInterface {
  id: string;
  note: string;
  nickname?: string;
  specialty?: string;
  position?: string;
  isPasswordSet?: boolean;
  practice: Practice | null;
}

export interface Doctor extends DoctorInterface {
  user: User | null;
}

export interface DoctorsGetParams {
  page?: number;
  take?: number;
  name?: string;
  fullName?: string;
  status?: string;
  email?: string;
  phone?: string;
  address?: string;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'fullName' | 'nickname' | 'status' | 'email' | 'phone' | 'role' | 'specialty' | 'position';
  practice?: string;
}

export interface TableDoctorRow extends Doctor {
  key: string;
}

interface DoctorsTableData {
  doctors: TableDoctorRow[];
  total: number;
}

// eslint-disable-next-line max-len
export const useTableDoctorRow = (): FetchGet<PagingDataResponse<Doctor>, DoctorsGetParams, DefaultFetchError, DoctorsTableData> => (
  useDoctorsGet((data: PagingDataResponse<Doctor>): DoctorsTableData => ({
    doctors: data.data.map((doctor: Doctor): TableDoctorRow => ({
      key: doctor.id,
      ...doctor,
    })),
    total: data.meta.itemCount,
  }))
);

export function useDoctorsGet<D = PagingDataResponse<Doctor>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, DoctorsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, DoctorsGetParams, DD>(
    'doctors',
    { decorateData, autoStart: false, startStateLoading: false },
  );
}

export const useDoctorId = (): FetchGetId<Doctor> => useFetchGetId('doctors', '', {
  autoStart: false, startStateLoading: false });

export interface DoctorConfirm {
  secretKey: string;
}

export const useConfirmDoctor = (): FetchCreate<Doctor, DefaultFetchError, DoctorConfirm> => useFetchCreate(
  'doctors/confirm-doctor',
  {
    authorization: false,
  },
);

export const useDoctorResendLink = (): FetchGetId<Doctor> => useFetchGetId('doctors', '', {
  autoStart: false, startStateLoading: false });
