import React, { useState } from 'react';
import { Col, Form, Input, Row, Select, Typography } from 'antd';

import { connect } from 'react-redux';
import ContentCard from '../../../../Common/ContentCard';
import ControlsEditMode from '../../../../Common/ControlsEditMode';
import { required } from '../../../../../utils/inputRules';
import SelectDoctor from '../../../../Common/Selects/SelectDoctor';
import { moduleName, User } from '../../../../../store/ducks/auth';
import { RootState } from '../../../../../store/reducers';
import { isRoleEnough } from '../../../../../utils/auth';

interface PatientInfoForm {
  cardLoading?: boolean;
  formType?: 'create' | 'update';
  editMode?: {
    onSubmit: () => void;
    onCancel: () => void;
    isFormValid?: boolean;
    loading?: boolean;
  };
  modalDoctorId?: string | number;
  disabledEdit?: boolean;
  user?: User | null;
}

const PatientInfoForm: React.FC<PatientInfoForm> = (props) => {
  const { cardLoading, editMode, modalDoctorId, disabledEdit, user } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isDisabledField = disabledEdit || !!(editMode && !isEditing);

  const handleCancel = () => {
    if (!editMode) return;
    editMode.onCancel();

    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (!editMode) return;

    editMode.onSubmit();
    setIsEditing(false);
  };

  return (
    <div>
      <Typography.Title level={4}>Personal Information</Typography.Title>
      <ContentCard loading={cardLoading}>
        <Row gutter={15}>
          <Col span={24}>
            {/** For users with role doctor render hidden form item which save data, but don't show up */}
            {isRoleEnough('practice manager') ? (
              <Form.Item
                label="Doctor"
                name="doctor"
                rules={required}
              >
                {modalDoctorId ? (
                  <Select
                    placeholder="Type here"
                    options={[{ label: `${user?.firstName} ${user?.lastName}`, value: modalDoctorId }]}
                    disabled
                  />
                ) : (<SelectDoctor disabled={isDisabledField} onlyStatus="active" />)}
              </Form.Item>
            ) : (
              <Form.Item name="doctor" className="hidden-input">
                <Input type="hidden" />
              </Form.Item>
            )}
          </Col>

          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={required}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={required}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
              />
            </Form.Item>
          </Col>

          {/** Uncomment if patient form should have 'Email' and 'Phone' fields
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[...email]}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Contact Phone"
              name="phone"
              rules={[...phoneNumber]}
            >
              <PhoneNumberInput disabled={isDisabledField} />
            </Form.Item>
          </Col> */}
          {
            !isRoleEnough('doctor', true) && (
              <Col span={24}>
                <Form.Item
                  label="Notes"
                  name="note"
                >
                  <Input.TextArea rows={4} placeholder="Notes (optional)" disabled={isDisabledField} />
                </Form.Item>
              </Col>
            )
          }

        </Row>
        {editMode && (
          <ControlsEditMode
            isEditing={isEditing}
            onCancel={handleCancel}
            onChangeEditing={setIsEditing}
            onSubmit={handleSubmit}
            isFormValid={typeof editMode.isFormValid !== 'undefined' ? editMode.isFormValid : true}
            loading={editMode.loading}
          />
        )}
      </ContentCard>
    </div>
  );
};

PatientInfoForm.defaultProps = {
  formType: 'update',
  cardLoading: false,
  editMode: undefined,
  modalDoctorId: undefined,
  disabledEdit: false,
  user: undefined,
};

export default connect((state: RootState) => ({
  user: state[moduleName].user,
}))(PatientInfoForm);
