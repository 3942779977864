import { PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchGet, FetchGetId,
  useFetchGet, useFetchGetId,
} from './fetch';

export interface CreateAddress {
  id?: string;
  name?: string;
  street?: string;
  apartment?: string;
  country?: string;
  state?: string;
  city?: string;
  cityString?: string;
  code?: string;
  status?: string;
  sameAsBilling?: boolean;
  asShipping?: boolean;
}

export interface Country {
  id: string;
  name: string;
  iso3: string;
}

export interface State {
  id: string;
  name: string;
  countryCode: string;
}

export interface City {
  id: string;
  name: string;
  stateCode: string;
}

export interface Address {
  id?: string;
  name?: string;
  street: string;
  apartment: string;
  country: Country;
  state: State;
  city?: City;
  cityString: string;
  code: string;
  status: string;
  sameAsBilling: boolean;
  asShipping: boolean;
}

export interface CountriesGetParams {
  search?: string;
  page?: number;
  take?: number;
  orderBy?: string;
  orderByColumn?: 'name' | 'id';
}

export interface StatesGetParams {
  search?: string;
  page?: number;
  take?: number;
  country?: number;
  orderBy?: string;
  orderByColumn?: 'name' | 'id';
}

export interface CitiesGetParams {
  search?: string;
  page?: number;
  take?: number;
  state?: number;
  orderBy?: string;
  orderByColumn?: 'name' | 'id';
}

export function useCountriesGet<D = PagingDataResponse<Country>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, CountriesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, CountriesGetParams, DD>(
    'countries',
    { decorateData, autoStart: false, authorization: false },
  );
}

export const useCountryId = (): FetchGetId<Country> => useFetchGetId(
  'countries',
  '',
  { autoStart: true, authorization: false },
);

export function useStatesGet<D = PagingDataResponse<State>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, StatesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, StatesGetParams, DD>(
    'states',
    { decorateData, autoStart: false, startStateLoading: false, authorization: false },
  );
}

export const useStateId = (): FetchGetId<State> => useFetchGetId(
  'states',
  '',
  { startStateLoading: false, authorization: false },
);

export function useCitiesGet<D = PagingDataResponse<City>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, CitiesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, CitiesGetParams, DD>(
    'cities',
    { decorateData, autoStart: false, startStateLoading: false, authorization: false },
  );
}

export const useCityId = (): FetchGetId<City> => useFetchGetId(
  'cities',
  '',
  { startStateLoading: false, authorization: false },
);
