import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { App, Button, Divider } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import AttachmentLoader from '../../../../../../Common/AttachmentLoader';
import { formatChatDate } from '../../../../ChatsList/Item';
import { IMessageWithId, useDeleteMessage } from '../../../../../../../hooks/chats';
import { useContextChats } from '../../../../../../../context/chats';
import { User } from '../../../../../../../hooks/users';
import { capitalizeFirstLetter, getUserFullName } from '../../../../../../../utils';
import { getMessageInError } from '../../../../../../../hooks/fetch';
import confirm from '../../../../../../Common/ModalConfirm';

import styles from './index.module.scss';

interface IChatMessageProps extends IMessageWithId {
  isMessageOwner: boolean;
  isLastRead: boolean;
}

const Message: React.FC<IChatMessageProps> = ({
  id,
  message,
  timestamp,
  speakerId,
  files,
  isMessageOwner,
  isLastRead,
}) => {
  const { message: antdMessage, modal } = App.useApp();
  const [searchParams] = useSearchParams();
  const roomId = searchParams.get('id') || '';
  const { roomUsers } = useContextChats();
  const deleteMessage = useDeleteMessage();

  const [currentMessageUser, setCurrentMessageUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    if (roomUsers?.[roomId]) {
      setCurrentMessageUser(roomUsers[roomId]?.find((user) => user.id === speakerId));
    }
  }, [roomUsers?.[roomId]]);

  const handleDelete = () => {
    confirm({
      modal,
      title: 'Delete message',
      content: 'Are you sure you want to delete this message?',
      onOk: () => deleteMessage.fetch(`${roomId}/messages/${id}`)
        .then((res) => {
          if (res) { antdMessage.success('Message deleted!'); }
        }),
    });
  };

  useEffect(() => {
    if (deleteMessage.error) {
      antdMessage.error(getMessageInError(deleteMessage.error));
      deleteMessage.clearError();
    }
  }, [deleteMessage.error]);

  return (
    <div
      className={clsx(styles.message, {
        [styles.owner]: isMessageOwner,
      })}
      id={id}
    >
      <div className={styles.head}>
        <div className={styles.userInfo}>
          <span className={styles.name}>{getUserFullName(currentMessageUser)}</span>
          <span className={styles.role}>{capitalizeFirstLetter(currentMessageUser?.role || '-')}</span>
        </div>
        {isMessageOwner && (
          <Button
            size="small"
            style={{ height: '28px', width: '28px', color: 'var(--color-icons-input)' }}
            type="link"
            icon={<DeleteOutlined style={{ fontSize: '16px' }} />}
            onClick={handleDelete}
            title="Delete message"
          />
        )}
      </div>

      <p className={clsx(styles.content)}>
        {message}
      </p>

      <div className={clsx(styles.extra)}>
        {files?.length && (
          <div className={styles.file}>
            <AttachmentLoader
              disabled
              hideControls
              onUpdate={undefined}
              defaultFiles={(files || []).map((chatFile) => ({
                file: {
                  id: chatFile?.fileId || '',
                  originalName: `${chatFile.originalName}`,
                  mimeType: chatFile?.mimeType || '',
                  createdAt: '',
                },
                id: chatFile.fileId,
                url: '-',
                removed: false,
              }))}
              handleFileDelete={undefined}
              commentView
            />
          </div>
        )}
        <span className={styles.dateTime}>
          {formatChatDate(timestamp?.seconds || 0, true)}
        </span>
      </div>
      <Divider style={{ margin: '4px 0' }}>
        {isLastRead ? <div>New messages ↓</div> : null}
      </Divider>
    </div>
  );
};

export default Message;
