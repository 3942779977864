import { FormInstance, Rule } from 'antd/es/form';
import React, { ReactNode, useEffect, useState } from 'react';
import { Alert, Col, Form, Input, Row, Space, Typography } from 'antd';
import { JsonResult } from '../../../../../types';
import { confirmPassword, email, password, phoneNumber, required } from '../../../../../utils/inputRules';

import ContentCard from '../../../../Common/ContentCard';
import ControlsEditMode from '../../../../Common/ControlsEditMode';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';

interface PracticeManagerInfo {
  initialData?: JsonResult;
  loading?: boolean;
  editMode?: boolean;
  isAdmin?: boolean;
  hasPassword?: boolean;
  disabled?: boolean;
  onSubmit?: () => void;
  message?: string | ReactNode;
  handleChange?: (field: JsonResult[]) => void;
  children?: React.ReactNode;
  form?: FormInstance;
  requiredFieldsType?: 'create' | 'completeRegistration';
}

const PracticeManagerInfo: React.FC<PracticeManagerInfo> = (props) => {
  const {
    loading,
    disabled,
    isAdmin,
    initialData,
    editMode,
    hasPassword,
    onSubmit,
    children,
    form,
    handleChange,
    requiredFieldsType,
    message,
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isDisabledField = disabled || !!(editMode && !isEditing);

  const isCompleteRegistrationForm = requiredFieldsType === 'completeRegistration';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isRequiredCompleteRegisterRule: Rule[] = requiredFieldsType === 'completeRegistration' ? required : [];

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (!onSubmit) return;

    onSubmit();
    setIsEditing(false);
  };

  const handleFormSubmit = () => {
    form?.submit();
  };

  useEffect(() => {
    if (!initialData) return;

    form?.setFieldsValue(initialData);
  }, [initialData]);

  return (
    <div>
      <Typography.Title level={4}>Practice Manager Personal Information</Typography.Title>
      <ContentCard loading={loading}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {message && <Alert message={message} type="info" />}
          <Form
            form={form}
            layout="vertical"
            initialValues={initialData}
            onFinish={onSubmit && handleSubmit}
            onFieldsChange={handleChange}
          >
            <Form.Item name="id" style={{ height: 0, margin: 0 }}>
              <Input type="hidden" disabled />
            </Form.Item>
            <Form.Item name="userId" style={{ height: 0, margin: 0 }}>
              <Input type="hidden" disabled />
            </Form.Item>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={!isAdmin || isCompleteRegistrationForm ? required : undefined}
                >
                  <Input
                    placeholder="Type here"
                    disabled={isDisabledField}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={!isAdmin || isCompleteRegistrationForm ? required : undefined}
                >
                  <Input
                    placeholder="Type here"
                    disabled={isDisabledField}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  label="Nickname"
                  name="nickname"
                >
                  <Input
                    placeholder="Type here"
                    disabled={isDisabledField}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Speciality"
                  name="specialty"
                >
                  <Input
                    placeholder="Type here"
                    disabled={isDisabledField}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[...required, ...email]}
                >
                  <Input
                    placeholder="Type here"
                    disabled={isDisabledField}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Contact Phone"
                  name="phone"
                  rules={!isAdmin || isCompleteRegistrationForm ? [...required, ...phoneNumber] : phoneNumber}
                >
                  <PhoneNumberInput disabled={isDisabledField} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15}>
              <Col span={24}>
                <Form.Item
                  label="Position"
                  name="position"
                >
                  <Input
                    placeholder="Type here"
                    disabled={isDisabledField}
                  />
                </Form.Item>
              </Col>
            </Row>
            {hasPassword && (
              <Row gutter={15}>
                <Col span={12}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[...password]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Type here"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[...required, ...confirmPassword]}
                    dependencies={['password']}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Type here"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
          {editMode && (
            <ControlsEditMode
              isEditing={isEditing}
              onCancel={handleCancel}
              onChangeEditing={setIsEditing}
              onSubmit={handleFormSubmit}
              isFormValid
            />
          )}
          {children}
        </Space>
      </ContentCard>
    </div>
  );
};

PracticeManagerInfo.defaultProps = {
  initialData: {},
  loading: false,
  editMode: false,
  isAdmin: false,
  disabled: false,
  hasPassword: false,
  onSubmit: () => undefined,
  handleChange: () => undefined,
  children: null,
  form: undefined,
  requiredFieldsType: 'create',
  message: undefined,
};

export default PracticeManagerInfo;
