import { SelectProps } from 'antd/lib/select';
import { App, Select } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Option } from '../../../../types';
import { getMessageInError } from '../../../../hooks/fetch';
import NotFoundContent from '../../NotFoundContent';
import { UserStatus } from '../../../../enums/user';
import { LabStaffGetParams, useLabStaffGet, useLabStaffId } from '../../../../hooks/labStaff';
import { useUserId } from '../../../../hooks/users';

interface SelectChatParticipantsMulti extends SelectProps<string[], Option> {
  optionsLoading?: boolean;
  lab?: string;
  onlyStatus?: UserStatus;
  getValueFromUser?: boolean;
  hiddenOptionsList?: string[];
}

const SelectChatParticipantsMulti: React.FC<SelectChatParticipantsMulti> = ({
  optionsLoading,
  lab,
  onlyStatus,
  getValueFromUser,
  hiddenOptionsList,
  ...props
}): React.JSX.Element => {
  const { message } = App.useApp();
  const labStaffGet = useLabStaffGet();
  const userById = useUserId();

  const [options, setOptions] = useState<Option[]>([]);
  const [labStaffName, setLabStaffName] = useState<string>('');

  const fetch = () => {
    const params: LabStaffGetParams = { page: 1, take: 100, orderBy: 'ASC', orderByColumn: 'name' };

    if (lab) { params.labs = [lab]; }

    if (onlyStatus) {
      params.userStatus = onlyStatus;
    }

    if (labStaffName) {
      params.name = labStaffName;
    }

    labStaffGet.fetch(params);
  };

  useEffect(() => {
    if (labStaffName && labStaffName.length > 1) {
      const id = setTimeout(fetch, 700);

      return () => clearTimeout(id);
    }

    if (labStaffName === '') {
      fetch();
    }

    return undefined;
  }, [labStaffName, lab]);

  useEffect(() => {
    if (labStaffGet.data?.data.length) {
      setOptions(labStaffGet.data.data.map(({ user, id }): Option => (
        { label: `${user?.firstName} ${user?.lastName}`, value: getValueFromUser ? user?.id || '' : id }
      ))
        /** filter out hidden options from list. */
        .filter((option) => !hiddenOptionsList?.includes(`${option.value}`)));
    }
  }, [labStaffGet.data]);

  const ref = useRef<boolean>(true);

  useEffect(() => {
    if (options.length && props.value && ref.current) {
      ref.current = false;
      const current = props.value.filter((item) => !options.map(({ value }) => value).includes(item));

      if (current.length && !userById.loading) {
        current.forEach((value) => {
          userById.fetch(undefined, `${value}`)
            .then((res) => setOptions([
              ...options,
              { label: `${res?.firstName} ${res?.lastName}`, value },
            ]));
        });
      }
    }
  }, [options, props.value]);

  useEffect(() => {
    if (labStaffGet.error) {
      message.error(getMessageInError(labStaffGet.error));
      labStaffGet.clearError();
    }
  }, [labStaffGet.error]);

  const filterOption = useCallback((input: string, option: Option | undefined) => (
    typeof option?.label === 'string' && option.label.toLowerCase().includes(input.toLowerCase())
  ), []);

  return (
    <Select
      mode="multiple"
      loading={optionsLoading || labStaffGet.loading || userById.loading}
      options={options}
      onSearch={(name) => setLabStaffName(name)}
      showSearch
      allowClear
      placeholder="Please select"
      filterOption={filterOption}
      notFoundContent={<NotFoundContent message="Staff not found." />}
      {...props}
      value={(labStaffGet.loading || userById.loading) && !options.length ? ['Loading...'] : props.value}
    />
  );
};

SelectChatParticipantsMulti.defaultProps = {
  optionsLoading: false,
  lab: undefined,
  onlyStatus: undefined,
  getValueFromUser: false,
  hiddenOptionsList: [],
};

export default SelectChatParticipantsMulti;
