import React, {
  ReactNode,
  useCallback, useEffect, useState,
} from 'react';
import { Calendar as AntdCalendar } from 'antd';
import type { Dayjs } from 'dayjs';
import type { CellRenderInfo } from 'rc-picker/lib/interface';
import dayjs from 'dayjs';
import clsx from 'clsx';
import Header, { HeaderPropsExport } from './Header';
import Day from './Day';
import CalendarProvider, { useContextCalendar } from './Context';
import Modal from './Day/Modal';
import { useSearchParams } from '../../../hooks/useSearchParams';
import Loading from '../Loading';
import { dateFormat } from '../../../contstant';
import { SimpleObject } from '../../../store/ducks/common';
import { useCalendarMonthGet } from '../../../hooks/case/calendar';
import { useMessageError } from '../../../hooks/common';
import { isRoleEnough } from '../../../utils/auth';

import styles from './index.module.scss';

interface WrapperProps extends SimpleObject {
  action?: boolean;
  deactivated?: boolean;
  onFiltersClick?: () => void;
}

function Wrapper(props: WrapperProps): React.JSX.Element {
  return (
    <CalendarProvider>
      <Calendar {...props} />
    </CalendarProvider>
  );
}

Wrapper.defaultProps = {
  action: undefined,
  deactivated: undefined,
  onFiltersClick: () => undefined,
};

interface CalendarProps extends WrapperProps {
  name?: string;
  headerChildren?: React.JSX.Element;
}

function Calendar({
  action, name, className, deactivated, headerChildren, onFiltersClick, ...props
}: CalendarProps): React.JSX.Element {
  const [date, setDate] = useState<Dayjs>(dayjs().utc());
  const { setSelected } = useContextCalendar();
  const caseMonthGet = useCalendarMonthGet();
  const [,, paramsWithoutTableProps] = useSearchParams();

  useMessageError([caseMonthGet]);

  useEffect(() => {
    if (date && deactivated !== true) {
      setSelected(undefined);

      if (isRoleEnough('admin') && !paramsWithoutTableProps?.practice) { return; }

      const month = (date?.get('month') || 0) + 1;

      caseMonthGet.fetch({
        ...paramsWithoutTableProps,
        dueDateMonth: `${date?.get('year') || 1}-${month > 9 ? month : `0${month}`}-01T00:00:00.000Z`,
      });
    }
  }, [date, paramsWithoutTableProps]);

  const customHeader = useCallback((propsHeader: HeaderPropsExport) => (
    <Header
      {...propsHeader}
      name={name}
      setDate={setDate}
      onFiltersClick={onFiltersClick}
    >
      {headerChildren}
    </Header>
  ), [setDate]);

  const cellRender = (current: Dayjs, info: CellRenderInfo<Dayjs>): ReactNode => {
    if (info.type === 'date') {
      return (
        <Day date={date} value={current} caseMonthGet={caseMonthGet} />
      );
    }

    return info.originNode;
  };

  return (
    <div style={{ position: 'relative' }}>
      <AntdCalendar
        {...props}
        className={clsx(className, styles.calendar)}
        headerRender={customHeader}
        cellRender={cellRender}
        onSelect={(value, { source }) => {
          if (source !== 'customize') {
            setSelected((beforeDate) => {
              if (beforeDate && beforeDate.date && beforeDate.date.format(dateFormat) === value.format(dateFormat)) {
                return beforeDate;
              }

              if (value.month() !== date.month()) {
                setDate(value);
              }

              return { date: value };
            });
          }
        }}
      />

      {caseMonthGet.loading ? (
        <div className={styles.loading}>
          <Loading size="large" visible />
        </div>
      ) : null}

      {!caseMonthGet.loading && (isRoleEnough('admin') && !paramsWithoutTableProps?.practice) ? (
        <div className={styles.loading}>
          <p>
            Please, select practice filter to browse the cases in the calendar view.
          </p>
        </div>
      ) : null}

      <Modal caseMonthGet={caseMonthGet} contentId="#layout-content" />
    </div>
  );
}

Calendar.defaultProps = {
  name: undefined,
  headerChildren: undefined,
  ...Wrapper.defaultProps,
};

export default Wrapper;
