import { ServiceStatus } from '../enums/services';
import { PagingDataResponse } from '../types';
import { AdditionalOption } from './additionalOptions';
import { DefaultFetchError, FetchGet, FetchGetId, useFetchGet, useFetchGetId } from './fetch';
import { Material } from './materials';
import { CaseService } from './case/cases';
import { ServiceType } from '../enums/case';

export interface Service {
  id: string;
  name: string;
  serviceType: ServiceType;
  status: ServiceStatus;
  materials?: Material[];
  additionalOptions?: AdditionalOption[];
}

export interface LabServiceTable {
  id: string;
  labAdditionalOptions?: AdditionalOption[];
  labMaterials?: Material[];
  caseServices?: CaseService[];
  service: Service;
  status?: 'active' | 'deactivated';
}

export interface LabService {
  id?: string;
  name?: string;
  labAdditionalOptions?: AdditionalOption[];
  labMaterials?: Material[];
  caseServices?: CaseService[];
  status?: 'active' | 'deactivated';
  // eslint-disable-next-line
  service?: any;
}

export interface ServiceTableRow {
  key: string;
  id: string;
  name: string;
  status: string;
}

export interface ServicesTableData {
  services: ServiceTableRow[];
  total: number;
}

export interface ServicesGetParams {
  page?: number;
  take?: number;
  status?: string; // 'active' | 'deactivated'
  name?: string;
  orderBy?: 'ASC';
  orderByColumn?: 'createdAt' | 'id'; // | 'created' | 'updated'
}

export function useServicesGet<D = PagingDataResponse<Service>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ServicesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ServicesGetParams, DD>(
    'services',
    { decorateData, autoStart: false, startStateLoading: false },
  );
}

// eslint-disable-next-line max-len
export const useTableServiceRow = (): FetchGet<PagingDataResponse<Service>, ServicesGetParams, DefaultFetchError, ServicesTableData> => (
  useServicesGet((data: PagingDataResponse<Service>): ServicesTableData => ({
    services: data.data.map((service: Service): ServiceTableRow => ({
      id: service.id,
      key: service.id,
      name: service.name,
      status: service.status,
    })),
    total: data.meta.itemCount,
  }))
);

export interface LabServicesGetParams {
  page?: number;
  take?: number;
  name?: string;
  lab?: string;
  isActive?: boolean;
  service?: string;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'name';
}

export const useLabServicesGet = () => useFetchGet<
  PagingDataResponse<LabServiceTable>, DefaultFetchError, LabServicesGetParams>(
    'lab-services',
    { autoStart: false, startStateLoading: false },
  );

export const useLabServicesPublicGet = () => useFetchGet<
  PagingDataResponse<LabServiceTable>, DefaultFetchError, LabServicesGetParams>(
    'lab-services/public',
    { autoStart: false, startStateLoading: false, authorization: false },
  );

export const useServiceId = (): FetchGetId<Service> => useFetchGetId(
  'services',
  '',
  { autoStart: false, startStateLoading: false },
);

export const useLabServiceId = (): FetchGetId<LabServiceTable> => useFetchGetId(
  'lab-services',
  '',
  { autoStart: false, startStateLoading: false },
);
