import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Logo as LogoCommon } from '../../../../Common/Icon';
import { RootState } from '../../../../../store/reducers';
import { moduleName as authModuleName, User } from '../../../../../store/ducks/auth';

import styles from './index.module.scss';

interface ILogo {
  link?: string;
  user: User | null;
}

const Logo: React.FC<ILogo> = ({ link, user }) => (
  <NavLink to={link || '/users'}>
    {user?.doctor?.practice?.file ? (
      <div className={styles.logoWrapper}>
        <img src={user?.doctor?.practice?.file?.realPath || ''} alt="logo" />
      </div>
    ) : (
      <LogoCommon />
    )}
  </NavLink>
);

Logo.defaultProps = {
  link: '/users',
};

export default connect((state: RootState) => ({
  user: state[authModuleName].user,
}), {})(Logo);
