export enum UserStatuses {
  active = 'Active',
  deactivated = 'Deactivated',
  pending = 'Pending',
  archived = 'Archived',
}

export type UserStatus = 'active' | 'deactivated' | 'pending' | 'archived';

export enum UserRolesOptions {
  // patient = 'Patient',
  doctor = 'Doctor',
  'practice manager' = 'Practice Manager',
  'lab manager' = 'Lab Manager',
  'lab staff' = 'Lab Staff',
  admin = 'Admin',
}

export enum UserRoles {
  patient = 'patient',
  doctor = 'doctor',
  practiceManager = 'practice manager',
  labManager = 'lab manager',
  labStaff = 'lab staff',
  admin = 'admin',
  root = 'root',
}

export type UserRole = 'patient' | 'lab staff' | 'lab manager' | 'doctor' | 'practice manager' | 'admin' | 'root';
