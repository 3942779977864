import { FetchSuccess, PagingDataResponse } from '../types';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';
import { LabStatus } from '../enums/lab';
import { LabStaffMember } from './labStaff';
import { LabService } from './services';
import { Address } from './addresses';

export interface Lab {
  id: string;
  name: string;
  accountNumber: string;
  status: LabStatus;
  email: string;
  phone: string;
  note: string;
  addresses: Address[];
  labServices: LabService[];
  labStaff: LabStaffMember[];
}

export interface LabsGetParams {
  name?: string;
  status?: LabStatus;
  accountNumber?: string;
  email?: string;
  phone?: string;
  address?: string;
  labServices?: string[];
  services?: string[];
  serviceAdditionalOptions?: string[];
  removed?: boolean;
  page?: number;
  take?: number;
  dueDates?: string[];
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: 'id' | 'name' | 'accountNumber' | 'status' | 'email' | 'phone';
}

export interface LabConfirm {
  secretKey: string;
}

export interface TableLabRow extends Lab {
  key: string;
}

export interface LabTableData {
  labs: TableLabRow[];
  total: number;
}

export const useTableLabRow = (): FetchGet<PagingDataResponse<Lab>, LabsGetParams, DefaultFetchError, LabTableData> => (
  useLabsGet((data: PagingDataResponse<Lab>): LabTableData => ({
    labs: data.data.map((lab: Lab): TableLabRow => ({
      key: lab.id,
      ...lab,
    })),
    total: data.meta.itemCount,
  }))
);

export function useLabsGet<D = PagingDataResponse<Lab>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, LabsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LabsGetParams, DD>(
    'labs',
    { decorateData, autoStart: false },
  );
}

export const useTableLabCalcCaseRow = (): FetchGetId<
  PagingDataResponse<Lab>, DefaultFetchError, LabsGetParams, LabTableData> => (
  useLabsCalcCaseGet((data: PagingDataResponse<Lab>): LabTableData => ({
    labs: data.data.map((lab: Lab): TableLabRow => ({
      key: lab.id,
      ...lab,
    })),
    total: data.meta.itemCount,
  }))
);

export function useLabsCalcCaseGet<D = PagingDataResponse<Lab>,
  DD = D>(decorateData?: (data: D) => DD): FetchGetId<D, DefaultFetchError, LabsGetParams, DD> {
  return useFetchGetId<D, DefaultFetchError, LabsGetParams, DD>(
    'labs/calculate-case',
    '',
    { decorateData, autoStart: false },
  );
}

export const useLabId = (): FetchGetId<Lab> => useFetchGetId('labs', '', { autoStart: false });

export const useConfirmLab = (): FetchCreate<Lab, DefaultFetchError, LabConfirm> => useFetchCreate(
  'labs/confirm-lab',
  {
    authorization: false,
  },
);

export const useUpdateLabStatus = (): FetchUpdate<FetchSuccess, DefaultFetchError, { status: LabStatus; }> => (
  useFetchUpdate('labs')
);

export const useLabResendLink = (): FetchGetId<Lab> => useFetchGetId('labs', '', {
  autoStart: false, startStateLoading: false });
