import React, { useEffect, useState } from 'react';
import { App, Col, Form, Row, Space, Typography } from 'antd';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Action } from '../../../../store';
import { updateCase as actionUpdateCase, UpdateCase, moduleName as casesModule } from '../../../../store/ducks/cases';
import { User, moduleName as authModuleName } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { DefaultModalProps } from '../../../../types';
import {
  prepareCaseAssignLabBody, prepareCaseAssignLabInitialData, PreparedCaseAssignLabInitialData,
} from '../../../../utils/prepareData';
import DrawerModal from '../../../Common/DrawerModal';
import { Error as ErrorCommon } from '../../../../store/ducks/common';
import { useContextCaseFlow } from '../../../../context/caseFlow';
import Loading from '../../../Common/Loading';
import AssignServiceForm from '../Forms/AssignServiceForm';
import AssignLabTable from '../AssignLabTable';
import { TableLabRow } from '../../../../hooks/labs';
import { getDaysDiffFromToday } from '../../../../utils';
import { countDaysOff } from '../../../../utils/datePickerHelpers';
import PriceBlock from '../../../Common/PriceBlock';

export interface IDueDateSize {
  parentServiceId: string;
  dateSize: number;
}

interface AssignLab extends DefaultModalProps {
  id: string | number;
  //
  title: string;
  close: () => void;
  isAdmin?: boolean;
  disabled?: {
    [key: string]: boolean;
  };
  handleSave?: (body: UpdateCase) => void;
  loading: boolean;
  error: ErrorCommon | null;
  updateCase: (payload: UpdateCase) => Action;
  user: User | null;
}

const AssignLab = (props: AssignLab): React.JSX.Element => {
  const { message } = App.useApp();
  const {
    id,
    handleSave,
    title,
    isOpen,
    close,
    loading,
    error,
    updateCase,
  } = props;

  const {
    caseByIdInContext,
    practicePriceLevelServiceInContext,
    getPracticePriceLevelByPriceParentServiceId,
    eventsListGetInContext,
  } = useContextCaseFlow();

  const [assignServiceForm] = Form.useForm();

  /** Get case info by id */
  const fetchCaseById = () => {
    caseByIdInContext?.fetch(undefined, id);
  };

  useEffect(() => {
    if (id && isOpen) {
      fetchCaseById();
    }
  }, [id]);

  const [initialValues, setInitialValues] = useState<PreparedCaseAssignLabInitialData>({});
  const [dueDates, setDueDates] = useState<IDueDateSize[]>([]);

  useEffect(() => {
    if (caseByIdInContext?.data && (caseByIdInContext?.data?.practice || practicePriceLevelServiceInContext?.data)) {
      setInitialValues(prepareCaseAssignLabInitialData(
        caseByIdInContext?.data,
        (caseByIdInContext?.data?.practice?.priceLevelServices
        || practicePriceLevelServiceInContext?.data?.priceLevel?.priceLevelServices),
      ));
    }
  }, [caseByIdInContext?.data, practicePriceLevelServiceInContext?.data]);

  useEffect(() => {
    if (assignServiceForm && caseByIdInContext?.data && !caseByIdInContext.error) {
      assignServiceForm.resetFields();
      assignServiceForm.setFieldsValue(initialValues);
    }
  }, [caseByIdInContext?.data, practicePriceLevelServiceInContext?.data, initialValues]);

  /** Handling close/next/back/submit buttons  */
  const handleClose = () => {
    close();
    assignServiceForm.resetFields();
    setDueDates([]);
  };

  useEffect(() => {
    if (!loading && !error) {
      /** close modal, when request finish without errors */
      handleClose();
    }
  }, [loading]);

  useEffect(() => {
    if (error?.message && isOpen) {
      message.error(error.message, 5);
    }
  }, [error]);

  const handleSubmit = (lab: TableLabRow) => {
    assignServiceForm.validateFields().then((values) => {
      // assignServiceForm returns just array of id's but i need whole service object here. so i do this:

      const selectedServices = (caseByIdInContext?.data?.practice?.priceLevelServices
        || practicePriceLevelServiceInContext?.data?.priceLevel.priceLevelServices)
        ?.filter((service) => (values?.services?.includes(service?.id || '')))
        ?.map((service) => ({
          ...service,
          priceLevelAdditionalOptions: service.priceLevelAdditionalOptions?.filter((priceAdditionalOption) => (
            caseByIdInContext?.data?.teeth?.services?.find((serv) => serv.id === service.id)?.additionalOptions
              ?.some((additionalOption) => additionalOption?.id === priceAdditionalOption?.id || '')
          )),
        }
        )) || [];

      const body = prepareCaseAssignLabBody(
        selectedServices,
        lab,
        values,
        dueDates.map((date) => date.dateSize),
        caseByIdInContext?.data?.dueDate,
        caseByIdInContext?.data?.caseServices,
      );

      if (handleSave) {
        handleSave(body);
      } else {
        updateCase({ ...body, id: `${id}` });
      }
    }).catch(() => message.error('Fill in all required fields!'));
  };

  const servicesArrValues: string[] = Form.useWatch('services', assignServiceForm);

  const [selectedPriceParentServices, setSelectedPriceParentServices] = useState<string[]>([]);
  const [selectedParentAdditionalOptions, setSelectedParentAdditionalOptions] = useState<string[]>([]);

  // Param to filter assign lab table by dueDate
  const [triggerReloadParams, setTriggerReloadParams] = useState();

  useEffect(() => {
    if (selectedPriceParentServices) {
      setDueDates(selectedPriceParentServices.map((parentServiceId) => {
        const dueDateFormValue = assignServiceForm
          .getFieldValue(`dueDate-${getPracticePriceLevelByPriceParentServiceId(parentServiceId)?.id}`);

        return {
          parentServiceId,
          /** If due date for this service selected, then get difference in days, if not - then set '0' */
          dateSize: dueDateFormValue
            ? getDaysDiffFromToday(dayjs(dueDateFormValue))
              - countDaysOff(dayjs(dueDateFormValue), eventsListGetInContext?.data?.data || [])
              + 1
            : 0,
        };
      }));
      // ?.filter((item) => item.dateSize - 1 >= 1)
    }
  }, [triggerReloadParams, selectedPriceParentServices]);

  useEffect(() => {
    if (isOpen) {
      if (servicesArrValues?.length) {
        const newSelectedPriceParentServices: string[] = [];
        const newSelectedParentAdditionalOptions: string[] = [];

        servicesArrValues.forEach((priceLevelService) => {
          const priceService = (caseByIdInContext?.data?.practice?.priceLevelServices
            || practicePriceLevelServiceInContext?.data?.priceLevel?.priceLevelServices)
            ?.find((service) => (service.id === priceLevelService));

          const selectedTeethService = caseByIdInContext?.data?.teeth?.services
            ?.find((service) => service.id === priceService?.id);

          newSelectedPriceParentServices.push(priceService?.service?.id as string);
          /** We get all selected services options from caseById, then take all additional options, and finds
           * parent additionalOption.id for each of selected options. */
          newSelectedParentAdditionalOptions.push(...(selectedTeethService?.additionalOptions?.map((option) => (
            priceService?.priceLevelAdditionalOptions?.find((adOption) => adOption.id === option?.id)
              ?.additionalOption.id
          )) || []));
        });

        setSelectedPriceParentServices(newSelectedPriceParentServices);
        setSelectedParentAdditionalOptions(newSelectedParentAdditionalOptions);
      } else {
        setSelectedPriceParentServices([]);
        setSelectedParentAdditionalOptions([]);
      }
    }
  }, [servicesArrValues]);

  const [stringOfAdditionalOptions, setStringOfAdditionalOptions] = useState<string>('');
  const [isEveryServAssigned, setIsEveryServAssigned] = useState<boolean>(false);

  useEffect(() => {
    if (caseByIdInContext?.data) {
      setIsEveryServAssigned(caseByIdInContext.data?.caseServices?.length
      && caseByIdInContext.data?.caseServices?.length === caseByIdInContext.data?.teeth?.services?.length
        ? caseByIdInContext?.data?.caseServices
          ?.every((caseServ) => (caseServ?.labService?.lab && caseServ?.status !== 'declined')) : false);

      setStringOfAdditionalOptions(caseByIdInContext?.data?.caseServices?.map((caseServ) => (
        caseServ?.caseAdditionalOptions
      ))?.map((additionalOptionArr) => additionalOptionArr
        /** Create array of arrays with Additional Option object */
        ?.map((addOpt) => addOpt?.labAdditionalOption?.additionalOption?.id)
        /** Make from objects just string with parent service id's */
        ?.filter((option) => option?.length)
        /** Filter array of arrays with additionalOptionIds, to be not empty */
        ?.join(','))
        /** Join items in each array */
        ?.filter((opt) => !!opt)
        /** Filter empty strings */
        ?.join(',') || '');
      /** Join result from all arrays */
    }
  }, [caseByIdInContext?.data]);

  const [tableParams, setTableParams] = useState({
    services: '',
    serviceAdditionalOptions: '',
    dueDates: '',
  });

  useEffect(() => {
    setTableParams({
      /** If every caseService assigned to lab, and not declined, then send services in filters all time */
      services: isEveryServAssigned
        ? caseByIdInContext?.data?.caseServices?.map((caseServ) => (
          caseServ?.labService?.service?.id))?.join(',') || ''
        : selectedPriceParentServices.join(',') || '',
      /** If every caseService assigned to lab */
      serviceAdditionalOptions: isEveryServAssigned
        ? stringOfAdditionalOptions
        : selectedParentAdditionalOptions?.join(',') || '',
      dueDates: dueDates.map((date) => date.dateSize)?.join(',') || '',
    });
  }, [isEveryServAssigned, caseByIdInContext?.data, selectedPriceParentServices, stringOfAdditionalOptions,
    selectedParentAdditionalOptions, dueDates]);

  return (
    <div>
      <DrawerModal
        title={title}
        open={isOpen}
        zIndex={1001}
        onClose={handleClose}
        destroyOnClose
        extra={(
          <Space>
            <div className="case-total-price">
              <Typography.Text>Total price per Case</Typography.Text>
              <div className="price">
                <PriceBlock
                  price={caseByIdInContext?.data?.totalPrice || 0}
                  leadTimePrice={caseByIdInContext?.data?.leadTimePrice || 0}
                />
              </div>
            </div>
          </Space>
        )}
      >
        <Row justify="center">
          <Loading visible={!!caseByIdInContext?.loading} absolute />
          <Col
            span={24}
            md={23}
            lg={22}
          >
            <div>
              <AssignServiceForm
                form={assignServiceForm}
                initialData={initialValues}
                formType="lab"
                setTriggerReloadParams={setTriggerReloadParams}
              />
              <AssignLabTable
                isActionDisabled={!servicesArrValues?.length}
                onRowAction={handleSubmit}
                caseId={`${id}`}
                propPriceParentServices={selectedPriceParentServices}
                params={tableParams}
                dueDates={dueDates}
              />
            </div>
          </Col>
        </Row>
      </DrawerModal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[casesModule].caseLoading,
  error: state[casesModule].caseError,
  user: state[authModuleName].user,
});

const mapDispatchToProps = {
  updateCase: actionUpdateCase,
};

AssignLab.defaultProps = {
  onReload: undefined,
  isAdmin: true,
  disabled: undefined,
  initialData: undefined,
  handleSave: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignLab);
