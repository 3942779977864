import { Form, Typography } from 'antd';
import { FormInstance } from 'antd/es/form';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CreateAddress } from '../../../../../hooks/addresses';
import { JsonResult } from '../../../../../types';

import Address from '../../../../Common/Address';
import ContentCard from '../../../../Common/ContentCard';
import PracticeInfoForm from '../PracticeInfoForm';
import ShippingAddress from '../ShippingAddress';

interface PracticeForm {
  disabled?: {
    [key: string]: boolean;
  };
  initialData?: JsonResult;
  editMode?: boolean;
  onSubmit?: () => void;
  isAdmin?: boolean;
  loading?: boolean;
  form?: FormInstance;
  addressesState: [CreateAddress[], Dispatch<SetStateAction<CreateAddress[]>>];
  sameAsBillingState: [boolean | undefined, Dispatch<SetStateAction<boolean | undefined>>];
  handleChange?: (field: JsonResult[]) => void;
  requiredFieldsType?: 'create' | 'completeRegistration';
}

const PracticeForm: React.FC<PracticeForm> = (props) => {
  const {
    disabled,
    isAdmin,
    initialData,
    editMode,
    onSubmit,
    form,
    addressesState,
    sameAsBillingState,
    loading,
    handleChange,
    requiredFieldsType,
  } = props;
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [isFieldsLoaded, setIsFieldsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!initialData || (initialData && !Object.keys(initialData).length) || isFieldsLoaded) return;

    form?.setFieldsValue(initialData);

    setIsFieldsLoaded(true);
  }, [initialData]);

  const handleFormSubmit = () => {
    form?.submit();
  };

  const handleCancel = () => {
    form?.resetFields();
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialData}
        onFinish={onSubmit && onSubmit}
        onFieldsChange={(e) => {
          if (handleChange) handleChange(e);
          setIsFormValid(!form?.getFieldsError().some(({ errors }) => errors.length));
        }}
        autoComplete="off"
      >
        <PracticeInfoForm
          loading={loading}
          isAdmin={isAdmin}
          requiredFieldsType={requiredFieldsType}
          editMode={editMode ? {
            onSubmit: handleFormSubmit,
            onCancel: handleCancel,
            isFormValid,
          } : undefined}
          disabled={disabled?.practice}
        />

        <div>
          <Typography.Title level={4}>Billing Address</Typography.Title>
          <ContentCard loading={loading}>
            <Address
              form={form}
              requiredFieldsType={requiredFieldsType}
              editMode={editMode ? {
                onSubmit: handleFormSubmit,
                onCancel: handleCancel,
                isFormValid,
              } : undefined}
              disabled={disabled?.billingAddress}
            />
          </ContentCard>
        </div>
      </Form>

      <ShippingAddress
        disabled={disabled?.shippingAddress}
        loading={loading}
        addressesState={addressesState}
        sameAsBillingState={sameAsBillingState}
      />
    </div>
  );
};

PracticeForm.defaultProps = {
  initialData: {},
  disabled: {
    practice: false,
    billingAddress: false,
  },
  handleChange: undefined,
  loading: false,
  editMode: false,
  onSubmit: () => undefined,
  isAdmin: false,
  form: undefined,
  requiredFieldsType: 'create',
};

export default PracticeForm;
