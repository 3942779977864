import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { alertDelayError, name as appName } from '../../config';
import { LabService } from '../../hooks/services';
import { cancelableLocationSaga, defaultResponseProcessing, Error, FetchResponse, JsonResult } from './common';
import { Action } from '../index';
import { fetchSaga } from './auth';

/**
 * Constants
 * */
export const moduleName = 'priceLevel';
const prefix = `${appName}/${moduleName}`;

export const PRICE_LEVEL_CREATE = `${prefix}/PRICE_LEVEL_CREATE`;
export const PRICE_LEVEL_UPDATE = `${prefix}/PRICE_LEVEL_UPDATE`;

export const PRICE_LEVEL_REQUEST_START = `${prefix}/PRICE_LEVEL_REQUEST_START`;
export const PRICE_LEVEL_REQUEST_SUCCESS = `${prefix}/PRICE_LEVEL_REQUEST_SUCCESS`;
export const PRICE_LEVEL_REQUEST_ERROR = `${prefix}/PRICE_LEVEL_REQUEST_ERROR`;
export const PRICE_LEVEL_REQUEST_ERROR_RESET = `${prefix}/PRICE_LEVEL_REQUEST_ERROR_RESET`;

/**
 * Reducer
 * */

export interface State {
  loading: boolean;
  error: Error | null;
  priceLevel: JsonResult | null;
}

const defaultState: State = {
  loading: false,
  error: null,
  priceLevel: null,
};

export default function reducer(
  state = defaultState,
  action: Action = { type: 'undefined' },
): State {
  const { type, payload } = action;

  switch (type) {
    case PRICE_LEVEL_REQUEST_START:
      return { ...state, loading: true };
    case PRICE_LEVEL_REQUEST_SUCCESS:
      return { ...state, loading: false, priceLevel: payload };
    case PRICE_LEVEL_REQUEST_ERROR:
      return { ...state, loading: false, error: payload };
    case PRICE_LEVEL_REQUEST_ERROR_RESET:
      return { ...state, loading: false, error: null };

    default:
      return { ...state };
  }
}
/**
 * Action Creators
 * */

export interface PriceLevelPayload {
  id?: string | number;
  isActive?: boolean;
  priceLevelServices?: LabService[];
  practices?: string[];
}

export const priceLevelCreate = (payload: PriceLevelPayload): Action => ({
  type: PRICE_LEVEL_CREATE,
  payload,
});

export const priceLevelUpdate = (payload: PriceLevelPayload): Action => ({
  type: PRICE_LEVEL_UPDATE,
  payload,
});

/**
 * Helper functions
 */

/**
 * Sagas
 */

export function* priceLevelCreateSaga({ payload }: { payload: PriceLevelPayload; }): Generator {
  yield put({
    type: PRICE_LEVEL_REQUEST_START,
  });

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/price-levels`,
    {
      method: 'POST',
      body: { ...payload },
    },
    {},
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    PRICE_LEVEL_REQUEST_SUCCESS,
    PRICE_LEVEL_REQUEST_ERROR,
    false,
  );
}
export function* priceLevelUpdateSaga({ payload }: { payload: PriceLevelPayload; }): Generator {
  yield put({
    type: PRICE_LEVEL_REQUEST_START,
  });

  const { id, ...data } = payload;

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/price-levels/${id}`,
    {
      method: 'PATCH',
      body: { ...data },
    },
    {},
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    PRICE_LEVEL_REQUEST_SUCCESS,
    PRICE_LEVEL_REQUEST_ERROR,
    false,
  );
}

export function* saga(): Generator {
  yield takeLatest(
    PRICE_LEVEL_CREATE,
    cancelableLocationSaga.bind(
      null,
      priceLevelCreateSaga,
      PRICE_LEVEL_REQUEST_ERROR,
      false,
    ),
  );
  yield takeLatest(
    PRICE_LEVEL_UPDATE,
    cancelableLocationSaga.bind(
      null,
      priceLevelUpdateSaga,
      PRICE_LEVEL_REQUEST_ERROR,
      false,
    ),
  );
  yield takeLatest(PRICE_LEVEL_REQUEST_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: PRICE_LEVEL_REQUEST_ERROR_RESET,
    });
  });
}
