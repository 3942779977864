import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { DownOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { useParams, useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { App, Button, Dropdown, FormInstance, Space } from 'antd';
import React, { Key, useCallback, useEffect, useRef, useState } from 'react';
import SelectServiceMulti from '../../../../../Common/Selects/SelectServiceMulti';

import Status from '../../../../../Common/Status';
import Table from '../../../../../Common/Table';
import { useAuth } from '../../../../../../context/auth';
import { getMessageInError } from '../../../../../../hooks/fetch';
import { ModalState, ModalTypes, Option } from '../../../../../../types';
import { UserRoles, UserStatuses } from '../../../../../../enums/user';
import { TableLabStaffRow, useTableLabStaffRow } from '../../../../../../hooks/labStaff';
import { capitalize, formatPhoneNumber, getSorterParams, queryFilterParams } from '../../../../../../utils';
import { LabRolesOptions } from '../../../../../../enums/lab';
import { LabServiceTable, useServicesGet } from '../../../../../../hooks/services';

interface TableLabStaff {
  params?: Record<string, string>;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
  labId?: string;
}

function TableLabStaff({ params, openModal, selectedRows, onRowSelection, labId }: TableLabStaff): React.JSX.Element {
  const { message } = App.useApp();
  const { isRoleEnough } = useAuth();
  const formRef = useRef<FormInstance>();
  const labStaffGet = useTableLabStaffRow();
  const actionRef = useRef<ActionType>();
  const [, setSearchParams] = useSearchParams();
  const { id: paramsLabId = '' } = useParams<{ id: string; }>();
  const getServices = useServicesGet();

  const requestLabId = paramsLabId || labId;

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [servicesFilterOptions, setServicesFilterOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (getServices?.data) {
      setServicesFilterOptions(getServices.data?.data?.map(({ name, id }): Option => (
        { label: name, value: id }
      )));
    }
  }, [getServices?.data]);

  useEffect(() => {
    getServices.fetch();
  }, []);

  useEffect(() => {
    if (labStaffGet.error) {
      message.error(getMessageInError(labStaffGet.error), 5);
      labStaffGet.clearError();
    }
  }, [labStaffGet.error]);

  const onReload = () => actionRef.current?.reload();

  const menuItems = [
    {
      label: 'Lab Manager',
      key: '1',
      onClick: () => openModal?.({
        type: ModalTypes.create,
        role: UserRoles.labManager,
        onReload,
      }),
      className: 'createLabManagerBtn',
    },
    {
      label: 'Staff',
      key: '2',
      onClick: () => openModal?.({
        type: ModalTypes.create,
        role: UserRoles.labStaff,
        onReload,
      }),
      className: 'createLabStaffBtn',
    },
  ];

  const toolBarRender = () => [
    <Button key="filterBtn" id="filterBtn" type="default" onClick={() => setIsFilterOpen(!isFilterOpen)}>
      <FilterOutlined />
      {' '}
      Filter
    </Button>,
    openModal ? (
      <Dropdown
        trigger={['click']}
        key="button"
        menu={{ items: menuItems }}
      >
        <Button type="primary" onClick={(e) => e.preventDefault()} id="addDropdownButton">
          <Space>
            <PlusOutlined />
            Add New
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    ) : null,
  ];

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableLabStaffRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return labStaffGet.fetch({
      ...newParams,
      ...params,
      services: newParams.services?.split(','),
      labs: requestLabId ? [requestLabId] : undefined,
    }).then((data) => {
      if (data) {
        const { labStaff, total } = data;

        return ({ data: labStaff || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  };

  const columns: ProColumns<TableLabStaffRow>[] = [
    {
      title: 'Staff Name',
      dataIndex: 'name',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => (row.user?.firstName ? (`${row.user?.firstName || ''} ${row.user?.lastName || ''}`) : ''),
    },
    {
      title: 'Service',
      dataIndex: 'services',
      sorter: false,
      ellipsis: true,
      render: (_, row) => (
        <div>
          {row.labServices?.length ? (
            <Space direction="vertical">
              {row.labServices?.sort((a, b) => a.service?.name < b.service?.name ? 1 : -1)
                ?.map((item: LabServiceTable) => (
                  <div key={item?.id}>
                    {item.service?.name}
                  </div>
                ))}
            </Space>
          ) : ' - '}
        </div>
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      renderFormItem: (_, { defaultRender, ...config }) => (
        <SelectServiceMulti
          {...config}
          selectOptions={servicesFilterOptions}
          placeholder="Please select"
          value={Array.isArray(config.value) ? config.value : config?.value?.split(',')}
          mode="multiple"
        />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'userStatus',
      sorter: true,
      renderText: (_, row) => (
        <Status status={row.user?.status} />
      ),
      valueEnum: UserStatuses,
      ellipsis: false,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => row.user?.email,
      formItemProps: {
        rules: [{ min: 2 }],
      },
    },
    {
      title: 'Contact Phone',
      dataIndex: 'phone',
      sorter: true,
      ellipsis: true,
      renderText: (_, row) => (formatPhoneNumber(row.user?.phone || '')),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: true,
      valueEnum: LabRolesOptions,
      width: 105,
      ellipsis: true,
      renderText: (_, row) => capitalize(row.user?.role),
    },
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: true,
      ellipsis: true,
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <Table<TableLabStaffRow>
      formRef={formRef}
      className={isFilterOpen ? 'activeFilter' : ''}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      form={{ ignoreRules: false }}
      headerTitle="Staff list"
      rowSelection={!!onRowSelection && !!isRoleEnough('admin') && rowSelection}
      rowClassName="cursor-pointer"
      columnsState={{ persistenceKey: 'pro-table-staff', persistenceType: 'localStorage' }}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
      onFilterClose={() => setIsFilterOpen(false)}
      onRow={(record) => ({
        onClick: () => {
          openModal?.({
            type: ModalTypes.info,
            id: record.id,
            role: record.user?.role === UserRoles.labStaff ? UserRoles.labStaff : UserRoles.labManager,
            onReload,
          });
        },
      })}
    />
  );
}

TableLabStaff.defaultProps = {
  params: {},
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
  labId: undefined,
};

export default TableLabStaff;
