import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import { DownOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { useSearchParams } from 'react-router-dom';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-table';
import { App, Button, Dropdown, FormInstance, Space } from 'antd';
import React, { Key, useCallback, useEffect, useRef, useState } from 'react';

import { MessageInstance } from 'antd/es/message/interface';
import Status from '../../../Common/Status';
import Table from '../../../Common/Table';
import { getMessageInError } from '../../../../hooks/fetch';
import { ModalState, ModalTypes } from '../../../../types';
import { UserRoles, UserRolesOptions, UserStatuses } from '../../../../enums/user';
import { TableUserRow, useTableUserRow } from '../../../../hooks/users';
import { capitalizeFirstLetter, formatPhoneNumber, getSorterParams, queryFilterParams } from '../../../../utils';
import { Profile } from '../../../Common/Icon';
import { isRoleEnough } from '../../../../utils/auth';

interface TableUsers {
  params?: Record<string, string>;
  openModal?: ((modal: ModalState) => void) | undefined;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
}

const getUserIdFromRole = (role: UserRoles, record: TableUserRow, onError: (err: string) => void): string => {
  if ((record.role === UserRoles.practiceManager || record.role === UserRoles.doctor) && !record.doctor?.id) {
    onError('This user don\'t have doctor id!');
  }
  if ((record.role === UserRoles.labManager || record.role === UserRoles.labStaff) && !record.labStaff?.id) {
    onError('This user don\'t have staff id!');
  }
  // practice
  if ((role === UserRoles.practiceManager || role === UserRoles.doctor) && record.doctor) {
    return record?.doctor?.id;
  }
  // lab
  if ((role === UserRoles.labManager || role === UserRoles.labStaff) && record.labStaff) {
    return record?.labStaff?.id;
  }
  // admin
  if (role === UserRoles.admin || role === UserRoles.root) {
    return record?.id;
  }

  return '';
};

const getPositionFromUser = (row: TableUserRow): string => {
  if (row?.doctor?.position) return row.doctor.position;

  if (row?.labStaff?.position) return row.labStaff.position;

  return '';
};

function TableUsers(props: TableUsers): React.JSX.Element {
  const { message } = App.useApp();
  const { params, openModal, selectedRows, onRowSelection } = props;
  const formRef = useRef<FormInstance>();
  const usersGet = useTableUserRow();
  const actionRef = useRef<ActionType>();
  const [, setSearchParams] = useSearchParams();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    if (usersGet.error) {
      message.error(getMessageInError(usersGet.error));
      usersGet.clearError();
    }
  }, [usersGet.error]);

  const onReload = () => actionRef.current?.reload();

  const onError = (err: string) => { message.error(err); };

  const menuItems = [
    {
      label: 'Practice Manager',
      key: '1',
      onClick: () => openModal?.({ type: ModalTypes.create, role: UserRoles.practiceManager, onReload }),
      className: 'createPracticeManagerBtn',
    },
    {
      label: 'Doctor',
      key: '2',
      onClick: () => openModal?.({ type: ModalTypes.create, role: UserRoles.doctor, onReload }),
      className: 'createDoctorBtn',
    },
    {
      label: 'Lab Manager',
      key: '3',
      onClick: () => openModal?.({ type: ModalTypes.create, role: UserRoles.labManager, onReload }),
      className: 'createLabManagerBtn',
    },
    {
      label: 'Lab Staff',
      key: '4',
      onClick: () => openModal?.({ type: ModalTypes.create, role: UserRoles.labStaff, onReload }),
      className: 'createLabStaffBtn',
    },
    {
      label: 'Admin',
      key: '5',
      onClick: () => openModal?.({ type: ModalTypes.create, role: UserRoles.admin, onReload }),
      className: 'createAdminBtn',
    },
  ].filter((item) => !isRoleEnough('root') ? item.label !== 'Admin' : item);

  const toolBarRender = () => [
    <Button key="filterBtn" id="filterBtn" type="default" onClick={() => setIsFilterOpen(!isFilterOpen)}>
      <FilterOutlined />
      {' '}
      Filter
    </Button>,
    openModal ? (
      <Dropdown
        trigger={['click']}
        key="button"
        menu={{ items: menuItems }}
      >
        <Button type="primary" onClick={(e) => e.preventDefault()} id="addDropdownButton">
          <Space>
            <PlusOutlined />
            Add New
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    ) : null,
  ];

  const tableRequest = (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<Partial<RequestData<TableUserRow>>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      take: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams({ ...args, ...getSorterParams(sorter) });

    return usersGet.fetch({
      ...newParams,
      ...params,
      role: undefined,
      roles: newParams?.role?.split(','),
    }).then((data) => {
      if (data) {
        const { users, total } = data;

        return ({ data: users || [], success: true, total });
      }

      return ({ data: [], success: false, total: 0 });
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
    });

    setSearchParams({ ...newParams, ...params });

    return { ...newParams, ...params };
  };

  const columns: ProColumns<TableUserRow>[] = [
    {
      title: 'User Type',
      dataIndex: 'role',
      sorter: true,
      valueEnum: UserRolesOptions,
      renderText: (_, row) => capitalizeFirstLetter(row.role),
      valueType: 'checkbox',
      fieldProps: {
        className: 'ant-pro-field-checkbox-vertical',
      },
      width: 140,
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      sorter: true,
      renderText: (_, row) => `${row.firstName ? `${row.firstName} ` : ''}${row.lastName || ''}`,
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      renderText: (status) => <Status status={status} />,
      valueEnum: UserStatuses,
      ellipsis: false,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      ellipsis: true,
      formItemProps: {
        rules: [{ min: 2 }],
      },
    },
    {
      title: 'Position',
      dataIndex: 'position',
      sorter: false,
      ellipsis: true,
      renderText: (_, row) => getPositionFromUser(row),
      hideInForm: true,
      hideInSearch: true,
    },
    {
      title: 'Contact Phone',
      dataIndex: 'phone',
      sorter: true,
      ellipsis: true,
      renderText: (phone) => formatPhoneNumber(phone, 'national'),
    },
  ];

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <Table<TableUserRow>
      formRef={formRef}
      className={isFilterOpen ? 'activeFilter' : ''}
      columns={columns}
      request={tableRequest}
      actionRef={actionRef}
      form={{ ignoreRules: false }}
      headerTitle={(
        <>
          <Profile />
          Users list
        </>
      )}
      rowSelection={!!onRowSelection && !!isRoleEnough('admin') && rowSelection}
      rowClassName="cursor-pointer"
      columnsState={{ persistenceKey: 'pro-table-users', persistenceType: 'localStorage' }}
      toolBarRender={toolBarRender}
      showSorterTooltip={false}
      beforeSearchSubmit={beforeSearchSubmit}
      onFilterClose={() => setIsFilterOpen(false)}
      onRow={(record) => ({
        onClick: () => {
          openModal?.({
            type: ModalTypes.info,
            id: getUserIdFromRole(record.role, record, onError),
            role: record.role,
            onReload,
          });
        },
      })}
    />
  );
}

TableUsers.defaultProps = {
  params: {},
  openModal: undefined,
  selectedRows: [],
  onRowSelection: undefined,
};

export default TableUsers;
