import { DefaultFetchError, FetchDelete, FetchUpdate, useFetchDelete, useFetchUpdate } from './fetch';
import { FetchSuccess } from '../types';

export interface UpdatePersonalization {
  cssColor?: string; // | null
  file?: File | null;
}

export const useUpdatePracticeBranding = (): FetchUpdate<FetchSuccess, DefaultFetchError, FormData> => useFetchUpdate(
  'practices',
  undefined,
);

export const useDeletePracticeLogo = (): FetchDelete<FetchSuccess> => useFetchDelete('practices');
