import React, { useEffect, useRef } from 'react';
import { Badge, Space, Typography } from 'antd';
import clsx from 'clsx';
import type { Dayjs } from 'dayjs';
import { useContextCalendar } from '../Context';
import { DefaultFetchError, FetchGet } from '../../../../hooks/fetch';
import { CalendarMonthGetParams, CalendarMonthResponse } from '../../../../hooks/case/calendar';

import styles from './index.module.scss';

export interface SelectedState {
  ref?: React.MutableRefObject<HTMLUListElement | null>;
  date: Dayjs;
}

interface DayProps {
  date: Dayjs;
  value: Dayjs;
  caseMonthGet: FetchGet<CalendarMonthResponse, CalendarMonthGetParams, DefaultFetchError, CalendarMonthResponse>;
}

function Day({ date, value, caseMonthGet }: DayProps) {
  const {
    selected, setSelected,
  } = useContextCalendar();
  const ref = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (ref.current && !selected?.ref) {
      if (selected?.date?.get('D') === value.get('D') && selected?.date?.get('M') === value.get('M')) {
        setSelected(
          (state) => ({
            ...state,
            ref,
          } as SelectedState),
        );
      }
    }
  }, [ref.current, value, selected]);

  if (!caseMonthGet.data || value.month() !== date.month()) {
    return null;
  }

  return (
    <ul className={styles.day} ref={ref}>
      {caseMonthGet.data[value.date()]?.length === 1
        ? caseMonthGet.data[value.date()]?.map((item) => (
          <li
            key={item.id}
            className={clsx(styles.item)}
          >
            <Badge
              color={`rgb(var(--color-status-${item?.status.toLowerCase().replaceAll(' ', '-')}))`}
              text={(
                <Space direction="vertical" className={styles.moreInfo}>
                  <div className={styles.locationRow}>
                    <div className={styles.textWrapper}>
                      <Typography.Text ellipsis>
                        Dr.
                        {' '}
                        {item.doctor.user.firstName}
                        {' '}
                        {item.doctor.user.lastName}
                      </Typography.Text>
                    </div>
                  </div>
                </Space>
                )}
            />
          </li>
        ))
        : caseMonthGet.data[value.date()]?.map((item, i) => (i === 4 ? (
          <li
            key={item.id}
            className={clsx(styles.theeDot)}
          >
            ...
          </li>
        ) : (
          <li
            key={item.id}
            className={clsx(styles.item)}
          >
            <Badge
              color={`rgb(var(--color-status-${item?.status.toLowerCase().replaceAll(' ', '-')}))`}
              text={(
                <Space direction="vertical" className={styles.moreInfo}>
                  <div className={styles.locationRow}>
                    <div className={styles.textWrapper}>
                      <Typography.Text ellipsis>
                        Dr.
                        {' '}
                        {item.doctor.user.firstName}
                        {' '}
                        {item.doctor.user.lastName}
                      </Typography.Text>
                    </div>
                  </div>
                </Space>
              )}
            />
          </li>
        )))}
    </ul>
  );
}

export default Day;
