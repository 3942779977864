import { DownloadOutlined, FileFilled } from '@ant-design/icons';
import { Button, DatePicker, Divider, Form, Space, Typography, App } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { useDownloadReportInvoiceFile } from '../../../hooks/reports';
import { JsonResult } from '../../../types';
import { requiredDatepicker } from '../../../utils/inputRules';
import ContentCard from '../../Common/ContentCard';
import { PriceChange } from '../../Common/Icon';
import SelectDoctor from '../../Common/Selects/SelectDoctor';
import SelectLab from '../../Common/Selects/SelectLab';
import SelectPractice from '../../Common/Selects/SelectPractice';
import LabInvoice from './LabInvoice';
import PracticeInvoice from './PracticeInvoice';
import Tile from './Tile';
import { getMessageInError } from '../../../hooks/fetch';
import { getTimeZoneName } from '../../../utils';
import { isRoleEnough } from '../../../utils/auth';
import SelectLabStaff from '../../Common/Selects/SelectLabStaff';
import { RootState } from '../../../store/reducers';
import { moduleName, User } from '../../../store/ducks/auth';

import styles from './index.module.scss';

const { RangePicker } = DatePicker;

type ActiveReportType = 'practice-invoice' | 'lab-invoice';

const Reports: React.FC<{ user: User | null; }> = ({ user }) => {
  const [form] = useForm();
  const { message } = App.useApp();

  const [activeReport, setActiveReport] = useState<ActiveReportType>(
    isRoleEnough('lab manager', true) ? 'lab-invoice' : 'practice-invoice',
  );
  const [params, setParams] = useState<JsonResult | null>(null);
  const downloadReportInvoiceFile = useDownloadReportInvoiceFile();

  const isRolePracticeManager = !!isRoleEnough('practice manager', true);
  const isRoleLabManager = !!isRoleEnough('lab manager', true);

  const practice = useWatch('practice', form);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const lab = useWatch('lab', form);

  const handleChangeActiveReport = (type: ActiveReportType) => {
    form.setFieldValue('practice', '');
    form.setFieldValue('lab', '');
    form.setFieldValue('doctor', ''); // undefined
    form.setFieldValue('labStaff', ''); // undefined
    setActiveReport(type);
  };

  const handleGenerateReport = () => {
    form.validateFields().then((values) => {
      const { timeRange, doctor, practice, lab, labStaff, ...newParams } = values;
      const [dueDateFrom, dueDateTo] = timeRange;

      setParams({
        ...newParams,
        practice: practice || undefined,
        doctor: doctor || undefined,
        lab: isRoleLabManager ? user?.labStaff?.lab?.id : lab || undefined,
        labStaff: labStaff || undefined,
        dueDateFrom: dayjs(dueDateFrom).format(),
        dueDateTo: dayjs(dueDateTo).format(),
      });
    });
  };

  const downloadFileFromResponse = (response: ArrayBuffer | null, name: string) => {
    if (!response) return;

    const file = new Blob([response]);
    const fileURL = window.URL.createObjectURL(file);
    const link = document.createElement('a');

    link.href = fileURL;
    link.setAttribute('download', `report-${name || ''}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadReport = () => {
    form.validateFields().then((values) => {
      const { timeRange, doctor, practice, lab, labStaff, ...newParams } = values;
      const [dueDateFrom, dueDateTo] = timeRange;

      const downloadParams = {
        ...newParams,
        practice: practice || undefined,
        doctor: doctor || undefined,
        lab: isRoleLabManager ? user?.labStaff?.lab?.id : lab || undefined,
        labStaff: labStaff || undefined,
        dueDateFrom: dayjs(dueDateFrom).format(),
        dueDateTo: dayjs(dueDateTo).format(),
        timezone: getTimeZoneName(),
      };

      if (activeReport === 'practice-invoice') {
        downloadReportInvoiceFile.fetch(downloadParams, 'generate-practice-report')
          .then((response) => downloadFileFromResponse(response, practice || 'practice'));
      }
      if (activeReport === 'lab-invoice') {
        downloadReportInvoiceFile.fetch(downloadParams, 'generate-lab-report')
          .then((response) => downloadFileFromResponse(response, lab || 'lab'));
      }
    });
  };

  useEffect(() => {
    if (downloadReportInvoiceFile.error) {
      message.error(getMessageInError(downloadReportInvoiceFile.error));
      downloadReportInvoiceFile.clearError();
    }
  }, [downloadReportInvoiceFile.error]);

  return (
    <Content>
      <ContentCard paddingSize="small">
        <Space direction="vertical" size={20}>
          <Space size={20}>
            {/* If user not 'lab manager' show practice reporting */}
            {!isRoleLabManager && (
              <Space direction="vertical">
                <Typography>Practice Reporting</Typography>
                <Space>
                  <Tile
                    icon={<PriceChange />}
                    title="Invoice"
                    isActive={activeReport === 'practice-invoice'}
                    onClick={() => handleChangeActiveReport('practice-invoice')}
                  />
                </Space>
              </Space>
            )}
            {!isRolePracticeManager && !isRoleLabManager && (
              <Divider type="vertical" orientation="center" style={{ height: '3em' }} />
            )}
            {/* If user not 'practice manager' show lab reporting */}
            {!isRolePracticeManager && (
              <Space direction="vertical">
                <Typography>Lab Reporting</Typography>
                <Space>
                  <Tile
                    icon={<PriceChange />}
                    title="Invoice"
                    isActive={activeReport === 'lab-invoice'}
                    onClick={() => handleChangeActiveReport('lab-invoice')}
                  />
                </Space>
              </Space>
            )}
          </Space>
          <Form form={form} layout="vertical" initialValues={{ practice: '', doctor: '', lab: '', labStaff: '' }}>
            <Space className={styles.row}>
              <Form.Item label="Time Range" name="timeRange" rules={requiredDatepicker} className={styles.range}>
                <RangePicker />
              </Form.Item>
              {activeReport === 'practice-invoice' && (
                <>
                  {isRolePracticeManager ? null : (
                    <Form.Item label="Practice" name="practice" className={styles.select}>
                      <SelectPractice onlyStatus="active" allowAll />
                    </Form.Item>
                  )}
                  <Form.Item label="Doctor" name="doctor" className={styles.select}>
                    <SelectDoctor
                      practice={practice}
                      clearOnPracticeChange
                      disabled={!practice && !isRolePracticeManager}
                      allowAll
                      onlyStatus="active"
                    />
                  </Form.Item>
                </>
              )}
              {activeReport === 'lab-invoice' && (
                <div>
                  {isRoleLabManager
                    ? (
                      <Form.Item label="Lab staff" name="labStaff" className={styles.select}>
                        <SelectLabStaff onlyStatus="active" allowAll lab={user?.labStaff?.lab?.id} />
                      </Form.Item>
                    ) : (
                      <Form.Item label="Lab" name="lab" className={styles.select}>
                        <SelectLab onlyStatus="active" allowAll />
                      </Form.Item>
                    )}
                </div>
              )}
              <Space className={styles.row}>
                <Button
                  type="primary"
                  icon={<FileFilled />}
                  onClick={handleGenerateReport}
                >
                  Generate Report
                </Button>
                <Button
                  type="default"
                  icon={<DownloadOutlined />}
                  onClick={handleDownloadReport}
                  loading={downloadReportInvoiceFile.loading}
                >
                  Download
                </Button>
              </Space>
            </Space>
          </Form>
        </Space>
      </ContentCard>
      {(activeReport === 'practice-invoice') && <PracticeInvoice params={params} />}
      {(activeReport === 'lab-invoice') && <LabInvoice params={params} />}
    </Content>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state[moduleName].user,
});

export default connect(mapStateToProps)(Reports);
