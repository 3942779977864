import { App, Col, Row, Space, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ServiceStatus, ServiceStatuses } from '../../../../enums/services';
import { Service, useServiceId } from '../../../../hooks/services';
import { Action } from '../../../../store';
import { Error } from '../../../../store/ducks/common';
import {
  moduleName,
  serviceUpdate as actionServiceUpdate,
  ServiceUpdatePayload,
} from '../../../../store/ducks/service';
import { RootState } from '../../../../store/reducers';
import { capitalize } from '../../../../utils';
import ContentCard from '../../../Common/ContentCard';
import DrawerModal from '../../../Common/DrawerModal';
import Loading from '../../../Common/Loading';
import ServiceView from '../ServiceView';

interface ServiceEdit {
  serviceId?: string | number;
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  loading: boolean;
  error: Error | null;
  serviceUpdate: (payload: ServiceUpdatePayload) => Action;
}

const ServiceEdit = (props: ServiceEdit) => {
  const { message } = App.useApp();
  const { serviceId, drawerOpen, setDrawerOpen, loading, error, serviceUpdate } = props;
  const serviceById = useServiceId();

  const [service, setService] = useState<Service | undefined>(undefined);
  const [status, setStatus] = useState<ServiceStatus>(ServiceStatuses.deactivated);
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false);
  const [isUpdateNeeded, setIsUpdateNeeded] = useState<boolean>(true);
  const [isServiceLoaded, setIsServiceLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      message.error(error.message, 5);
    }
  }, [error]);

  useEffect(() => {
    if (!drawerOpen || !serviceId || loading || !!error) return;

    serviceById.fetch(undefined, serviceId);
  }, [drawerOpen, loading]);

  useEffect(() => {
    if (serviceById.data && isUpdateNeeded) {
      setService(serviceById.data);
    }

    if (!isUpdateNeeded) {
      setIsUpdateNeeded(true);
    }

    if (serviceById?.data?.status) {
      setStatus(serviceById.data.status);
    }
  }, [serviceById.data]);

  useEffect(() => {
    if (!service || isServiceLoaded) return;

    setIsServiceLoaded(true);
  }, [service]);

  const handleStatusChange = (checked: boolean) => {
    if (!serviceId) return;

    setIsUpdateNeeded(false);

    const newStatus = checked ? ServiceStatuses.active : ServiceStatuses.deactivated;

    serviceUpdate({ id: serviceId, status: newStatus });
    setStatus(newStatus);
  };

  const handleClose = () => {
    setDrawerOpen(false);
    setService(undefined);
    setIsServiceLoaded(false);
  };

  return (
    <div>
      <DrawerModal
        title={`${serviceById?.data?.name || ''} Settings`}
        open={drawerOpen && isServiceLoaded}
        onClose={handleClose}
        isFormChanged={isFormTouched}
        extra={(
          <Space>
            <div className="switch-wrapper">
              <Typography className="label">
                {`Status ${capitalize(status)}`}
              </Typography>
              <Switch checked={status === ServiceStatuses.active} onChange={handleStatusChange} />
            </div>
          </Space>
        )}
      >
        <Row justify="center">
          <Col span={24} md={20} lg={16} xxl={12}>
            <Typography.Title level={4}>Services</Typography.Title>
            <ContentCard loading={loading || serviceById.loading}>
              {!!service && (
                <ServiceView service={service} setIsFormTouched={setIsFormTouched} hidePrice />
              )}
            </ContentCard>
          </Col>
        </Row>
      </DrawerModal>

      <Loading absolute visible={!isServiceLoaded && serviceById.loading} />
    </div>
  );
};

ServiceEdit.defaultProps = {
  serviceId: undefined,
};

const mapStateToProps = (state: RootState) => ({
  loading: state[moduleName].loading,
  error: state[moduleName].error,
});

const mapDispatchToProps = {
  serviceUpdate: actionServiceUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEdit);
