import React from 'react';
import { Layout } from 'antd';

import Content from '../../components/Pages/Labs';
import { displayName } from '../../config';

function Labs(): React.JSX.Element {
  document.title = `${displayName}: Labs`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}

export default Labs;
