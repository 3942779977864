import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { alertDelayError, name as appName } from '../../config';
import { ServiceStatus } from '../../enums/services';
import { AdditionalOption } from '../../hooks/additionalOptions';
import { Material } from '../../hooks/materials';
import { cancelableLocationSaga, defaultResponseProcessing, Error, FetchResponse } from './common';
import { Action } from '../index';
import { fetchSaga } from './auth';

/**
 * Constants
 * */
export const moduleName = 'service';
const prefix = `${appName}/${moduleName}`;

export const SERVICE_UPDATE = `${prefix}/SERVICE_UPDATE`;
export const SERVICE_UPDATE_START = `${prefix}/SERVICE_UPDATE_START`;
export const SERVICE_UPDATE_SUCCESS = `${prefix}/SERVICE_UPDATE_SUCCESS`;
export const SERVICE_UPDATE_ERROR = `${prefix}/SERVICE_UPDATE_ERROR`;
export const SERVICE_UPDATE_ERROR_RESET = `${prefix}/SERVICE_UPDATE_ERROR_RESET`;

/**
 * Reducer
 * */

export interface State {
  loading: boolean;
  error: Error | null;
}

const defaultState: State = {
  loading: false,
  error: null,
};

export default function reducer(
  state = defaultState,
  action: Action = { type: 'undefined' },
): State {
  const { type, payload } = action;

  switch (type) {
    case SERVICE_UPDATE_START:
      return { ...state, loading: true };
    case SERVICE_UPDATE_SUCCESS:
      return { ...state, loading: false };
    case SERVICE_UPDATE_ERROR:
      return { ...state, loading: false, error: payload };
    case SERVICE_UPDATE_ERROR_RESET:
      return { ...state, error: null };

    default:
      return { ...state };
  }
}
/**
 * Action Creators
 * */

export interface ServiceUpdatePayload {
  id: string | number;
  status?: ServiceStatus;
  materials?: Material[];
  additionalOptions?: AdditionalOption[];
}

export const serviceUpdate = (payload: ServiceUpdatePayload): Action => ({
  type: SERVICE_UPDATE,
  payload,
});

/**
 * Helper functions
 */

/**
 * Sagas
 */

function* serviceUpdateSaga({ payload }: { payload: ServiceUpdatePayload; }): Generator {
  yield put({
    type: SERVICE_UPDATE_START,
  });

  const { id, ...data } = payload;

  const response = (yield call(
    fetchSaga,
    `${process.env.REACT_APP_API_URL}/services/${id}`,
    {
      method: 'PATCH',
      body: { ...data },
    },
    {},
  )) as FetchResponse;

  yield defaultResponseProcessing(
    response,
    SERVICE_UPDATE_SUCCESS,
    SERVICE_UPDATE_ERROR,
    false,
    () => data,
  );
}

export function* saga(): Generator {
  yield takeLatest(
    SERVICE_UPDATE,
    cancelableLocationSaga.bind(
      null,
      serviceUpdateSaga,
      SERVICE_UPDATE_ERROR,
      false,
    ),
  );
  yield takeLatest(SERVICE_UPDATE_ERROR, function* errorReset() {
    yield delay(alertDelayError);
    yield put({
      type: SERVICE_UPDATE_ERROR_RESET,
    });
  });
}
