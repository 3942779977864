import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import styles from './index.module.scss';

export const formatChatDate = (date: string | number, fromSeconds?: boolean): string => (
  dayjs(fromSeconds ? Number(date) * 1000 : date).isSame(dayjs(), 'day')
    ? dayjs(fromSeconds ? Number(date) * 1000 : date).format('hh:mm a')
    : dayjs(fromSeconds ? Number(date) * 1000 : date).format('DD MMM YYYY')
);

interface IListItem {
  onClick: () => void;
  active?: boolean;
  chatName: string;
  updatedAtSecond: number;
  message: string;
  unread: boolean;
  isNew: boolean;
}

const ChatListItem: React.FC<IListItem> = ({ onClick, active, chatName, updatedAtSecond, message, unread, isNew }) => (
  <div
    className={clsx(styles.item, { [styles.active]: !!active })}
    role="button"
    onClick={onClick}
    tabIndex={0}
  >
    <div className={styles.head}>
      <h4 className={clsx('clip', styles.title)} title={chatName}>{chatName}</h4>
      <span
        className={clsx('clip', styles.dateTime)}
        title={dayjs(updatedAtSecond * 1000).format('DD MMM YYYY hh:mm a')}
      >
        {formatChatDate(updatedAtSecond, true)}
      </span>
    </div>
    <p
      className={clsx('clip', styles.message, {
        [styles.unread]: unread,
        [styles.new]: isNew,
      })}
      title={message}
    >
      {message}
    </p>
  </div>
);

ChatListItem.defaultProps = {
  active: false,
};

export default ChatListItem;
