import React from 'react';
import { Layout } from 'antd';

import Content from '../../components/Pages/Patients';
import { displayName } from '../../config';

function Patients(): React.JSX.Element {
  document.title = `${displayName}: Patients`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
}

export default Patients;
