import React from 'react';
import clsx from 'clsx';

import dayjs, { Dayjs } from 'dayjs';
import styles from './index.module.scss';

interface DateColorStatus {
  date?: string | Dayjs;
  format?: string;
  beforeContent?: React.ReactNode;
}

const DateColorStatus: React.FC<DateColorStatus> = ({ date, format, beforeContent }) => (
  <div className={clsx(styles.date, {
    // [styles.green]: dayjs(date).diff(dayjs(), 'hours') >= 24,
    [styles.green]: dayjs(date).diff(dayjs(), 'days') >= 1,
    [styles.yellow]: dayjs(date).diff(dayjs(), 'days') < 1,
    [styles.red]: dayjs(date).diff(dayjs(), 'days') < 0,
  })}
  >
    {beforeContent && `${beforeContent} `}
    {dayjs(date)?.format(format)}
  </div>
);

DateColorStatus.defaultProps = {
  date: '',
  format: 'DD MMM, YYYY',
  beforeContent: undefined,
};

export default DateColorStatus;
