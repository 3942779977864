import { ConfigProvider, App } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from './store/reducers';
import { moduleName as authModuleName, User } from './store/ducks/auth';
import { getColorMixTransparentProp } from './utils';

interface AntdConfigProviderProps {
  children: React.ReactNode;
  user: User | null;
}

export const COLOR_PRIMARY = '#E6833B';

function AntdConfigProvider({ children, user }: AntdConfigProviderProps): React.JSX.Element {
  const [primaryColor, setPrimaryColor] = useState(COLOR_PRIMARY);

  useEffect(() => {
    const color = user?.doctor?.practice?.cssColor || COLOR_PRIMARY;

    if (color) {
      setPrimaryColor(color);
    }
  }, [user?.doctor?.practice?.cssColor]);

  useEffect(() => {
    const root = document.documentElement;

    const cssVarProps: { prop: string; value?: string; }[] = [
      { prop: '--color-button-primary' },
      { prop: '--color-button-primary-hover' },
      { prop: '--color-controls-checked' },
      { prop: '--color-main-orange' },
      { prop: '--color-main-orange-5', value: getColorMixTransparentProp(primaryColor, 5) },
      { prop: '--color-main-orange-10', value: getColorMixTransparentProp(primaryColor, 10) },
      { prop: '--color-main-orange-15', value: getColorMixTransparentProp(primaryColor, 15) },
      { prop: '--color-main-orange-20', value: getColorMixTransparentProp(primaryColor, 20) },
      { prop: '--color-main-orange-50', value: getColorMixTransparentProp(primaryColor, 50) },
    ];

    if (primaryColor !== COLOR_PRIMARY) {
      cssVarProps.forEach(({ prop, value }) => {
        root?.style.setProperty(prop, value || primaryColor);
      });
    } else {
      /** If color equals default-primary then remove all variable rewrites. */
      cssVarProps.forEach(({ prop }) => {
        root?.style.removeProperty(prop);
      });
    }
  }, [primaryColor]);

  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        token: {
          colorPrimary: primaryColor,
          colorLink: primaryColor,
          colorPrimaryText: primaryColor,

          borderRadius: 4,
          fontFamily: "'Inter', sans-serif",
          colorTextBase: '#1F1F1F',
          colorBorder: 'rgba(31, 31, 31, 0.24)',
        },
      }}
    >
      <App>
        {children}
      </App>
    </ConfigProvider>
  );
}

export default connect((state: RootState) => ({
  user: state[authModuleName].user,
}), {})(AntdConfigProvider);
