export const displayName = 'Dental Planning Portal';
export const name = 'dental-planning-portal';
export const pageSize = 10;
export const paginationSize = 6;
export const getUserDelay = 2000;

export const userLifetime = 14400000;

export const alertDelaySuccess = 2000;
export const alertDelayWarning = 5000;
export const alertDelayError = 10000;

export const alertDelayConfirmationEmail = 60000;
export const alertDelayChangeEmail = 60000;
export const alertDelayNewPassword = 60000;
export const alertDelayForgotPassword = 60000;
export const alertDelayResendEmail = 60000;

export const defaultPriceLabel = 'Basic 1-1';
