import { PagingDataResponse } from '../types';
import { DefaultFetchError, FetchGetId, useFetchGetId } from './fetch';

interface ReportParams {
  [key: string]: string | undefined;
}

export interface Report {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface ReportsTableData {
  reports: Report[];
  total: number;
}

export const useDownloadReportInvoiceFile = (): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    'reports',
    '',
    { autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
      },
    },
    'arraybuffer',
  )
);

export const useTablePracticeInvoiceReport = (): FetchGetId<
  PagingDataResponse<Report>, DefaultFetchError, ReportParams, ReportsTableData> => (
  useGeneratePracticeInvoiceReport((data: PagingDataResponse<Report>): ReportsTableData => ({
    reports: data.data.map((report: Report): Report => ({
      key: report.id,
      ...report,
    })),
    total: data.meta.itemCount,
  }))
);

export function useGeneratePracticeInvoiceReport<D = PagingDataResponse<Report>,
  DD = D>(decorateData?: (data: D) => DD): FetchGetId<D, DefaultFetchError, ReportParams, DD> {
  return useFetchGetId<D, DefaultFetchError, ReportParams, DD>(
    'reports/practice-report',
    '',
    { decorateData, autoStart: false },
  );
}

export const useTableLabInvoiceReport = (): FetchGetId<
  PagingDataResponse<Report>, DefaultFetchError, ReportParams, ReportsTableData> => (
  useGenerateLabInvoiceReport((data: PagingDataResponse<Report>): ReportsTableData => ({
    reports: data.data.map((report: Report): Report => ({
      key: report.id,
      ...report,
    })),
    total: data.meta.itemCount,
  }))
);

export function useGenerateLabInvoiceReport<D = PagingDataResponse<Report>,
  DD = D>(decorateData?: (data: D) => DD): FetchGetId<D, DefaultFetchError, ReportParams, DD> {
  return useFetchGetId<D, DefaultFetchError, ReportParams, DD>(
    'reports/lab-report',
    '',
    { decorateData, autoStart: false },
  );
}
