import React, { ReactNode, useState } from 'react';
import { Alert, Col, Form, Input, Row, Select, Space, Typography } from 'antd';

import ContentCard from '../../../../../../Common/ContentCard';
import ControlsEditMode, { PropEditMode } from '../../../../../../Common/ControlsEditMode';
import { email, confirmPassword, phoneNumber, required, password } from '../../../../../../../utils/inputRules';
import SelectPractice from '../../../../../../Common/Selects/SelectPractice';
import PhoneNumberInput from '../../../../../../Common/PhoneNumberInput';
import { Option } from '../../../../../../../types';
import { isRoleEnough } from '../../../../../../../utils/auth';

interface DoctorInfoForm {
  disabled?: {
    [key: string]: boolean;
  };
  isProfileView?: boolean;
  admin?: boolean;
  cardLoading?: boolean;
  formType?: 'create' | 'update';
  message?: string | ReactNode;
  editMode?: PropEditMode;
  modalPracticeId?: string | number;
  hasPassword?: boolean;
  practiceSelectOption?: Option;
}

const DoctorInfoForm: React.FC<DoctorInfoForm> = (props) => {
  const {
    disabled,
    isProfileView,
    formType,
    cardLoading,
    editMode,
    modalPracticeId,
    hasPassword,
    practiceSelectOption,
    message,
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isDisabledField = !!(editMode && !isEditing);

  const handleCancel = () => {
    if (!editMode) return;
    editMode.onCancel();

    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (!editMode) return;

    editMode.onSubmit();
    setIsEditing(false);
  };

  return (
    <div>
      {!isProfileView && (<Typography.Title level={4}>Personal Information</Typography.Title>)}
      <ContentCard loading={cardLoading}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {message && <Alert message={message} type="info" />}
          <div>
            <Row gutter={15}>
              <Form.Item name="id" style={{ height: 0, margin: 0 }}>
                <Input type="hidden" disabled />
              </Form.Item>
              <Form.Item name="userId" style={{ height: 0, margin: 0 }}>
                <Input type="hidden" disabled />
              </Form.Item>
              {!isProfileView && (
                <Col span={formType === 'create' ? 12 : 24}>
                  <Form.Item
                    label="Practice"
                    name="practice"
                    rules={required}
                  >
                    {practiceSelectOption ? (
                      <Select
                        placeholder="Type here"
                        options={[practiceSelectOption]}
                        disabled
                      />
                    ) : (
                      <SelectPractice
                        disabled={isProfileView || !!modalPracticeId || isDisabledField || disabled?.practice}
                        onlyStatus="active"
                      />
                    )}
                  </Form.Item>
                </Col>
              )}
              {formType === 'create' && (
                <Col span={12}>
                  <Form.Item
                    label="Position"
                    name="position"
                  >
                    <Input
                      placeholder="Type here"
                      disabled={isDisabledField || disabled?.position}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col span={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={required}
                >
                  <Input
                    placeholder="Type here"
                    disabled={isDisabledField || disabled?.firstName}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={required}
                >
                  <Input
                    placeholder="Type here"
                    disabled={isDisabledField || disabled?.lastName}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Nickname"
                  name="nickname"
                >
                  <Input
                    placeholder="Type here"
                    disabled={isDisabledField || disabled?.nickname}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Specialty"
                  name="specialty"
                >
                  <Input
                    placeholder="Type here"
                    disabled={isDisabledField || disabled?.specialty}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[...email, ...required]}
                >
                  <Input
                    placeholder="Type here"
                    disabled={isProfileView || isDisabledField || disabled?.email}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Contact Phone"
                  name="phone"
                  rules={[...required, ...phoneNumber]}
                >
                  <PhoneNumberInput disabled={isDisabledField || disabled?.phone} />
                </Form.Item>
              </Col>

              {formType === 'update' && (
                <Col span={12}>
                  <Form.Item
                    label="Position"
                    name="position"
                  >
                    <Input
                      placeholder="Type here"
                      disabled={isDisabledField || disabled?.position}
                    />
                  </Form.Item>
                </Col>
              )}
              {formType === 'update' && (
                <Col span={12}>
                  <Form.Item name="role" label="Role" rules={required}>
                    <Select
                      options={[
                        { label: 'Doctor', value: 'doctor' },
                        { label: 'Practice Manager', value: 'practice manager' }]}
                      disabled={isProfileView || isDisabledField || !isRoleEnough('admin') || disabled?.role}
                    />
                  </Form.Item>
                </Col>
              )}

            </Row>
            {hasPassword && (
              <Row gutter={15}>
                <Col span={12}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[...password]}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Type here"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[...required, ...confirmPassword]}
                    dependencies={['password']}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Type here"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {editMode && (
              <ControlsEditMode
                isEditing={isEditing}
                onCancel={handleCancel}
                onChangeEditing={setIsEditing}
                onSubmit={handleSubmit}
                isFormValid={typeof editMode.isFormValid !== 'undefined' ? editMode.isFormValid : true}
                loading={editMode.loading}
              />
            )}
          </div>
        </Space>
      </ContentCard>
    </div>
  );
};

DoctorInfoForm.defaultProps = {
  disabled: undefined,
  isProfileView: false,
  admin: false,
  formType: 'update',
  cardLoading: false,
  editMode: undefined,
  message: undefined,
  modalPracticeId: undefined,
  hasPassword: false,
  practiceSelectOption: undefined,
};

export default DoctorInfoForm;
