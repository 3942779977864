import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { Space } from 'antd';
import SettingFilled from '@ant-design/icons/lib/icons/SettingFilled';
import UpdateForm from './UpdateForm';

const Personalization: React.FC = () => (
  <Content>
    <PageHeader
      className="profile-header-content"
      title={(
        <Space size={10}>
          <SettingFilled />
          Personalization
        </Space>
        )}
    />
    <UpdateForm />
  </Content>
);

export default Personalization;
