import { Form, FormInstance, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLabServicesGet, useLabServicesPublicGet } from '../../../../../../../hooks/services';
import { JsonResult } from '../../../../../../../types';
import ContentCard from '../../../../../../Common/ContentCard';
import ControlsEditMode from '../../../../../../Common/ControlsEditMode';
import LabStaffServiceRow from './Row';

interface LabStaffServices {
  form: FormInstance;
  labId?: string;
  isPublic?: boolean;
  initialValues?: JsonResult;
  editMode?: {
    onSubmit: () => void;
    onCancel?: () => void;
    loading?: boolean;
  };
}

const LabStaffServices: React.FC<LabStaffServices> = (props) => {
  const { form, labId, initialValues, editMode, isPublic } = props;
  const labServicesGet = useLabServicesGet();
  const labServicesPublicGet = useLabServicesPublicGet();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const handleCancel = () => {
    editMode?.onCancel?.();

    setIsEditing(false);
  };

  const handleFormSubmit = () => {
    editMode?.onSubmit();

    setIsEditing(false);
  };

  const fetch = () => {
    if (isPublic) {
      labServicesPublicGet.fetch({ lab: labId, isActive: true, orderBy: 'ASC', orderByColumn: 'id' });
    } else {
      labServicesGet.fetch({ lab: labId, isActive: true, orderBy: 'ASC', orderByColumn: 'id' });
    }
  };

  useEffect(() => {
    if (!labId) return;

    fetch();
  }, [labId]);

  useEffect(() => {
    if (!initialValues) return;

    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const labServicesData = isPublic ? labServicesPublicGet.data?.data : labServicesGet.data?.data;

  return (
    <div>
      {!!labServicesData && (
        <>
          <Typography.Title level={4}>Services</Typography.Title>
          <ContentCard>
            <Form
              form={form}
              name="lab-staff-services-form"
              layout="vertical"
            >
              {labServicesData?.map((service) => (
                <LabStaffServiceRow
                  isDisabled={editMode && !isEditing}
                  key={service.id}
                  id={service.id}
                  service={service.service}
                  materials={service.labMaterials?.filter((option) => option?.isActive)
                    ?.filter((option) => option?.material?.isActive)}
                  additionalOptions={service.labAdditionalOptions?.filter((option) => option?.isActive)
                    ?.filter((option) => option?.additionalOption?.isActive)}
                />
              ))}
            </Form>
            {editMode && (
              <ControlsEditMode
                isEditing={isEditing}
                onCancel={handleCancel}
                onChangeEditing={setIsEditing}
                onSubmit={handleFormSubmit}
                loading={editMode.loading}
                isFormValid
              />
            )}
          </ContentCard>
        </>
      )}
    </div>
  );
};

LabStaffServices.defaultProps = {
  labId: undefined,
  editMode: undefined,
  initialValues: undefined,
  isPublic: false,
};

export default LabStaffServices;
