import React from 'react';
import { UserRoles } from '../enums/user';
import { CaseStatus } from '../enums/case';

export interface JsonResult {
  // eslint-disable-next-line
  [key: string]: any;
}

export interface RequestResult {
  loading: boolean;
  error: Error | null;
}

export interface FetchError {
  message: string | string[];
}

export interface FetchSuccess {
  success: boolean;
}

export interface PagingDataResponse<I> {
  data: I[];
  meta: {
    page: number;
    take: number;
    itemCount: number;
    pageCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}

export interface Option {
  value: string | boolean | number;
  label: string | JSX.Element;
  id?: string | number;
}

export interface ModalState {
  type: ModalTypes;
  id?: number | string;
  role?: UserRoles;
  key?: string;
  onReload?: () => void;
}

export interface CaseModalState extends ModalState {
  caseStatus?: CaseStatus;
}

export interface DefaultModalProps {
  isOpen: boolean;
  close: () => void;
  onReload?: () => void;
}

export enum ModalTypes {
  'create' = 'create',
  'update' = 'update',
  'delete' = 'delete',
  'info' = 'info',
}

export interface Step {
  title: string | number;
  content: React.JSX.Element;
  isValid?: boolean;
}

export interface StoredFile {
  createdAt: string;
  id: string;
  mimeType: string;
  originalName: string;
  realPath?: string;

}
