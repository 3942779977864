import React, { useState } from 'react';
import { Col, Form, Input, Row, Select, Typography } from 'antd';
import { isRoleEnough } from '../../../../../../../utils/auth';

import ContentCard from '../../../../../../Common/ContentCard';
import ControlsEditMode, { PropEditMode } from '../../../../../../Common/ControlsEditMode';
import { confirmPassword, email, password, phoneNumber, required } from '../../../../../../../utils/inputRules';
import SelectLab from '../../../../../../Common/Selects/SelectLab';
import PhoneNumberInput from '../../../../../../Common/PhoneNumberInput';
import { UserRoles } from '../../../../../../../enums/user';

interface LabStaffInfoForm {
  disabled?: {
    [key: string]: boolean;
  };
  isProfileView?: boolean;
  admin?: boolean;
  cardLoading?: boolean;
  formType?: 'create' | 'update';
  editMode?: PropEditMode;
  hasPassword?: boolean;
  labId?: string | number;
}

const LabStaffInfoForm: React.FC<LabStaffInfoForm> = (props): JSX.Element => {
  const { disabled, isProfileView, formType, cardLoading, editMode, labId, hasPassword } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const isDisabledField = !!(editMode && !isEditing);

  const handleCancel = () => {
    if (!editMode) return;
    editMode.onCancel();

    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (!editMode) return;

    editMode.onSubmit();
    setIsEditing(false);
  };

  return (
    <div>
      {!isProfileView && (<Typography.Title level={4}>Personal Information</Typography.Title>)}
      <ContentCard loading={cardLoading}>
        <Row gutter={15}>
          <Form.Item name="id" style={{ height: 0, margin: 0 }}>
            <Input type="hidden" disabled />
          </Form.Item>
          <Form.Item name="userId" style={{ height: 0, margin: 0 }}>
            <Input type="hidden" disabled />
          </Form.Item>
          {isRoleEnough('lab manager') && (
          <Col span={formType === 'create' ? 12 : 24}>
            <Form.Item
              label="Lab"
              name="lab"
              rules={required}
            >
              <SelectLab disabled={isProfileView || !!labId || isDisabledField || disabled?.lab} onlyStatus="active" />
            </Form.Item>
          </Col>
          )}

          {formType === 'create' && (
          <Col span={12}>
            <Form.Item
              label="Position"
              name="position"
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField || disabled?.position}
              />
            </Form.Item>
          </Col>
          )}

          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={required}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField || disabled?.firstName}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={required}
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField || disabled?.lastName}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[...email, ...required]}
            >
              <Input
                placeholder="Type here"
                disabled={isProfileView || isDisabledField || disabled?.email}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Contact Phone"
              name="phone"
              rules={[...required, ...phoneNumber]}
            >
              <PhoneNumberInput disabled={isDisabledField || disabled?.phone} />
            </Form.Item>
          </Col>
          {formType === 'update' && (
          <Col span={labId ? 12 : 24}>
            <Form.Item
              label="Position"
              name="position"
            >
              <Input
                placeholder="Type here"
                disabled={isDisabledField || disabled?.position}
              />
            </Form.Item>
          </Col>
          )}
          {formType === 'update' && labId && (
          <Col span={12}>
            <Form.Item name="role" label="Role" rules={required}>
              <Select
                options={[
                  { label: 'Lab Staff', value: UserRoles.labStaff },
                  { label: 'Lab Manager', value: UserRoles.labManager }]}
                disabled={isProfileView || isDisabledField || !isRoleEnough('admin') || disabled?.role}
              />
            </Form.Item>
          </Col>
          )}
          {hasPassword && (
          <>
            <Col span={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[...password]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Type here"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[...required, ...confirmPassword]}
                dependencies={['password']}
                hasFeedback
              >
                <Input.Password
                  placeholder="Type here"
                />
              </Form.Item>
            </Col>
          </>
          )}
        </Row>
        {editMode && (
        <ControlsEditMode
          isEditing={isEditing}
          onCancel={handleCancel}
          onChangeEditing={setIsEditing}
          onSubmit={handleSubmit}
          isFormValid={typeof editMode.isFormValid !== 'undefined' ? editMode.isFormValid : true}
          loading={editMode.loading}
        />
        )}
      </ContentCard>
    </div>
  );
};

LabStaffInfoForm.defaultProps = {
  disabled: undefined,
  isProfileView: false,
  admin: false,
  formType: 'update',
  cardLoading: false,
  hasPassword: false,
  editMode: undefined,
  labId: undefined,
};

export default LabStaffInfoForm;
