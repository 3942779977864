import { Content } from 'antd/es/layout/layout';
import React, { useCallback, useEffect, useState } from 'react';

import { PageHeader } from '@ant-design/pro-layout';

import { App, Button, Collapse, Descriptions, Divider, Typography } from 'antd';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AddressStatus } from '../../../../enums/address';
import { PracticeStatuses } from '../../../../enums/practice';
import { Address } from '../../../../hooks/addresses';
import { Action } from '../../../../store';
import { Error } from '../../../../store/ducks/common';
import {
  moduleName as practiceModuleName,
  practiceUpdate as actionPracticeUpdate,
  PracticeUpdatePayload,
} from '../../../../store/ducks/practice';
import { moduleName as authModuleName, User } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { preparePracticeInitialData } from '../../../../utils/prepareData';
import Status from '../../../Common/Status';
import TableDoctors from './Doctor/Table';
import { ModalState, ModalTypes } from '../../../../types';
import { EyeClose, EyeOpen } from '../../../Common/Icon';
import DoctorInfoDrawer from './Doctor/DoctorInfo';
import PracticeInfo from './PracticeInfo';
import { usePracticeId } from '../../../../hooks/practices';
import { getMessageInError } from '../../../../hooks/fetch';
import { formatAddressToString, formatPhoneNumber } from '../../../../utils';
import { UserRoles } from '../../../../enums/user';
import DoctorCreateDrawer from './Doctor/Create';
import { isRoleEnough } from '../../../../utils/auth';

const getAddressString = (addresses?: Address[], type?: string) => {
  const filteredAddresses = addresses?.filter((address) => address.status === AddressStatus.ACTIVE);
  const billingAddress = filteredAddresses?.find((address) => !address.asShipping);
  const shippingAddresses = filteredAddresses?.filter((address) => address.asShipping) || [];

  if (type === 'shipping' && !billingAddress?.sameAsBilling) {
    return formatAddressToString(shippingAddresses[0]);
  }

  return formatAddressToString(billingAddress);
};

interface PracticeProfile {
  practiceLoading: boolean;
  error: Error | null;
  practiceUpdate: (payload: PracticeUpdatePayload) => Action;
  user: User | null;
}

const Practices: React.FC<PracticeProfile> = (props) => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { id: paramsPracticeId = '' } = useParams<{ id: string; }>();
  const { practiceLoading, error, practiceUpdate, user } = props;

  const requestPracticeId = paramsPracticeId || user?.doctor?.practice?.id || 'id';

  const [modal, setModal] = useState<ModalState | null>(null);

  const [collapseState, setCollapseState] = useState<boolean>(false);
  const practiceById = usePracticeId();

  useEffect(() => {
    if (error?.message) {
      message.error(error.message, 5);
    }
  }, [error]);

  const initialData = preparePracticeInitialData(practiceById.data);

  const fetchPracticeId = () => {
    practiceById.fetch(undefined, requestPracticeId);
  };

  useEffect(() => {
    if (requestPracticeId && !practiceLoading && !error) {
      fetchPracticeId();
    }
  }, [requestPracticeId, practiceLoading]);

  useEffect(() => {
    if (practiceById.error) {
      message.error(getMessageInError(practiceById.error), 5);
      practiceById.clearError();
    }
  }, [practiceById.error]);

  const handleSubmit = (body: PracticeUpdatePayload) => {
    practiceUpdate({ id: requestPracticeId, ...body });
  };

  const openModal = useCallback((state: ModalState | null) => setModal(state), [setModal]);
  const closeModal = useCallback(() => setModal(null), [setModal]);

  return (
    <>
      <DoctorCreateDrawer
        isOpen={
          (modal?.type === ModalTypes.create && modal.role === UserRoles.doctor)
          || (modal?.type === ModalTypes.create && modal.role === UserRoles.practiceManager)
        }
        close={closeModal}
        isManager={modal?.role === UserRoles.practiceManager}
        onReload={modal?.onReload}
        withEmailForm
      />
      <DoctorInfoDrawer
        isOpen={modal?.type === ModalTypes.info && modal.role === UserRoles.doctor}
        close={closeModal}
        id={modal?.id || ''}
        onReload={modal?.onReload}
      />
      <Content>
        <PageHeader
          className="profile-header-content" // needed to add specific styles to header
          title={practiceById.data?.name || 'Practice Name'}
          extra={(
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              {isRoleEnough('admin') && (
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Typography.Text type="secondary">
                    ID:
                  </Typography.Text>
                  <Typography.Text>
                    {practiceById.data?.accountNumber || '-'}
                  </Typography.Text>
                </div>
              )}
              <Status status={practiceById.data?.status || PracticeStatuses.active} />
            </div>
          )}
          onBack={paramsPracticeId ? () => navigate('/practices', { replace: true }) : undefined}
          footer={(
            <Collapse
              ghost
              bordered={false}
              accordion
              activeKey={collapseState ? 'showHideInfo' : 'table'}
              // onChange={(e) => setCollapseState(!!e)}
            >
              <Collapse.Panel
                key="showHideInfo"
                showArrow={false}
                extra={(
                  <Button
                    ghost
                    icon={collapseState ? <EyeClose /> : <EyeOpen />}
                    onClick={() => setCollapseState(!collapseState)}
                    style={{ width: '120px', display: 'flex', alignItems: 'center' }}
                    loading={practiceById.loading}
                    id="toggleShowHideBtn"
                  >
                    <span style={{ width: '70px' }}>{collapseState ? 'Hide Info' : 'Show Info'}</span>
                  </Button>
                )}
                header={(
                  <Descriptions
                    size="small"
                    column={2}
                    labelStyle={{ color: 'var(--color-main-dark-gray)', opacity: '0.7', fontSize: '14px' }}
                    contentStyle={{ color: 'var(--color-main-dark-gray)', fontWeight: '500', fontSize: '15px' }}
                  >
                    <Descriptions.Item label="Email">
                      {practiceById.data?.email || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Billing Address">
                      {getAddressString(practiceById.data?.addresses, 'billing')}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone">
                      {practiceById.data?.phone ? formatPhoneNumber(practiceById.data?.phone) : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Shipping address">
                      {getAddressString(practiceById.data?.addresses, 'shipping')}
                    </Descriptions.Item>
                  </Descriptions>
                )}
              >
                <Divider />
                <PracticeInfo
                  loading={practiceLoading}
                  initialData={initialData}
                  handleSubmit={handleSubmit}
                  reloadData={fetchPracticeId}
                />
              </Collapse.Panel>
              <Collapse.Panel
                key="table"
                header=""
                showArrow={false}
              >
                <TableDoctors openModal={openModal} practiceId={requestPracticeId} />
              </Collapse.Panel>
            </Collapse>
          )}
        />
      </Content>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  practiceLoading: state[practiceModuleName].loading,
  error: state[practiceModuleName].error,
  user: state[authModuleName].user,
});

const mapDispatchToProps = {
  practiceUpdate: actionPracticeUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(Practices);
